import { api } from "../ModuloGerenciador/UrlPadrao"

export const insereSMP = async (embarque, token) => {
  return api.put("/ApiSul/" + embarque,
    null
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const buscaSMP = async (idEmbarque, token) => {
  return api.put("/ApiSul/buscaSmp/" + idEmbarque,
  {},
    {
      headers: {
        'Authorization': token
      }
    })
}
import { api } from "./UrlPadrao"

export const cadastraGrupoCarga = async (grupoCarga, token) => {
  return api.post("GrupoCarga",
    grupoCarga
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoCarga = async (token) => {
  return api.get("GrupoCarga",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoCargaPorId = async (id, token) => {
  return api.get("GrupoCarga/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteGrupoCargaPorId = async (id, token) => {
  return api.delete("GrupoCarga/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaGrupoCargaPorId = async (grupoCarga, token) => {
  return api.put("GrupoCarga",
    grupoCarga,
  {
    headers: {
      'Authorization': token
    }
  })
}
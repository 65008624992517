import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import "./Solicitacoes.css";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import Tabela from "../../../components/Tabela/Tabela";
import TabelaRedux from "../../../contexts/Tabela";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import MenusRedux from "../../../contexts/Menus";
import { getSolicitacao } from "../../../services/ModuloGerenciador/Solicitacao";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";

function Solicitacoes() {

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [editando, setEditando] = useState(false);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { menusRedux, setDadosMenusRedux } = useContext(MenusRedux);

    const [loading, setLoading] = useState(false);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const buscarSolicitacoes = async () => {
        setLoading(true);
        await getSolicitacao(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    useEffect(() => {
        const camposFormatados = JSON.stringify(menusRedux).replace(/"/g, `'`)
        buscarSolicitacoes();
    }, [])

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Usuario') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Usuario') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    })

    useEffect(() => {
        if (dadosRequestControllerRedux === "Solicitacao Aceita") {
            buscarSolicitacoes();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Solicitação aceita com sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Solicitacao Recusada") {
            buscarSolicitacoes();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Solicitação recusada com sucesso");
            setOpenToastSucesso(true);
        }
    }, [dadosRequestControllerRedux])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV2 selecionado="Solicitações" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV2 selecionado="Solicitações" />
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div className="embarques">
                                    <div className="header-cliente-v2">
                                        <div className="flex">
                                            <p className="indicador-principal-embarque-v2">Solicitações</p>
                                        </div>
                                    </div>
                                    <TabelaV2
                                        classeSelecionado="usuarioId"
                                        nomeClasse1="outer-wrapper-rota"
                                        nomeClasse2="table-wrapper-v2"
                                        colunas={dadosTabelaRedux.Solicitacoes}
                                        dados={dadosTabela} />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Solicitacoes;
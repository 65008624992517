import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Select from 'react-select';
import '../Select/Select.css';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import '../SelectEmbarqueV2/SelectEmbarqueV2.css';

function SelectEmbarqueV2(props) {

    const [open] = useState(props.open);

    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    const [atual, setAtual] = useState();

    const [disable, setDisable] = useState(false);

    const [filtro, setFiltro] = useState('');

    const filtra = () => {
        var dadosfiltra = props.dadosSelect;
        dadosfiltra.sort(function (a, b) {
            if (a.cidade < b.cidade || a.nome < b.nome || a.nomeFantasia < b.nomeFantasia) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltrados(dadosfiltra);
    }

    useEffect(() => {
        filtra();
        if (props.value) {
            setAtual(props.value.value);
        }
    }, [])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
            zIndex: 999,
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
            zIndex: 999,
        }),
    }

    const customStylesErro = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid red',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const customStylesGrande = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const customStylesGrandeErro = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid red',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const dadosOrdenados = dadosFiltrados.map(item => ({
        value: item[props.referenciaId],
        label: props.referenciaItem2 ? item[props.referenciaItem][props.referenciaItem2] : item[props.referenciaItem],
        id: item[props.id],
    }));

    const handleChange = option => {
        setAtual(option.value);
        props.onChange(option);
    };

    const formatOptionLabel = ({ label, value, id }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {label}
            {atual !== value && props.info !== false &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTimeout(() => {
                            props.onInfoIconClick(id);
                            props.onClick(e);
                        }, 0);
                    }}
                />
            }
            {atual === value && props.info !== false &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.clearSelection();
                        setAtual(null);
                    }} />
            }
        </div>
    );

    return (
        <div className='principal-select'>
            <FormControl sx={{ width: props.classeCss ? props.classeCss : 250 }}>
                {!props.erro ? (
                    <Select
                        value={props.value}
                        onChange={handleChange}
                        styles={props.grande ? customStylesGrande : customStyles}
                        options={dadosOrdenados}
                        isDisabled={props.disabled}
                        formatOptionLabel={formatOptionLabel}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Não há mais opções"}
                        isMulti={props.multi}
                    />
                ) : (
                    <Select
                        value={props.value}
                        onChange={handleChange}
                        styles={props.grande ? customStylesGrandeErro : customStylesErro}
                        options={dadosOrdenados}
                        isDisabled={props.disabled}
                        formatOptionLabel={formatOptionLabel}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Não há mais opções"}
                        isMulti={props.multi}
                    />
                )}
            </FormControl>
        </div>
    );
}

export default SelectEmbarqueV2;
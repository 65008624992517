import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraNotificacao = async (notificacao, token) => {
    return api.post("notificacao",
        notificacao
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const visualizaNotificacao = async (usuarioId, notificaoId, token) => {
    return api.post("notificacao/visualizaNotificacao/" + notificaoId + '/' + usuarioId,
        null,
        {
            headers: {
                'Authorization': token
            }
        })
}


export const getNotificacao = async (token) => {
    return api.get("notificacao",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getNotificacaoTodos = async (token) => {
    return api.get("notificacao/todos",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getNotificacaoPorId = async (id, token) => {
    return api.get("notificacao/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getNotificacaoPorUsuario = async (id, token) => {
    return api.get("notificacao/buscanMensagem/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}
import { api } from "./UrlPadrao"

export const enviarProposta = async (lista, token) => {
  return api.post("PropostaMotoProp/Lista",
  lista
  , {
    headers: {
      'Authorization': token
    }
  })
}
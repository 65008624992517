import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalSolucao/ModalSolucao.css';
import CustomButton from '../CustomButton/CustomButton.js';
import CoresRedux from "../../contexts/Cor.js";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2.js";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2.js";
import TabelaRedux from "../../contexts/Tabela.js";
import LoadingBar from "../LoadingBar/LoadingBar.js";
import NewIcon from "../NewIcon/NewIcon.js";
import ModalCadastro from "../ModalCadastro/ModalCadastro.tsx";
import RequestControllerRedux from "../../contexts/RequestController.js";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    texto: string;
    open: boolean;
    tituloInput: string;
    onClose: () => void;
    onClick: () => void;
    setTextoSolucao: (e) => void;
}

function ModalSolucao(props: SimpleDialogProps) {

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-ontime'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-ontime">
                        <DialogContentText id="alert-dialog-description">
                            <div className="coluna-modalmotivo">
                                <div>
                                    <p className="input-label-emb">{props.tituloInput}</p>
                                    <input
                                        type="text"
                                        className="input-emb"
                                        style={{ width: '500px' }}
                                        onChange={(e) => {
                                            props.setTextoSolucao(e.target.value);
                                        }} />
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="action-ontime">
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="Registrar"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalSolucao;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraTipoRastreamento = async (tipoRastreamento, token) => {
  return api.post("TipoRastreamento",
    tipoRastreamento
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoRastreamento = async (token) => {
  return api.get("TipoRastreamento",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoRastreamentoPorId = async (id, token) => {
  return api.get("TipoRastreamento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTipoRastreamentoPorId = async (id, token) => {
  return api.delete("TipoRastreamento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTipoRastreamentoPorId = async (tipoRastreamento, token) => {
  return api.put("TipoRastreamento",
    tipoRastreamento,
  {
    headers: {
      'Authorization': token
    }
  })
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraCliente = async (data, token) => {
  return api.post("cliente",
    data
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const cadastraClienteComEndereco = async (data, token) => {
  return api.post("cliente/clienteComEndereco",
    data
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getCliente = async (token) => {
  return api.get("cliente",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getClientePorId = async (id, token) => {
  return api.get("cliente/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteClientePorId = async (id, token) => {
  return api.delete("cliente/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaClientePorId = async (data, token) => {
  return api.put("cliente",
    data,
    {
      headers: {
        'Authorization': token
      }
    })
}


export default api;
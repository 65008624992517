export const tenantData = [
  {
    titulo: 'Trans Scalcon',
    tenant: 'transscalcon',
  },
  {
    titulo: 'Demonstração',
    tenant: 'demonstracao',
  },
];

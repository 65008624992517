import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraFotoUsuario = async (id, midia, token) => {
    return api.post("Midia/editar/fotoUsuario/" + id,
        midia
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const getMidiaIdUsuario = async (id, token) => {
    return api.get("Midia/buscarPorIdUsuario/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraTipoCarroceria = async (tipoCarroceria, token) => {
  return api.post("tipoCarroceria",
    tipoCarroceria
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoCarroceria = async (token) => {
  return api.get("tipoCarroceria",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoCarroceriaPorId = async (id, token) => {
  return api.get("tipoCarroceria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTipoCarroceriaPorId = async (id, token) => {
  return api.delete("tipoCarroceria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTipoCarroceriaPorId = async (tipoCarroceria, token) => {
  return api.put("tipoCarroceria",
    tipoCarroceria,
  {
    headers: {
      'Authorization': token
    }
  })
}
import React from 'react';
import Select from 'react-select';
import '../SelectEstado/SelectEstado.css';
import Text from '../Text/Text';

function SelectEstado(props) {

    const names = [
        { value: 'AC', label: 'AC' },
        { value: 'AL', label: 'AL' },
        { value: 'AM', label: 'AM' },
        { value: 'AP', label: 'AP' },
        { value: 'BA', label: 'BA' },
        { value: 'CE', label: 'CE' },
        { value: 'DF', label: 'DF' },
        { value: 'ES', label: 'ES' },
        { value: 'GO', label: 'GO' },
        { value: 'MA', label: 'MA' },
        { value: 'MG', label: 'MG' },
        { value: 'MS', label: 'MS' },
        { value: 'MT', label: 'MT' },
        { value: 'PA', label: 'PA' },
        { value: 'PB', label: 'PB' },
        { value: 'PE', label: 'PE' },
        { value: 'PI', label: 'PI' },
        { value: 'PR', label: 'PR' },
        { value: 'RJ', label: 'RJ' },
        { value: 'RN', label: 'RN' },
        { value: 'RO', label: 'RO' },
        { value: 'RR', label: 'RR' },
        { value: 'RS', label: 'RS' },
        { value: 'SC', label: 'SC' },
        { value: 'SE', label: 'SE' },
        { value: 'SP', label: 'SP' },
        { value: 'TO', label: 'TO' },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            width: '270px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '270px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    return (
        <div className='principal-select-estado'>
            <Text texto={props.titulo} />
            <Select
                styles={customStyles}
                options={names}
                value={names.find(option => option.value === props.value)}
                onChange={props.onChange}
                placeholder="Selecione"
            />
        </div>
    );
}

export default SelectEstado;
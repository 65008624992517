import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraRota = async (rota, token) => {
  return api.post("rota",
    rota
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getRota = async (token) => {
  return api.get("rota",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getRotaPorId = async (id, token) => {
  return api.get("rota/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteRotaPorId = async (id, token) => {
  return api.delete("rota/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaRotaPorId = async (rota, token) => {
  return api.put("rota",
    rota,
  {
    headers: {
      'Authorization': token
    }
  })
}
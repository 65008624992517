import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalLancarCTE/ModalLancarCTE.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import { valorMask } from "../../regex/valorMask";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    onClose: () => void;
    onClick: () => void;
    setCTE: (e) => void;
}

function ModalLancarCTE(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const [values, setValues] = useState<any>([]);

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    useEffect(() => {
        setValues(current => ({ ...current, cte: tabelaRedux?.row?.cte }));
    }, [tabelaRedux]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className="usuario-data">
                                <input
                                    maxLength={5}
                                    placeholder='00000'
                                    type="text"
                                    className="input-emb"
                                    style={{ width: '172px' }}
                                    onChange={(e) => {
                                        let valor = e.target.value;
                                        valor = valor.replace(/\D/g, '');
                                        setValues(current => ({ ...current, cte: valor }));
                                        props.setCTE(valor);
                                    }}
                                    value={valorMask(values.cte)} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalLancarCTE;
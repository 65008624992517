import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraProprietario = async (empresaSub, token) => {
  return api.post("Proprietario",
    empresaSub
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getProprietario = async (token) => {
  return api.get("Proprietario",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getRelatorioProprietarios = async (token, contexto, ordenarPor, pesquisa) => {
  return api.get("Proprietario/relatorioProprietarios/" + contexto + "?" + ordenarPor,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getProprietarioPorId = async (id, token) => {
  return api.get("Proprietario/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteProprietarioPorId = async (id, token) => {
  return api.delete("Proprietario/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaProprietarioPorId = async (empresaSub, token) => {
  return api.put("Proprietario",
    empresaSub,
    {
      headers: {
        'Authorization': token
      }
    })
}

export default api;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const getAlertaTodos = async (token) => {
    return api.get("alerta/todos",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getAlertaId = async (token, id) => {
    return api.get("alerta/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getAlerta = async (token) => {
  return api.get("alerta",
      {
          headers: {
              'Authorization': token
          }
      })
}

export const getAlertaStatus = async (token, status) => {
  return api.get("alerta/" + status,
      {
          headers: {
              'Authorization': token
          }
      })
}

export const getAlertaPorStatus = async (token, status) => {
    return api.get("alerta/buscarPorStatus/" + status,
        {
            headers: {
                'Authorization': token
            }
        })
  }

export const atribuirAlerta = async (token, alertaId, usuarioId) => {
    return api.post("alerta/atribuir/" + alertaId + "/" + usuarioId,
        null
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const passarFaseAlerta = async (token, id) => {
  return api.post("alerta/passarFase/" + id,
      null
      , {
          headers: {
              'Authorization': token
          }
      })
}

export const voltarFaseAlerta = async (token, id) => {
  return api.post("alerta/voltarFase/" + id,
      null
      , {
          headers: {
              'Authorization': token
          }
      })
}

export const resolucaoAlerta = async (token, post) => {
    return api.put("alerta/adicionaResolucao",
        post,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const aprovaAlerta = async (token, post) => {
  return api.post("alerta/aprova",
      post,
      {
          headers: {
              'Authorization': token
          }
      })
}

export const reprovaAlerta = async (token, post) => {
  return api.post("alerta/reprova",
      post,
      {
          headers: {
              'Authorization': token
          }
      })
}
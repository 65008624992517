import { api } from "./UrlPadrao"

export const cadastraSubTipoOcorrencia = async (subTipoOcorrencia, token) => {
  return api.post("SubTipoOcorrencia",
  subTipoOcorrencia
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getSubTipoOcorrencia = async (token) => {
  return api.get("SubTipoOcorrencia",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getSubTipoOcorrenciaPorId = async (id, token) => {
  return api.get("SubTipoOcorrencia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteSubTipoOcorrenciaPorId = async (id, token) => {
  return api.delete("SubTipoOcorrencia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaSubTipoOcorrenciaPorId = async (subTipoOcorrencia, token) => {
  return api.put("SubTipoOcorrencia",
  subTipoOcorrencia,
  {
    headers: {
      'Authorization': token
    }
  })
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraGrupoClientes = async (grupoClientes, token) => {
  return api.post("grupoClientes",
    grupoClientes
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoClientes = async (token) => {
  return api.get("grupoClientes",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoClientesPorId = async (id, token) => {
  return api.get("grupoClientes/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteGrupoClientesPorId = async (id, token) => {
  return api.delete("grupoClientes/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaGrupoClientesPorId = async (grupoClientes, token) => {
  return api.put("grupoClientes",
    grupoClientes,
  {
    headers: {
      'Authorization': token
    }
  })
}
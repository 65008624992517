import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import { InputLabel } from "@mui/material";
import "../CadastrarUsuario/CadastrarUsuario.css";
import Box from '@mui/material/Box';
import CoresRedux from "../../../contexts/Cor";
import DadosUsuarioRedux from "../../../../src/contexts/Usuario";
import ToastSucesso from "../../../../src/components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../src/components/Toast/ToastErro/ToastErro.tsx";
import { saveUser, getUser, getUserId, editaUser, deletaUser, atualizaRegra, resetSenhaUser } from "../../../../src/services/ModuloGerenciador/Usuario";
import { getRegras } from "../../../../src/services/ModuloGerenciador/Regras";
import { telefoneMask } from '../../../../src/regex/telefoneMask';
import LoadingComponent from "../../../../src/components/LoadingComponent/LoadingComponent";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import TabelaRedux from "../../../contexts/Tabela";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import MenusRedux from "../../../contexts/Menus";
import { getUsuarioDependencias } from "../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";

function CadastrarUsuario() {

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [editando2, setEditando2] = useState(false);

    const [open, setOpen] = useState(false);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const [openResetSenha, setOpenResetSenha] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const handleCloseResetSenha = (value) => {
        setOpenResetSenha(false);
    };

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { menusRedux, setDadosMenusRedux } = useContext(MenusRedux);

    const [values, setValues] = useState([]);

    const [regras, setRegras] = useState([]);

    const [loading, setLoading] = useState(false);

    const [regrasCheck, setRegrasCheck] = useState([]);

    const [regrasEdit, setRegrasEdit] = useState([]);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [regrasEditada, setRegrasEditada] = useState([]);

    const [listaRegrasSalvar, setListaRegrasSalvar] = useState([]);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const buscarDependencias = async () => {
        setLoading(true);
        await getUsuarioDependencias(dadosUsuarioRedux.token).then((result) => {
            setRegras(result.data.regras);
            setDadosTabela(result.data.usuarios);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscaUsuarios = async () => {
        setLoading(true);
        await getUser(dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setDadosTabela(result.data);
                setTabelaRedux({});
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        setLoading(true);
        values.regras.splice(0, 1);
        await saveUser(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDependencias();
                setTextoSucesso("Sucesso ao Cadastrar o Usuário!");
                setOpenToastSucesso(true);
                setValues([]);
            } else {
                setLoading(false);
                setTextoErro("Erro ao Cadastrar o Usuário!")
                setOpenToastErro(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscaRegras = async () => {
        await getRegras(dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setRegras(result.data)
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const editar = async () => {
        setLoading(true);
        let newBody = {
            idUsuario: values.idUsuario,
            usuario: values.usuario,
            senha: "",
            email: values.email,
            telefone: values.telefone,
            nascimento: values.nascimento,
            data: values.data,
            deletado: false
        };
        await editaUser(newBody, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                atualizaRegra(listaRegrasSalvar, newBody.idUsuario, dadosUsuarioRedux.token).then((resultado) => {
                    if (resultado.status === 200) {
                        setTabelaRedux({});
                        setTextoSucesso("Editado Com Sucesso");
                        setOpenToastSucesso(true);
                        setLoading(false);
                        window.location.reload();
                    }
                }).catch((error) => {
                    console.error(error);
                    setLoading(false);
                    if (error.response.data.lenght > 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                });
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarUsuarioPorId = async () => {
        setLoading(true);
        await getUserId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    var todayEm = new Date(result.data.nascimento);
                    var ddPrEn = String(todayEm.getDate()).padStart(2, '0');
                    var mmPrEn = String(todayEm.getMonth() + 1).padStart(2, '0');
                    var yyyyPrEn = todayEm.getFullYear();
                    todayEm = yyyyPrEn + '-' + mmPrEn + '-' + ddPrEn;
                    setValues(current => ({ ...current, nascimento: todayEm }))
                    comparaRegras(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const comparaRegras = async (valor) => {
        let lista = [];
        let lista2 = [];
        regras.forEach((dado, index) => {
            valor.regras.forEach((data) => {
                if (dado.regra === data) {
                    regras[index] = [{ regrasId: dado.regrasId, regra: dado.regra, deletado: dado.deletado, check: true }][0];
                    lista2.push(dado.regrasId);
                }
                if (dado.regra === data) {
                    lista.push(true);
                }
            })
        })
        setRegrasEditada({ regras: lista2 });
        setRegrasEdit(lista);
        setRegras(regras);
        setRegrasCheck(regras);
    }

    const deletarEmbarcadorPorId = async () => {
        setLoading(true);
        await deletaUser(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDependencias();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const resataSenha = async () => {
        setLoading(true);
        await resetSenhaUser(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDependencias();
                setLoading(false);
                setTabelaRedux({});
                setOpenToastSucesso(true);
                setTextoSucesso("Senha Redefinida com Sucesso!");
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!editando) setValues(current => ({ ...current, regras: [0] }))
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarUsuarioPorId();
        }
    }, [editando]);

    useEffect(() => {
        const camposFormatados = JSON.stringify(menusRedux).replace(/"/g, `'`)
        setValues(current => ({ ...current, colunas: camposFormatados }));
        buscarDependencias();
    }, [])

    useEffect(() => {
        setEditando2(false);
    }, [editando2])

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Usuario') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                if (result.status === 200) {
                    let regras = [];
                    result.data.forEach((dado) => {
                        regras.push(dado.regra)
                        if (dado.regra === 'Usuario') {
                            setSemAutorizacao(false);
                        }
                    });
                    dadosUsuarioRedux.regras = regras;
                    setDadosUsuarioRedux(dadosUsuarioRedux);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    });

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV2 selecionado="Usuários" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV2 selecionado="Usuários" />
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Usuários</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idUsuario"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.UsuarioGeren}
                                                dados={dadosTabela}
                                                breakTabela="table-break"
                                                breakTd="td-break" />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Resetar Senha"
                                                        width="200px"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenResetSenha(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="indicadores">
                                                <p className="indicador-emb">{"Dados de Usuário >"}</p>
                                                <p className="indicador-emb">{"Cadastro de Usuário"}</p>
                                            </div>
                                            <div className="containerCadastra">
                                                <p className="text">Dados Usuário</p>
                                                <div style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
                                                    <p className="input-label-emb" htmlFor="descricao">Nome Usuário:</p>
                                                    <input
                                                        id="nomeUsuario"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '250px' }}
                                                        onChange={(e) => setValues(current => ({ ...current, usuario: e.target.value }))}
                                                        disabled={true}
                                                        value={values.usuario}
                                                    />
                                                    <p className="input-label-emb" htmlFor="descricao">E-Mail:</p>
                                                    <input
                                                        disabled
                                                        id="email"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '250px' }}
                                                        onChange={(e) => setValues(current => ({ ...current, email: e.target.value }))}
                                                        value={values.email}
                                                    />
                                                    <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                                    <input
                                                        id="telefone"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '250px' }}
                                                        onChange={(e) => setValues(current => ({ ...current, telefone: e.target.value }))}
                                                        value={telefoneMask(values.telefone)}
                                                    />
                                                    <p className="input-label-emb" htmlFor="descricao">Nascimento:</p>
                                                    <input
                                                        id="nascimento"
                                                        type="date"
                                                        className="input-emb"
                                                        style={{ width: '250px' }}
                                                        onChange={(e) => setValues(current => ({ ...current, nascimento: e.target.value }))}
                                                        value={values.nascimento}
                                                    />
                                                </div>
                                                <div className="containerPermissao">
                                                    <h3>Permissões:</h3>
                                                    <p>Para atualizar uma permissão, apenas selecione!</p>

                                                    {
                                                        !editando2 ? (
                                                            <>
                                                                {regras.map((dado, index) => (
                                                                    <Box display={'flex'} key={dado.regrasId}>
                                                                        <input
                                                                            type={'checkbox'}
                                                                            checked={dado.check}
                                                                            onChange={(e) => {
                                                                                let parametro = dado.regrasId;
                                                                                if (editando) {
                                                                                    if (e.target.checked) {
                                                                                        setListaRegrasSalvar([...listaRegrasSalvar, dado.regrasId]);
                                                                                        let result = listaRegrasSalvar.includes(parametro);
                                                                                        if (result === true) {
                                                                                            let teste = listaRegrasSalvar.filter(item => item !== parametro);
                                                                                            setListaRegrasSalvar(teste);
                                                                                        } else {
                                                                                            setListaRegrasSalvar([...listaRegrasSalvar, dado.regrasId]);
                                                                                        }
                                                                                        setEditando2(true);
                                                                                        regrasEdit[index] = e.target.checked;
                                                                                        dado.check = e.target.checked;
                                                                                    } else {
                                                                                        const result = listaRegrasSalvar.includes(parametro);
                                                                                        if (result === true) {
                                                                                            let teste = listaRegrasSalvar.filter(item => item !== parametro);
                                                                                            setListaRegrasSalvar(teste);
                                                                                        } else {
                                                                                            setListaRegrasSalvar([...listaRegrasSalvar, dado.regrasId]);
                                                                                        }
                                                                                        setEditando2(true);
                                                                                        regrasEdit[index] = e.target.checked;
                                                                                        dado.check = e.target.checked;
                                                                                    }
                                                                                } else {
                                                                                    if (e.target.checked) {
                                                                                        setValues(current => ({ ...current, regras: [...values.regras, dado.regrasId] }));
                                                                                    } else {
                                                                                        let lista = values.regras.splice(values.regras.indexOf(dado.regrasId));
                                                                                        setValues(current => ({ ...current, regras: lista }));
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                        </input>{dado.regra}
                                                                    </Box>
                                                                ))}</>
                                                        ) : (
                                                            <>
                                                                {regras.map((dado, index) => (
                                                                    <Box display={'flex'} key={dado.regrasId}>
                                                                        <input
                                                                            type={'checkbox'}
                                                                            checked={dado.check}
                                                                            onChange={(e) => {
                                                                                if (editando) {
                                                                                    let listaRegrasEditada = regrasEditada.regras;
                                                                                    if (e.target.checked) {
                                                                                        listaRegrasEditada.push(dado.regrasId);
                                                                                        setEditando2(true);
                                                                                        regrasEdit[index] = e.target.checked;
                                                                                    } else {
                                                                                        listaRegrasEditada.splice(listaRegrasEditada.indexOf(dado.regrasId), 1);
                                                                                        setEditando2(true);
                                                                                        regrasEdit[index] = e.target.checked;
                                                                                    }
                                                                                } else {
                                                                                    if (e.target.checked) {
                                                                                        setValues(current => ({ ...current, regras: [...values.regras, dado.regrasId] }));
                                                                                    } else {
                                                                                        let lista = values.regras.splice(values.regras.indexOf(dado.regrasId));
                                                                                        setValues(current => ({ ...current, regras: lista }));
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                        </input>{dado.regra}
                                                                    </Box>
                                                                ))}
                                                            </>
                                                        )
                                                    }


                                                </div>
                                            </div>
                                            <div className="botoes-cidade">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => {
                                                                editar();
                                                                setTelaCadastro(false);
                                                                setEditando(false);
                                                                setValues({});
                                                            }}
                                                        />
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { cadastrar(); setTelaCadastro(false); setValues({}); }}
                                                        />
                                                    )
                                                }
                                                <CustomButtonV2
                                                    classCss="custom-button-cancelar"
                                                    classText="button-texto-cancelar"
                                                    titulo="Cancelar"
                                                    cor="#0946B8"
                                                    valor={values}
                                                    // eslint-disable-next-line no-restricted-globals
                                                    onClick={() => { location.reload(); }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarEmbarcadorPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Você irá reiniciar a senha desse usuario para: 12345678  Você tem certeza disso?"
                                        tituloConfirmar="OK"
                                        textoBotao="Confirmar"
                                        open={openResetSenha}
                                        onClose={handleCloseResetSenha}
                                        onClick={resataSenha}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default CadastrarUsuario;
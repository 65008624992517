import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraAvisos = async (aviso, token) => {
  return api.post("aviso",
    aviso
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getAvisos = async (token) => {
  return api.get("aviso",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getAvisosTelaInicial = async (token) => {
  return api.get("aviso/telaInicial",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getAvisosPorId = async (id, token) => {
  return api.get("aviso/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteAvisosPorId = async (id, token) => {
  return api.delete("aviso/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaAvisosPorId = async (aviso, token) => {
  return api.put("aviso",
    aviso,
  {
    headers: {
      'Authorization': token
    }
  })
}
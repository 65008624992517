import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraEmail = async (email, token) => {
    return api.post("email/",
        email
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const cadastraListaEmail = async (email, token) => {
    return api.post("email/enviaListaEmail",
        email
        , {
            headers: {
                'Authorization': token
            }
        })
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../../components/NavbarV2/NavbarV2";
import InputLabel from "@mui/material/InputLabel";
import "./CrudCheck.css";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import Tabela from "../../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "./../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import { EmbalagemModel } from "../../../../models/EmbalagemModel";
import Text from "../../../../components/Text/Text";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../../contexts/RequestController";
import { motion } from "framer-motion";
import { checkListCaminhaoTemplete, checkListCarretaTemplete, vinculaCaminhao, vinculaCarreta } from "../../../../services/ModuloChecklist/GerenciamentoFrota";
import { valorMask } from "../../../../regex/valorMask";
import ModalCadastro from "../../../../components/ModalCadastro/ModalCadastro.tsx";
import SelectEmbarque from "../../../../components/SelectEmbarque/SelectEmbarque";
import TextLink from "../../../../components/TextLink/TextLink";
import { Box, FormControl, LinearProgress, MenuItem, Select } from "@mui/material";
import ReactSelect from 'react-select';
import { getGeralCheck } from "../../../../services/ModuloChecklist/Geral";
import { deleteCheckagemPorId, editaCheckagemPorIdVinculo, getCheckagemPorId } from "../../../../services/ModuloChecklist/Checkagem";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon";
import LoadingBar from "../../../../components/LoadingBar/LoadingBar";
import SelectEmbarqueV2 from "../../../../components/SelectEmbarqueV2/SelectEmbarqueV2";
import NewIcon from "../../../../components/NewIcon/NewIcon";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";

function CrudCheckAdiciona(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState(new EmbalagemModel({}));

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [ehCarreta, setEhCarreta] = useState(false);

    const [ehCaminhao, setEhCaminhao] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [listaCheck, setListaCheck] = useState([]);

    const [listaTipoCheck, setListaTipoCheckagem] = useState([]);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [openCadastroTipoCheck, setOpenCadastroTipoCheck] = useState(false);

    const [disable, setDisable] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const [loadingSelectTipoCheck, setLoadingSelectTipoCheck] = useState(false);

    const [idFrota, setIdFrota] = useState(false);

    const [tipoManutencao] = useState(['Preventiva', 'Corretiva']);

    const handleCloseCadastroTipoCheck = () => {
        if (idAtual) {
            setOpenCadastroTipoCheck(false);
        } else {
            setLoadingSelectTipoCheck(true);
            buscarCheckagemSemLoading();
            setOpenCadastroTipoCheck(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = () => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = () => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = () => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const buscarCheckagemSemLoading = async () => {
        await getGeralCheck(dadosUsuarioRedux.token).then((result) => {
            setListaCheck(result.data.checkListTemplete);
            setListaTipoCheckagem(result.data.tipoCheck);
            setLoadingSelectTipoCheck(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }


    const cadastrar = async () => {
        if (tabelaRedux && tabelaRedux.row && tabelaRedux.row.idCaminhao) {
            if ((values.tipoManutencao !== "") && (validator.tipoManutencao === "true") &&
                (values.dados !== "") && (validator.dados === "true") &&
                (values.tipoCheck !== "") && (validator.tipoCheck === "true") &&
                (values.km !== "") && (validator.km === "true") &&
                (values.local !== "") && (validator.local === "true") &&
                (values.dataCheckagem !== "") && (validator.dataCheckagem === "true")) {
                setLoading(true);
                await vinculaCaminhao(values, tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
                    if (result.status === 200) {
                        buscarCheckPorIdEdita();
                        setDadosRequestControllerRedux("Fechar Modal Embalagem");
                        setTextoSucesso("Cadastrado Com Sucesso");
                        setOpenToastSucesso(true);
                    }
                }).catch((error) => {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                });
            } else {
                setTextoErro('Há campos sem preencher!');
                setOpenToastErro(true);
                setLoading(false);
            }
        } else {
            if ((values.tipoManutencao !== "") && (validator.tipoManutencao === "true") &&
                (values.dados !== "") && (validator.dados === "true") &&
                (values.tipoCheck !== "") && (validator.tipoCheck === "true") &&
                (values.km !== "") && (validator.km === "true") &&
                (values.local !== "") && (validator.local === "true") &&
                (values.dataCheckagem !== "") && (validator.dataCheckagem === "true")) {
                setLoading(true);
                await vinculaCarreta(values, tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
                    if (result.status === 200) {
                        buscarCheckPorIdEdita();
                        setDadosRequestControllerRedux("Fechar Modal Embalagem");
                        setTextoSucesso("Cadastrado Com Sucesso");
                        setOpenToastSucesso(true);
                    }
                }).catch((error) => {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                });
            } else {
                setTextoErro('Há campos sem preencher!');
                setOpenToastErro(true);
                setLoading(false);
            }
        }

    }

    const editar = async () => {
        setLoading(true);
        await editaCheckagemPorIdVinculo(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCheckPorIdEdita();
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCheckagem = async () => {
        setLoading(true);
        await getGeralCheck(dadosUsuarioRedux.token).then((result) => {
            setListaCheck(result.data.checkListTemplete);
            setListaTipoCheckagem(result.data.tipoCheck);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCheckPorId = async () => {
        setLoading(true);
        if (tabelaRedux && tabelaRedux.row && tabelaRedux.row.idCaminhao) {
            setEhCaminhao(true);
            await checkListCaminhaoTemplete(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
                setDadosTabela(result.data);
                setLoading(false);
            }).catch((error) => {
                if (!editando) {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                }
            });
        } else {
            setEhCarreta(true);
            await checkListCarretaTemplete(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
                setDadosTabela(result.data);
                setLoading(false);
            }).catch((error) => {
                if (!editando) {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                }
            });
        }
    }

    const buscarCheckPorIdEdita = async () => {
        setLoading(true);
        if (tabelaRedux && tabelaRedux.row && tabelaRedux.row.idCaminhao) {
            setEhCaminhao(true);
            await checkListCaminhaoTemplete(idFrota, dadosUsuarioRedux.token).then((result) => {
                setDadosTabela(result.data);
            }).catch((error) => {
                if (!editando) {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                }
            });
        } else {
            setEhCarreta(true);
            await checkListCarretaTemplete(idFrota, dadosUsuarioRedux.token).then((result) => {
                setDadosTabela(result.data);
            }).catch((error) => {
                if (!editando) {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                }
            });
        }
    }

    const buscarCheckagemPorId = async () => {
        setLoading(true);
        await getCheckagemPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    await preencherDatas(result.data);
                    await preencherDadosEditando(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarCheckagemPorId = async () => {
        setLoading(true);
        await deleteCheckagemPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCheckPorIdEdita();
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValues(current => ({ ...current, proximaRevisaoData: null }));
        setValues(current => ({ ...current, proximaRevisaoKM: null }));
    }, [loading]);

    useEffect(() => {
        setValidator(current => ({ ...current, tipoManutencao: "false" }));
        setValidator(current => ({ ...current, dados: "false" }));
        setValidator(current => ({ ...current, tipoCheck: "false" }));
        setValidator(current => ({ ...current, km: "false" }));
        setValidator(current => ({ ...current, local: "false" }));
        setValidator(current => ({ ...current, dataCheckagem: "false" }));
    }, [telaCadastro])

    useEffect(() => {
        if (editando) {
            buscarCheckagemPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarCheckPorId();
        setIdFrota(tabelaRedux.colunaSelecionada);
        buscarCheckagem();
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        if (tabelaRedux.colunaSelecionada === "") {
            navigate('/adicionar-check-veiculo');
        }
    });

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Gerenciamento de Frota') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Gerenciamento de Frota') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const separarPorTipo = (dados) => {
        return dados.reduce((acc, item) => {
            if (!acc[item.tipoCheck.nome]) {
                acc[item.tipoCheck.nome] = [];
            }
            acc[item.tipoCheck.nome].push(item);
            return acc;
        }, {});
    }

    const [dadosPorTipo, setDadosPorTipo] = useState({});

    useEffect(() => {
        if (dadosTabela.length > 0) {
            setDadosPorTipo(separarPorTipo(dadosTabela));
            setLoading(false);
        }
    }, [dadosTabela]);

    const opcoesTemplate = listaCheck.map((data) => ({
        value: data.dados,
        label: data.dados,
        ...data
    }));


    const labelTemplate = (data) => (
        <div>
            {data.dados}
        </div>
    );

    const opcoesTipoManutencao = [
        {
            'opcao': 'Preventiva',
        },
        {
            'opcao': 'Corretiva',
        },
    ];

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = opcoesTipoManutencao.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const preencherDatas = async (result) => {
        var todayEm = new Date(result.dataCheckagem);
        var ddEm = String(todayEm.getDate()).padStart(2, '0');
        var mmEm = String(todayEm.getMonth() + 1).padStart(2, '0');
        var yyyyEm = todayEm.getFullYear();
        todayEm = yyyyEm + '-' + mmEm + '-' + ddEm;
        setValues(current => ({ ...current, dataCheckagem: todayEm }));
    };

    const preencherDadosEditando = async (result) => {
        const tipoCheckSelecionado = listaTipoCheck.find(option => option.idTipoCheck === result.tipoCheckId);
        if (tipoCheckSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoCheckId: {
                    value: tipoCheckSelecionado.nome,
                    label: tipoCheckSelecionado.nome,
                    id: tipoCheckSelecionado.idTipoCheck
                }
            }));
        }
    };

    const clearTipoCheckagem = () => {
        setValuesSelect(current => ({ ...current, tipoCheckId: '' }));
        setValues(current => ({ ...current, tipoCheckId: null }));
        setValidator(current => ({ ...current, tipoCheck: "false" }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Embalagem" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Embalagem" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div>
                                            {ehCaminhao && (
                                                <p className="indicador-principal">Checklist Caminhão</p>
                                            )}
                                            {ehCarreta && (
                                                <p className="indicador-principal">Checklist Carreta</p>
                                            )}
                                            {
                                                Object.keys(dadosPorTipo).length === 1
                                                    ? (
                                                        // código comentado
                                                        Object.keys(dadosPorTipo).map((tipo, index) => (
                                                            <div key={index}>
                                                                <h3>{tipo}</h3>
                                                                <TabelaV2
                                                                    setTelaCadastro={setTelaCadastro}
                                                                    setEditando={setEditando}
                                                                    classeSelecionado="idCheck"
                                                                    nomeClasse1="outer-wrapper-rota"
                                                                    nomeClasse2="table-wrapper-v2"
                                                                    colunas={dadosTabelaRedux.Checkagem}
                                                                    dados={dadosPorTipo[tipo]}
                                                                />
                                                            </div>
                                                        ))
                                                    )
                                                    : (
                                                        // código descomentado
                                                        Object.keys(dadosPorTipo).map((tipo, index, array) => {
                                                            if (index % 2 === 0) {
                                                                return (
                                                                    <div key={index} className="flexContainer">
                                                                        <div>
                                                                            <h3>{tipo}</h3>
                                                                            <TabelaV2
                                                                                setTelaCadastro={setTelaCadastro}
                                                                                setEditando={setEditando}
                                                                                classeSelecionado="idCheck"
                                                                                nomeClasse1="outer-wrapper-rota"
                                                                                nomeClasse2="table-wrapper-v2"
                                                                                colunas={dadosTabelaRedux.Checkagem}
                                                                                dados={dadosPorTipo[tipo]}
                                                                            />
                                                                        </div>

                                                                        {(array[index + 1]) && (
                                                                            <div>
                                                                                <h3>{array[index + 1]}</h3>
                                                                                <TabelaV2
                                                                                    setTelaCadastro={setTelaCadastro}
                                                                                    setEditando={setEditando}
                                                                                    classeSelecionado="idCheck"
                                                                                    nomeClasse1="outer-wrapper-rota"
                                                                                    nomeClasse2="table-wrapper-v2"
                                                                                    colunas={dadosTabelaRedux.Checkagem}
                                                                                    dados={dadosPorTipo[array[index + 1]]}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                    )
                                            }
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-historico"
                                                        classText="button-texto-user"
                                                        titulo="Voltar"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => { navigate("/adicionar-check-veiculo"); setTabelaRedux({}); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de CheckList >"}</p>
                                                        <p className="indicador-emb">{"Cadastro de CheckList"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de CheckList" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false);
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Usar Modelo:</p>
                                                                <FormControl sx={{ width: 250 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.template}
                                                                        options={opcoesTemplate}
                                                                        formatOptionLabel={labelTemplate}
                                                                        styles={estiloFrota}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            setValues({});
                                                                            setValuesSelect({});
                                                                            setLoading(true);
                                                                            if (selectedOption.dados != null) {
                                                                                setValidator(current => ({ ...current, dados: "true" }));
                                                                            }
                                                                            if (selectedOption.tipoCheck != null) {
                                                                                setValidator(current => ({ ...current, tipoCheck: "true" }));
                                                                            }
                                                                            if (selectedOption.km != null) {
                                                                                setValidator(current => ({ ...current, km: "true" }));
                                                                            }
                                                                            if (selectedOption.local != null) {
                                                                                setValidator(current => ({ ...current, local: "true" }));
                                                                            }
                                                                            if (selectedOption.dataCheckagem != null) {
                                                                                setValidator(current => ({ ...current, dataCheckagem: "true" }));
                                                                            }
                                                                            if (selectedOption.tipoManutencao != null) {
                                                                                setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                            }
                                                                            setTimeout(() => {
                                                                                if (selectedOption) {
                                                                                    setValuesSelect(current => ({ ...current, template: selectedOption }));
                                                                                    preencherDadosEditando(selectedOption);
                                                                                    for (let chave in selectedOption) {
                                                                                        if (selectedOption[chave] === null) {
                                                                                            setValues(current => ({ ...current, [chave]: " " }));
                                                                                        } else {
                                                                                            setValues(current => ({ ...current, [chave]: selectedOption[chave] }));
                                                                                        }
                                                                                    }
                                                                                    if (selectedOption.tipoManutencao === "Preventiva") {
                                                                                        setValues(current => ({ ...current, tipoManutencao: selectedOption.tipoManutencao }));
                                                                                        setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                                    } else {
                                                                                        setValues(current => ({ ...current, tipoManutencao: selectedOption.tipoManutencao }));
                                                                                        setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                                    }
                                                                                    preencherDatas(selectedOption);
                                                                                }
                                                                                setLoading(false);
                                                                            }, 200);
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Tipo de Manuteção:</p>
                                                                <FormControl sx={{ width: 250 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.tipoManutencao}
                                                                        options={opcoesFrota}
                                                                        formatOptionLabel={labelFrota}
                                                                        styles={estiloFrota}
                                                                        placeholder=""
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption.value === 'Preventiva') {
                                                                                setValues(current => ({ ...current, tipoManutencao: 'Preventiva' }));
                                                                                setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                            } else {
                                                                                setValues(current => ({ ...current, tipoManutencao: 'Corretiva' }));
                                                                                setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {/* <Select
                                                                    className='input-emb'
                                                                    variant="standard"
                                                                    id="tipoManutencao"
                                                                    style={{ width: 250 }}
                                                                    value={values.tipoManutencao}
                                                                    disabled={props.desabilitar}
                                                                >
                                                                    {tipoManutencao.map((data) => (
                                                                        <MenuItem
                                                                            key={data}
                                                                            value={data}
                                                                            onClick={() => {
                                                                                if (data === 'Preventiva') {
                                                                                    setValues(current => ({ ...current, tipoManutencao: 'Preventiva' }));
                                                                                    setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                                } else {
                                                                                    setValues(current => ({ ...current, tipoManutencao: 'Corretiva' }));
                                                                                    setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                                }
                                                                            }}
                                                                        >
                                                                            {data}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select> */}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Local:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, local: e.target.value }));
                                                                        setValidator(current => ({ ...current, local: "true" }));
                                                                    }}
                                                                    placeholder="Ex: Toledo"
                                                                    value={values.local}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {
                                                                    loadingSelectTipoCheck ? (
                                                                        <LoadingBar />
                                                                    ) : (
                                                                        <div className="coluna-emb">
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Tipo Checkagem:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={210}
                                                                                    onChange={(e) => {
                                                                                        setValuesSelect(current => ({ ...current, tipoCheckId: e }));
                                                                                        setValues(current => ({ ...current, tipoCheckId: e.id }));
                                                                                        setValidator(current => ({ ...current, tipoCheck: "true" }));
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroTipoCheck(true);
                                                                                    }}
                                                                                    clearSelection={clearTipoCheckagem}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="idTipoCheck"
                                                                                    grande={true}
                                                                                    value={valuesSelect.tipoCheckId}
                                                                                    titulo="TipoCheck"
                                                                                    dadosSelect={listaTipoCheck}
                                                                                    referenciaId="nome"
                                                                                    referenciaItem="nome" />
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroTipoCheck(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">KM:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, km: e.target.value }));
                                                                        setValidator(current => ({ ...current, km: "true" }));
                                                                    }}
                                                                    placeholder="Ex: 100000"
                                                                    value={valorMask(values.km)}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Data Checkagem:</p>
                                                                <input
                                                                    type="date"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, dataCheckagem: e.target.value }));
                                                                        setValidator(current => ({ ...current, dataCheckagem: "true" }));
                                                                    }}
                                                                    value={values.dataCheckagem}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, observacao: e.target.value }));
                                                                        setValidator(current => ({ ...current, observacao: "true" }));
                                                                    }}
                                                                    value={values.observacao}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Dados:</p>
                                                                <textarea
                                                                    type="text"
                                                                    className="input-emb-avisos"
                                                                    style={{ width: '100vh' }}
                                                                    rows="10"
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, dados: e.target.value }));
                                                                        setValidator(current => ({ ...current, dados: "true" }));
                                                                    }}
                                                                    value={values.dados}
                                                                    disabled={props.desabilitar}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-checkagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({}); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.tipoManutencao !== "") && (validator.tipoManutencao === "true") &&
                                                                                    (values.dados !== "") && (validator.dados === "true") &&
                                                                                    (values.tipoCheck !== "") && (validator.tipoCheck === "true") &&
                                                                                    (values.km !== "") && (validator.km === "true") &&
                                                                                    (values.local !== "") && (validator.local === "true") &&
                                                                                    (values.dataCheckagem !== "") && (validator.dataCheckagem === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.tipoManutencao !== "") && (validator.tipoManutencao === "true") &&
                                                                                    (values.dados !== "") && (validator.dados === "true") &&
                                                                                    (values.tipoCheck !== "") && (validator.tipoCheck === "true") &&
                                                                                    (values.km !== "") && (validator.km === "true") &&
                                                                                    (values.local !== "") && (validator.local === "true") &&
                                                                                    (values.dataCheckagem !== "") && (validator.dataCheckagem === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarCheckagemPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroTipoCheck="sim"
                                        open={openCadastroTipoCheck}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroTipoCheck}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div >
    );
}

export default CrudCheckAdiciona;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraUnidadeEmbarque = async (unidadeEmbarque, token) => {
  return api.post("unidEmbarque",
    unidadeEmbarque
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getUnidadeEmbarque = async (token) => {
  return api.get("unidEmbarque",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getUnidadeEmbarquePorId = async (id, token) => {
  return api.get("unidEmbarque/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteUnidadeEmbarquePorId = async (id, token) => {
  return api.delete("unidEmbarque/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaUnidadeEmbarquePorId = async (unidadeEmbarque, token) => {
  return api.put("unidEmbarque",
    unidadeEmbarque,
  {
    headers: {
      'Authorization': token
    }
  })
}
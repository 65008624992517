import { React, useEffect } from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import AnimatedRoutes from './AnimatedRoutes';

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "l7u6wvitfe");
    }
  }, []);

  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
}

export default App;

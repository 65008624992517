import React, { useState, createContext } from "react";

const valorInicial = {
    colunaSelecionada: "",
    usuarioAlteracao: "",
    dataAlteracao: "",
};

const Tabela = createContext();

export default Tabela;

export const TabelaRedux = ({ children }) => {
    const [tabelaRedux, setTabelaRedux] = useState(valorInicial);

    return (
        <Tabela.Provider value={{
            tabelaRedux,
            setTabelaRedux,
        }}>
            {children}
        </Tabela.Provider>
    );
};
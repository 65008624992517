import React, { useEffect, useState, useContext } from "react";
import TabelaRedux from "../../contexts/Tabela";
import "./Tabela.css";
import styled from "styled-components";
import CoresRedux from "../../contexts/Cor";
import MenusRedux from "../../contexts/Menus";
import NomeTabelaRedux from "../../contexts/NomeTabela";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import SettingsIcon from '@mui/icons-material/Settings';
import ModalConfigTabela from "../ModalConfigTabela/ModalConfigTabela.tsx";
import InputLabel from "@mui/material/InputLabel";
import ModalFiltroEmbarque from "../ModalFiltroEmbarque/ModalFiltroEmbarque.tsx";
import ModalEntregas from "../ModalEntregas/ModalEntregas.tsx";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltIcon2 from '@mui/icons-material/Search';
import ModalFiltroCidade from "../ModalFiltroCidade/ModalFiltroCidade.tsx";
import RequestControllerRedux from "../../contexts/RequestController";
import ToastSucesso from "../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../components/Toast/ToastErro/ToastErro.tsx";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { verificaDadosUsuarioRedux } from "../../services/ModuloGerenciador/Usuario";
import DadosUsuarioRedux from "../../contexts/Usuario";
import FiltrosRedux from "../../contexts/Filtros";
import { getUsuarioDependencias } from "../../services/ModuloGerenciador/Geral";
import { aceitaSolicitacao, recusaSolicitacao } from "../../services/ModuloGerenciador/Solicitacao";
import Text from "../Text/Text";
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink, CSVDownload } from "react-csv";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { cnpjMask } from "../../regex/cnpjMask";
import RastrearRedux from "../../contexts/Rastreio";
import { useNavigate } from "react-router-dom";
import { cpfMask } from "../../regex/cpfMask";

function Tabela(props) {

    const headers = [
        { label: "ID", key: "idEmbarque" },
        { label: "Empresa", key: "empresa" },
        { label: "Filial", key: "filial" },
        { label: "Unidade Embarque", key: "unidEmbarque" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Estado Origem", key: "estadoOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Estado Destino", key: "estadoDestino" },
        { label: "Pedido", key: "pedido" },
        { label: "Previsão Embarque", key: "previsaoEmbarque" },
        { label: "Data Embarque", key: "dataEmbarque" },
        { label: "Previsão Entrega", key: "previsaoEntrega" },
        { label: "Data Entrega", key: "dataEntrega" },
        { label: "Aceite", key: "aceite" },
        { label: "Status", key: "status" },
        { label: "Placa Cavalo", key: "placaCavalo" },
        { label: "Placa Carreta", key: "placaCarreta" },
        { label: "Placa Carreta 2", key: "placaCarreta2" },
        { label: "Proprietário", key: "proprietario" },
        { label: "Motorista", key: "motorista" },
        { label: "Frete Fiscal", key: "freteFiscal" },
        { label: "ICMS", key: "icms" },
        { label: "Frete Motorista", key: "freteMotorista" },
        { label: "Adicional Empresa", key: "adicionalEmpresa" },
        { label: "Adicional Motorista", key: "adicionalMotorista" },
        { label: "Pedagio", key: "pedagio" },
        { label: "Classificacao", key: "classificacao" },
        { label: "Adiantamento", key: "adiantamento" },
        { label: "Saldo", key: "saldo" },
        { label: "Meio Pagamento", key: "meioPagamento" },
        { label: "Meio Pagamento 2", key: "meioPagamento2" },
        { label: "KM", key: "km" },
        { label: "Cliente", key: "cliente" },
        { label: "Embarcador", key: "embarcador" },
        { label: "Destinatário", key: "destinatario" },
        { label: "Load", key: "load" },
        { label: "Mercadoria", key: "mercadoria" },
        { label: "Peso", key: "peso" },
        { label: "Embalagem", key: "embalagem" },
        { label: "Observação", key: "observacao" },
        { label: "Frota", key: "frota" },
        { label: "Cancelado", key: "cancelado" },
        { label: "Motivo", key: "motivo" },
    ];

    const headersPedido = [
        { label: "N Pedido", key: "pedido" },
        { label: "Rota", key: "tipoRota" },
        { label: "Frete Fiscal", key: "freteFiscal" },
        { label: "ICMS", key: "icms" },
        { label: "Pedagio", key: "pedagio" },
        { label: "Classificação", key: "classificacao" },
        { label: "Km", key: "km" },
        { label: "Cliente", key: "cliente" },
        { label: "Embarcador", key: "embarcador" },
        { label: "Embarcador Estado", key: "embarcadorEstado" },
        { label: "Destinatario", key: "destinatario" },
        { label: "Destinatario Estado", key: "destinatarioEstado" },
        { label: "Frete Motorista", key: "freteMotorista" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Observação", key: "observacao" },
    ];

    const headersVeiculo = [
        { label: "Placa Cavalo", key: "placaCavalo" },
        { label: "Placa Carreta", key: "placaCarreta" },
        { label: "Placa Carreta 2", key: "placaCarreta2" },
        { label: "Proprietário", key: "proprietario" },
        { label: "Motorista", key: "motorista" },
        { label: "Motorista 2", key: "motorista2" },
    ];

    const headersRota = [
        { label: "Rota", key: "nome" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Estado Origem", key: "estadoOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Estado Destino", key: "estadoDestino" },
    ];


    const novaArray = props.dados;
    const chavesRemover = ["deletado", "embarqueHistoricoId", "contexto", "dataHoraChegadaEmbarque", "dataHoraEmbarcado", "dataHoraChegadaEntrega", "dataHoraEntregue", "data", "usuario"];
    const listaFiltradaObjetos = novaArray.map((obj) => {
        const novoObjeto = Object.keys(obj)
            .filter(key => !chavesRemover.includes(key))
            .reduce((novoObjeto, key) => {
                novoObjeto[key] = obj[key];
                return novoObjeto;
            }, {});
        return novoObjeto;
    });

    const chavesFormatar = ["previsaoEmbarque", "dataEmbarque", "previsaoEntrega", "dataEntrega"];
    const listaFormatada = listaFiltradaObjetos.map((obj) => {
        const novoObj = {};
        Object.keys(obj).forEach(key => {
            if (chavesFormatar.includes(key) && obj[key]) {
                novoObj[key] = obj[key].replace(/T00:00:00/, "");
            } else {
                novoObj[key] = obj[key];
            }
        });
        return novoObj;
    });

    const colunas = {
        'Entregas': [
            {
                'nomeColuna': 'Rota',
                'field': 'nomeRota'
            },
            {
                'nomeColuna': 'Cidade Origem',
                'field': 'cidadeOrigemRota'
            },
            {
                'nomeColuna': 'Cidade Destino',
                'field': 'cidadeDestinoRota'
            },
            {
                'nomeColuna': 'Estado Origem',
                'field': 'estadoOrigemRota'
            },
            {
                'nomeColuna': 'Estado Destino',
                'field': 'estadoDestinoRota'
            },
            {
                'nomeColuna': 'Valor',
                'field': 'valor'
            },
            {
                'nomeColuna': 'Tarifa',
                'field': 'tarifa'
            },
            {
                'nomeColuna': 'Peso',
                'field': 'peso'
            },
            {
                'nomeColuna': 'Destinatario',
                'field': 'destinatario'
            },
            {
                'nomeColuna': 'CTE',
                'field': 'cte'
            },
            {
                'nomeColuna': 'Data Chegada Entrega ',
                'field': 'dataHoraChegadaEntrega'
            },
        ],
    }

    const navigate = useNavigate();

    const [valuesFiltro, setValuesFiltro] = useState({});

    const [hover] = useState(true);

    const [regras, setRegras] = useState([]);

    const [fileName, setFileName] = useState("");

    const [regrasEditada, setRegrasEditada] = useState([]);

    const [values, setValues] = useState([]);

    const [values2, setValues2] = useState([]);

    const [valuesFiltroData, setValuesFiltroData] = useState([]);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const [editando, setEditando] = useState(false);

    const [striped] = useState(true);

    const [data, setData] = useState([]);

    const [selecionado, setSelecionado] = useState("");

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosRastrearRedux, setDadosRastrearRedux } = useContext(RastrearRedux);

    const { menusRedux, setDadosMenusRedux } = useContext(MenusRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosTabelaRedux, setDadosTabelaRedux } = useContext(NomeTabelaRedux);

    const [quebraTabelaPedido, setQuebraTabelaPedidoValue] = useState(dadosTabelaRedux.Pedido[18]);

    const [quebraTabelaEmbarque, setQuebraTabelaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[51]);

    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    const [dadosEntregas, setDadosEntregas] = useState([]);

    const [loading, setLoading] = useState(true);

    const [openFiltro, setOpenFiltro] = useState(false);

    const [openEntregas, setOpenEntregas] = useState(false);

    const [openFiltroCidade, setOpenFiltroCidade] = useState(false);

    const [odenConfig, setOpenConfig] = useState(false);

    const [menuConfig, setMenuConfig] = useState(false);

    const [textoErro, setTextoErro] = useState("");

    const [textoSucesso, setTextoSucesso] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [ehCidade, setEhCidade] = useState(false);

    const [openRegras, setOpenRegras] = useState(false);

    const showDropDowEmbarques = (row) => {
        setDadosEntrega(prevState => ({ ...prevState, [row.idEmbarque]: row.entrega }));
        if (linhaSelecionada.includes(row.idEmbarque)) {
            setLinhaSelecionada(linhaSelecionada.filter(id => id !== row.idEmbarque));
        } else {
            setLinhaSelecionada([...linhaSelecionada, row.idEmbarque]);
        }
    };

    const [dadosEntrega, setDadosEntrega] = useState([]);

    const [linhaSelecionada, setLinhaSelecionada] = useState([]);

    const handleCloseRegras = (value) => {
        setOpenRegras(false);
    };

    const [openMotivo, setOpenMotivo] = useState(false);

    const handleCloseMotivo = (value) => {
        setValues2({});
        setOpenMotivo(false);
    };

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = () => {
        setOpenToastErro(false);
    };

    const handleCloseConfig = (value) => {
        if (dadosRequestControllerRedux === "Sucesso") {
            setTextoSucesso("Alterado Com Sucesso");
            setOpenToastSucesso(true);
            setOpenConfig(false);
        }
        setOpenConfig(false);
    };

    const handleCloseFiltro = (value) => {
        const dataStorage = localStorage.getItem('filtros');
        if (dataStorage) {
            const storageParse = JSON.parse(dataStorage);
            if (storageParse) {
                filtraData(storageParse)
            }
        }

        setOpenFiltro(false);
    };

    const handleCloseEntregas = (value) => {
        setOpenEntregas(false);
    };

    const handleCloseFiltroCidade = (value) => {
        const dataStorage = localStorage.getItem('filtros');
        if (dataStorage) {
            const storageParse = JSON.parse(dataStorage);
            if (storageParse) {
                filtraData(storageParse)
            }
        }
        setOpenFiltroCidade(false);
    };

    const TableTh = styled.th`     
    padding: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: sticky; 
    top: 0px;
    background-color: ${dadosCoresRedux.tituloColuna};
    color: ${dadosCoresRedux.textoTituloColuna};
    text-align: center;
    font-size: 16px;
    border: 0.5px solid rgba(0, 0, 0, 0.9);
    `

    const filtra = () => {
        if (window.location.href.endsWith("embarque") || window.location.href.endsWith("embarque-toledo") || window.location.href.endsWith("embarque-gloria")
            || window.location.href.endsWith("embarque-paranavai") || window.location.href.endsWith("embarque-rio-verde") || window.location.href.endsWith("embarque-frota")) {
            setDadosFiltrados();
            setLoading(false);
        } else {
            setLoading(true);
            setDadosFiltrados("1");
            var dadosfiltra = props.dados;
            dadosfiltra.sort(function (a, b) {
                if (a.placaCavalo < b.placaCavalo || a.pedido < b.pedido || a.cidade < b.cidade) {
                    return -1;
                } else {
                    return true;
                }
            });
            setDadosFiltrados(dadosfiltra);
            setLoading(false);
        }
    }

    const filtraData = (valor) => {
        const dataInicial = converteData(converteDataPadrao(valor.embarque));
        const dataFinal = converteData(converteDataPadrao(valor.embarqueSegundo));
        if (props.classeSelecionado === "idEmbarque") {
            if (valor.embarque && valor.embarqueSegundo) {
                setData(props.dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial && converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal }));
            } else if (valor.embarque && !valor.embarqueSegundo) {
                setData(props.dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial }));
            } else if (valor.embarqueSegundo && !valor.embarque) {
                setData(props.dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal }));
            } else {
                setData(props.dados);
            }
        } else if (props.classeSelecionado === "idCidadeEstado") {
            if (valor.cidade && valor.estado) {
                setDadosFiltrados(props.dados.filter((data) => { return data.cidade.toLowerCase().includes(valor.cidade.toLowerCase()) && data.estado.toLowerCase().includes(valor.estado.toLowerCase()) }));
            } else if (valor.cidade && !valor.estado) {
                setDadosFiltrados(props.dados.filter((data) => { return data.cidade.toLowerCase().includes(valor.cidade.toLowerCase()) }));
            } else if (valor.estado && !valor.cidade) {
                setDadosFiltrados(props.dados.filter((data) => { return data.estado.toLowerCase().includes(valor.estado.toLowerCase()) }));
            } else {
                setDadosFiltrados(props.dados);
            }
        }
    }

    function resetFiltro() {
        let dados = props.dados;
        setDadosFiltrados(dados);
    }

    function filtrarCidade(value) {
        let dados = props.dados;
        setDadosFiltrados(dados.filter((data) => { return data.cidade.toLowerCase().includes(value.filtroOrigem.toLowerCase()) }));
    }

    function filtrarCidade2(value) {
        let dados = props.dados;
        setDadosFiltrados(dados.filter((data) => { return data.estado.toLowerCase().includes(value.filtroOrigemEstado.toLowerCase()) }));
    }

    function filtrarEstado(value) {
        let dados = props.dados;
        setDadosFiltrados(dados.filter((data) => { return data.cidade.toLowerCase().includes(value.filtroOrigem.toLowerCase()) && data.estado.toLowerCase().includes(value.filtroOrigemEstado.toLowerCase()) }));
    }

    const dadosOrdenados = dadosFiltrados;

    function converteDataPadrao(data) {
        return new Date(data).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" });
    }

    function converteData(DataDDMMYY) {
        const dataSplit = DataDDMMYY.split("/");
        const novaData = new Date(parseInt(dataSplit[2], 10),
            parseInt(dataSplit[1], 10) - 1,
            parseInt(dataSplit[0], 10));
        return novaData;
    }

    const buscarRegras = async () => {
        setLoading(true);
        await getUsuarioDependencias(dadosUsuarioRedux.token).then((result) => {
            setRegras(result.data.regras);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const aceitarSolicitacao = async () => {
        setLoading(true);
        var arr = values.regras;
        var value = 0
        arr = arr.filter(function (item) {
            return item !== value
        })
        const objetoSolicitacao = {
            usuarioId: tabelaRedux.colunaSelecionada,
            regras: arr,
            colunas: values.colunas
        }
        await aceitaSolicitacao(objetoSolicitacao, dadosUsuarioRedux.token).then((result) => {
            setLoading(false);
            handleCloseRegras();
            setDadosRequestControllerRedux("Solicitacao Aceita");
            setValues(current => ({ ...current, regras: [0] }));
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const recusarSolicitacao = async () => {
        setLoading(true);
        const objetoSolicitacao = {
            usuarioId: tabelaRedux.colunaSelecionada,
            motivo: values2.motivo
        }
        await recusaSolicitacao(objetoSolicitacao, dadosUsuarioRedux.token).then((result) => {
            setLoading(false);
            setDadosRequestControllerRedux("Solicitacao Recusada");
            setValues2({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    };

    const filtrarData = () => {
        localStorage.setItem('filtros', JSON.stringify(values));
        setFiltrosRedux(valuesFiltroData);
    };

    const limparFiltro = () => {
        setValuesFiltroData([]);
        setFiltrosRedux([]);
    };

    useEffect(() => {
        if (window.location.href.endsWith("embarque")) {
            setFileName("Todos");
        }
        if (window.location.href.endsWith("embarque-toledo")) {
            setFileName("T S - Too");
        }
        if (window.location.href.endsWith("embarque-gloria")) {
            setFileName("T S - Ms");
        }
        if (window.location.href.endsWith("embarque-paranavai")) {
            setFileName("T S - Pvai");
        }
        if (window.location.href.endsWith("embarque-rio-verde")) {
            setFileName("T S - Go");
        }
        if (window.location.href.endsWith("embarque-frota")) {
            setFileName("T S - Frota");
        }
        if (window.location.href.endsWith("pedido")) {
            setFileName("Pedidos");
        }
        if (window.location.href.endsWith("rota")) {
            setFileName("Rotas");
        }
        if (window.location.href.endsWith("veiculo")) {
            setFileName("Veiculos");
        }
        const camposFormatados = JSON.stringify(menusRedux).replace(/"/g, `'`)
        setValues(current => ({ ...current, colunas: camposFormatados }));
        setValues(current => ({ ...current, regras: [0] }));
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setData(props.dados)
        if (props.nome) {
            setMenuConfig(true);
        }
        filtra();
        if (window.location.href.endsWith("cidade-estado")) {
            setEhCidade(true);
        }
        if (window.location.href.endsWith("solicitacoes")) {
            buscarRegras();
        }
        setValuesFiltroData(filtrosRedux);
    }, []);

    useEffect(() => {
        if (props.dados && props.dados.length > 0 && props.dados[0] && props.dados[0].idEmbarque) {
            const tableWrapper = document.getElementById("tableWrapper");
            if (tableWrapper && (window.location.href.endsWith("embarque")
                || window.location.href.endsWith("embarque-toledo") || window.location.href.endsWith("embarque-gloria")
                || window.location.href.endsWith("embarque-paranavai") || window.location.href.endsWith("embarque-rio-verde")
                || window.location.href.endsWith("embarque-frota"))) {
                tableWrapper.scrollTop = tableWrapper.scrollHeight;
            }
        }
    }, [loading]);

    return (
        <div>
            {
                !loading ? (
                    <div class={props.nomeClasse1}>
                        {!!ehCidade && (
                            <div>
                                <InputLabel htmlFor="filtro">Filtrar</InputLabel>
                                <input
                                    placeholder="Cidade"
                                    type="text"
                                    className="input-text-filtrar"
                                    onChange={(e) => {
                                        setValuesFiltro(current => ({ ...current, filtroOrigem: e.target.value }));
                                    }}
                                    value={valuesFiltro.filtroOrigem} />
                                <input
                                    placeholder="UF"
                                    maxLength={2}
                                    type="text"
                                    onChange={(e) => {
                                        setValuesFiltro(current => ({ ...current, filtroOrigemEstado: e.target.value }));
                                    }}
                                    className="input-text-filtrar-estado"
                                    value={valuesFiltro.filtroOrigemEstado} />
                                <FilterAltIcon2 className="icon-filtro" onClick={(e) => {
                                    if (valuesFiltro.filtroOrigem && valuesFiltro.filtroOrigemEstado) {
                                        filtrarEstado(valuesFiltro);
                                    } else if (valuesFiltro.filtroOrigem && !valuesFiltro.filtroOrigemEstado) {
                                        filtrarCidade(valuesFiltro);
                                    } else if (valuesFiltro.filtroOrigemEstado && !valuesFiltro.filtroOrigem) {
                                        filtrarCidade2(valuesFiltro);
                                    } else {
                                        resetFiltro();
                                    }
                                }} />
                                <ClearIcon className="icon-filtro"
                                    onClick={(e) => {
                                        setValuesFiltro(current => ({ ...current, filtroOrigem: '' }));
                                        setValuesFiltro(current => ({ ...current, filtroOrigemEstado: '' }));
                                        resetFiltro();
                                    }}
                                />
                            </div>
                        )}
                        {
                            menuConfig ? (
                                <div className="config-tabela">
                                    {/* {props.classeSelecionado === "idEmbarque" && !window.location.href.endsWith("faturamento")
                                        && !window.location.href.endsWith("embarque-template") ? (
                                        <div className="div-data">
                                            <div className="div-datas">
                                                <p htmlFor="embarque">Embarque Início</p>
                                                <input
                                                    type={'date'}
                                                    className='input-filtro-data'
                                                    value={valuesFiltroData.embarqueInicio}
                                                    style={{ width: 120 }}
                                                    onChange={(e) => { setValuesFiltroData(current => ({ ...current, embarqueInicio: e.target.value })) }}
                                                    id='embarque'>
                                                </input>
                                            </div>
                                            <div className="div-datas">
                                                <p htmlFor="embarquef">Embarque Final</p>
                                                <input
                                                    type={'date'}
                                                    className='input-filtro-data'
                                                    value={valuesFiltroData.embarqueFinal}
                                                    style={{ width: 120 }}
                                                    onChange={(e) => { setValuesFiltroData(current => ({ ...current, embarqueFinal: e.target.value })) }}
                                                    id='embarquef'>
                                                </input>
                                            </div>
                                            <div className="div-datas">
                                                <Tooltip title={<p>Filtrar</p>}>
                                                    <FilterAltIcon2 className="icon-filtro"
                                                        onClick={(e) => {
                                                            filtrarData();
                                                        }} />
                                                </Tooltip>

                                            </div>
                                            <div className="div-datas">
                                                <Tooltip title={<p>Limpar Filtro</p>}>
                                                    <ClearIcon className="icon-filtro"
                                                        onClick={(e) => {
                                                            limparFiltro();
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    ) : (<></>)} */}
                                    {props.classeSelecionado === "idEmbarque" && !window.location.href.endsWith("faturamento") && !window.location.href.endsWith("embarque-template") ? (
                                        <>
                                            <CSVLink className="csv-botao" data={listaFormatada} headers={headers} filename={fileName}>
                                                <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                    <IconButton className="butao-csv">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </CSVLink>
                                        </>
                                    ) : (<></>)}
                                    {props.classeSelecionado === "idPedido" ? (
                                        <CSVLink className="csv-botao" data={listaFormatada} headers={headersPedido} filename={fileName}>
                                            <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                <IconButton className="butao-csv">
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CSVLink>
                                    ) : (<></>)}
                                    {props.classeSelecionado === "idVeiculo" ? (
                                        <CSVLink className="csv-botao" data={listaFormatada} headers={headersVeiculo} filename={fileName}>
                                            <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                <IconButton className="butao-csv">
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CSVLink>
                                    ) : (<></>)}
                                    {props.classeSelecionado === "idRota" ? (
                                        <CSVLink className="csv-botao" data={listaFormatada} headers={headersRota} filename={fileName}>
                                            <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                <IconButton className="butao-csv">
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CSVLink>
                                    ) : (<></>)}
                                    {props.classeSelecionado != "idFaturamento" && !window.location.href.endsWith("faturamento") ? (
                                        <div className="filtro">
                                            <Tooltip title={<p>Abrir configurações colunas</p>}>
                                                <IconButton className="butao-csv">
                                                    <SettingsIcon className="click-configurar" onClick={() => setOpenConfig(true)} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ) : (<></>)}
                                    {props.classeSelecionado === "idCidadeEstado" ? (
                                        <div className="filtro">
                                            <Tooltip title={<p>Filtros</p>}>
                                                <IconButton className="butao-csv">
                                                    <FilterAltIcon onClick={() => setOpenFiltroCidade(true)} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ) : (<></>)}
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        <div class={props.nomeClasse2} id="tableWrapper">
                            <table className={props.breakTabela ? props.breakTabela : ''}>
                                <thead>
                                    {
                                        props.nomeTable ? (
                                            <tr>
                                                {menusRedux[props.nomeTable].map((head, index) => {
                                                    const nomeColuna = props.colunas[index];
                                                    if (head.visivel === 'true' || head.visivel === true) {
                                                        const headsplit = nomeColuna.nomeColuna?.split(' ');
                                                        if (head.field === "descricao") {
                                                            if (window.location.href.endsWith("embarque-template")) {
                                                                return (
                                                                    <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                                )
                                                            } else {
                                                                return (
                                                                    <></>
                                                                )
                                                            }
                                                        }
                                                        if (headsplit?.length >= 0 && headsplit?.length <= 2) {
                                                            if (window.location.href.endsWith("embarque") || window.location.href.endsWith("embarque-toledo")
                                                                || window.location.href.endsWith("embarque-gloria") || window.location.href.endsWith("embarque-paranavai")
                                                                || window.location.href.endsWith("embarque-rio-verde") || window.location.href.endsWith("embarque-frota")) {
                                                                if (quebraTabelaEmbarque.quebraColunaEmbarque === true) {
                                                                    const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                    return (
                                                                        <TableTh>
                                                                            {headsplit[0]}
                                                                            <br></br>
                                                                            {headsplit[1]}
                                                                        </TableTh>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                                    );
                                                                }
                                                            }
                                                            if (quebraTabelaPedido.quebraColunaPedido === true) {
                                                                const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                return (
                                                                    <TableTh>
                                                                        {headsplit[0]}
                                                                        <br></br>
                                                                        {headsplit[1]}
                                                                    </TableTh>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                                );
                                                            }
                                                        } else if (headsplit?.length === 3) {
                                                            if (window.location.href.endsWith("embarque") || window.location.href.endsWith("embarque-toledo")
                                                                || window.location.href.endsWith("embarque-gloria") || window.location.href.endsWith("embarque-paranavai")
                                                                || window.location.href.endsWith("embarque-rio-verde") || window.location.href.endsWith("embarque-frota")) {
                                                                if (quebraTabelaEmbarque.quebraColunaEmbarque === true) {
                                                                    const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                    return (
                                                                        <TableTh>
                                                                            {headsplit[0]} {headsplit[1]}
                                                                            <br></br>
                                                                            {headsplit[2]}
                                                                        </TableTh>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                                    );
                                                                }
                                                            }
                                                            if (quebraTabelaPedido.quebraColunaPedido === true) {
                                                                const headsplit = nomeColuna.nomeColuna?.split(' ');
                                                                return (
                                                                    <TableTh>
                                                                        {headsplit[0]} {headsplit[1]}
                                                                        <br></br>
                                                                        {headsplit[2]}
                                                                    </TableTh>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                                );
                                                            }
                                                        } else if (headsplit?.length === 4) {
                                                            if (window.location.href.endsWith("embarque") || window.location.href.endsWith("embarque-toledo")
                                                                || window.location.href.endsWith("embarque-gloria") || window.location.href.endsWith("embarque-paranavai")
                                                                || window.location.href.endsWith("embarque-rio-verde") || window.location.href.endsWith("embarque-frota")) {
                                                                if (quebraTabelaEmbarque.quebraColunaEmbarque === true) {
                                                                    const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                    return (
                                                                        <TableTh>
                                                                            {headsplit[0]} {headsplit[1]}
                                                                            <br></br>
                                                                            {headsplit[2]} {headsplit[3]}
                                                                        </TableTh>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                                    );
                                                                }
                                                            }
                                                            if (quebraTabelaPedido.quebraColunaPedido === true) {
                                                                const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                return (
                                                                    <TableTh>
                                                                        {headsplit[0]} {headsplit[1]}
                                                                        <br></br>
                                                                        {headsplit[2]} {headsplit[3]}
                                                                    </TableTh>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                                );
                                                            }
                                                        } else {
                                                            return (
                                                                <TableTh>{nomeColuna.nomeColuna}</TableTh>
                                                            );
                                                        }
                                                    }
                                                })}
                                            </tr>
                                        ) : (
                                            <tr>
                                                {props.colunas && props.colunas.map((head) => {
                                                    if (window.location.href.endsWith("faturamento")) {
                                                        const headsplit = head.nomeColuna.split(' ');
                                                        return (
                                                            <TableTh>
                                                                {headsplit[0]}
                                                                <br></br>
                                                                {headsplit[1]}
                                                            </TableTh>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableTh>{head.nomeColuna}</TableTh>
                                                        );
                                                    }
                                                })}
                                            </tr>
                                        )
                                    }
                                </thead>
                                {
                                    !dadosOrdenados ? (
                                        <tbody>
                                            {data &&
                                                data.map((row) => (
                                                    <>
                                                        <tr onClick={() => {
                                                            setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                            setSelecionado(row[props.classeSelecionado]);
                                                        }} className={`${hover && "hover"}  ${striped && "striped"}
                                                            ${selecionado === row[props.classeSelecionado] && "selecionado"}
                                                            ${row.cte && row.cte !== null && row.cte !== "" && row.cte !== " " && row.cte !== "  " && "cte"}
                                                            ${row.cancelado === true && "cancelado"}`}
                                                            onDoubleClick={() => {
                                                                if (!window.location.href.endsWith("frota") && !window.location.href.endsWith("veiculos-vencidos")) {
                                                                    setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                    setSelecionado(row[props.classeSelecionado]);
                                                                    props.setTelaCadastro(true);
                                                                    props.setEditando(true);
                                                                } else {
                                                                    setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                    setSelecionado(row[props.classeSelecionado]);
                                                                    props.setTelaCheck();
                                                                }
                                                            }}>
                                                            {
                                                                props.nomeTable ? (
                                                                    <>
                                                                        {menusRedux[props.nomeTable].map((col) => {
                                                                            if (col.visivel === 'true' || col.visivel === true) {
                                                                                if (col.field === "placaCavalo") {
                                                                                    if (row.caminhao != null) {
                                                                                        return (
                                                                                            <td className="rastreio"
                                                                                                onClick={(e) => {
                                                                                                    if (row.caminhao != null) {
                                                                                                        setDadosRastrearRedux((current) => ({
                                                                                                            ...current,
                                                                                                            idVeiculo: row.caminhao.idCaminhao,
                                                                                                            tipoVeiculo: 1,
                                                                                                        }));
                                                                                                        navigate("/rastrear");
                                                                                                    }
                                                                                                }}>
                                                                                                {row.placaCavalo}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        <td style={{ color: row.corCliente }}>{row.placaCavalo}</td>
                                                                                    }
                                                                                }
                                                                                if (col.field === "placaCarreta") {
                                                                                    if (row.carreta != null) {
                                                                                        return (
                                                                                            <td className="rastreio"
                                                                                                onClick={(e) => {
                                                                                                    if (row.carreta != null) {
                                                                                                        setDadosRastrearRedux((current) => ({
                                                                                                            ...current,
                                                                                                            idVeiculo: row.carreta.idCarreta,
                                                                                                            tipoVeiculo: 2,
                                                                                                        }));
                                                                                                        navigate("/rastrear");
                                                                                                    }
                                                                                                }}>
                                                                                                {row.placaCarreta}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        <td style={{ color: row.corCliente }}>{row.placaCarreta}</td>
                                                                                    }
                                                                                }
                                                                                if (col.field === "placaCarreta2") {
                                                                                    if (row.carreta2 != null) {
                                                                                        return (
                                                                                            <td className="rastreio"
                                                                                                onClick={(e) => {
                                                                                                    if (row.carreta2 != null) {
                                                                                                        setDadosRastrearRedux((current) => ({
                                                                                                            ...current,
                                                                                                            idVeiculo: row.carreta2.idCarreta,
                                                                                                            tipoVeiculo: 2,
                                                                                                        }));
                                                                                                        navigate("/rastrear");
                                                                                                    }
                                                                                                }}>
                                                                                                {row.placaCarreta2}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        <td style={{ color: row.corCliente }}>{row.placaCarreta2}</td>
                                                                                    }
                                                                                }
                                                                                if (col.field === "descricao") {
                                                                                    if (window.location.href.endsWith("embarque-template")) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === 'margem') {
                                                                                    if (row.margem != null) {
                                                                                        const numMargem = parseFloat(row.margem);
                                                                                        const margem = numMargem.toFixed(2);
                                                                                        return (
                                                                                            <td className="td-center">{margem}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === 'adiantamento') {
                                                                                    if (row.adiantamento != null) {
                                                                                        const adtSplit = row.adiantamento.split(',');
                                                                                        return (
                                                                                            <td className="td-center">{adtSplit[0]}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === 'saldo') {
                                                                                    if (row.saldo != null) {
                                                                                        const adtSplit = row.saldo.split(',');
                                                                                        return (
                                                                                            <td className="td-center">{adtSplit[0]}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.objetoUnidadeEmbarque === "true") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "empresa") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="v-entrega">
                                                                                            {
                                                                                                row.entrega.length > 0 ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            linhaSelecionada.includes(row.idEmbarque) ? (
                                                                                                                <KeyboardArrowUpIcon
                                                                                                                    className="icon-entrega"
                                                                                                                    onClick={() => {
                                                                                                                        showDropDowEmbarques(row);
                                                                                                                    }}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                <KeyboardArrowDownIcon
                                                                                                                    className="icon-entrega"
                                                                                                                    onClick={() => {
                                                                                                                        showDropDowEmbarques(row);
                                                                                                                    }}
                                                                                                                />
                                                                                                            )
                                                                                                        }

                                                                                                        <span>{row[col.field]}</span>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <span className="span-margin">{row[col.field]}</span>
                                                                                                )
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "embarcadorEstado") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.embarcadorEstado}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "unidEmbarque") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.unidEmbarque}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "estadoOrigem") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.estadoOrigem}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "estadoDestino") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.estadoDestino}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "previsaoEmbarque") {
                                                                                    if (row.previsaoEmbarque === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEmbarque") {
                                                                                    if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "aceite") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.aceite}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "destinatarioEstado") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.destinatarioEstado}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "dataHoraChegadaEmbarque") {
                                                                                    if (row.dataHoraChegadaEmbarque === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraChegadaEntrega") {
                                                                                    if (row.dataHoraChegadaEntrega === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraEntregue") {
                                                                                    if (row.dataHoraEntregue === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEntregue).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraEmbarcado") {
                                                                                    if (row.dataHoraEmbarcado === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEmbarcado).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "frota") {
                                                                                    if (row.frota === "Sim") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Sim</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Não</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "notificarACadaData") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "tipoCheck") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "dataCheckagem") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "proximaRevisaoData") {
                                                                                    if (row.proximaRevisaoData === null) {
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "notificarAutomatico") {
                                                                                    if (row.notificarAutomatico === true) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Sim</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Não</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "cancelado") {
                                                                                    if (row.cancelado === true) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Sim</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Não</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "previsaoEntrega") {
                                                                                    if (row.previsaoEntrega === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEntrega") {
                                                                                    if (row.dataEntrega === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEmbarque") {
                                                                                    if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                } else {
                                                                                    if (col.tipo === "number") {
                                                                                        let value;
                                                                                        if (typeof row[col.field] === 'string') {
                                                                                            value = row[col.field].replace(',', '.');
                                                                                        }
                                                                                        if (isNaN(parseFloat(value))) {
                                                                                            return <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>;
                                                                                        } else {
                                                                                            let floatValue = parseFloat(value);
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }} className="td-right">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        let value;
                                                                                        if (typeof row[col.field] === 'string') {
                                                                                            value = row[col.field].replace(',', '.');
                                                                                        }
                                                                                        if (isNaN(parseFloat(value))) {
                                                                                            return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                                        } else {
                                                                                            let floatValue = parseFloat(value);
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {props.colunas.map((col) => {
                                                                            if (col.field === "descricao") {
                                                                                if (window.location.href.endsWith("embarque-template")) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <></>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.tipo === "number") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "previsaoEmbarque") {
                                                                                if (row.previsaoEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "frota") {
                                                                                if (row.frota === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarACadaData") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "tipoCheck") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "dataCheckagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "proximaRevisaoData") {
                                                                                if (row.proximaRevisaoData === null) {
                                                                                    <td style={{ color: row.corCliente }}></td>
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarAutomatico") {
                                                                                if (row.notificarAutomatico === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "cancelado") {
                                                                                if (row.cancelado === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "previsaoEntrega") {
                                                                                if (row.previsaoEntrega === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraChegadaEmbarque") {
                                                                                if (row.dataHoraChegadaEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraChegadaEntrega") {
                                                                                if (row.dataHoraChegadaEntrega === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraEntregue") {
                                                                                if (row.dataHoraEntregue === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEntregue).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraEmbarcado") {
                                                                                if (row.dataHoraEmbarcado === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEmbarcado).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataEntrega") {
                                                                                if (row.dataEntrega === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataEmbarque") {
                                                                                if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            } else {
                                                                                if (isNaN(parseFloat(row[col.field]))) {
                                                                                    return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{parseFloat(row[col.field]).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                )
                                                            }
                                                        </tr>
                                                        {linhaSelecionada.includes(row.idEmbarque) && (
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <Tabela classeSelecionado="idEntrega" nomeClasse1="outer-wrapper-rota" nomeClasse2="table-wrapper-entregas"
                                                                        colunas={colunas.Entregas} dados={dadosEntrega[row.idEmbarque]} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {dadosOrdenados &&
                                                dadosOrdenados.map((row) => (
                                                    <>
                                                        <tr onClick={() => {
                                                            setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                            setSelecionado(row[props.classeSelecionado]);
                                                        }}
                                                            onDoubleClick={() => {
                                                                if (!window.location.href.endsWith("frota") && !window.location.href.endsWith("adicionar-check-veiculo") && !window.location.href.endsWith("veiculos-vencidos")) {
                                                                    setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                    setSelecionado(row[props.classeSelecionado]);
                                                                    props.setTelaCadastro(true);
                                                                    props.setEditando(true);
                                                                } else {
                                                                    setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                    setSelecionado(row[props.classeSelecionado]);
                                                                    props.setTelaCheck();
                                                                }
                                                            }}
                                                            className={`${hover && "hover"}  ${striped && "striped"}  ${selecionado === row[props.classeSelecionado] && "selecionado"}`}>
                                                            {
                                                                props.nomeTable ? (
                                                                    <>
                                                                        {menusRedux[props.nomeTable].map((col) => {
                                                                            if (col.field === "freteFiscal" || col.field === "icms" || col.field === "pedagio" ||
                                                                                col.field === "classificao" || col.field === "km" || col.field === "freteMotorista") {
                                                                                let value;
                                                                                if (typeof row[col.field] === 'string') {
                                                                                    value = row[col.field].replace(',', '.');
                                                                                }
                                                                                if (isNaN(parseFloat(value))) {
                                                                                    return <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>;
                                                                                } else {
                                                                                    let floatValue = parseFloat(value);
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-right">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "descricao") {
                                                                                if (window.location.href.endsWith("embarque-template")) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <></>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "previsaoEmbarque") {
                                                                                if (row.previsaoEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataEmbarque") {
                                                                                if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataEntrega") {
                                                                                if (row.dataEntrega === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "previsaoEntrega") {
                                                                                if (row.previsaoEntrega === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "embarcadorEstado") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{row.embarcadorEstado}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "destinatarioEstado") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{row.destinatarioEstado}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "permissoes") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{`${row.regras},`}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "nascimento") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.nascimento).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "ultimoLogin" && row.ultimoLogin !== null) {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogin).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "ultimoLogout" && row.ultimoLogout !== null) {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogout).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "data") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "mensagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className={props.breakTd ? props.breakTd : ''}>{row.mensagem}</td>
                                                                                )
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.visivel === 'true' || col.visivel === true) {
                                                                                if (col.objetoUnidadeEmbarque === "true") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row[col.field]}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarACadaData") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "tipoCheck") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "dataCheckagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "proximaRevisaoData") {
                                                                                if (row.proximaRevisaoData === null) {
                                                                                    <td style={{ color: row.corCliente }}></td>
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarAutomatico") {
                                                                                if (row.notificarAutomatico === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "frota") {
                                                                                if (row.frota === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {props.colunas.map((col) => {
                                                                            if (col.field === "ano" || col.field === "renavam" || col.field === "modelo") {
                                                                                return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                            }
                                                                            if (col.objetoRastreamento === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.tipoRastreamento.nome}</td>
                                                                                );
                                                                            }
                                                                            if (col.objetoVeiculo === "true") {
                                                                                if (row.caminhao != null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.caminhao.placa}</td>
                                                                                    );
                                                                                }
                                                                                if (row.carreta != null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.carreta.placa}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "tipoVeiculo") {
                                                                                if (row.tipoVeiculo === 1) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Caminhão</td>
                                                                                    );
                                                                                }
                                                                                if (row.tipoVeiculo === 2) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Carreta</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "ip") {
                                                                                return (
                                                                                    <td>{row.ip}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "cnpj") {
                                                                                if (row.cnpj.length === 11) {
                                                                                    return (
                                                                                        <td>{cpfMask(row.cnpj)}</td>
                                                                                    )
                                                                                } else if (row.cnpj.length > 11) {
                                                                                    return (
                                                                                        <td>{cnpjMask(row.cnpj)}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "descricao") {
                                                                                if (window.location.href.endsWith("embarque-template")) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <></>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.tipo === "number") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "notificarAutomatico") {
                                                                                if (row.notificarAutomatico === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarACadaData") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "tipoCheck") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "dataCheckagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "proximaRevisaoData") {
                                                                                if (row.proximaRevisaoData === null) {
                                                                                    <td style={{ color: row.corCliente }}></td>
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "frota") {
                                                                                if (row.frota === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "previsaoEmbarque") {
                                                                                if (row.previsaoEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "permissoes") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{`${row.regras},`}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "nascimento") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.nascimento).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "ultimoLogin" && row.ultimoLogin !== null) {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogin).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "ultimoLogout" && row.ultimoLogout !== null) {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogout).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "data") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "mensagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className={props.breakTd ? props.breakTd : ''}>{row.mensagem}</td>
                                                                                )
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "aceitar") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center"><CheckIcon
                                                                                        className="icon-filtro"
                                                                                        onClick={(e) => {
                                                                                            setOpenRegras(true);
                                                                                        }}
                                                                                    /></td>
                                                                                );
                                                                            }
                                                                            if (col.field === "recusar") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center"><ClearIcon
                                                                                        className="icon-filtro"
                                                                                        onClick={(e) => {
                                                                                            setOpenMotivo(true);
                                                                                        }}
                                                                                    /></td>
                                                                                );
                                                                            } else {
                                                                                if (isNaN(parseFloat(row[col.field]))) {
                                                                                    return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{parseFloat(row[col.field]).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                )
                                                            }
                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>
                        <div>
                            <ModalConfigTabela
                                titulo="Colunas Visíveis"
                                tituloConfirmar="OK"
                                open={odenConfig}
                                onClose={handleCloseConfig}
                                checks={props.colunas}
                                nome={props.nome}
                            />
                        </div>
                        <div>
                            <ModalFiltroEmbarque
                                open={openFiltro}
                                onClose={handleCloseFiltro}>
                            </ModalFiltroEmbarque>
                            <ModalEntregas
                                open={openEntregas}
                                onClose={handleCloseEntregas}
                                dadosEntregas={dadosEntregas}>
                            </ModalEntregas>
                            <ModalFiltroCidade
                                open={openFiltroCidade}
                                onClose={handleCloseFiltroCidade}>
                            </ModalFiltroCidade>
                            <ToastSucesso
                                texto={textoSucesso}
                                open={openToastSucesso}
                                onClose={handleCloseToastSucesso}
                            />
                            <ToastErro
                                texto={textoErro}
                                open={openToastErro}
                                onClose={handleCloseToastErro}
                            />
                        </div>
                        <div className="modal-usuario">
                            <Dialog className="corpoModalCookies" open={openRegras} onClose={handleCloseRegras}>
                                {
                                    !loading ? (
                                        <>
                                            <DialogTitle>Defina as Permissões do Usuário</DialogTitle>
                                            <DialogContent>
                                                <div className="containerPermissao">
                                                    <h3>Permissões:</h3>
                                                    {regras.map((dado, index) => (
                                                        <Box display={'flex'} key={dado.regrasid}>
                                                            <input
                                                                type={'checkbox'}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setValues(current => ({ ...current, regras: [...values.regras, dado.regrasid] }));
                                                                    } else {
                                                                        var arr = values.regras;
                                                                        var value = dado.regrasid
                                                                        arr = arr.filter(function (item) {
                                                                            return item !== value
                                                                        })
                                                                        setValues(current => ({ ...current, regras: arr }));
                                                                    }
                                                                }}
                                                            >
                                                            </input>{dado.regra} {
                                                                dado.check ? (<p> - Já possui</p>) : (<p></p>)
                                                            }
                                                        </Box>
                                                    ))}
                                                </div>
                                            </DialogContent></>
                                    ) : (
                                        <LoadingComponent />
                                    )
                                }
                                <div className="botaoConcluir">
                                    <Button
                                        sx={{ width: 180 }}
                                        variant="contained"
                                        onClick={() => { aceitarSolicitacao(); }}>
                                        Aceitar
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                        <div className="modal-usuario">
                            <Dialog className="corpoModalCookies" open={openMotivo} onClose={handleCloseMotivo}>
                                {
                                    !loading ? (
                                        <>
                                            <DialogTitle>Digite porque está recusando está solicitação</DialogTitle>
                                            <DialogContent>
                                                <div className="principal">
                                                    <InputLabel htmlFor="status"><Text texto="Motivo" /></InputLabel>
                                                    <textarea
                                                        type="text"
                                                        className="input-text-motivo"
                                                        rows="3"
                                                        onChange={(e) => setValues2(current => ({ ...current, motivo: e.target.value }))}
                                                        value={values2.motivo}
                                                    ></textarea>
                                                </div>
                                            </DialogContent></>
                                    ) : (
                                        <LoadingComponent />
                                    )
                                }
                                <div className="botaoConcluir">
                                    <Button
                                        sx={{ width: 180 }}
                                        variant="contained"
                                        onClick={() => { recusarSolicitacao(); }}>
                                        Recusar
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                ) : (
                    <LoadingComponent />
                )
            }

        </div>
    );
}

export default Tabela;
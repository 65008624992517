import React, { useContext, useEffect, useState } from "react";
import Tabela from "../../components/Tabela/Tabela";
import DadosUsuarioRedux from "../../contexts/Usuario";
import CoresRedux from "../../contexts/Cor";
import NomeTabelaRedux from "../../contexts/NomeTabela";
import TabelaRedux from "../../contexts/Tabela";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from "../CustomButton/CustomButton";
import "../ModalHistoricoEdicao/ModalHistoricoEdicao.css"
import ModalUsuario from "../ModalUsuario/ModalUsuario.tsx";
import TabelaV2 from "../TabelaV2/TabelaV2";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2.js";

export interface SimpleDialogProps {
    titulo: string;
    valueTabela: any;
    valueDados: any;
    valueUnico: any;
    colunas: any;
    nomeTable: any;
    classeSelecionado: any;
    nome: any;
    open: boolean;
    onClose: () => void;
}

function ModalHistoricoEdicao(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [openUsuario, setOpenUsuario] = useState(false);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [loading, setLoading] = useState(true);

    const [textoErro, setTextoErro] = useState("");

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const handleClose = () => {
        props.onClose();
    };

    const handleClick = () => {
        props.onClose();
    };

    let colunas = typeof props.colunas === 'string'
        ? dadosTabelaRedux[props.colunas]
        : props.colunas;

    colunas = colunas.filter(function (item) {
        return item.field !== "descricao";
    });

    return (
        <div>
            <Dialog
                className="dialog-historico"
                open={props.open}
                onClose={handleClose}
            >
                <div className="dialog"
                >
                    <DialogTitle className="titulo">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <TabelaV2
                            classeSelecionado={props.classeSelecionado}
                            nomeClasse1="outer-wrapper"
                            nomeClasse2="table-wrapper-v2"
                            colunas={colunas}
                            dados={props.valueDados}
                            nome={props.nome} />
                    </DialogContent>
                    <DialogActions className="botoesModal">
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-user"
                            classText="button-texto-user"
                            titulo="Obter Usuário"
                            cor="#FFFFFF"
                            onClick={() => {
                                if (tabelaRedux.usuarioAlteracao) {
                                    setOpenUsuario(true);
                                } else {
                                    setTextoErro("Selecione Uma Coluna");
                                    setOpenToastErro(true);
                                }
                            }}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <ModalUsuario
                titulo="Usuário"
                tituloConfirmar="OK"
                open={openUsuario}
                onClose={handleCloseUsuario}
            />
        </div>
    )

}

export default ModalHistoricoEdicao;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraCidadeEstado = async (cidadeEstado, token) => {
  return api.post("cidadeEstado",
    cidadeEstado
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getCidadeEstado = async (token) => {
  return api.get("cidadeEstado",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCidadeEstadoComSigla = async (token) => {
  return api.get("cidadeEstado/buscaComSigla",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCidadeEstadoPorId = async (id, token) => {
  return api.get("cidadeEstado/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteCidadeEstadoPorId = async (id, token) => {
  return api.delete("cidadeEstado/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCidadeEstadoPorId = async (cidadeEstado, token) => {
  return api.put("cidadeEstado/",
    cidadeEstado,
  {
    headers: {
      'Authorization': token
    }
  })
}
import { Model } from "./Base.js";

export class CarretaModel extends Model {
    get defaults() {
        return {
            placa: "",
            ano: "",
            renavam: "",
            marca: "",
            modelo: "",
            usuario: ""
        };
    }
}
import React, { useContext } from "react";
import styled from "styled-components";

function TextLink(props) {


    const TextP = styled.p`     
    color: #5353ef;
    text-decoration: underline;
    `

    return (
        <TextP>{props.texto}</TextP>
    );
}

export default TextLink;
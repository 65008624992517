import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "../Charts/Charts.css"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import iconeInfo2 from "../../images/iconeInfo2.png"

export default function Charts(props) {

    const [state, setState] = useState<any>({})

    const [loading, setLoading] = useState(false);

    function timeToSeconds(time) {
        let isNegative = false;
        if (time.startsWith("-")) {
            isNegative = true;
            time = time.substring(1);
        }
        const parts = time.split('.');
        let days = 0;
        let [hours, minutes, seconds] = [0, 0, 0];
        if (parts.length === 2) {
            days = Number(parts[0]);
            [hours, minutes, seconds] = parts[1].split(':').map(Number);
        } else {
            [hours, minutes, seconds] = parts[0].split(':').map(Number);
        }
        const totalMinutes = days * 86400 + hours * 3600 + minutes * 60 + seconds;
        return isNegative ? totalMinutes : totalMinutes;
    }

    function secondsToTime(seconds) {
        let isNegative = seconds < 0;
        if (isNegative) {
            seconds = Math.abs(seconds);
        }

        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;

        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;

        return isNegative
            ? `${hours}:${minutes}`
            : `${hours}:${minutes}`;
    }

    function secondsToTimeNegative(seconds) {
        let isNegative = seconds < 0;
        if (isNegative) {
            seconds = Math.abs(seconds);
        }

        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;

        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;

        return isNegative
            ? `-${hours}:${minutes}`
            : `-${hours}:${minutes}`;
    }

    useEffect(() => {
        const dadosDash = props.data;
        if (props.type === "pie-time") {
            const valoresInSeconds = dadosDash.valores.map(timeToSeconds);
            const combinedLabels = dadosDash.titulos.map((titulo, index) => {
                return `${titulo}: ${secondsToTime(valoresInSeconds[index])}`;
            });
            if (props.data) {
                const dados = {
                    series: valoresInSeconds,
                    options: {
                        chart: {
                            height: props.height,
                            width: props.width,
                            type: 'pie',
                            zoom: {
                                enabled: false
                            }
                        },
                        title: { text: dadosDash.titulo },
                        noData: { text: "Não há dados para serem exibidos" },
                        labels: combinedLabels
                    },
                };
                setState(dados);
                setLoading(true);
            }
        } else if (props.type === "pie") {
            if (props.data) {
                const dados = {
                    series: dadosDash.valores,
                    options: {
                        chart: {
                            height: props.height,
                            width: props.width,
                            type: props.type,
                            zoom: {
                                enabled: false
                            }
                        },
                        title: { text: dadosDash.titulo },
                        noData: { text: "Não há dados para serem exibidos" },
                        labels: dadosDash.titulos
                    },
                };
                setState(dados);
                setLoading(true);
            }
        }
        else if (props.type === "pie-negative") {
            const valoresInSeconds = dadosDash.valores.map(timeToSeconds);
            const combinedLabels = dadosDash.titulos.map((titulo, index) => {
                return `${titulo}: ${secondsToTimeNegative(valoresInSeconds[index])}`;
            });
            if (props.data) {
                const dados = {
                    series: valoresInSeconds,
                    options: {
                        chart: {
                            height: props.height,
                            width: props.width,
                            type: 'pie',
                            zoom: {
                                enabled: false
                            }
                        },
                        title: { text: dadosDash.titulo },
                        noData: { text: "Não há dados para serem exibidos" },
                        labels: combinedLabels
                    },
                };
                setState(dados);
                setLoading(true);
            }
        } else if (props.type === "line") {
            const dados = {
                series: [{
                    name: "Já Faturado",
                    data: props.data.valoresPorData,
                },
                {
                    name: "Projeção",
                    data: props.data.projecao,
                },
                {
                    name: "Meta",
                    data: props.data.metas,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: ['#DA1919', '#0946B8', '#44DF44'],
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Projeção Faturamento',
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    tooltip: {
                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                            const valores = w.config.series.map((serie, index) => {
                                const value = serie.data[dataPointIndex];
                                const color = w.config.colors[index]; // Pegando a cor da série atual
                                return value ? `
                                <div style="display: flex; align-items: center;">
                                    <span style="display: inline-block; width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin: 4px;">
                                    </span>
                                    ${w.config.series[index].name}: <b>${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                                </div>` : '';
                            });
                            return valores.join('<br>');
                        }
                    },
                    xaxis: {
                        categories: props.data.datas,
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            }
                        }
                    }
                },
            };
            setState(dados);
            setLoading(true);
        } else if (props.type === "bar" && props.group !== true) {
            if (props.data) {
                let dadosDash = [];
                if (props && props.data && props.data.series && props.data.series[0] && props.data.series[0].data) {
                    props.data.series[0].data.forEach((item, i) => {
                        if (props.data.series[0].data[i].data) {
                            props.data.series[0].data[i].data[0] = Math.floor(props.data.series[0].data[i].data[0]);
                        }
                    });
                    dadosDash = props.data.series[0].data;
                }
                const dados = {
                    series: dadosDash,
                    options: {
                        chart: {
                            type: 'bar',
                            height: 350
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded'
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        },
                        xaxis: {
                            categories: [''],
                        },
                        yaxis: {
                            title: {
                                text: props.data.titulo,
                            }
                        },
                        fill: {
                            opacity: 1
                        },
                        noData: { text: "Não há dados para serem exibidos" },
                    },


                };
                setState(dados);
                setLoading(true);
            }
        } else if (props.type === "bar" && props.group === true) {
            if (props.data) {
                const dados = {
                    series: props.data.series,
                    options: {
                        colors: ['#5A9BD3', '#ED7D32', '#A5A4A5'],
                        chart: {
                            type: 'bar',
                            height: 350
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded'
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        },
                        xaxis: {
                            categories: props.data.estados,
                        },
                        yaxis: {
                            title: {
                                text: 'Cockpit'
                            }
                        },
                        fill: {
                            opacity: 1
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return "" + val + ""
                                }
                            }
                        },
                        noData: { text: "Não há dados para serem exibidos" },
                    },
                };
                setState(dados);
                setLoading(true);
            }
        } else if (props.type === "boxplot") {
            if (props.data) {
                const dados = {
                    series: [
                        {
                            name: 'box',
                            type: 'boxPlot',
                            data: [
                                {
                                    x: props.data[0].categoria,
                                    y: [props.data[0].minimo, props.data[0].q1, props.data[0].mediana, props.data[0].q3, props.data[0].maximo]
                                },
                                {
                                    x: props.data[1].categoria,
                                    y: [props.data[1].minimo, props.data[1].q1, props.data[1].mediana, props.data[1].q3, props.data[1].maximo]
                                },
                                {
                                    x: props.data[2].categoria,
                                    y: [props.data[2].minimo, props.data[2].q1, props.data[2].mediana, props.data[2].q3, props.data[2].maximo]
                                },
                                {
                                    x: props.data[3].categoria,
                                    y: [props.data[3].minimo, props.data[3].q1, props.data[3].mediana, props.data[3].q3, props.data[3].maximo]
                                },
                            ]
                        },
                        /*{
                            name: 'outliers',
                            type: 'scatter',
                            data: [
                                {
                                    x: props.data[0].categoria,
                                    y: formataOutliers(props.data[0].outliers)
                                },
                                {
                                    x: props.data[1].categoria,
                                    y: formataOutliers(props.data[1].outliers)
                                },
                                {
                                    x: props.data[2].categoria,
                                    y: formataOutliers(props.data[2].outliers)
                                },
                                {
                                    x: props.data[3].categoria,
                                    y: formataOutliers(props.data[3].outliers)
                                }
                            ]
                        }*/
                    ],
                    options: {
                        chart: {
                            type: 'boxPlot',
                            height: 1050
                        },
                        colors: ['#008FFB', '#FEB019'],
                        title: {
                            text: 'BoxPlot',
                            align: 'left'
                        },
                        xaxis: {
                            type: 'category',
                        },
                        yaxis: {
                            labels: {
                                formatter: function (val) {
                                    return formatMinutesToHoursMinutes(val);
                                }
                            }
                        },
                        tooltip: {
                            shared: false,
                            intersect: true,
                            y: {
                                labels: {
                                    formatter: function (val) {
                                        const minutes = typeof val === 'string' ? parseInt(val, 10) : val;
                                        return formatMinutesToHoursMinutes(minutes) + ' minutos';
                                    }
                                }
                            }
                        }
                    }
                }
                setState(dados);
                setLoading(true);
            }
        }
    }, [])

    function formatMinutesToHoursMinutes(minutes) {
        const hrs = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hrs}:${mins.toString().padStart(2, '0')}`;
    }

    function formataOutliers(valores) {
        let dados: string[] = [];

        valores.forEach(element => {
            const horas = Math.floor(element / 60);
            const minutos = element % 60;
            dados.push(`${horas}h ${minutos.toString().padStart(2, '0')}min`);
        });

        return dados;
    }

    return (
        <div id="chart">
            {!!loading && (
                <div>
                    {
                        props.data.media ? (
                            <div className="texto-dash">
                                <Tooltip title={<><b>Média: </b><p>{props.data.media}</p><b>Total: </b><p>{props.data.total}</p></>}>
                                    <IconButton>
                                        <img src={iconeInfo2} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    <ReactApexChart
                        type={state.options.chart.type} series={state.series}
                        options={state.options} width="100%" height={props.height}
                    ></ReactApexChart>
                </div>
            )}
        </div>
    )
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraVeiculo = async (veiculo, token) => {
  return api.post("veiculo",
    veiculo
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getVeiculo = async (token) => {
  return api.get("veiculo",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getVeiculoPorId = async (id, token) => {
  return api.get("veiculo/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteVeiculoPorId = async (id, token) => {
  return api.delete("veiculo/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaVeiculoPorId = async (veiculo, token) => {
  return api.put("veiculo",
    veiculo,
  {
    headers: {
      'Authorization': token
    }
  })
}
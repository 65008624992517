import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalLancarVeiculo/ModalLancarVeiculo.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import { valorMask } from "../../regex/valorMask";
import ModalCadastro from "../ModalCadastro/ModalCadastro.tsx";
import { FormControl } from "@mui/material";
import LoadingBar from "../LoadingBar/LoadingBar";
import Select from 'react-select';
import ClearIcon from '@mui/icons-material/Clear';
import NewIcon from "../NewIcon/NewIcon";
import RequestControllerRedux from "../../contexts/RequestController.js";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    listaVeiculos: any[];
    open: boolean;
    onClose: () => void;
    onClick: () => void;
    setVeiculo: (e) => void;
    setMotorista: (e) => void;
}

function ModalLancarVeiculo(props: SimpleDialogProps) {

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const [values, setValues] = useState<any>([]);

    const handleClose = () => {
        onClose();
        clearPlacaCavalo();
    };

    const handleClick = () => {
        onClick();
    };

    const [verificaMotorista2, setVerificaMorista2] = useState(true);

    const [listaMotoristas, setListaMotoristas] = useState([]);

    const [listaMotoristas2, setListaMotoristas2] = useState([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const [idAtual, setIdAtual] = useState(0);

    const [disable, setDisable] = useState(false);

    const [openCadastroVeiculo, setOpenCadastroVeiculo] = useState(false);

    const [loadingSelectVeiculo, setLoadingSelectVeiculo] = useState(false);

    const handleCloseCadastroVeiculo = () => {
        if (idAtual) {
            setOpenCadastroVeiculo(false);
            setDadosRequestControllerRedux("Close Veiculo");
        } else {
            setLoadingSelectVeiculo(true);
            setDadosRequestControllerRedux("Close Veiculo");
        }
    };

    const clearPlacaCavalo = () => {
        setValues((current) => ({
            ...current,
            veiculoId: null,
            placaCavalo: '',
            placaCarreta: '',
            placaCarreta2: '',
            proprietario: '',
            motorista: '',
        }));
        setValuesSelect(current => ({ ...current, placaCavalo: '' }));
    };

    const opcoesCavalo = props?.listaVeiculos?.map((data) => ({
        value: data.placaCavalo,
        label: data.placaCavalo,
        ...data
    }));

    const labelPlaca = (data) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row' }}>
            {data.placaCavalo}
            {values.placaCavalo === data.label &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearPlacaCavalo();
                    }} />
            }
        </div>
    );

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            //border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    useEffect(() => {
        setLoadingSelectVeiculo(true);
        setTimeout(() => {
            setLoadingSelectVeiculo(false);
        }, 1000);
    }, [props.listaVeiculos]);

    useEffect(() => {
        const placaSelecionado = opcoesCavalo.find(option =>
            option.placaCavalo === tabelaRedux?.row?.placaCavalo
        );
        if (placaSelecionado) {
            setValuesSelect(current => ({ ...current, placaCavalo: placaSelecionado }));
            setValues(current => ({ ...current, veiculoId: placaSelecionado.idVeiculo }));
            props.setVeiculo(placaSelecionado.idVeiculo);
        }
        if (placaSelecionado?.proprietario != null) {
            setValues(current => ({ ...current, proprietario: placaSelecionado.proprietario }));
        }
        if (placaSelecionado?.placaCarreta != null) {
            setValues(current => ({ ...current, placaCarreta: placaSelecionado.placaCarreta }));
        }
        if (placaSelecionado?.motorista != null) {
            setValues(current => ({ ...current, motorista: placaSelecionado.motorista }));
            props.setMotorista(placaSelecionado.motorista);
        }
        if (placaSelecionado?.placaCarreta2 != null) {
            setValues(current => ({ ...current, placaCarreta2: placaSelecionado.placaCarreta2 }));
        }
    }, [tabelaRedux]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-veiculo">
                        <DialogContentText id="alert-dialog-description">
                            <div>
                                <div className="coluna-lancar-veiculo">
                                    {
                                        loadingSelectVeiculo ? (
                                            <LoadingBar />
                                        ) : (
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb">Placa do Cavalo:</p>
                                                    <FormControl sx={{ width: 250 }}>
                                                        <Select
                                                            value={valuesSelect.placaCavalo}
                                                            options={opcoesCavalo}
                                                            formatOptionLabel={labelPlaca}
                                                            styles={estiloFrota}
                                                            placeholder="Selecione"
                                                            noOptionsMessage={() => "Não há mais opções"}
                                                            onChange={(selectedOption) => {
                                                                if (selectedOption) {
                                                                    if (selectedOption.motorista2 != null && selectedOption.motorista2 != "") {
                                                                        setVerificaMorista2(false);
                                                                        setListaMotoristas(selectedOption.motorista);
                                                                        setListaMotoristas2(selectedOption.motorista2);
                                                                    } else {
                                                                        setVerificaMorista2(true);
                                                                        setListaMotoristas(selectedOption.motorista);
                                                                        setValues(current => ({ ...current, motorista: selectedOption.motorista }));
                                                                        props.setMotorista(selectedOption.motorista);
                                                                    }
                                                                    props.setVeiculo(selectedOption.idVeiculo);
                                                                    setValues((current) => ({
                                                                        ...current,
                                                                        veiculoId: selectedOption.idVeiculo,
                                                                        placaCavalo: selectedOption.placaCavalo,
                                                                        placaCarreta: selectedOption.placaCarreta,
                                                                        placaCarreta2: selectedOption.placaCarreta2,
                                                                        proprietario: selectedOption.proprietario,
                                                                        motorista: selectedOption.motorista,
                                                                    }));
                                                                    setValuesSelect(current => ({ ...current, placaCavalo: selectedOption }));
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <NewIcon
                                                    onClick={(e) => {
                                                        setOpenCadastroVeiculo(true); setIdAtual(0); setDisable(false);
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                    <div className="coluna-emb">
                                        <div>
                                            <p className="input-label-emb">Proprietário:</p>
                                            <input
                                                type="text"
                                                className="input-emb"
                                                style={{ width: '290px' }}
                                                onChange={(e) => {
                                                    setValues(current => ({ ...current, proprietario: e.target.value }));
                                                }}
                                                value={values.proprietario}
                                                disabled={true} />
                                        </div>
                                    </div>
                                    <div className="coluna-emb">
                                        <div>
                                            <p className="input-label-emb">Placa da Carreta:</p>
                                            <input
                                                type="text"
                                                className="input-emb"
                                                style={{ width: '290px' }}
                                                onChange={(e) => {
                                                    setValues(current => ({ ...current, placaCarreta: e.target.value }));
                                                }}
                                                value={values.placaCarreta}
                                                disabled={true} />
                                        </div>
                                    </div>
                                    <div className="coluna-emb">
                                        <div>
                                            <p className="input-label-emb">Motorista:</p>
                                            {
                                                verificaMotorista2 ? (
                                                    <input
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '290px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, motorista: e.target.value }));
                                                            props.setMotorista(e.target.value);
                                                        }}
                                                        value={values.motorista}
                                                        disabled={verificaMotorista2} />
                                                ) : (
                                                    <Select
                                                        options={[
                                                            { value: listaMotoristas, label: listaMotoristas },
                                                            { value: listaMotoristas2, label: listaMotoristas2 },
                                                        ]}
                                                        styles={estiloFrota}
                                                        value={{ value: values.motorista, label: values.motorista }}
                                                        isDisabled={verificaMotorista2}
                                                        onChange={(selectedOption) => {
                                                            setValues((current) => ({ ...current, motorista: selectedOption?.value }));
                                                            props.setMotorista(selectedOption?.value);
                                                        }}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="coluna-emb">
                                        <div>
                                            <p className="input-label-emb">Placa Segunda Carreta:</p>
                                            <input
                                                type="text"
                                                className="input-emb"
                                                style={{ width: '290px' }}
                                                onChange={(e) => {
                                                    setValues(current => ({ ...current, placaCarreta2: e.target.value }));
                                                }}
                                                value={values.placaCarreta2}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <div>
                <ModalCadastro
                    titulo="Cadastro"
                    tituloConfirmar="OK"
                    cadastroVeiculo="sim"
                    open={openCadastroVeiculo}
                    idAtual={idAtual}
                    desabilitar={disable}
                    onClose={handleCloseCadastroVeiculo}
                />
            </div>
        </div>
    );
}

export default ModalLancarVeiculo;
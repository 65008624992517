import { api } from "../ModuloGerenciador/UrlPadrao"

export const gerarUsuarioMotorista = async (motorista, token) => {
  return api.post("UsuariosClientes/gerarUsuario",
    motorista
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const cadastraMotorista = async (motorista, token) => {
  return api.post("motorista",
    motorista
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotorista = async (token) => {
  return api.get("motorista",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotoristaPorId = async (id, token) => {
  return api.get("motorista/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteMotoristaPorId = async (id, token) => {
  return api.delete("motorista/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaMotoristaPorId = async (motorista, token) => {
  return api.put("motorista",
    motorista,
  {
    headers: {
      'Authorization': token
    }
  })
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraMercadoria = async (mercadoria, token) => {
  return api.post("Mercadoria",
    mercadoria
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getMercadoria = async (token) => {
  return api.get("Mercadoria",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getMercadoriaPorId = async (id, token) => {
  return api.get("Mercadoria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteMercadoriaPorId = async (id, token) => {
  return api.delete("Mercadoria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaMercadoriaPorId = async (mercadoria, token) => {
  return api.put("Mercadoria",
    mercadoria,
  {
    headers: {
      'Authorization': token
    }
  })
}
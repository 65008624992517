import React, { useContext } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalConfirmacao/ModalConfirmacao.css';
import { PropaneSharp } from '@mui/icons-material';
import CustomButton from '../CustomButton/CustomButton';
import CoresRedux from "../../contexts/Cor";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    texto: string;
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}

function ModalConfirmacao(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
        onClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-confirmacao'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.texto}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar-gray"
                            classText="button-texto-cadastrar"
                            titulo="Voltar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cadastrar-red"
                            classText="button-texto-cadastrar"
                            titulo="Excluir"
                            cor="#DC2D2D"
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalConfirmacao;
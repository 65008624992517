import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraCaminhao = async (caminhao, token) => {
  return api.post("caminhao",
    caminhao
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getCaminhao = async (token) => {
  return api.get("caminhao",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCaminhaoPorId = async (id, token) => {
  return api.get("caminhao/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteCaminhaoPorId = async (id, token) => {
  return api.delete("caminhao/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCaminhaoPorId = async (caminhao, token) => {
  return api.put("caminhao",
    caminhao,
  {
    headers: {
      'Authorization': token
    }
  })
}
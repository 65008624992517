import React, { useContext, useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import NavbarV2 from "../../../../components/NavbarV2/NavbarV2";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import CoresRedux from "../../../../contexts/Cor";
import MenusRedux from "../../../../contexts/Menus";
import "./ConfiguracoesColunas.css";
import InputLabel from "@mui/material/InputLabel";
import Text from "../../../../components/Text/Text";
import ModalAtualizar from "../../../../components/ModalAtualizar/ModalAtualizar.tsx";
import { editaEmpresa, getEmpresaPorId } from "../../../../services/ModuloGerenciador/Administrativo";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { editaUsuarioTabelaPorId } from "../../../../services/ModuloGerenciador/UsuarioTabela";
import { motion } from "framer-motion";

function ConfiguracoesColunas() {
    const { dadosTabelaRedux, setDadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { menusRedux, setDadosMenusRedux } = useContext(MenusRedux);

    const { dadosCoresRedux, setDadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [showCidadeEstado, setShowCidadeEstado] = useState(false);

    const [showEmbalagem, setShowEmbalagem] = useState(false);

    const [showMotivoOnTime, setShowMotivoOnTime] = useState(false);

    const [showGrupoCarga, setShowGrupoCarga] = useState(false);

    const [showEmbarcador, setShowEmbarcador] = useState(false);

    const [showEmbarque, setShowEmbarque] = useState(false);

    const [showEmpresaSub, setShowEmpresaSub] = useState(false);

    const [showFilial, setShowFilial] = useState(false);

    const [showMeioPagamento, setShowMeioPagamento] = useState(false);

    const [showMercadoria, setShowMercadoria] = useState(false);

    const [showMotorista, setShowMotorista] = useState(false);

    const [showNotificacao, setShowNotificacao] = useState(false);

    const [showPedido, setShowPedido] = useState(false);

    const [showProprietario, setShowProprietario] = useState(false);

    const [showRota, setShowRota] = useState(false);

    const [showSolicitacao, setShowSolicitacao] = useState(false);

    const [showStatus, setShowStatus] = useState(false);

    const [showTaxaIcms, setShowTaxaIcms] = useState(false);

    const [showUnidadeEmbarque, setShowUnidadeEmbarque] = useState(false);

    const [showUsuarioGeren, setShowUsuarioGeren] = useState(false);

    const [showMotivo, setShowMotivo] = useState(false);

    const [showVeiculo, setShowVeiculo] = useState(false);

    const [showCaminhao, setShowCaminhao] = useState(false);

    const [showCarreta, setShowCarreta] = useState(false);

    const [showAvisos, setShowAvisos] = useState(false);

    const [showTipoCheckagem, setShowTipoCheckagem] = useState(false);

    const [showCheckagem, setShowCheckagem] = useState(false);

    const [showTipoRastreamento, setShowTipoRastreamento] = useState(false);

    const [showGrupoClientes, setShowGrupoClientes] = useState(false);

    const [showCockpit, setShowCockpit] = useState(false);

    const [showGrupoComClientes, setShowGrupoComClientes] = useState(false);

    const [showGrupoCargaVinculo, setShowGrupoCargaVinculo] = useState(false);

    const [showCotacao, setShowCotacao] = useState(false);

    const [showRastreamento, setShowRastreamento] = useState(false);

    const [showGrupoPrecificacao, setShowGrupoPrecificacao] = useState(false);

    const [showValorEmbarque, setShowValorEmbarque] = useState(false);

    const [showTipoVeiculo, setShowTipoVeiculo] = useState(false);

    const [showTipoCarroceria, setShowTipoCarroceria] = useState(false);

    const [showMotivoCotacao, setShowMotivoCotacao] = useState(false);

    const [showSegmentoCliente, setShowSegmentoCliente] = useState(false);

    const [showOcorrencia, setShowOcorrencia] = useState(false);

    const [showTipoOcorrencia, setShowTipoOcorrencia] = useState(false);

    const [showSubTipoOcorrencia, setShowSubTipoOcorrencia] = useState(false);

    const [cidadeValue, setCidadeValue] = useState(dadosTabelaRedux.CidadeEstado[0]);

    const [estadoValue, setEstadoValue] = useState(dadosTabelaRedux.CidadeEstado[1]);

    const [abreviacaoValue, setAbreviacaoValue] = useState(dadosTabelaRedux.CidadeEstado[2]);

    const [embalagemValue, setEmbalagemValue] = useState(dadosTabelaRedux.Embalagem[0]);

    const [nomeFantasiaValue, setNomeFantasiaValue] = useState(dadosTabelaRedux.Cliente[0]);

    const [razaoSocialValue, setRazaoSocialValue] = useState(dadosTabelaRedux.Cliente[1]);

    const [cnpjClienteValue, setCnpjClienteValue] = useState(dadosTabelaRedux.Cliente[2]);

    const [enderecoValue, setEnderecoValue] = useState(dadosTabelaRedux.Cliente[3]);

    const [apelidoValue, setApelidoValue] = useState(dadosTabelaRedux.Cliente[4]);

    const [diFreteFiscalValue, setDiFreteFiscalValue] = useState(dadosTabelaRedux.Cliente[5]);

    const [diPedagioValue, setDiPedagioValue] = useState(dadosTabelaRedux.Cliente[6]);

    const [diClassificacaoValue, setDiClassificacaoValue] = useState(dadosTabelaRedux.Cliente[7]);

    const [usuarioClienteValue, setUsuarioClienteValue] = useState(dadosTabelaRedux.Cliente[8]);

    const [descricaoValue, setDescricaoValue] = useState(dadosTabelaRedux.Embarque[0]);

    const [unidEmbarqueValue, setUnidEmbarqueValue] = useState(dadosTabelaRedux.Embarque[1]);

    const [empresaValue, setEmpresaValue] = useState(dadosTabelaRedux.Embarque[2]);

    const [filialValue, setFilialValue] = useState(dadosTabelaRedux.Embarque[3]);

    const [rotaEmbarqueValue, setRotaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[4]);

    const [pedidoValue, setPedidoValue] = useState(dadosTabelaRedux.Embarque[5]);

    const [cteValue, setCteValue] = useState(dadosTabelaRedux.Embarque[6]);

    const [previsaoEmbarqueValue, setPrevisaoEmbarqueValue] = useState(dadosTabelaRedux.Embarque[7]);

    const [previsaoEntregaValue, setPrevisaoEntregaValue] = useState(dadosTabelaRedux.Embarque[8]);

    const [dataHoraChegadaEmbarqueValue, setDataHoraChegadaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[9]);

    const [dataHoraEmbarcadoValue, setDataHoraEmbarcadoValue] = useState(dadosTabelaRedux.Embarque[10]);

    const [dataHoraChegadaEntregaValue, setDataHoraChegadaEntregaValue] = useState(dadosTabelaRedux.Embarque[11]);

    const [dataHoraEntregueValue, setDataHoraEntregueValue] = useState(dadosTabelaRedux.Embarque[12]);

    const [aceiteValue, setAceiteValue] = useState(dadosTabelaRedux.Embarque[13]);

    const [statusValue, setStatusValue] = useState(dadosTabelaRedux.Embarque[14]);

    const [placaCavaloValue, setPlacaCavaloValue] = useState(dadosTabelaRedux.Embarque[15]);

    const [placaCarretaValue, setPlacaCarretaValue] = useState(dadosTabelaRedux.Embarque[16]);

    const [placaCarreta2Value, setPlacaCarreta2Value] = useState(dadosTabelaRedux.Embarque[17]);

    const [proprietarioValue, setProprietarioValue] = useState(dadosTabelaRedux.Embarque[18]);

    const [motoristaValue, setMotoristaValue] = useState(dadosTabelaRedux.Embarque[19]);

    const [freteFiscalValue, setFreteFiscalValue] = useState(dadosTabelaRedux.Embarque[20]);

    const [icmsValue, setIcmsValue] = useState(dadosTabelaRedux.Embarque[21]);

    const [freteMotoristaValue, setFreteMotoristaValue] = useState(dadosTabelaRedux.Embarque[22]);

    const [adicionalEmpresaValue, setAdicionalEmpresaValue] = useState(dadosTabelaRedux.Embarque[23]);

    const [adicionalMotoristaValue, setAdicionalMotoristaValue] = useState(dadosTabelaRedux.Embarque[24]);

    const [numeroEixosEmbarqueValue, setNumeroEixosEmbarqueValue] = useState(dadosTabelaRedux.Embarque[25]);

    const [pedagioValue, setPedagioValue] = useState(dadosTabelaRedux.Embarque[26]);

    const [classificacaoValue, setClassificacaoValue] = useState(dadosTabelaRedux.Embarque[27]);

    const [margemValue, setMargemValue] = useState(dadosTabelaRedux.Embarque[28]);

    const [adiantamentoValue, setAdiamentoValue] = useState(dadosTabelaRedux.Embarque[29]);

    const [saldoValue, setSaldoValue] = useState(dadosTabelaRedux.Embarque[30]);

    const [meioPagamentoValue, setMeioPagamentoValue] = useState(dadosTabelaRedux.Embarque[31]);

    const [meioPagamento2Value, setMeioPagamento2Value] = useState(dadosTabelaRedux.Embarque[32]);

    const [kmValue, setKmValue] = useState(dadosTabelaRedux.Embarque[33]);

    const [clienteValue, setClienteValue] = useState(dadosTabelaRedux.Embarque[34]);

    const [embarcadorEmbValue, setEmbarcadorEmbValue] = useState(dadosTabelaRedux.Embarque[35]);

    const [destinatarioEmbValue, setDestinatarioEmbValue] = useState(dadosTabelaRedux.Embarque[36]);

    const [loadValue, setLoadValue] = useState(dadosTabelaRedux.Embarque[37]);

    const [mercadoriaValue, setMercadoriaValue] = useState(dadosTabelaRedux.Embarque[38]);

    const [pesoValue, setPesoValue] = useState(dadosTabelaRedux.Embarque[39]);

    const [embalagemEmbValue, setEmbalagemEmbValue] = useState(dadosTabelaRedux.Embarque[40]);

    const [observacaoValue, setObservacaoValue] = useState(dadosTabelaRedux.Embarque?.[41]);

    const [frotaValue, setFrotaValue] = useState(dadosTabelaRedux.Embarque?.[42]);

    const [canceladoValue, setCanceladoValue] = useState(dadosTabelaRedux.Embarque?.[43]);

    const [motivoEmbarqueValue, setMotivoEmbarqueValue] = useState(dadosTabelaRedux.Embarque?.[44]);

    const [usuarioResponsavelValue, setUsuarioResponsavelValue] = useState(dadosTabelaRedux.Embarque?.[45]);

    const [nEntregasValue, setNEntregasValue] = useState(dadosTabelaRedux.Embarque?.[46]);

    const [comprovanteValue, setComprovanteValue] = useState(dadosTabelaRedux.Embarque?.[47]);

    const [checkinValue, setCheckinValue] = useState(dadosTabelaRedux.Embarque?.[48]);

    const [quebraTabelaEmbarque, setQuebraTabelaEmbarqueValue] = useState(dadosTabelaRedux.Embarque?.[49]);

    const [nomeValue, setNomeValue] = useState(dadosTabelaRedux.EmpresaSub[0]);

    const [cnpjValue, setCnpjValue] = useState(dadosTabelaRedux.EmpresaSub[1]);

    const [cidadeEmpresaSubValue, setCidadeEmpresaSubValue] = useState(dadosTabelaRedux.EmpresaSub[2]);

    const [regimeTributarioValue, setegimeTributarioValue] = useState(dadosTabelaRedux.EmpresaSub[3]);

    const [nomeFiliaValue, setNomeFilialValue] = useState(dadosTabelaRedux.Filial[0]);

    const [complementoLocalizacaoValue, setComplementoLocalizacaoValue] = useState(dadosTabelaRedux.Filial[1]);

    const [responsavelValue, setResponsavelValue] = useState(dadosTabelaRedux.Filial[2]);

    const [telefoneValue, setTelefoneValue] = useState(dadosTabelaRedux.Filial[3]);

    const [emailValue, setEmailValue] = useState(dadosTabelaRedux.Filial[4]);

    const [cidadeFilialValue, setCidadeFilialValue] = useState(dadosTabelaRedux.Filial[5]);

    const [nomeMeioPagamentoValue, setNomeMeioPagamentoValue] = useState(dadosTabelaRedux.MeioPagamento[0]);

    const [mercadoriaNomeValue, setMercadoriaNomeValue] = useState(dadosTabelaRedux.Mercadoria[0]);

    const [nomeMotoristaValue, setNomeMotoristaValue] = useState(dadosTabelaRedux.Motorista?.[0]);

    const [numeroDocumentoValue, setNumeroDocumentoValue] = useState(dadosTabelaRedux.Motorista?.[1]);

    const [tipoDocumentoValue, setTipoDocumentoValue] = useState(dadosTabelaRedux.Motorista?.[2]);

    const [telefoneMotoristaValue, setTelefoneMotoristaValue] = useState(dadosTabelaRedux.Motorista?.[3]);

    const [nomeEmergenciaValue, setNomeEmergenciaValue] = useState(dadosTabelaRedux.Motorista?.[4]);

    const [telefoneEmergenciaValue, setTelefoneEmergenciaValue] = useState(dadosTabelaRedux.Motorista?.[5]);

    const [frotaMotoristaValue, setFrotaMotoristaValue] = useState(dadosTabelaRedux.Motorista?.[6]);

    const [usuarioMotoristaValue, setUsuarioMotoristaValue] = useState(dadosTabelaRedux.Motorista?.[7]);

    const [bloqueadoValue, setBloqueadoValue] = useState(dadosTabelaRedux.Motorista?.[8]);

    const [motivoBloqueadoValue, setMotivoBloqueadoValue] = useState(dadosTabelaRedux.Motorista?.[9]);

    const [tituloNotificaoValue, setTituloNotificaoValue] = useState(dadosTabelaRedux.Notificacao[0]);

    const [mensagemNotificacaoValue, setMensagemNotificacaoValue] = useState(dadosTabelaRedux.Notificacao[1]);

    const [acaoNotificacaoValue, setAcaoNotificacaoValue] = useState(dadosTabelaRedux.Notificacao[2]);

    const [numeroVisualizacoesNotificacaoValue, setNumeroVisualizacoesNotificacaoValueValue] = useState(dadosTabelaRedux.Notificacao[3]);

    const [dataNotificacaoValue, setDataNotificacaoValuValue] = useState(dadosTabelaRedux.Notificacao[4]);

    const [pedidoPValue, setPedidoPValue] = useState(dadosTabelaRedux.Pedido[0]);

    const [tipoRotaValue, setTipoRotaValue] = useState(dadosTabelaRedux.Pedido[1]);

    const [freteFiscalPValue, setFreteFiscalPValue] = useState(dadosTabelaRedux.Pedido[2]);

    const [icmsPValue, setIcmsPValue] = useState(dadosTabelaRedux.Pedido[3]);

    const [pedagioPValue, setPedagioPValue] = useState(dadosTabelaRedux.Pedido[4]);

    const [classificacaoPValue, setClassificacaoPValue] = useState(dadosTabelaRedux.Pedido[5]);

    const [kmPValue, setKmPValue] = useState(dadosTabelaRedux.Pedido[6]);

    const [clientePValue, setClientePValue] = useState(dadosTabelaRedux.Pedido[7]);

    const [embarcadorPValue, setEmbarcadorPValue] = useState(dadosTabelaRedux.Pedido[8]);

    const [destinatarioPValue, setDestinatarioPValue] = useState(dadosTabelaRedux.Pedido[9]);

    const [freteMotoristaPValue, setFreteMotoristaPValue] = useState(dadosTabelaRedux.Pedido[10]);

    const [observacaoPValue, setObservacaoPValue] = useState(dadosTabelaRedux.Pedido[11]);

    const [permiteAlterarFreteFiscalValue, setPermiteAlterarFreteFiscalValue] = useState(dadosTabelaRedux.Pedido?.[12]);

    const [valorEmbarquePedidoValue, setValorEmbarquePedidoValue] = useState(dadosTabelaRedux.Pedido[13]);

    const [icmsEmbutido, setIcmsEmbutidoValue] = useState(dadosTabelaRedux.Pedido[14]);

    const [icmsEmbarcador, setIcmsEmbarcadorValue] = useState(dadosTabelaRedux.Pedido[15]);

    const [freteNormal, setFreteNormalValue] = useState(dadosTabelaRedux.Pedido[16]);

    const [quebraTabelaPedido, setQuebraTabelaPedidoValue] = useState(dadosTabelaRedux.Pedido[17]);

    const [proprietarioNomeValue, setProprietarioNomeValue] = useState(dadosTabelaRedux.Proprietario[0]);

    const [cnpjProprietarioValue, setCnpjProprietarioValue] = useState(dadosTabelaRedux.Proprietario[1]);

    const [usuarioProprietarioValue, setUsuarioProprietarioValue] = useState(dadosTabelaRedux.Proprietario[2]);

    const [nomeRotaValue, setNomeRotaValue] = useState(dadosTabelaRedux.Rota[0]);

    const [motivoValue, setMotivoValues] = useState(dadosTabelaRedux.Motivo[0]);

    const [cidadeOrigemRotaValue, setCidadeOrigemRotaValue] = useState(dadosTabelaRedux.Rota[1]);

    const [estadoOrigemRotaValue, setEstadoOrigemRotaValue] = useState(dadosTabelaRedux.Rota[2]);

    const [cidadeDestinoRotaValue, setCidadeDestinoRotaValue] = useState(dadosTabelaRedux.Rota[3]);

    const [estadoDestinoRotaValue, setEstadoDestinoRotaValue] = useState(dadosTabelaRedux.Rota[4]);

    const [usuarioSolicitacaoValue, setUsuarioSolicitacaoValue] = useState(dadosTabelaRedux.Solicitacoes[0]);

    const [emailSolicitacaoValue, setEmailSolicitacaoValue] = useState(dadosTabelaRedux.Solicitacoes[1]);

    const [telefoneSolicitacaoValue, setTelefoneSolicitacaoValue] = useState(dadosTabelaRedux.Solicitacoes[2]);

    const [nascimentoSolicitacaoValue, setNascimentoSolicitacaoValue] = useState(dadosTabelaRedux.Solicitacoes[3]);

    const [aceitarSolicitacaoValue, setAceitarSolicitacaoValue] = useState(dadosTabelaRedux.Solicitacoes[4]);

    const [recusarSolicitacaoValue, setRecusarSolicitacaoValue] = useState(dadosTabelaRedux.Solicitacoes[5]);

    const [nomeStatusValue, setNomeStatusValue] = useState(dadosTabelaRedux.Status[0]);

    const [taxaIcmsValue, setTaxaIcmsValue] = useState(dadosTabelaRedux.TaxaIcms[0]);

    const [ufOrigemTaxaValue, setUfOrigemTaxaValue] = useState(dadosTabelaRedux.TaxaIcms[1]);

    const [ufDestinoTaxaValue, setUfDestinoTaxaValue] = useState(dadosTabelaRedux.TaxaIcms[2]);

    const [nomeUnidadeEmbarqueValue, setNomeUnidadeEmbarqueValue] = useState(dadosTabelaRedux.UnidadeEmbarque[0]);

    const [cidadeUnidadeEmbarqueValue, setCidadeUnidadeEmbarqueValue] = useState(dadosTabelaRedux.UnidadeEmbarque[1]);

    const [frotaUnidadeEmbarqueValue, setFrotaUnidadeEmbarqueValue] = useState(dadosTabelaRedux.UnidadeEmbarque[2]);

    const [nomeUsuarioGerenValue, setNomeUsuarioGerenValue] = useState(dadosTabelaRedux.UsuarioGeren[0]);

    const [senhaUsuarioGerenValue, setSenhaUsuarioGerenValue] = useState(dadosTabelaRedux.UsuarioGeren[1]);

    const [emailUsuarioGerenValue, setEmailUsuarioGerenValue] = useState(dadosTabelaRedux.UsuarioGeren[2]);

    const [telefoneUsuarioGerenValue, setTelefoneUsuarioGerenValue] = useState(dadosTabelaRedux.UsuarioGeren[3]);

    const [nascimentoUsuarioGerenValue, setNascimentoUsuarioGerenValue] = useState(dadosTabelaRedux.UsuarioGeren[4]);

    const [permissoesUsuarioGerenValue, setPermissoesUsuarioGerenValue] = useState(dadosTabelaRedux.UsuarioGeren[5]);

    const [ultimoLoginValue, setUltimoLoginValue] = useState(dadosTabelaRedux.UsuarioGeren[6]);

    const [ultimoLogoutValue, setUltimoLogoutValue] = useState(dadosTabelaRedux.UsuarioGeren[7]);

    const [placaCavaloVeiculoValue, setPlacaCavaloVeiculoValue] = useState(dadosTabelaRedux.Veiculo[0]);

    const [placaCarretaVeiculoValue, setPlacaCarretaVeiculoValue] = useState(dadosTabelaRedux.Veiculo[1]);

    const [placaCarreta2VeiculoValue, setPlacaCarreta2VeiculoValue] = useState(dadosTabelaRedux.Veiculo[2]);

    const [proprietarioVeiculoValue, setProprietarioVeiculoValue] = useState(dadosTabelaRedux.Veiculo[3]);

    const [motoristaVeiculoValue, setMotoristaVeiculoValue] = useState(dadosTabelaRedux.Veiculo[4]);

    const [motorista2VeiculoValue, setMotorista2VeiculoValue] = useState(dadosTabelaRedux.Veiculo[5]);

    const [eixosValue, setEixosValue] = useState(dadosTabelaRedux.Veiculo?.[6]);

    const [tipoVeiculoValue, setTipoVeiculoValue] = useState(dadosTabelaRedux.Veiculo?.[7]);

    const [placaCaminhaoValue, setPlacaCaminhaoValue] = useState(dadosTabelaRedux.Caminhao[0]);

    const [anoCaminhaoValue, setAnoCaminhaoValue] = useState(dadosTabelaRedux.Caminhao[1]);

    const [renavamCaminhaoValue, setRenavamCaminhaoValue] = useState(dadosTabelaRedux.Caminhao[2]);

    const [marcaCaminhaoValue, setMarcaCaminhaoValue] = useState(dadosTabelaRedux.Caminhao[3]);

    const [modeloCaminhaoValue, setModeloCaminhaoValue] = useState(dadosTabelaRedux.Caminhao[4]);

    const [frotaCaminhaoValue, setFrotaCaminhaoValue] = useState(dadosTabelaRedux.Caminhao[5]);

    const [kmCaminhaoValue, setKmCaminhaoValue] = useState(dadosTabelaRedux.Caminhao?.[6]);

    const [eixosCaminhaoValue, setEixosCaminhaoValue] = useState(dadosTabelaRedux.Caminhao?.[7]);

    const [placaCarretaCaminhaoValue, setPlacaCarretaCaminhaoValue] = useState(dadosTabelaRedux.Carreta[0]);

    const [anoCarretaCaminhaoValue, setAnoCarretaCaminhaoValue] = useState(dadosTabelaRedux.Carreta[1]);

    const [renavamCarretaCaminhaoValue, setRenavamCarretaCaminhaoValue] = useState(dadosTabelaRedux.Carreta[2]);

    const [marcaCarretaCaminhaoValue, setMarcaCarretaCaminhaoValue] = useState(dadosTabelaRedux.Carreta[3]);

    const [modeloCarretaCaminhaoValue, setModeloCarretaCaminhaoValue] = useState(dadosTabelaRedux.Carreta[4]);

    const [frotaCarretaValue, setFrotaCarretaValue] = useState(dadosTabelaRedux.Carreta[5]);

    const [kmCarretaValue, setKmCarretaValue] = useState(dadosTabelaRedux.Carreta?.[6]);

    const [eixosCarretaValue, setEixosCarretaValue] = useState(dadosTabelaRedux.Carreta?.[7]);

    const [mensagemAvisoValue, setMensagemAvisoValue] = useState(dadosTabelaRedux.Avisos?.[0]);

    const [dataAvisoValue, setDataAvisoValue] = useState(dadosTabelaRedux.Avisos?.[1]);

    const [nomeTipoCheckagemValue, setNomeTipoCheckagemValue] = useState(dadosTabelaRedux.TipoCheckagem?.[0]);

    const [notificarAutomaticoValue, setNotificarAutomaticoValue] = useState(dadosTabelaRedux.TipoCheckagem?.[1]);

    const [tipoNotificacaoValue, setTipoNotificacaoValue] = useState(dadosTabelaRedux.TipoCheckagem?.[2]);

    const [notificarACadaDataValue, setNotificarACadaDataValue] = useState(dadosTabelaRedux.TipoCheckagem?.[3]);

    const [notificarACadaKMValue, setNotificarACadaKMValue] = useState(dadosTabelaRedux.TipoCheckagem?.[4]);

    const [dadosCheckagemValue, setDadosCheckagemValue] = useState(dadosTabelaRedux.Checkagem?.[0]);

    const [dataCheckagemValue, setDataCheckagemValue] = useState(dadosTabelaRedux.Checkagem?.[1]);

    const [observacaoCheckagemValue, setObservacaoCheckagemValue] = useState(dadosTabelaRedux.Checkagem?.[2]);

    const [kmCheckagemValue, setKmCheckagemValue] = useState(dadosTabelaRedux.Checkagem?.[3]);

    const [localCheckagemValue, setLocalCheckagemValue] = useState(dadosTabelaRedux.Checkagem?.[4]);

    const [tipoManutencaoCheckagemValue, setTipoManutencaoCheckagemValue] = useState(dadosTabelaRedux.Checkagem?.[5]);

    const [proximaRevisaoDataValue, setProximaRevisaoDataValue] = useState(dadosTabelaRedux.Checkagem?.[6]);

    const [proximaRevisaoKMValue, setProximaRevisaoKMValue] = useState(dadosTabelaRedux.Checkagem?.[7]);

    const [tipoCheckValue, setTipoCheckValue] = useState(dadosTabelaRedux.Checkagem?.[8]);

    const [nomeTipoRastreamentoValue, setNomeTipoRastreamentoValue] = useState(dadosTabelaRedux.TipoRastreamento?.[0]);

    const [ipValue, setIpValue] = useState(dadosTabelaRedux.TipoRastreamento?.[1]);

    const [usuarioRastreamentoValue, setUsuarioRastreamentoValue] = useState(dadosTabelaRedux.Rastreamento?.[0]);

    const [senhaRastreamentoValue, setSenhaRastreamentoValue] = useState(dadosTabelaRedux.Rastreamento?.[1]);

    const [tipoVeiculoRastreamentoValue, setTipoVeiculoRastreamentoValue] = useState(dadosTabelaRedux.Rastreamento?.[2]);

    const [tipoRastreamentoValue, setTipoRastreamentoValue] = useState(dadosTabelaRedux.Rastreamento?.[3]);

    const [veiculoRastreamentoValue, setVeiculoRastreamentoValue] = useState(dadosTabelaRedux.Rastreamento?.[4]);

    const [fabricanteRastreamentoValue, setFabricanteRastreamentoValue] = useState(dadosTabelaRedux.Rastreamento?.[5]);

    const [numeroRastreadorValue, setNumeroRastreadorValue] = useState(dadosTabelaRedux.Rastreamento?.[6]);

    const [nomeGrupoClienteValue, setNomeGrupoClienteValue] = useState(dadosTabelaRedux.GrupoClientes?.[0]);

    const [projetadoValue, setProjetadoValue] = useState(dadosTabelaRedux.Cockpit?.[0]);

    const [estadoOrigemCockpitValue, setEstadoOrigemCockpitValue] = useState(dadosTabelaRedux.Cockpit?.[1]);

    const [estadoDestinoCockpitValue, setEstadoDestinoCockpitValue] = useState(dadosTabelaRedux.Cockpit?.[2]);

    const [grupoClienteCockpitValue, setGrupoClienteCockpitValue] = useState(dadosTabelaRedux.Cockpit?.[3]);

    const [clienteVinculoValue, setClienteVinculoValue] = useState(dadosTabelaRedux.GrupoComClientes?.[0]);

    const [grupoClienteVinculoValue, setGrupoClienteVinculoValue] = useState(dadosTabelaRedux.GrupoComClientes?.[1]);

    const [grupoPrecificacaoValue, setGrupoPrecificacaoValue] = useState(dadosTabelaRedux.GrupoPrecificacao?.[0]);

    const [eixosValorEmbarqueValue, setEixosValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[0]);

    const [kmValorEmbarqueValue, setKmValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[1]);

    const [valorEixoValorEmbarqueValue, setValorEixoValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[2]);

    const [adicionalCargaDescargaValorEmbarqueValue, setAdicionalCargaDescargaValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[3]);

    const [motoristaValorEmbarqueValue, setMotoristaValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[4]);

    const [empresaValorEmbarqueValue, setEmpresaValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[5]);

    const [icmsValorEmbarqueValue, setIcmsValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[6]);

    const [descargaValorEmbarqueValue, setDescargaValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[7]);

    const [pedagioValorEmbarqueValue, setPedagioValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[8]);

    const [freteFiscalValorEmbarqueValue, setFreteFiscalValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[9]);

    const [rotaValorEmbarqueValue, setRotaValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[10]);

    const [clienteValorEmbarqueValue, setClienteValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[11]);

    const [grupoPrecificacaoValorEmbarqueValue, setGrupoPrecificacaoValorEmbarqueValue] = useState(dadosTabelaRedux.ValorEmbarque?.[12]);

    const [motivoOnTimeValue, setMotivoOnTimeValue] = useState(dadosTabelaRedux.MotivoOnTime?.[0]);

    const [grupoCargaValue, setGrupoCargaValue] = useState(dadosTabelaRedux.GrupoCarga?.[0]);

    const [grupoCargaVinculoValue, setGrupoCargaVinculoValue] = useState(dadosTabelaRedux.GrupoCargaVinculo?.[0]);

    const [motoristaGrupoCargaVinculoVinculoValue, setMotoristaGrupoCargaVinculoValue] = useState(dadosTabelaRedux.GrupoCargaVinculo?.[1]);

    const [proprietarioGrupoCargaVinculoVinculoValue, setProprietarioGrupoCargaVinculoValue] = useState(dadosTabelaRedux.GrupoCargaVinculo?.[2]);

    const [unidadeEmbarqueCotacaoValue, setUnidadeEmbarqueCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[0]);

    const [rotaCotacaoValue, setRotaCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[1]);

    const [clienteCotacaoValue, setClienteCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[2]);

    const [valorCotacaoValue, setValorCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[3]);

    const [icmsCotacaoValue, setIcmsCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[4]);

    const [pedagioCotacaoValue, setPedagioCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[5]);

    const [distanciaKmCotacaoValue, setDistanciaKmCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[6]);

    const [valorPorKmCotacaoValue, setValorPorKmCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[7]);

    const [valorDesejavelMotoristaCotacaoValue, setValorDesejavelMotoristaCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[8]);

    const [descricaoRotaCotacaoValue, setDescricaoRotaCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[9]);

    const [foiCarregadoCotacaoValue, setFoiCarregadoCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[10]);

    const [tipoVeiculoCotacaoValue, setTipoVeiculoCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[11]);

    const [tipoCarroceriaCotacaoValue, setTipoCarroceriaCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[12]);

    const [segmentoCliente, setSegmentoCliente] = useState(dadosTabelaRedux.Cotacao?.[13]);

    const [motivoCotacao, setMotivoCotacao] = useState(dadosTabelaRedux.Cotacao?.[14]);

    const [dataCotacaoValue, setDataCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[15]);

    const [usuarioResponsavelCotacaoValue, setUsuarioResponsavelCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[16]);

    const [nomeContatoCotacaoValue, setNomeContatoCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[17]);

    const [telefoneContatoCotacaoValue, setTelefoneContatoCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[18]);

    const [emailContatoCotacaoValue, setEmailContatoCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[19]);

    const [observacaoCotacaoValue, setObservacaoCotacaoValue] = useState(dadosTabelaRedux.Cotacao?.[20]);

    const [nomeTipoVeiculo, setNomeTipoVeiculo] = useState(dadosTabelaRedux.TipoVeiculo?.[0]);

    const [nomeTipoCarroceria, setNomeTipoCarroceria] = useState(dadosTabelaRedux.TipoCarroceria?.[0]);

    const [motivoCotacaoValue, setMotivoCotacaoValue] = useState(dadosTabelaRedux.MotivoCotacao?.[0]);

    const [segmentoClienteValue, setSegmentoClienteValue] = useState(dadosTabelaRedux.SegmentoCliente?.[0]);

    const [ocorrenciaValue, setOcorrenciaValue] = useState(dadosTabelaRedux.Ocorrencia?.[0]);

    const [tipoOcorrenciaValue, setTipoOcorrenciaValue] = useState(dadosTabelaRedux.TipoOcorrencia?.[0]);

    const [subTipoOcorrenciaValue, setSubTipoOcorrenciaValue] = useState(dadosTabelaRedux.SubTipoOcorrencia?.[0]);

    const [tipoOcorrenciaSubValue, setTipoOcorrenciaSubValue] = useState(dadosTabelaRedux.SubTipoOcorrencia?.[1]);

    const [open, setOpen] = useState(false);

    const [jaPassou, setJaPassou] = useState(false);

    const [loading, setLoading] = useState(false);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const valorInicialVisivel = {
        'Embarque': [
            {
                'nomeColuna': descricaoValue?.nomeColuna,
                'field': 'descricao',
                'visivel': 'true'
            },
            {
                'nomeColuna': unidEmbarqueValue.nomeColuna,
                'field': 'unidEmbarque',
                'visivel': 'true'
            },
            {
                'nomeColuna': empresaValue.nomeColuna,
                'field': 'empresa',
                'visivel': 'true'
            },
            {
                'nomeColuna': filialValue.nomeColuna,
                'field': 'filial',
                'visivel': 'true'
            },
            {
                'nomeColuna': rotaEmbarqueValue.nomeColuna,
                'field': 'rota',
                'visivel': 'true'
            },
            {
                'nomeColuna': pedidoValue.nomeColuna,
                'field': 'pedido',
                'visivel': 'true'
            },
            {
                'nomeColuna': cteValue.nomeColuna,
                'field': 'cte',
                'visivel': 'true'
            },
            {
                'nomeColuna': previsaoEmbarqueValue.nomeColuna,
                'field': 'previsaoEmbarque',
                'visivel': 'true'
            },
            {
                'nomeColuna': previsaoEntregaValue.nomeColuna,
                'field': 'previsaoEntrega',
                'visivel': 'true'
            },
            {
                'nomeColuna': dataHoraChegadaEmbarqueValue.nomeColuna,
                'field': 'dataHoraChegadaEmbarque',
                'visivel': 'true'
            },
            {
                'nomeColuna': dataHoraEmbarcadoValue.nomeColuna,
                'field': 'dataHoraEmbarcado',
                'visivel': 'true'
            },
            {
                'nomeColuna': dataHoraChegadaEntregaValue.nomeColuna,
                'field': 'dataHoraChegadaEntrega',
                'visivel': 'true'
            },
            {
                'nomeColuna': dataHoraEntregueValue.nomeColuna,
                'field': 'dataHoraEntregue',
                'visivel': 'true'
            },
            {
                'nomeColuna': aceiteValue.nomeColuna,
                'field': 'aceite',
                'visivel': 'true'
            },
            {
                'nomeColuna': statusValue.nomeColuna,
                'field': 'status',
                'visivel': 'true'
            },
            {
                'nomeColuna': placaCavaloValue.nomeColuna,
                'field': 'placaCavalo',
                'visivel': 'true'
            },
            {
                'nomeColuna': placaCarretaValue.nomeColuna,
                'field': 'placaCarreta',
                'visivel': 'true'
            },
            {
                'nomeColuna': placaCarreta2Value.nomeColuna,
                'field': 'placaCarreta2',
                'visivel': 'true'
            },
            {
                'nomeColuna': proprietarioValue.nomeColuna,
                'field': 'proprietario',
                'visivel': 'true'
            },
            {
                'nomeColuna': motoristaValue.nomeColuna,
                'field': 'motorista',
                'visivel': 'true'
            },
            {
                'nomeColuna': freteFiscalValue.nomeColuna,
                'field': 'freteFiscal',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': icmsValue.nomeColuna,
                'field': 'icms',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': freteMotoristaValue.nomeColuna,
                'field': 'freteMotorista',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalEmpresaValue.nomeColuna,
                'field': 'adicionalEmpresa',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalMotoristaValue.nomeColuna,
                'field': 'adicionalMotorista',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': numeroEixosEmbarqueValue.nomeColuna,
                'field': 'numeroEixos',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': pedagioValue.nomeColuna,
                'field': 'pedagio',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': classificacaoValue.nomeColuna,
                'field': 'classificacao',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': margemValue.nomeColuna,
                'field': 'margem',
                'visivel': 'true'
            },
            {
                'nomeColuna': adiantamentoValue.nomeColuna,
                'field': 'adiantamento',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': saldoValue.nomeColuna,
                'field': 'saldo',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': meioPagamentoValue.nomeColuna,
                'field': 'meioPagamento',
                'visivel': 'true'
            },
            {
                'nomeColuna': meioPagamento2Value.nomeColuna,
                'field': 'meioPagamento2',
                'visivel': 'true'
            },
            {
                'nomeColuna': kmValue.nomeColuna,
                'field': 'km',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': clienteValue.nomeColuna,
                'field': 'cliente',
                'visivel': 'true'
            },
            {
                'nomeColuna': embarcadorEmbValue.nomeColuna,
                'field': 'embarcador',
                'visivel': 'true'
            },
            {
                'nomeColuna': destinatarioEmbValue.nomeColuna,
                'field': 'destinatario',
                'visivel': 'true'
            },
            {
                'nomeColuna': loadValue.nomeColuna,
                'field': 'load',
                'visivel': 'true'
            },
            {
                'nomeColuna': mercadoriaValue.nomeColuna,
                'field': 'mercadoria',
                'visivel': 'true'
            },
            {
                'nomeColuna': pesoValue?.nomeColuna,
                'field': 'peso',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': embalagemEmbValue?.nomeColuna,
                'field': 'embalagem',
                'visivel': 'true'
            },
            {
                'nomeColuna': observacaoValue?.nomeColuna,
                'field': 'observacao',
                'visivel': 'true'
            },
            {
                'nomeColuna': frotaValue?.nomeColuna,
                'field': 'frota',
                'visivel': 'true'
            },
            {
                'nomeColuna': canceladoValue?.nomeColuna,
                'field': 'cancelado',
                'visivel': 'true'
            },
            {
                'nomeColuna': motivoEmbarqueValue?.nomeColuna,
                'field': 'motivo',
                'visivel': 'true'
            },
            {
                'nomeColuna': usuarioResponsavelValue?.nomeColuna,
                'field': 'usuarioResponsavel',
                'visivel': 'true'
            },
            {
                'nomeColuna': nEntregasValue?.nomeColuna,
                'field': 'nEntregas',
                'visivel': 'true'
            },
            {
                'nomeColuna': comprovanteValue?.nomeColuna,
                'field': 'comprovante',
                'visivel': 'true'
            },
            {
                'nomeColuna': checkinValue?.nomeColuna,
                'field': 'checkInFeito',
                'visivel': 'true'
            },
            {
                'quebraColunaEmbarque': quebraTabelaEmbarque?.quebraColunaEmbarque
            },
        ],
        'Pedido': [
            {
                'nomeColuna': pedidoPValue.nomeColuna,
                'field': 'pedido',
                'visivel': 'true'
            },
            {
                'nomeColuna': tipoRotaValue.nomeColuna,
                'field': 'tipoRota',
                'visivel': 'true'
            },
            {
                'nomeColuna': freteFiscalPValue.nomeColuna,
                'field': 'freteFiscal',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': icmsPValue.nomeColuna,
                'field': 'icms',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': pedagioPValue.nomeColuna,
                'field': 'pedagio',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': classificacaoPValue.nomeColuna,
                'field': 'classificacao',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': kmPValue.nomeColuna,
                'field': 'km',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': clientePValue.nomeColuna,
                'field': 'cliente',
                'visivel': 'true'
            },
            {
                'nomeColuna': embarcadorPValue.nomeColuna,
                'field': 'embarcador',
                'visivel': 'true'
            },
            {
                'nomeColuna': destinatarioPValue.nomeColuna,
                'field': 'destinatario',
                'visivel': 'true'
            },
            {
                'nomeColuna': freteMotoristaPValue.nomeColuna,
                'field': 'freteMotorista',
                'visivel': 'true',
                'tipo': 'number'
            },
            {
                'nomeColuna': observacaoPValue.nomeColuna,
                'field': 'observacao',
                'visivel': 'true'
            },
            {
                'nomeColuna': permiteAlterarFreteFiscalValue.nomeColuna,
                'field': 'permiteAlterarFreteFiscal',
                'visivel': 'true'
            },
            {
                'nomeColuna': valorEmbarquePedidoValue?.nomeColuna,
                'field': 'valorEmbarque',
                'objetoValorEmbarque': 'true',
                'visivel': 'true'
            },
            {
                'nomeColuna': icmsEmbutido?.nomeColuna,
                'field': 'icmsEmbutido',
                'visivel': 'true'
            },
            {
                'nomeColuna': icmsEmbarcador?.nomeColuna,
                'field': 'icmsEmbarcador',
                'visivel': 'true'
            },
            {
                'nomeColuna': freteNormal?.nomeColuna,
                'field': 'freteNormal',
                'visivel': 'true'
            },
            {
                'quebraColunaPedido': quebraTabelaPedido?.quebraColunaPedido
            },
        ],
    };

    const valorInicial = {
        'CidadeEstado': [
            {
                'nomeColuna': cidadeValue.nomeColuna,
                'field': 'cidade'
            },
            {
                'nomeColuna': estadoValue.nomeColuna,
                'field': 'estado'
            },
            {
                'nomeColuna': 'Abreviação',
                'field': 'abreviacao'
            },
        ],
        'Embalagem': [
            {
                'nomeColuna': embalagemValue.nomeColuna,
                'field': 'nome'
            },
        ],
        'Cliente': [
            {
                'nomeColuna': nomeFantasiaValue?.nomeColuna,
                'field': 'nomeFantasia'
            },
            {
                'nomeColuna': razaoSocialValue?.nomeColuna,
                'field': 'razaoSocial'
            },
            {
                'nomeColuna': cnpjClienteValue?.nomeColuna,
                'field': 'cnpj'
            },
            {
                'nomeColuna': enderecoValue?.nomeColuna,
                'field': 'endereco',
                'objetoEndereco': 'true'
            },
            {
                'nomeColuna': apelidoValue?.nomeColuna,
                'field': 'apelido'
            },
            {
                'nomeColuna': diFreteFiscalValue?.nomeColuna,
                'field': 'diFreteFiscal'
            },
            {
                'nomeColuna': diPedagioValue?.nomeColuna,
                'field': 'diPedagio'
            },
            {
                'nomeColuna': diClassificacaoValue?.nomeColuna,
                'field': 'diClassificacao'
            },
            {
                'nomeColuna': usuarioClienteValue?.nomeColuna,
                'field': 'usuarioClientes',
                'objetoUsuarios': 'true'
            },
        ],
        'Embarque': [
            {
                'nomeColuna': descricaoValue?.nomeColuna,
                'field': 'descricao'
            },
            {
                'nomeColuna': unidEmbarqueValue.nomeColuna,
                'field': 'unidEmbarque'
            },
            {
                'nomeColuna': empresaValue.nomeColuna,
                'field': 'empresa'
            },
            {
                'nomeColuna': filialValue.nomeColuna,
                'field': 'filial'
            },
            {
                'nomeColuna': rotaEmbarqueValue.nomeColuna,
                'field': 'rota'
            },
            {
                'nomeColuna': pedidoValue.nomeColuna,
                'field': 'pedido'
            },
            {
                'nomeColuna': cteValue.nomeColuna,
                'field': 'cte'
            },
            {
                'nomeColuna': previsaoEmbarqueValue.nomeColuna,
                'field': 'previsaoEmbarque'
            },
            {
                'nomeColuna': previsaoEntregaValue.nomeColuna,
                'field': 'previsaoEntrega'
            },
            {
                'nomeColuna': dataHoraChegadaEmbarqueValue.nomeColuna,
                'field': 'dataHoraChegadaEmbarque'
            },
            {
                'nomeColuna': dataHoraEmbarcadoValue.nomeColuna,
                'field': 'dataHoraEmbarcado'
            },
            {
                'nomeColuna': dataHoraChegadaEntregaValue.nomeColuna,
                'field': 'dataHoraChegadaEntrega'
            },
            {
                'nomeColuna': dataHoraEntregueValue.nomeColuna,
                'field': 'dataHoraEntregue'
            },
            {
                'nomeColuna': aceiteValue.nomeColuna,
                'field': 'aceite'
            },
            {
                'nomeColuna': statusValue.nomeColuna,
                'field': 'status'
            },
            {
                'nomeColuna': placaCavaloValue.nomeColuna,
                'field': 'placaCavalo'
            },
            {
                'nomeColuna': placaCarretaValue.nomeColuna,
                'field': 'placaCarreta'
            },
            {
                'nomeColuna': placaCarreta2Value.nomeColuna,
                'field': 'placaCarreta2'
            },
            {
                'nomeColuna': proprietarioValue.nomeColuna,
                'field': 'proprietario'
            },
            {
                'nomeColuna': motoristaValue.nomeColuna,
                'field': 'motorista'
            },
            {
                'nomeColuna': freteFiscalValue.nomeColuna,
                'field': 'freteFiscal',
                'tipo': 'number'
            },
            {
                'nomeColuna': icmsValue.nomeColuna,
                'field': 'icms',
                'tipo': 'number'
            },
            {
                'nomeColuna': freteMotoristaValue.nomeColuna,
                'field': 'freteMotorista',
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalEmpresaValue.nomeColuna,
                'field': 'adicionalEmpresa',
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalMotoristaValue.nomeColuna,
                'field': 'adicionalMotorista',
                'tipo': 'number'
            },
            {
                'nomeColuna': numeroEixosEmbarqueValue.nomeColuna,
                'field': 'numeroEixos',
                'tipo': 'number'
            },
            {
                'nomeColuna': pedagioValue.nomeColuna,
                'field': 'pedagio',
                'tipo': 'number'
            },
            {
                'nomeColuna': classificacaoValue.nomeColuna,
                'field': 'classificacao',
                'tipo': 'number'
            },
            {
                'nomeColuna': margemValue.nomeColuna,
                'field': 'margem'
            },
            {
                'nomeColuna': adiantamentoValue.nomeColuna,
                'field': 'adiantamento',
                'tipo': 'number'
            },
            {
                'nomeColuna': saldoValue.nomeColuna,
                'field': 'saldo',
                'tipo': 'number'
            },
            {
                'nomeColuna': meioPagamentoValue.nomeColuna,
                'field': 'meioPagamento'
            },
            {
                'nomeColuna': meioPagamento2Value.nomeColuna,
                'field': 'meioPagamento2'
            },
            {
                'nomeColuna': kmValue.nomeColuna,
                'field': 'km',
                'tipo': 'number'
            },
            {
                'nomeColuna': clienteValue.nomeColuna,
                'field': 'cliente'
            },
            {
                'nomeColuna': embarcadorEmbValue.nomeColuna,
                'field': 'embarcador'
            },
            {
                'nomeColuna': destinatarioEmbValue.nomeColuna,
                'field': 'destinatario'
            },
            {
                'nomeColuna': loadValue.nomeColuna,
                'field': 'load'
            },
            {
                'nomeColuna': mercadoriaValue.nomeColuna,
                'field': 'mercadoria'
            },
            {
                'nomeColuna': pesoValue?.nomeColuna,
                'field': 'peso',
                'tipo': 'number'
            },
            {
                'nomeColuna': embalagemEmbValue?.nomeColuna,
                'field': 'embalagem'
            },
            {
                'nomeColuna': observacaoValue?.nomeColuna,
                'field': 'observacao'
            },
            {
                'nomeColuna': frotaValue?.nomeColuna,
                'field': 'frota'
            },
            {
                'nomeColuna': canceladoValue?.nomeColuna,
                'field': 'cancelado'
            },
            {
                'nomeColuna': motivoEmbarqueValue?.nomeColuna,
                'field': 'motivo'
            },
            {
                'nomeColuna': usuarioResponsavelValue?.nomeColuna,
                'field': 'usuarioResponsavel'
            },
            {
                'nomeColuna': nEntregasValue?.nomeColuna,
                'field': 'nEntregas'
            },
            {
                'nomeColuna': comprovanteValue?.nomeColuna,
                'field': 'comprovante'
            },
            {
                'nomeColuna': checkinValue?.nomeColuna,
                'field': 'checkInFeito'
            },
            {
                'quebraColunaEmbarque': quebraTabelaEmbarque?.quebraColunaEmbarque
            },
        ],
        'EmpresaSub': [
            {
                'nomeColuna': nomeValue.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': cnpjValue.nomeColuna,
                'field': 'cnpj'
            },
            {
                'nomeColuna': cidadeEmpresaSubValue.nomeColuna,
                'field': 'cidade',
                'objetoUnidadeEmbarque': 'true'
            },
            {
                'nomeColuna': regimeTributarioValue.nomeColuna,
                'field': 'regimeTributario'
            }
        ],
        'Filial': [
            {
                'nomeColuna': filialValue.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': complementoLocalizacaoValue.nomeColuna,
                'field': 'complementoLocalizacao'
            },
            {
                'nomeColuna': responsavelValue.nomeColuna,
                'field': 'responsavel'
            },
            {
                'nomeColuna': telefoneValue.nomeColuna,
                'field': 'telefone'
            },
            {
                'nomeColuna': emailValue.nomeColuna,
                'field': 'email'
            },
            {
                'nomeColuna': 'Cidade/UF',
                'field': 'cidade',
                'objetoUnidadeEmbarque': 'true'
            },
        ],
        'Motivo': [
            {
                'nomeColuna': motivoValue?.nomeColuna,
                'field': 'motivo'
            },
        ],
        'MeioPagamento': [
            {
                'nomeColuna': nomeMeioPagamentoValue.nomeColuna,
                'field': 'nome'
            }
        ],
        'Mercadoria': [
            {
                'nomeColuna': mercadoriaNomeValue?.nomeColuna,
                'field': 'nome'
            },
        ],
        'Motorista': [
            {
                'nomeColuna': nomeMotoristaValue?.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': numeroDocumentoValue?.nomeColuna,
                'field': 'numeroDocumento'
            },
            {
                'nomeColuna': tipoDocumentoValue?.nomeColuna,
                'field': 'tipoDocumento'
            },
            {
                'nomeColuna': telefoneMotoristaValue?.nomeColuna,
                'field': 'telefone'
            },
            {
                'nomeColuna': nomeEmergenciaValue?.nomeColuna,
                'field': 'nomeEmergencia'
            },
            {
                'nomeColuna': telefoneEmergenciaValue?.nomeColuna,
                'field': 'telefoneEmergencia'
            },
            {
                'nomeColuna': frotaMotoristaValue?.nomeColuna,
                'field': 'frota'
            },
            {
                'nomeColuna': usuarioMotoristaValue?.nomeColuna,
                'field': 'usuarioClientes',
                'objetoUsuarios': 'true'
            },
            {
                'nomeColuna': bloqueadoValue?.nomeColuna,
                'field': 'bloqueado'
            },
            {
                'nomeColuna': motivoBloqueadoValue?.nomeColuna,
                'field': 'motivo'
            },
        ],
        'Notificacao': [
            {
                'nomeColuna': tituloNotificaoValue?.nomeColuna,
                'field': 'titulo'
            },
            {
                'nomeColuna': mensagemNotificacaoValue?.nomeColuna,
                'field': 'mensagem'
            },
            {
                'nomeColuna': acaoNotificacaoValue?.nomeColuna,
                'field': 'acao'
            },
            {
                'nomeColuna': numeroVisualizacoesNotificacaoValue?.nomeColuna,
                'field': 'numeroVisualizacoes'
            },
            {
                'nomeColuna': dataNotificacaoValue?.nomeColuna,
                'field': 'data'
            }
        ],
        'Pedido': [
            {
                'nomeColuna': pedidoPValue.nomeColuna,
                'field': 'pedido'
            },
            {
                'nomeColuna': tipoRotaValue.nomeColuna,
                'field': 'tipoRota'
            },
            {
                'nomeColuna': freteFiscalPValue.nomeColuna,
                'field': 'freteFiscal',
                'tipo': 'number'
            },
            {
                'nomeColuna': icmsPValue.nomeColuna,
                'field': 'icms',
                'tipo': 'number'
            },
            {
                'nomeColuna': pedagioPValue.nomeColuna,
                'field': 'pedagio',
                'tipo': 'number'
            },
            {
                'nomeColuna': classificacaoPValue.nomeColuna,
                'field': 'classificacao',
                'tipo': 'number'
            },
            {
                'nomeColuna': kmPValue.nomeColuna,
                'field': 'km',
                'tipo': 'number'
            },
            {
                'nomeColuna': clientePValue.nomeColuna,
                'field': 'cliente'
            },
            {
                'nomeColuna': embarcadorPValue.nomeColuna,
                'field': 'embarcador'
            },
            {
                'nomeColuna': destinatarioPValue.nomeColuna,
                'field': 'destinatario'
            },
            {
                'nomeColuna': freteMotoristaPValue.nomeColuna,
                'field': 'freteMotorista',
                'tipo': 'number'
            },
            {
                'nomeColuna': observacaoPValue.nomeColuna,
                'field': 'observacao'
            },
            {
                'nomeColuna': permiteAlterarFreteFiscalValue.nomeColuna,
                'field': 'permiteAlterarFreteFiscal '
            },
            {
                'nomeColuna': valorEmbarquePedidoValue?.nomeColuna,
                'field': 'valorEmbarque',
                'objetoValorEmbarque': 'true'
            },
            {
                'nomeColuna': icmsEmbutido?.nomeColuna,
                'field': 'icmsEmbutido',
            },
            {
                'nomeColuna': icmsEmbarcador?.nomeColuna,
                'field': 'icmsEmbarcador',
            },
            {
                'nomeColuna': freteNormal?.nomeColuna,
                'field': 'freteNormal',
            },
            {
                'quebraColunaPedido': quebraTabelaPedido?.quebraColunaPedido
            },
        ],
        'Proprietario': [
            {
                'nomeColuna': proprietarioNomeValue?.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': cnpjProprietarioValue?.nomeColuna,
                'field': 'cnpj'
            },
            {
                'nomeColuna': usuarioProprietarioValue?.nomeColuna,
                'field': 'usuarioClientes',
                'objetoUsuarios': 'true'
            },
        ],
        'Rota': [
            {
                'nomeColuna': nomeRotaValue.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': cidadeOrigemRotaValue.nomeColuna,
                'field': 'cidadeOrigem'
            },
            {
                'nomeColuna': estadoOrigemRotaValue.nomeColuna,
                'field': 'estadoOrigem'
            },
            {
                'nomeColuna': cidadeDestinoRotaValue.nomeColuna,
                'field': 'cidadeDestino'
            },
            {
                'nomeColuna': estadoDestinoRotaValue.nomeColuna,
                'field': 'estadoDestino'
            },
        ],
        'Solicitacoes': [
            {
                'nomeColuna': usuarioSolicitacaoValue?.nomeColuna,
                'field': 'usuario'
            },
            {
                'nomeColuna': emailSolicitacaoValue?.nomeColuna,
                'field': 'email'
            },
            {
                'nomeColuna': telefoneSolicitacaoValue?.nomeColuna,
                'field': 'telefone'
            },
            {
                'nomeColuna': nascimentoSolicitacaoValue?.nomeColuna,
                'field': 'nascimento'
            },
            {
                'nomeColuna': aceitarSolicitacaoValue?.nomeColuna,
                'field': 'aceitar'
            },
            {
                'nomeColuna': recusarSolicitacaoValue?.nomeColuna,
                'field': 'recusar'
            },
        ],
        'Status': [
            {
                'nomeColuna': nomeStatusValue.nomeColuna,
                'field': 'nome'
            },
        ],
        'TaxaIcms': [
            {
                'nomeColuna': taxaIcmsValue?.nomeColuna,
                'field': 'taxa',
                'tipo': 'number'
            },
            {
                'nomeColuna': ufOrigemTaxaValue?.nomeColuna,
                'field': 'ufOrigem'
            },
            {
                'nomeColuna': ufDestinoTaxaValue?.nomeColuna,
                'field': 'ufDestino'
            },
        ],
        'UnidadeEmbarque': [
            {
                'nomeColuna': nomeUnidadeEmbarqueValue.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': cidadeUnidadeEmbarqueValue.nomeColuna,
                'field': 'usuario',
                'objetoUnidadeEmbarque': 'true'
            },
            {
                'nomeColuna': frotaUnidadeEmbarqueValue?.nomeColuna,
                'field': 'frota'
            },
        ],
        'UsuarioGeren': [
            {
                'nomeColuna': nomeUsuarioGerenValue.nomeColuna,
                'field': 'usuario'
            },
            {
                'nomeColuna': senhaUsuarioGerenValue.nomeColuna,
                'field': ''
            },
            {
                'nomeColuna': emailUsuarioGerenValue.nomeColuna,
                'field': 'email'
            },
            {
                'nomeColuna': telefoneUsuarioGerenValue.nomeColuna,
                'field': 'telefone'
            },
            {
                'nomeColuna': nascimentoUsuarioGerenValue.nomeColuna,
                'field': 'nascimento'
            },
            {
                'nomeColuna': permissoesUsuarioGerenValue.nomeColuna,
                'field': 'permissoes'
            },
            {
                'nomeColuna': ultimoLoginValue?.nomeColuna,
                'field': 'ultimoLogin'
            },
            {
                'nomeColuna': ultimoLogoutValue?.nomeColuna,
                'field': 'ultimoLogout'
            },
        ],
        'Veiculo': [
            {
                'nomeColuna': placaCavaloVeiculoValue.nomeColuna,
                'field': 'placaCavalo'
            },
            {
                'nomeColuna': placaCarretaVeiculoValue.nomeColuna,
                'field': 'placaCarreta'
            },
            {
                'nomeColuna': placaCarreta2VeiculoValue.nomeColuna,
                'field': 'placaCarreta2'
            },
            {
                'nomeColuna': proprietarioVeiculoValue.nomeColuna,
                'field': 'proprietario'
            },
            {
                'nomeColuna': motoristaVeiculoValue.nomeColuna,
                'field': 'motorista'
            },
            {
                'nomeColuna': motorista2VeiculoValue.nomeColuna,
                'field': 'motorista2'
            },
            {
                'nomeColuna': eixosValue?.nomeColuna,
                'field': 'numeroEixos'
            },
            {
                'nomeColuna': tipoVeiculoValue?.nomeColuna,
                'field': 'tipoVeiculo'
            },
        ],
        'Caminhao': [
            {
                'nomeColuna': placaCaminhaoValue.nomeColuna,
                'field': 'placa'
            },
            {
                'nomeColuna': anoCaminhaoValue.nomeColuna,
                'field': 'ano'
            },
            {
                'nomeColuna': renavamCaminhaoValue.nomeColuna,
                'field': 'renavam'
            },
            {
                'nomeColuna': marcaCaminhaoValue.nomeColuna,
                'field': 'marca'
            },
            {
                'nomeColuna': modeloCaminhaoValue.nomeColuna,
                'field': 'modelo'
            },
            {
                'nomeColuna': frotaCaminhaoValue.nomeColuna,
                'field': 'frota'
            },
            {
                'nomeColuna': kmCaminhaoValue?.nomeColuna,
                'field': 'km'
            },
            {
                'nomeColuna': eixosCaminhaoValue?.nomeColuna,
                'field': 'numeroEixos'
            },
        ],
        'Carreta': [
            {
                'nomeColuna': placaCarretaCaminhaoValue.nomeColuna,
                'field': 'placa'
            },
            {
                'nomeColuna': anoCarretaCaminhaoValue.nomeColuna,
                'field': 'ano'
            },
            {
                'nomeColuna': renavamCarretaCaminhaoValue.nomeColuna,
                'field': 'renavam'
            },
            {
                'nomeColuna': marcaCarretaCaminhaoValue.nomeColuna,
                'field': 'marca'
            },
            {
                'nomeColuna': modeloCarretaCaminhaoValue.nomeColuna,
                'field': 'modelo'
            },
            {
                'nomeColuna': frotaCarretaValue.nomeColuna,
                'field': 'frota'
            },
            {
                'nomeColuna': kmCarretaValue?.nomeColuna,
                'field': 'km'
            },
            {
                'nomeColuna': eixosCarretaValue?.nomeColuna,
                'field': 'numeroEixos'
            },
        ],
        'Avisos': [
            {
                'nomeColuna': mensagemAvisoValue?.nomeColuna,
                'field': 'mensagem'
            },
            {
                'nomeColuna': dataAvisoValue?.nomeColuna,
                'field': 'data'
            },
        ],
        'TipoCheckagem': [
            {
                'nomeColuna': nomeTipoCheckagemValue?.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': notificarAutomaticoValue?.nomeColuna,
                'field': 'notificarAutomatico'
            },
            {
                'nomeColuna': tipoNotificacaoValue?.nomeColuna,
                'field': 'tipoNotificacao'
            },
            {
                'nomeColuna': notificarACadaDataValue?.nomeColuna,
                'field': 'notificarACadaData'
            },
            {
                'nomeColuna': notificarACadaKMValue?.nomeColuna,
                'field': 'notificarACadaKM'
            },
        ],
        'Checkagem': [
            {
                'nomeColuna': dadosCheckagemValue?.nomeColuna,
                'field': 'dados'
            },
            {
                'nomeColuna': dataCheckagemValue?.nomeColuna,
                'field': 'dataCheckagem'
            },
            {
                'nomeColuna': observacaoCheckagemValue?.nomeColuna,
                'field': 'observacao'
            },
            {
                'nomeColuna': kmCheckagemValue?.nomeColuna,
                'field': 'km'
            },
            {
                'nomeColuna': localCheckagemValue?.nomeColuna,
                'field': 'local'
            },
            {
                'nomeColuna': tipoManutencaoCheckagemValue?.nomeColuna,
                'field': 'tipoManutencao'
            },
            {
                'nomeColuna': proximaRevisaoDataValue?.nomeColuna,
                'field': 'proximaRevisaoData'
            },
            {
                'nomeColuna': proximaRevisaoKMValue?.nomeColuna,
                'field': 'proximaRevisaoKM'
            },
            {
                'nomeColuna': tipoCheckValue?.nomeColuna,
                'field': 'tipoCheck'
            },
        ],
        'TipoRastreamento': [
            {
                'nomeColuna': nomeTipoRastreamentoValue?.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': ipValue?.nomeColuna,
                'field': 'ip'
            },
        ],
        'Rastreamento': [
            {
                'nomeColuna': usuarioRastreamentoValue?.nomeColuna,
                'field': 'usuarioRastreamento'
            },
            {
                'nomeColuna': senhaRastreamentoValue?.nomeColuna,
                'field': 'senha'
            },
            {
                'nomeColuna': tipoVeiculoRastreamentoValue?.nomeColuna,
                'field': 'tipoVeiculo'
            },
            {
                'nomeColuna': tipoRastreamentoValue?.nomeColuna,
                'field': 'tipoRastreamento',
                'objetoRastreamento': 'true'
            },
            {
                'nomeColuna': veiculoRastreamentoValue?.nomeColuna,
                'field1': 'carreta',
                'field2': 'caminhao',
                'objetoVeiculo': 'true'

            },
            {
                'nomeColuna': fabricanteRastreamentoValue?.nomeColuna,
                'field': 'fabricanteRastreamento'
            },
            {
                'nomeColuna': numeroRastreadorValue?.nomeColuna,
                'field': 'numeroRastreador'
            },
        ],
        'GrupoClientes': [
            {
                'nomeColuna': nomeGrupoClienteValue?.nomeColuna,
                'field': 'nome'
            },
        ],
        'Cockpit': [
            {
                'nomeColuna': projetadoValue?.nomeColuna,
                'field': 'projetado'
            },
            {
                'nomeColuna': estadoOrigemCockpitValue?.nomeColuna,
                'field': 'estadoOrigem'
            },
            {
                'nomeColuna': estadoDestinoCockpitValue?.nomeColuna,
                'field': 'estadoDestino'
            },
            {
                'nomeColuna': grupoClienteCockpitValue?.nomeColuna,
                'field': 'grupoCliente',
                'objetoGrupoCliente': 'true'
            },
        ],
        'GrupoComClientes': [
            {
                'nomeColuna': clienteVinculoValue?.nomeColuna,
                'field': 'cliente',
                'objetoCliente': 'true'
            },
            {
                'nomeColuna': grupoClienteVinculoValue?.nomeColuna,
                'field': 'grupoCliente',
                'objetoGrupoCliente': 'true'
            },
        ],
        'GrupoPrecificacao': [
            {
                'nomeColuna': grupoPrecificacaoValue?.nomeColuna,
                'field': 'nome'
            },
        ],
        'ValorEmbarque': [
            {
                'nomeColuna': eixosValorEmbarqueValue?.nomeColuna,
                'field': 'eixos'
            },
            {
                'nomeColuna': kmValorEmbarqueValue?.nomeColuna,
                'field': 'km'
            },
            {
                'nomeColuna': valorEixoValorEmbarqueValue?.nomeColuna,
                'field': 'valorEixo'
            },
            {
                'nomeColuna': adicionalCargaDescargaValorEmbarqueValue?.nomeColuna,
                'field': 'adicionalCargaDescarga'
            },
            {
                'nomeColuna': motoristaValorEmbarqueValue?.nomeColuna,
                'field': 'motorista'
            },
            {
                'nomeColuna': empresaValorEmbarqueValue?.nomeColuna,
                'field': 'empresa'
            },
            {
                'nomeColuna': icmsValorEmbarqueValue?.nomeColuna,
                'field': 'icms'
            },
            {
                'nomeColuna': descargaValorEmbarqueValue?.nomeColuna,
                'field': 'descarga'
            },
            {
                'nomeColuna': pedagioValorEmbarqueValue?.nomeColuna,
                'field': 'pedagio'
            },
            {
                'nomeColuna': freteFiscalValorEmbarqueValue?.nomeColuna,
                'field': 'freteFiscal'
            },
            {
                'nomeColuna': rotaValorEmbarqueValue?.nomeColuna,
                'field': 'rota',
                'objetoRota': 'true',
            },
            {
                'nomeColuna': clienteValorEmbarqueValue?.nomeColuna,
                'field': 'cliente',
                'objetoCliente': 'true'
            },
            {
                'nomeColuna': grupoPrecificacaoValorEmbarqueValue?.nomeColuna,
                'field': 'grupoPrecificacao',
                'objetoGrupoPrecificacao': 'true'
            },
        ],
        'MotivoOnTime': [
            {
                'nomeColuna': motivoOnTimeValue?.nomeColuna,
                'field': 'motivo'
            },
        ],
        'GrupoCarga': [
            {
                'nomeColuna': grupoCargaValue?.nomeColuna,
                'field': 'nomeGrupo'
            },
        ],
        'GrupoCargaVinculo': [
            {
                'nomeColuna': grupoCargaValue?.nomeColuna,
                'field': 'grupoCarga',
                'objetoGrupoCarga': 'true'
            },
            {
                'nomeColuna': motoristaGrupoCargaVinculoVinculoValue?.nomeColuna,
                'field': 'motorista',
                'objetoMotorista': 'true'
            },
            {
                'nomeColuna': proprietarioGrupoCargaVinculoVinculoValue?.nomeColuna,
                'field': 'proprietario',
                'objetoProprietario': 'true'
            },
        ],
        'Cotacao': [
            {
                'nomeColuna': unidadeEmbarqueCotacaoValue?.nomeColuna,
                'field': 'unidadeEmbarque',
                'objetoUnidadeEmbarqueNome': 'true'
            },
            {
                'nomeColuna': rotaCotacaoValue?.nomeColuna,
                'field': 'rota',
                'objetoRota': 'true'
            },
            {
                'nomeColuna': clienteCotacaoValue?.nomeColuna,
                'field': 'cliente',
                'objetoCliente': 'true'
            },
            {
                'nomeColuna': valorCotacaoValue?.nomeColuna,
                'field': 'valor',
            },
            {
                'nomeColuna': icmsCotacaoValue?.nomeColuna,
                'field': 'icms',
            },
            {
                'nomeColuna': pedagioCotacaoValue?.nomeColuna,
                'field': 'pedagio',
            },
            {
                'nomeColuna': distanciaKmCotacaoValue?.nomeColuna,
                'field': 'distanciaKm',
            },
            {
                'nomeColuna': valorPorKmCotacaoValue?.nomeColuna,
                'field': 'valorPorKm',
            },
            {
                'nomeColuna': valorDesejavelMotoristaCotacaoValue?.nomeColuna,
                'field': 'valorDesejavelMotorista',
            },
            {
                'nomeColuna': descricaoRotaCotacaoValue?.nomeColuna,
                'field': 'descricaoRota',
            },
            {
                'nomeColuna': foiCarregadoCotacaoValue?.nomeColuna,
                'field': 'foiCarregado',
            },
            {
                'nomeColuna': tipoVeiculoCotacaoValue?.nomeColuna,
                'field': 'tipoVeiculo',
                'objetoTipoVeiculo': 'true'
            },
            {
                'nomeColuna': tipoCarroceriaCotacaoValue?.nomeColuna,
                'field': 'cotacaoCarroceria',
                'objetoTipoCarroceria': 'true'
            },
            {
                'nomeColuna': segmentoCliente?.nomeColuna,
                'field': 'segmentoCliente',
                'objetoSegmentoCliente': 'true'
            },
            {
                'nomeColuna': motivoCotacaoValue?.nomeColuna,
                'field': 'motivoCotacao',
                'objetoMotivo': 'true'
            },
            {
                'nomeColuna': dataCotacaoValue?.nomeColuna,
                'field': 'dataCotacao',
            },
            {
                'nomeColuna': usuarioResponsavelCotacaoValue?.nomeColuna,
                'field': 'usuarioResponsavel',
            },
            {
                'nomeColuna': nomeContatoCotacaoValue?.nomeColuna,
                'field': 'nomeContato',
            },
            {
                'nomeColuna': telefoneContatoCotacaoValue?.nomeColuna,
                'field': 'telefoneContato',
            },
            {
                'nomeColuna': emailContatoCotacaoValue?.nomeColuna,
                'field': 'emailContato',
            },
            {
                'nomeColuna': observacaoCotacaoValue?.nomeColuna,
                'field': 'observacao',
            },
        ],
        'TipoVeiculo': [
            {
                'nomeColuna': nomeTipoVeiculo?.nomeColuna,
                'field': 'nome'
            },
        ],
        'TipoCarroceria': [
            {
                'nomeColuna': nomeTipoCarroceria?.nomeColuna,
                'field': 'nome'
            },
        ],
        'MotivoCotacao': [
            {
                'nomeColuna': motivoCotacaoValue?.nomeColuna,
                'field': 'motivo'
            },
        ],
        'SegmentoCliente': [
            {
                'nomeColuna': segmentoClienteValue?.nomeColuna,
                'field': 'nome'
            },
        ],
        'Ocorrencia': [
            {
                'nomeColuna': ocorrenciaValue?.nomeColuna,
                'field': 'tipo'
            },
        ],
        'TipoOcorrencia': [
            {
                'nomeColuna': tipoOcorrenciaValue?.nomeColuna,
                'field': 'nome'
            },
        ],
        'SubTipoOcorrencia': [
            {
                'nomeColuna': subTipoOcorrenciaValue?.nomeColuna,
                'field': 'nome'
            },
            {
                'nomeColuna': tipoOcorrenciaSubValue?.nomeColuna,
                'field': 'tipoOcorrencia',
                'objetoTipoOcorrencia': 'true'
            },
        ],
        'OcorrenciaEmbarque': [
            {
                'nomeColuna': ' Ocorrência',
                'field': 'ocorrencia'
            },
            {
                'nomeColuna': 'Tipo Ocorrência',
                'field': 'tipoOcorrencia'
            },
            {
                'nomeColuna': 'Sub Tipo Ocorrência',
                'field': 'subTipoOcorrencia'
            },
            {
                'nomeColuna': 'Data Ocorrência',
                'field': 'dataOcorrencia'
            },
            {
                'nomeColuna': 'Observação',
                'field': 'observacao'
            },
        ],
        'Opcoes': [
            {
                'opcao': 'Sim',
            },
            {
                'opcao': 'Não',
            },
        ],
        'Contexto': [
            {
                'nome': 'Toledo',
            },
            {
                'nome': 'Glória de Dourados',
            },
            {
                'nome': 'Paranavaí',
            },
            {
                'nome': 'Rio Verde',
            },
        ],
    };

    const cadastrar = async () => {
        setLoading(true);
        try {
            const camposFormatados = JSON.stringify(valorInicialVisivel).replace(/"/g, `'`)
            localStorage.setItem('usuarioTabela', JSON.stringify(valorInicialVisivel));
            const usuarioTabela = {
                usuarioGerenId: dadosUsuarioRedux.usuario.idUsuario,
                campoVisiveis: camposFormatados
            }
            await editaUsuarioTabelaPorId(usuarioTabela, dadosUsuarioRedux.token).then((result) => {
                setLoading(false);
                setDadosMenusRedux(valorInicialVisivel);
                setTextoSucesso("Sucesso");
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setLoading(false);
            });
            await getEmpresaPorId(localStorage.getItem('tenant')).then((result) => {
                if (result.status === 200) {
                    const cores = result.data.cores.replace(/'/g, '"');
                    const cor = JSON.parse(cores);
                    setDadosCoresRedux(cor);
                    const objeto = {
                        tenant: localStorage.getItem('tenant'),
                        cores: JSON.stringify(dadosCoresRedux).replace(/"/g, `'`),
                        colunas: JSON.stringify(dadosTabelaRedux).replace(/"/g, `'`),
                    }
                    editaEmpresa(dadosUsuarioRedux.token, objeto).then((result) => {
                        if (result.status === 200) {
                            if (!jaPassou) {
                                setOpen(true);
                            }
                            setJaPassou(true);
                            setTextoSucesso("Configurações Atualizadas com Sucesso!");
                            setOpenToastSucesso(true);
                        }
                    }).catch((error) => {
                        console.error(error);
                        setTextoErro(error.response.data);
                        setOpenToastErro(true);
                    });
                }
            });
        } catch (error) {
            console.error(error);
            setTextoErro(error.response.data);
            setOpenToastErro(true);
        }
    }

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Configuracoes') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra == 'Configuracoes') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV2 selecionado="ConfiguracoesColunas" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <NavbarV2 selecionado="ConfiguracoesColunas" />
                    <div className="corpo-cores">
                        <div className="header-configuracoes-v2">
                            <div className="flex">
                                <p className="indicador-principal-embarque-v2">Painel Configurações Nomes Colunas</p>
                            </div>
                        </div>
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showCidadeEstado ? 'FECHAR' : 'Cidade/Estado'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowCidadeEstado(showCidadeEstado => !showCidadeEstado);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                                setShowProprietario(false);
                            }}
                        />
                    </div>
                    {
                        showCidadeEstado && (
                            <div className="grid-container-coluna-empresa">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cidade" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCidadeValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cidade',
                                                },
                                            )
                                        }}
                                        value={cidadeValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Estado" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEstadoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'estado',
                                                },
                                            )
                                        }}
                                        value={estadoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Abreviação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAbreviacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'abreviacao',
                                                },
                                            )
                                        }}
                                        value={abreviacaoValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showEmbalagem ? 'FECHAR' : 'Embalagem'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowEmbalagem(showEmbalagem => !showEmbalagem);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showEmbalagem && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Embalagem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmbalagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={embalagemValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showEmbarcador ? 'FECHAR' : 'Clientes'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowEmbarcador(showEmbarcador => !showEmbarcador);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showEmbarcador && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome Fantasia" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeFantasiaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nomeFantasia',
                                                },
                                            )
                                        }}
                                        value={nomeFantasiaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Razão Social" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setRazaoSocialValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'razaoSocial',
                                                },
                                            )
                                        }}
                                        value={razaoSocialValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="CNPJ" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCnpjClienteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cnpj',
                                                },
                                            )
                                        }}
                                        value={cnpjClienteValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Endereço" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEnderecoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'endereco',
                                                },
                                            )
                                        }}
                                        value={enderecoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Apelido" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setApelidoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'apelido',
                                                },
                                            )
                                        }}
                                        value={apelidoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frete Fiscal" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDiFreteFiscalValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'diFreteFiscal',
                                                },
                                            )
                                        }}
                                        value={diFreteFiscalValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Pedágio" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDiPedagioValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'diPedagio',
                                                },
                                            )
                                        }}
                                        value={diPedagioValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Classificação/Descarga" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDiClassificacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'diClassificacao',
                                                },
                                            )
                                        }}
                                        value={diClassificacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUsuarioClienteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuarioClientes',
                                                    objetoUsuarios: 'true'
                                                },
                                            )
                                        }}
                                        value={usuarioClienteValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showEmbarque ? 'FECHAR' : 'Embarque'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowEmbarque(showEmbarque => !showEmbarque);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showEmbarque && (
                            <div className="grid-container-coluna-embarque">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Descrição" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDescricaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'descricao',
                                                },
                                            )
                                        }}
                                        value={descricaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Unidade Embarque" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUnidEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'unidEmbarque',
                                                },
                                            )
                                        }}
                                        value={unidEmbarqueValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Empresa" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmpresaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'empresa',
                                                },
                                            )
                                        }}
                                        value={empresaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Filial" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFilialValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'filial',
                                                },
                                            )
                                        }}
                                        value={filialValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Rota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setRotaEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'rota',
                                                },
                                            )
                                        }}
                                        value={rotaEmbarqueValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Pedido" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPedidoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'pedido',
                                                },
                                            )
                                        }}
                                        value={pedidoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cte" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cte',
                                                },
                                            )
                                        }}
                                        value={cteValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Previsão Embarque" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPrevisaoEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'previsaoEmbarque',
                                                },
                                            )
                                        }}
                                        value={previsaoEmbarqueValue.nomeColuna}
                                    />
                                </div>
                                {/* <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data de Embarque" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataEmbarque',
                                                },
                                            )
                                        }}
                                        value={dataEmbarqueValue.nomeColuna}
                                    />
                                </div> */}
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Previsão de Entrega" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPrevisaoEntregaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'previsaoEntrega',
                                                },
                                            )
                                        }}
                                        value={previsaoEntregaValue.nomeColuna}
                                    />
                                </div>
                                {/* <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data de Entrega" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataEntregaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataEntrega',
                                                },
                                            )
                                        }}
                                        value={dataEntregaValue.nomeColuna}
                                    />
                                </div> */}
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data Hora Chegada Embarque" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataHoraChegadaEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataHoraChegadaEmbarque',
                                                },
                                            )
                                        }}
                                        value={dataHoraChegadaEmbarqueValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data Hora Embarcado" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataHoraEmbarcadoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataHoraEmbarcado',
                                                },
                                            )
                                        }}
                                        value={dataHoraEmbarcadoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data Hora Chegada Entrega" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataHoraChegadaEntregaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataHoraChegadaEntrega',
                                                },
                                            )
                                        }}
                                        value={dataHoraChegadaEntregaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data Hora Entregue" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataHoraEntregueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataHoraEntregue',
                                                },
                                            )
                                        }}
                                        value={dataHoraEntregueValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Aceite" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAceiteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'aceite',
                                                },
                                            )
                                        }}
                                        value={aceiteValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Status" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setStatusValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'status',
                                                },
                                            )
                                        }}
                                        value={statusValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa Cavalo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCavaloValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placaCavalo',
                                                },
                                            )
                                        }}
                                        value={placaCavaloValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa Carreta" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCarretaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placaCarreta',
                                                },
                                            )
                                        }}
                                        value={placaCarretaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa Carreta 2" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCarreta2Value(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placaCarreta2',
                                                },
                                            )
                                        }}
                                        value={placaCarreta2Value.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Proprietário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setProprietarioValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'proprietario',
                                                },
                                            )
                                        }}
                                        value={proprietarioValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotoristaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motorista',
                                                },
                                            )
                                        }}
                                        value={motoristaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frete Fiscal" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFreteFiscalValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'freteFiscal',
                                                    tipo: 'number',
                                                },
                                            )
                                        }}
                                        value={freteFiscalValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="ICMS" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setIcmsValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'icms',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={icmsValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frete Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFreteMotoristaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'freteMotorista',
                                                    tipo: 'number',
                                                },
                                            )
                                        }}
                                        value={freteMotoristaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Adicional Empresa" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAdicionalEmpresaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'adicionalEmpresa',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={adicionalEmpresaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Adicional Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAdicionalMotoristaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'adicionalMotorista',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={adicionalMotoristaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Eixos" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNumeroEixosEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'numeroEixos',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={numeroEixosEmbarqueValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Pedagio" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPedagioValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'pedagio',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={pedagioValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Classificação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setClassificacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'classificacao',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={classificacaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Margem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMargemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'margem',
                                                },
                                            )
                                        }}
                                        value={margemValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Adiamento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAdiamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'adiantamento',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={adiantamentoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Saldo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setSaldoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'saldo',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={saldoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Meio Pagamento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMeioPagamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'meioPagamento',
                                                },
                                            )
                                        }}
                                        value={meioPagamentoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Meio Pagamento 2" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMeioPagamento2Value(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'meioPagamento2',
                                                },
                                            )
                                        }}
                                        value={meioPagamento2Value.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setKmValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'km',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={kmValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setClienteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cliente',
                                                },
                                            )
                                        }}
                                        value={clienteValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Embarcador" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmbarcadorEmbValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'embarcador',
                                                },
                                            )
                                        }}
                                        value={embarcadorEmbValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Destinatário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDestinatarioEmbValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'destinatario',
                                                },
                                            )
                                        }}
                                        value={destinatarioEmbValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Load" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setLoadValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'load',
                                                },
                                            )
                                        }}
                                        value={loadValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Mercadoria" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMercadoriaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'mercadoria',
                                                },
                                            )
                                        }}
                                        value={mercadoriaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Peso" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPesoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'peso',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={pesoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Embalagem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmbalagemEmbValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'embalagem',
                                                },
                                            )
                                        }}
                                        value={embalagemEmbValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Observação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setObservacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'observacao',
                                                },
                                            )
                                        }}
                                        value={observacaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFrotaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'frota',
                                                },
                                            )
                                        }}
                                        value={frotaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cancelado" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCanceladoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cancelado',
                                                },
                                            )
                                        }}
                                        value={canceladoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motivo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotivoEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motivo',
                                                },
                                            )
                                        }}
                                        value={motivoEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário Responsável" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUsuarioResponsavelValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuarioResponsavel',
                                                },
                                            )
                                        }}
                                        value={usuarioResponsavelValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Entregas" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNEntregasValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nEntregas',
                                                },
                                            )
                                        }}
                                        value={nEntregasValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Comprovante" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setComprovanteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'comprovante',
                                                },
                                            )
                                        }}
                                        value={comprovanteValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Check-In" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCheckinValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'checkInFeito',
                                                },
                                            )
                                        }}
                                        value={checkinValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-embarquepedido">
                                    <input
                                        type="checkbox"
                                        checked={quebraTabelaEmbarque?.quebraColunaEmbarque}
                                        onChange={(e) => {
                                            setQuebraTabelaEmbarqueValue(
                                                {
                                                    quebraColunaEmbarque: e.target.checked,
                                                },
                                            );
                                        }}
                                    ></input><InputLabel htmlFor="cidade"><Text texto="Quebra Linha" /></InputLabel>
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showEmpresaSub ? 'FECHAR' : 'Empresas Subsidiárias'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowEmpresaSub(showEmpresaSub => !showEmpresaSub);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowCidadeEstado(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showEmpresaSub && (
                            <div className="grid-container-coluna-empresa">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cnpj" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCnpjValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cnpj',
                                                },
                                            )
                                        }}
                                        value={cnpjValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cidade" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCidadeEmpresaSubValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cidade',
                                                },
                                            )
                                        }}
                                        value={cidadeEmpresaSubValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Regime Tributário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setegimeTributarioValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'regimeTributario',
                                                },
                                            )
                                        }}
                                        value={regimeTributarioValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showFilial ? 'FECHAR' : 'Filial'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowFilial(showFilial => !showFilial);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showFilial && (
                            <div className="grid-container-coluna-embarque">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Filial" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeFilialValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeFiliaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Complemento Localização" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setComplementoLocalizacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'complementoLocalizacao',
                                                },
                                            )
                                        }}
                                        value={complementoLocalizacaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Responsável" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setResponsavelValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'responsavel',
                                                },
                                            )
                                        }}
                                        value={responsavelValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Telefone" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTelefoneValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'telefone',
                                                },
                                            )
                                        }}
                                        value={telefoneValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Email" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmailValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'email',
                                                },
                                            )
                                        }}
                                        value={emailValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="CidadeFilial" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCidadeFilialValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cidade',
                                                },
                                            )
                                        }}
                                        value={cidadeFilialValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showMeioPagamento ? 'FECHAR' : 'Meio Pagamento'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowMeioPagamento(showMeioPagamento => !showMeioPagamento);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showMeioPagamento && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Meio Pagamento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeMeioPagamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeMeioPagamentoValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showMercadoria ? 'FECHAR' : 'Mercadoria'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowMercadoria(showMercadoria => !showMercadoria);
                                setShowMeioPagamento(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showMercadoria && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Mercadoria" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMercadoriaNomeValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={mercadoriaNomeValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showMotorista ? 'FECHAR' : 'Motorista'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowMotorista(showMotorista => !showMotorista);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showMotorista && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeMotoristaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeMotoristaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Numero Documento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNumeroDocumentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'numeroDocumento',
                                                },
                                            )
                                        }}
                                        value={numeroDocumentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Documento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoDocumentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoDocumento',
                                                },
                                            )
                                        }}
                                        value={tipoDocumentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Telefone" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTelefoneMotoristaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'telefone',
                                                },
                                            )
                                        }}
                                        value={telefoneMotoristaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome Emergência" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeEmergenciaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nomeEmergencia',
                                                },
                                            )
                                        }}
                                        value={nomeEmergenciaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Telefone Emergência" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTelefoneEmergenciaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'telefoneEmergencia',
                                                },
                                            )
                                        }}
                                        value={telefoneEmergenciaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFrotaMotoristaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'frota',
                                                },
                                            )
                                        }}
                                        value={frotaMotoristaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUsuarioMotoristaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuarioClientes',
                                                    objetoUsuarios: 'true'
                                                },
                                            )
                                        }}
                                        value={usuarioMotoristaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Bloqueado" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setBloqueadoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'bloqueado'
                                                },
                                            )
                                        }}
                                        value={bloqueadoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motivo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotivoBloqueadoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motivo'
                                                },
                                            )
                                        }}
                                        value={motivoBloqueadoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showNotificacao ? 'FECHAR' : 'Notificações'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowNotificacao(showNotificacao => !showNotificacao);
                                setShowMercadoria(false);
                                setShowMotorista(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showNotificacao && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Título" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTituloNotificaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'titulo',
                                                },
                                            )
                                        }}
                                        value={tituloNotificaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Mensagem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMensagemNotificacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'mensagem',
                                                },
                                            )
                                        }}
                                        value={mensagemNotificacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Ação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAcaoNotificacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'acao',
                                                },
                                            )
                                        }}
                                        value={acaoNotificacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Número de Visualizações" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNumeroVisualizacoesNotificacaoValueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'numeroVisualizacoes',
                                                },
                                            )
                                        }}
                                        value={numeroVisualizacoesNotificacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataNotificacaoValuValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'data',
                                                },
                                            )
                                        }}
                                        value={dataNotificacaoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showPedido ? 'FECHAR' : 'Pedido'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowPedido(showPedido => !showPedido);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showPedido && (
                            <div className="grid-container-coluna-embarque">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Pedido" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPedidoPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'pedido',
                                                },
                                            )
                                        }}
                                        value={pedidoPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Rota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoRotaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoRota',
                                                },
                                            )
                                        }}
                                        value={tipoRotaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frete Fiscal" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFreteFiscalPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'freteFiscal',
                                                    tipo: 'number',
                                                },
                                            )
                                        }}
                                        value={freteFiscalPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="ICMS" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setIcmsPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'icms',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={icmsPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Pedágio" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPedagioPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'pedagio',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={pedagioPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Classificação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setClassificacaoPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'classificacao',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={classificacaoPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setKmPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'km',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={kmPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setClientePValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cliente',
                                                },
                                            )
                                        }}
                                        value={clientePValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Embarcador" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmbarcadorPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'embarcador',
                                                },
                                            )
                                        }}
                                        value={embarcadorPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Destinatário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDestinatarioPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'destinatario',
                                                },
                                            )
                                        }}
                                        value={destinatarioPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frete Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFreteMotoristaPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'freteMotorista',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={freteMotoristaPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Observação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setObservacaoPValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'observacao',
                                                },
                                            )
                                        }}
                                        value={observacaoPValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Permitir Alterar Frete Fiscal" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPermiteAlterarFreteFiscalValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'permiteAlterarFreteFiscal',
                                                },
                                            )
                                        }}
                                        value={permiteAlterarFreteFiscalValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Valor Embarque" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setValorEmbarquePedidoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'valorEmbarque',
                                                },
                                            )
                                        }}
                                        value={valorEmbarquePedidoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="ICMS Embutido" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setIcmsEmbutidoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'icmsEmbutido',
                                                },
                                            )
                                        }}
                                        value={icmsEmbutido?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="ICMS Embarcador" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setIcmsEmbarcadorValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'icmsEmbarcador',
                                                },
                                            )
                                        }}
                                        value={icmsEmbarcador?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frete Normal" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFreteNormalValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'freteNormal',
                                                },
                                            )
                                        }}
                                        value={freteNormal?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-embarquepedido">
                                    <input
                                        type="checkbox"
                                        checked={quebraTabelaPedido?.quebraColunaPedido}
                                        onChange={(e) => {
                                            setQuebraTabelaPedidoValue(
                                                {
                                                    quebraColunaPedido: e.target.checked,
                                                },
                                            );
                                        }}
                                    ></input><InputLabel htmlFor="cidade"><Text texto="Quebra Linha" /></InputLabel>
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showProprietario ? 'FECHAR' : 'Proprietário'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowProprietario(showProprietario => !showProprietario);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                                setShowRota(false);
                            }}
                        />
                    </div>
                    {
                        showProprietario && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setProprietarioNomeValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={proprietarioNomeValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="CNPJ" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCnpjProprietarioValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cnpj',
                                                },
                                            )
                                        }}
                                        value={cnpjProprietarioValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUsuarioProprietarioValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuarioClientes',
                                                    objetoUsuarios: 'true'
                                                },
                                            )
                                        }}
                                        value={usuarioProprietarioValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showRota ? 'FECHAR' : 'Rota'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowRota(showRota => !showRota);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showRota && (
                            <div className="grid-container-coluna-embarque">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Rota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeRotaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeRotaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cidade Origem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCidadeOrigemRotaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cidadeOrigem',
                                                },
                                            )
                                        }}
                                        value={cidadeOrigemRotaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Estado Origem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEstadoOrigemRotaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'estadoOrigem',
                                                },
                                            )
                                        }}
                                        value={estadoOrigemRotaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cidade Destino" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCidadeDestinoRotaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cidadeDestino',
                                                },
                                            )
                                        }}
                                        value={cidadeDestinoRotaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Estado Destino" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEstadoDestinoRotaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'estadoDestino',
                                                },
                                            )
                                        }}
                                        value={estadoDestinoRotaValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showSolicitacao ? 'FECHAR' : 'Solicitações'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowSolicitacao(showSolicitacao => !showSolicitacao);
                                setShowMercadoria(false);
                                setShowStatus(false);
                                setShowNotificacao(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showSolicitacao && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUsuarioSolicitacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuario',
                                                },
                                            )
                                        }}
                                        value={usuarioSolicitacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Email" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmailSolicitacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'email',
                                                },
                                            )
                                        }}
                                        value={emailSolicitacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Telefone" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTelefoneSolicitacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'telefone',
                                                },
                                            )
                                        }}
                                        value={telefoneSolicitacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nascimento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNascimentoSolicitacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nascimento',
                                                },
                                            )
                                        }}
                                        value={nascimentoSolicitacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Aceitar" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAceitarSolicitacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'aceitar',
                                                },
                                            )
                                        }}
                                        value={aceitarSolicitacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Recusar" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setRecusarSolicitacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'recusar',
                                                },
                                            )
                                        }}
                                        value={recusarSolicitacaoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showStatus ? 'FECHAR' : 'Status'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowStatus(showStatus => !showStatus);
                                setShowMercadoria(false);
                                setShowNotificacao(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showStatus && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Status" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeStatusValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeStatusValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showTaxaIcms ? 'FECHAR' : 'Taxa Icms'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowTaxaIcms(showTaxaIcms => !showTaxaIcms);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowStatus(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showTaxaIcms && (
                            <div className="grid-container-coluna-empresa">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Taxa ICMS" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTaxaIcmsValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'taxa',
                                                    tipo: 'number'
                                                },
                                            )
                                        }}
                                        value={taxaIcmsValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="UF Origem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUfOrigemTaxaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'ufOrigem',
                                                },
                                            )
                                        }}
                                        value={ufOrigemTaxaValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="UF Destino" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUfDestinoTaxaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'ufDestino',
                                                },
                                            )
                                        }}
                                        value={ufDestinoTaxaValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showUnidadeEmbarque ? 'FECHAR' : 'Unidade Embarque'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowUnidadeEmbarque(showUnidadeEmbarque => !showUnidadeEmbarque);
                                setShowMercadoria(false);
                                setShowNotificacao(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowProprietario(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showUnidadeEmbarque && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Unidade Embarque" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeUnidadeEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeUnidadeEmbarqueValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cidade" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setCidadeUnidadeEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuario',
                                                },
                                            )
                                        }}
                                        value={cidadeUnidadeEmbarqueValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFrotaUnidadeEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'frota',
                                                },
                                            )
                                        }}
                                        value={frotaUnidadeEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showMotivo ? 'FECHAR' : 'Motivo'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowMotivo(showMotivo => !showMotivo);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowUnidadeEmbarque(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showMotivo && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="motivo"><Text texto="Motivo" /></InputLabel>
                                    <input
                                        type="text"
                                        id="motivo"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotivoValues(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motivo',
                                                },
                                            )
                                        }}
                                        value={motivoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showUsuarioGeren ? 'FECHAR' : 'Usuário'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowUsuarioGeren(showUsuarioGeren => !showUsuarioGeren);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showUsuarioGeren && (
                            <div className="grid-container-coluna-empresa">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeUsuarioGerenValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuario',
                                                },
                                            )
                                        }}
                                        value={nomeUsuarioGerenValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Senha" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setSenhaUsuarioGerenValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: '',
                                                },
                                            )
                                        }}
                                        value={senhaUsuarioGerenValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Email" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmailUsuarioGerenValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'email',
                                                },
                                            )
                                        }}
                                        value={emailUsuarioGerenValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Telefone" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTelefoneUsuarioGerenValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'telefone',
                                                },
                                            )
                                        }}
                                        value={telefoneUsuarioGerenValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nascimento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNascimentoUsuarioGerenValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nascimento',
                                                },
                                            )
                                        }}
                                        value={nascimentoUsuarioGerenValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Permissões" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPermissoesUsuarioGerenValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'permissoes',
                                                },
                                            )
                                        }}
                                        value={permissoesUsuarioGerenValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Último Login" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUltimoLoginValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'ultimoLogin',
                                                },
                                            )
                                        }}
                                        value={ultimoLoginValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Último Logout" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUltimoLogoutValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'ultimoLogout',
                                                },
                                            )
                                        }}
                                        value={ultimoLogoutValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showVeiculo ? 'FECHAR' : 'Veículo'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowVeiculo(showVeiculo => !showVeiculo);
                                setShowMercadoria(false);
                                setShowNotificacao(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                            }}
                        />
                    </div>
                    {
                        showVeiculo && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa Cavalo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCavaloVeiculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placaCavalo',
                                                },
                                            )
                                        }}
                                        value={placaCavaloVeiculoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa Carreta" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCarretaVeiculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placaCarreta',
                                                },
                                            )
                                        }}
                                        value={placaCarretaVeiculoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa Carreta 2" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCarreta2VeiculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placaCarreta2',
                                                },
                                            )
                                        }}
                                        value={placaCarreta2VeiculoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Proprietário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setProprietarioVeiculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'proprietario',
                                                },
                                            )
                                        }}
                                        value={proprietarioVeiculoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotoristaVeiculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motorista',
                                                },
                                            )
                                        }}
                                        value={motoristaVeiculoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motorista 2" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotorista2VeiculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motorista2',
                                                },
                                            )
                                        }}
                                        value={motorista2VeiculoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Eixos" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEixosValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'eixos',
                                                },
                                            )
                                        }}
                                        value={eixosValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Veiculo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoVeiculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoVeiculo',
                                                },
                                            )
                                        }}
                                        value={tipoVeiculoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showCaminhao ? 'FECHAR' : 'Caminhão'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowCaminhao(showCaminhao => !showCaminhao);
                                setShowCarreta(false);
                                setShowAvisos(false);
                                setShowMercadoria(false);
                                setShowNotificacao(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                            }}
                        />
                    </div>
                    {
                        showCaminhao && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placa',
                                                },
                                            )
                                        }}
                                        value={placaCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Ano" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAnoCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'ano',
                                                },
                                            )
                                        }}
                                        value={anoCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Renavam" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setRenavamCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'renavam',
                                                },
                                            )
                                        }}
                                        value={renavamCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Marca" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMarcaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'marca',
                                                },
                                            )
                                        }}
                                        value={marcaCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Modelo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setModeloCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'modelo',
                                                },
                                            )
                                        }}
                                        value={modeloCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFrotaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'frota',
                                                },
                                            )
                                        }}
                                        value={frotaCaminhaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setKmCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'km',
                                                },
                                            )
                                        }}
                                        value={kmCaminhaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Eixos" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEixosCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'numeroEixos',
                                                },
                                            )
                                        }}
                                        value={eixosCaminhaoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showCarreta ? 'FECHAR' : 'Carreta'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowCarreta(showCarreta => !showCarreta);
                                setShowAvisos(false);
                                setShowCaminhao(false);
                                setShowMercadoria(false);
                                setShowNotificacao(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                            }}
                        />
                    </div>
                    {
                        showCarreta && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Placa" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPlacaCarretaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'placa',
                                                },
                                            )
                                        }}
                                        value={placaCarretaCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Ano" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAnoCarretaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'ano',
                                                },
                                            )
                                        }}
                                        value={anoCarretaCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Renavam" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setRenavamCarretaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'renavam',
                                                },
                                            )
                                        }}
                                        value={renavamCarretaCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Marca" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMarcaCarretaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'marca',
                                                },
                                            )
                                        }}
                                        value={marcaCarretaCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Modelo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setModeloCarretaCaminhaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'modelo',
                                                },
                                            )
                                        }}
                                        value={modeloCarretaCaminhaoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFrotaCarretaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'frota',
                                                },
                                            )
                                        }}
                                        value={frotaCarretaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setKmCarretaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'km',
                                                },
                                            )
                                        }}
                                        value={kmCarretaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Eixos" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEixosCarretaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'numeroEixos',
                                                },
                                            )
                                        }}
                                        value={eixosCarretaValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showAvisos ? 'FECHAR' : 'Avisos'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowAvisos(showAvisos => !showAvisos);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowProprietario(false);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                                setShowRota(false);
                            }}
                        />
                    </div>
                    {
                        showAvisos && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Mensagem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMensagemAvisoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'mensagem',
                                                },
                                            )
                                        }}
                                        value={mensagemAvisoValue.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataAvisoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'data',
                                                },
                                            )
                                        }}
                                        value={dataAvisoValue.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showTipoCheckagem ? 'FECHAR' : 'Tipo de Checkagem'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowTipoCheckagem(showTipoCheckagem => !showTipoCheckagem);
                                setShowCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showTipoCheckagem && (
                            <div className="grid-container-coluna-embarque">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo de Checkagem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeTipoCheckagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeTipoCheckagemValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Notificar Automatico" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNotificarAutomaticoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'notificarAutomatico',
                                                },
                                            )
                                        }}
                                        value={notificarAutomaticoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Notificação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoNotificacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoNotificacao',
                                                },
                                            )
                                        }}
                                        value={tipoNotificacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Notificar Data" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNotificarACadaDataValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'notificarACadaData',
                                                },
                                            )
                                        }}
                                        value={notificarACadaDataValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Notificar KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNotificarACadaKMValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'notificarACadaKM',
                                                },
                                            )
                                        }}
                                        value={notificarACadaKMValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showCheckagem ? 'FECHAR' : 'Checkagem'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowCheckagem(showCheckagem => !showCheckagem);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showCheckagem && (
                            <div className="grid-container-coluna-embarque">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Dados" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDadosCheckagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dados',
                                                },
                                            )
                                        }}
                                        value={dadosCheckagemValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataCheckagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataCheckagem',
                                                },
                                            )
                                        }}
                                        value={dataCheckagemValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Observação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setObservacaoCheckagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'observacao',
                                                },
                                            )
                                        }}
                                        value={observacaoCheckagemValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setKmCheckagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'km',
                                                },
                                            )
                                        }}
                                        value={kmCheckagemValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Local" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setLocalCheckagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'local',
                                                },
                                            )
                                        }}
                                        value={localCheckagemValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Manutenção" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoManutencaoCheckagemValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoManutencao',
                                                },
                                            )
                                        }}
                                        value={tipoManutencaoCheckagemValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Proxima Revisão Data" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setProximaRevisaoDataValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'proximaRevisaoData',
                                                },
                                            )
                                        }}
                                        value={proximaRevisaoDataValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Proxima Revisão KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setProximaRevisaoKMValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'proximaRevisaoKM',
                                                },
                                            )
                                        }}
                                        value={proximaRevisaoKMValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Check" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoCheckValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoCheck',
                                                },
                                            )
                                        }}
                                        value={tipoCheckValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showTipoRastreamento ? 'FECHAR' : 'Tipo Rastreamento'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowTipoRastreamento(showTipoRastreamento => !showTipoRastreamento);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showTipoRastreamento && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeTipoRastreamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeTipoRastreamentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="IP" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setIpValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'ip',
                                                },
                                            )
                                        }}
                                        value={ipValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showRastreamento ? 'FECHAR' : 'Rastreamento'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowRastreamento(showRastreamento => !showRastreamento);
                                setShowTipoRastreamento(false);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showRastreamento && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário Rastreamento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUsuarioRastreamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuarioRastreamento',
                                                },
                                            )
                                        }}
                                        value={usuarioRastreamentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Senha" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setSenhaRastreamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'senha',
                                                },
                                            )
                                        }}
                                        value={senhaRastreamentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Veículo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoVeiculoRastreamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoVeiculo',
                                                },
                                            )
                                        }}
                                        value={tipoVeiculoRastreamentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Rastreamento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoRastreamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoRastreamento',
                                                },
                                            )
                                        }}
                                        value={tipoRastreamentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Veículo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setVeiculoRastreamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field1: 'carreta',
                                                    field2: 'caminhao',
                                                },
                                            )
                                        }}
                                        value={veiculoRastreamentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Fabricante Rastreamento" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFabricanteRastreamentoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'fabricanteRastreamento',
                                                },
                                            )
                                        }}
                                        value={fabricanteRastreamentoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Número Rastreador" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNumeroRastreadorValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'numeroRastreador',
                                                },
                                            )
                                        }}
                                        value={numeroRastreadorValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showGrupoClientes ? 'FECHAR' : 'Grupo Clientes'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowGrupoClientes(showGrupoClientes => !showGrupoClientes);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showGrupoClientes && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeGrupoClienteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeGrupoClienteValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showCockpit ? 'FECHAR' : 'Cockpit'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowCockpit(showCockpit => !showCockpit);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showCockpit && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Projetado" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setProjetadoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'projetado',
                                                },
                                            )
                                        }}
                                        value={projetadoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Estado Origem" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEstadoOrigemCockpitValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'estadoOrigem',
                                                },
                                            )
                                        }}
                                        value={estadoOrigemCockpitValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Estado Destino" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEstadoDestinoCockpitValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'estadoDestino',
                                                },
                                            )
                                        }}
                                        value={estadoDestinoCockpitValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Grupo Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setGrupoClienteCockpitValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'grupoCliente',
                                                },
                                            )
                                        }}
                                        value={grupoClienteCockpitValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showGrupoComClientes ? 'FECHAR' : 'Vincular Clientes'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowGrupoComClientes(showGrupoComClientes => !showGrupoComClientes);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showGrupoComClientes && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setClienteVinculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cliente',
                                                },
                                            )
                                        }}
                                        value={clienteVinculoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Grupo Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setGrupoClienteVinculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'grupoCliente',
                                                },
                                            )
                                        }}
                                        value={grupoClienteVinculoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showGrupoPrecificacao ? 'FECHAR' : 'Grupo Precificação'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowGrupoPrecificacao(showGrupoPrecificacao => !showGrupoPrecificacao);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showGrupoPrecificacao && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setGrupoPrecificacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={grupoPrecificacaoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showValorEmbarque ? 'FECHAR' : 'Precificação'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowValorEmbarque(showValorEmbarque => !showValorEmbarque);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowProprietario(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showValorEmbarque && (
                            <div className="grid-container-coluna-embarque">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Eixos" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEixosValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'eixos',
                                                },
                                            )
                                        }}
                                        value={eixosValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setKmValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'km',
                                                },
                                            )
                                        }}
                                        value={kmValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Valor Eixo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setValorEixoValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'valorEixo',
                                                },
                                            )
                                        }}
                                        value={valorEixoValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Adicional Carga Descarga" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setAdicionalCargaDescargaValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'adicionalCargaDescarga'
                                                },
                                            )
                                        }}
                                        value={adicionalCargaDescargaValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotoristaValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motorista'
                                                },
                                            )
                                        }}
                                        value={motoristaValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Empresa" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmpresaValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'empresa'
                                                },
                                            )
                                        }}
                                        value={empresaValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="ICMS" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setIcmsValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'icms'
                                                },
                                            )
                                        }}
                                        value={icmsValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Descarga" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDescargaValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'descarga',
                                                },
                                            )
                                        }}
                                        value={descargaValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Pedágio" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPedagioValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'pedagio',
                                                },
                                            )
                                        }}
                                        value={pedagioValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Frete Fiscal" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFreteFiscalValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'freteFiscal',
                                                },
                                            )
                                        }}
                                        value={freteFiscalValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Rota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setRotaValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'rota',
                                                    objetoRota: 'true',
                                                },
                                            )
                                        }}
                                        value={rotaValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setClienteValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cliente',
                                                    objetoCliente: 'true'
                                                },
                                            )
                                        }}
                                        value={clienteValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Grupo Precificação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setGrupoPrecificacaoValorEmbarqueValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'grupoPrecificacao',
                                                    objetoGrupoPrecificacao: 'true'
                                                },
                                            )
                                        }}
                                        value={grupoPrecificacaoValorEmbarqueValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showMotivoOnTime ? 'FECHAR' : 'Motivo On Time'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowMotivoOnTime(showMotivoOnTime => !showMotivoOnTime);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showMotivoOnTime && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motivo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotivoOnTimeValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motivo',
                                                },
                                            )
                                        }}
                                        value={motivoOnTimeValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showGrupoCarga ? 'FECHAR' : 'Grupo Carga Vinculo'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowGrupoCarga(showGrupoCarga => !showGrupoCarga);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showGrupoCarga && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome Grupo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setGrupoCargaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nomeGrupo',
                                                },
                                            )
                                        }}
                                        value={grupoCargaValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showGrupoCargaVinculo ? 'FECHAR' : 'Vincular Grupo Carga'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowGrupoCargaVinculo(showGrupoCargaVinculo => !showGrupoCargaVinculo);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showGrupoCargaVinculo && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Grupo Carga" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setGrupoCargaVinculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'grupoCarga',
                                                    objetoGrupoCarga: 'true'
                                                },
                                            )
                                        }}
                                        value={grupoCargaVinculoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotoristaGrupoCargaVinculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motorista',
                                                    objetoMotorista: 'true'
                                                },
                                            )
                                        }}
                                        value={motoristaGrupoCargaVinculoVinculoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Proprietário" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setProprietarioGrupoCargaVinculoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'proprietario',
                                                    objetoProprietario: 'true'
                                                },
                                            )
                                        }}
                                        value={proprietarioGrupoCargaVinculoVinculoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showCotacao ? 'FECHAR' : 'Cotação'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowCotacao(showCotacao => !showCotacao);
                                setShowTipoCheckagem(false);
                                setShowAvisos(false);
                                setShowCarreta(false);
                                setShowCaminhao(false);
                                setShowNotificacao(false);
                                setShowProprietario(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowCidadeEstado(false);
                                setShowEmbalagem(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showCotacao && (
                            <div className="grid-container-coluna-motorista">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Unidade Embarque" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUnidadeEmbarqueCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'unidadeEmbarque',
                                                    objetoUnidadeEmbarqueNome: 'true'
                                                },
                                            )
                                        }}
                                        value={unidadeEmbarqueCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Rota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setRotaCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'rota',
                                                    objetoRota: 'true'
                                                },
                                            )
                                        }}
                                        value={rotaCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setClienteCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cliente',
                                                    objetoCliente: 'true'
                                                },
                                            )
                                        }}
                                        value={clienteCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Valor" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setValorCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'valor'
                                                },
                                            )
                                        }}
                                        value={valorCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="ICMS" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setIcmsCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'icms'
                                                },
                                            )
                                        }}
                                        value={icmsCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Pedágio" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setPedagioCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'pedagio'
                                                },
                                            )
                                        }}
                                        value={pedagioCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Distância KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDistanciaKmCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'distanciaKm'
                                                },
                                            )
                                        }}
                                        value={distanciaKmCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Valor Por KM" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setValorPorKmCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'valorPorKm'
                                                },
                                            )
                                        }}
                                        value={valorPorKmCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Valor Desejavel Motorista" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setValorDesejavelMotoristaCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'valorDesejavelMotorista'
                                                },
                                            )
                                        }}
                                        value={valorDesejavelMotoristaCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Descrição Rota" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDescricaoRotaCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'descricaoRota'
                                                },
                                            )
                                        }}
                                        value={descricaoRotaCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Foi Carregado" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setFoiCarregadoCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'foiCarregado'
                                                },
                                            )
                                        }}
                                        value={foiCarregadoCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Veículo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoVeiculoCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoVeiculo',
                                                    objetoTipoVeiculo: 'true'
                                                },
                                            )
                                        }}
                                        value={tipoVeiculoCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Carroceria" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoCarroceriaCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'segmentoCliente',
                                                    objetoTipoCarroceria: 'true'
                                                },
                                            )
                                        }}
                                        value={tipoCarroceriaCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Segmento Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setSegmentoCliente(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'cotacaoCarroceria',
                                                    objetoSegmentoCliente: 'true'
                                                },
                                            )
                                        }}
                                        value={segmentoCliente?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motivo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotivoCotacao(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motivoCotacao',
                                                    objetoMotivo: 'true'
                                                },
                                            )
                                        }}
                                        value={motivoCotacao?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Data Cotação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setDataCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'dataCotacao'
                                                },
                                            )
                                        }}
                                        value={dataCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Usuário Responsável" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setUsuarioResponsavelCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'usuarioResponsavel'
                                                },
                                            )
                                        }}
                                        value={usuarioResponsavelCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Nome" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeContatoCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nomeContato'
                                                },
                                            )
                                        }}
                                        value={nomeContatoCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Telefone" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTelefoneContatoCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'telefoneContato'
                                                },
                                            )
                                        }}
                                        value={telefoneContatoCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Email" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setEmailContatoCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'emailContato'
                                                },
                                            )
                                        }}
                                        value={emailContatoCotacaoValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Observação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setObservacaoCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'observacao'
                                                },
                                            )
                                        }}
                                        value={observacaoCotacaoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showTipoVeiculo ? 'FECHAR' : 'Tipo Veículo'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowTipoVeiculo(showTipoVeiculo => !showTipoVeiculo);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showTipoVeiculo && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Veículo" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeTipoVeiculo(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeTipoVeiculo?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showTipoCarroceria ? 'FECHAR' : 'Tipo Carroceria'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowTipoCarroceria(showTipoCarroceria => !showTipoCarroceria);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showTipoCarroceria && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Carroceria" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setNomeTipoCarroceria(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={nomeTipoCarroceria?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showMotivoCotacao ? 'FECHAR' : 'Motivo Cotação'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowMotivoCotacao(showMotivoCotacao => !showMotivoCotacao);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showMotivoCotacao && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Motivo Cotação" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setMotivoCotacaoValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'motivo',
                                                },
                                            )
                                        }}
                                        value={motivoCotacaoValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showSegmentoCliente ? 'FECHAR' : 'Segmento Cliente'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowSegmentoCliente(showSegmentoCliente => !showSegmentoCliente);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showSegmentoCliente && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Segmento Cliente" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setSegmentoClienteValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={segmentoClienteValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showOcorrencia ? 'FECHAR' : 'Ocorrência'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowOcorrencia(showOcorrencia => !showOcorrencia);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showOcorrencia && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Ocorrência" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setOcorrenciaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipo',
                                                },
                                            )
                                        }}
                                        value={ocorrenciaValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showTipoOcorrencia ? 'FECHAR' : 'Tipo Ocorrência'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowTipoOcorrencia(showTipoOcorrencia => !showTipoOcorrencia);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showTipoOcorrencia && (
                            <div className="grid-container-coluna-embalagem">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Ocorrência" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoOcorrenciaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={tipoOcorrenciaValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo={showSubTipoOcorrencia ? 'FECHAR' : 'Sub Tipo Ocorrência'}
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                setShowSubTipoOcorrencia(showSubTipoOcorrencia => !showSubTipoOcorrencia);
                                setShowNotificacao(false);
                                setShowMercadoria(false);
                                setShowTaxaIcms(false);
                                setShowProprietario(false);
                                setShowCidadeEstado(false);
                                setShowEmbarcador(false);
                                setShowEmbarque(false);
                                setShowEmpresaSub(false);
                                setShowFilial(false);
                                setShowMeioPagamento(false);
                                setShowMotorista(false);
                                setShowPedido(false);
                                setShowRota(false);
                                setShowStatus(false);
                                setShowUnidadeEmbarque(false);
                                setShowUsuarioGeren(false);
                                setShowVeiculo(false);
                            }}
                        />
                    </div>
                    {
                        showSubTipoOcorrencia && (
                            <div className="grid-container-coluna-cidade">
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Sub Tipo Ocorrência" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setSubTipoOcorrenciaValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'nome',
                                                },
                                            )
                                        }}
                                        value={subTipoOcorrenciaValue?.nomeColuna}
                                    />
                                </div>
                                <div className="grid-item-coluna-cidade">
                                    <InputLabel htmlFor="cidade"><Text texto="Tipo Ocorrência" /></InputLabel>
                                    <input
                                        type="text"
                                        className="input-text"
                                        onChange={(e) => {
                                            setTipoOcorrenciaSubValue(
                                                {
                                                    nomeColuna: e.target.value,
                                                    field: 'tipoOcorrencia',
                                                    objetoTipoOcorrencia: 'true'
                                                },
                                            )
                                        }}
                                        value={tipoOcorrenciaSubValue?.nomeColuna}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="corpo-cores">
                        <CustomButton
                            classCss="custom-button-giant"
                            titulo="Salvar"
                            cor="#008000"
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => { setDadosTabelaRedux(valorInicial); cadastrar(); }}
                        />
                    </div>
                    <div>
                        <ModalAtualizar
                            titulo="Colunas"
                            texto="Clique no botão para Atulizar a Página!"
                            open={open}
                            onClose={handleClose}
                            onClick={cadastrar}
                        />
                        <ToastSucesso
                            texto={textoSucesso}
                            open={openToastSucesso}
                            onClose={handleCloseToastSucesso}
                        />
                        <ToastErro
                            texto={textoErro}
                            open={openToastErro}
                            onClose={handleCloseToastErro}
                        />
                    </div>
                </motion.div>
            )
            }
        </motion.div>
    );
}

export default ConfiguracoesColunas;
import React, { useState, useContext, useEffect, useRef } from "react";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import '../NavbarV2/NavbarV2.css';
import { IconContext } from 'react-icons';
import CoresRedux from "../../contexts/Cor";
import logoNavbar from '../../images/logoNavbar.png'
import iconNotification from '../../images/IconNotification.png'
import iconConfig from '../../images/IconConfig.png'
import * as MdIcons from 'react-icons/md';
import styled from "styled-components";
import FilialCidade from "../../contexts/CidadesFilial";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import ModalConfirmacao from "../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getNumeroSolicitacoes } from "../../services/ModuloGerenciador/Solicitacao";
import { logoutUser, verificaDadosUsuarioRedux } from "../../services/ModuloGerenciador/Usuario";
import DadosUsuarioRedux from "../../contexts/Usuario";
import ToastSucesso from "../Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../Toast/ToastErro/ToastErro.tsx";
import { getMidiaIdUsuario } from "../../services/ModuloGerenciador/Midia";
import ModalNotificacao from "../ModalNotificacao/ModalNotificacao.tsx";
import { SidebarDataV2 } from "./SidebarDataV2";
import { useNavigate } from "react-router-dom";
import iconeHome from '../../images/iconeHome.png'
import iconeEmbarque from '../../images/iconeEmbarque.png'
import iconeFeedback from '../../images/iconeFeedback.png'
import iconeAjuda from '../../images/iconeAjuda.png'
import iconeMenu from '../../images/iconeMenu.png'
import iconeUsuario from '../../images/iconeUsuario.png'
import iconeAdm from '../../images/iconeAdm.png'
import ModalConfirmacao2 from "../ModalConfirmacao2/ModalConfirmacao2.tsx";
import ModalAlerta from "../ModalAlerta/ModalAlerta.tsx";

function NavbarV2(props) {
  const [sidebar, setSidebar] = useState(false);

  const ref = useRef();

  const [loading, setLoading] = useState(false);

  const [numeroSolicitacoes, setNumeroSolicitacoes] = useState();

  const [quantidadePedidosAguardando, setQuantidadePedidosAguardando] = useState();

  const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

  const showSidebar = () => {
    localStorage.removeItem("filtros");
    setSidebar(!sidebar);
  }

  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSidebar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const [dropDown, setDropDown] = useState(false);

  const showDropDow = () => setDropDown(!dropDown);

  const [dropDownEmbarques, setDropDownEmbarques] = useState(false);

  const showDropDowEmbarques = () => setDropDownEmbarques(!dropDownEmbarques);

  const [dropDownVeiculos, setDropDownVeiculos] = useState(false);

  const showDropDowVeiculos = () => setDropDownVeiculos(!dropDownVeiculos);

  const [dropDownPessoas, setDropDownPessoas] = useState(false);

  const showDropDowPessoas = () => setDropDownPessoas(!dropDownPessoas);

  const [dropDownDashboard, setDropDownDashboard] = useState(false);

  const showDropDowDashboard = () => setDropDownDashboard(!dropDownDashboard);

  const [dropDownPrecificacao, setDropDownPrecificacao] = useState(false);

  const showDropDowPrecificacao = () => setDropDownPrecificacao(!dropDownPrecificacao);

  const [dropDownCotacao, setDropDownCotacao] = useState(false);

  const showDropDowCotacao = () => setDropDownCotacao(!dropDownCotacao);

  const [dropDownPedido, setDropDownPedido] = useState(false);

  const showDropDowPedido = () => setDropDownPedido(!dropDownPedido);

  const [dropDownOnTime, setDropDownOnTime] = useState(false);

  const showDropDowOnTime = () => setDropDownOnTime(!dropDownOnTime);

  const [dropDownGerenciamentoFrota, setDropDownGerenciamentoFrota] = useState(false);

  const showDropDowGerenciamentoFrota = () => setDropDownGerenciamentoFrota(!dropDownGerenciamentoFrota);

  const [dropDownRastreamento, setDropDownRastreamento] = useState(false);

  const showDropDowRastreamento = () => setDropDownRastreamento(!dropDownRastreamento);

  const [dropDownDiversos, setDropDownDiversos] = useState(false);

  const showDropDowDiversos = () => {
    setDropDownDiversos(!dropDownDiversos)
  };

  const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

  const [dropDownConfigs, setDropDownConfigs] = useState(false);

  const showDropDowConfigs = () => setDropDownConfigs(!dropDownConfigs);

  const [dropDownUsuario, setDropDownUsuario] = useState(false);

  const showDropDowUsuario = () => setDropDownUsuario(!dropDownUsuario);

  const { dadosCoresRedux } = useContext(CoresRedux);
  const { dadosFilialRedux } = useContext(FilialCidade);

  const [open, setOpen] = useState(false);

  const [textoSucesso, setTextoSucesso] = useState("");

  const [textoErro, setTextoErro] = useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

  const [openToastErro, setOpenToastErro] = useState(false);

  const handleCloseToastErro = (value) => {
    setOpenToastErro(false);
  };

  const [openToastSucesso, setOpenToastSucesso] = useState(false);

  const handleCloseToastSucesso = (value) => {
    setOpenToastSucesso(false);
  };

  const [notificacao, setNotificacao] = useState([]);

  const [openNotificacao, setOpenNotificacao] = useState(false);

  const handleCloseNotificacao = (value) => {
    setOpenNotificacao(false);
  };

  const [openExpiracao, setOpenExpiracao] = useState(false);

  const handleCloseExpiracao = (value) => {
    setOpenExpiracao(false);
  };

  const [configDropdownOpen, setConfigDropdownOpen] = useState(false);

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const logout = async () => {
    await logoutUser(dadosUsuarioRedux.usuario.idUsuario, dadosUsuarioRedux.token).then((result) => {
      setTextoSucesso(result.message);
    }).catch((error) => {
      setTextoErro(error.message);
    });
    localStorage.removeItem("usuario");
    localStorage.removeItem("token");
    localStorage.removeItem("expiracao");
    localStorage.removeItem("expirateTime");
    localStorage.removeItem("usuarioTabela");
    localStorage.removeItem("Cookies");
    localStorage.removeItem("flagPassword");
    window.location.reload();
  };

  const Menu = styled.div`     
    background: ${dadosCoresRedux.fundoMenu};

    &:hover {
    background: ${dadosCoresRedux.botaoSelecionado};
    }
  `

  const Rota = styled(Link)`     
    color: ${dadosCoresRedux.textoBotaoNormal};

    &:hover {
    color: ${dadosCoresRedux.textoBotaoSelecionado};
    }
  `

  const onClick2 = (item) => {
    setDadosContextoEmbarqueRedux(item.contexto);
  }

  const MenuConfigs = [
    {
      menu: "Avisos",
      titulo: "Avisos",
      rota: "/avisos"
    },
    {
      menu: "Cores",
      titulo: "ConfiguracoesCores",
      rota: "/configuracoes-cores"
    },
    {
      menu: "Colunas",
      titulo: "ConfiguracoesColunas",
      rota: "/configuracoes-coluna"
    },
    {
      menu: "Notificações",
      titulo: "Notificacoes",
      rota: "/notificacoes"
    },
    {
      menu: "Enviar Email",
      titulo: "EnviarEmail",
      rota: "/envia-email"
    },
  ];

  const MenuUsuario = [
    {
      menu: "Usuários",
      titulo: "Usuários",
      rota: "/cadastrar-usuario"
    },
    {
      menu: "Solicitações",
      titulo: "Solicitações",
      rota: "/solicitacoes",
      notificao: true
    },
  ];

  const buscarNumeroSolicitacoes = async () => {
    if (!window.location.href.endsWith("login")) {
      await getNumeroSolicitacoes(dadosUsuarioRedux.token).then((result) => {
        if (result.status === 200) {
          setNumeroSolicitacoes(result.data.quantidadeSolicitacoes);
          setQuantidadePedidosAguardando(result.data.quantidadePedidosAguardando)
        }
      }).catch((error) => {
        setLoading(false);
      });
    }
  }

  const [imageSrc, setImageSrc] = useState(null);

  const buscarMidiaUsuario = async () => {
    if (!window.location.href.endsWith("login")) {
      await getMidiaIdUsuario(dadosUsuarioRedux?.usuario?.idUsuario, dadosUsuarioRedux.token).then((result) => {
        if (result.status === 200) {
          setImageSrc(result.data);
        }
      }).catch((error) => {
        setImageSrc(error.response.data);
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    buscarNumeroSolicitacoes();
    buscarMidiaUsuario();
  }, []);

  const verificaMenuAberto = async () => {
    dadosFilialRedux.map((filial) => {
      if (props.selecionado === filial.contexto) {
        setDropDownEmbarques(true);
      }
    });
    switch (props.selecionado) {
      case 'Conjuntos':
      case 'Caminhões':
      case 'Carretas':
      case 'GrupoCarga':
      case 'GrupoCargaVinculo':
        setDropDownVeiculos(true);
        break;
      case 'Frota':
      case 'Veículos Vencidos':
        setDropDownGerenciamentoFrota(true);
        break;
      case 'Adicionar Checklist Veículo':
      case 'Tipo de Checkagem':
      case 'Checkagem':
        setDropDownGerenciamentoFrota(true);
        setDropDownDiversos(true);
        break;
      case 'Proprietário':
      case 'Motoristas':
      case 'Clientes':
      case 'Grupo de Clientes':
      case 'Vincular Clientes':
        setDropDownPessoas(true);
        break;
      case 'Cadastro Rastreio':
      case 'Tipo Rastreio':
        setDropDownRastreamento(true);
        break;
      case 'Cidade':
      case 'Embalagem':
      case 'Mercadoria':
      case 'Empresas Subsidiárias':
      case 'Filial':
      case 'Meio Pagamento':
      case 'Status':
      case 'Unidade Embarque':
      case 'Motivo':
      case 'Taxa ICMS':
      case 'Motivo On Time':
      case 'Tipo Veículo':
      case 'Tipo Carroceria':
      case 'Motivo Cotação':
      case 'Segmento Cliente':
      case 'Ocorrência':
      case 'Tipo Ocorrência':
      case 'Sub Tipo Ocorrência':
        setDropDown(true);
        break;
      case 'Dashboard':
      case 'Dashboard Cockpit':
      case 'Painel':
      case 'Responsáveis':
        setDropDownDashboard(true);
        break;
      case 'Precificação':
      case 'Grupo Precificação':
        setDropDownPrecificacao(true);
        break;
      case 'Usuários':
      case 'Solicitações':
        setDropDownUsuario(true);
        break;
      case 'Pedidos':
      case 'Gerenciamento de Pedido':
        setDropDownPedido(true);
        break;
      case 'Cotações':
      case 'Dashboard Cotações':
        setDropDownCotacao(true);
        break;
      case 'Check List Frota':
      case 'Veículos Vencidos':
        setDropDownGerenciamentoFrota(true);
        break;
      case 'Adicionar Checklist Veículo':
      case 'Tipo de Checkagem':
      case 'Checkagem':
        setDropDownGerenciamentoFrota(true);
        setDropDownDiversos(true);
        break;
      case 'Todos':
      case 'Embarque':
      case 'Entrega':
        setDropDownOnTime(true);
        break;
      default:
        return;
    }
  }

  const verificarExpiracao = () => {
    const alertaJaExibido = localStorage.getItem("expirateTime");
    if (alertaJaExibido) return;

    const expiracao = localStorage.getItem("expiracao");
    const dataExpiracao = new Date(expiracao);
    const agora = new Date();
    const diferenca = dataExpiracao - agora;
    const dezMinutos = 10 * 60 * 1000;

    if (diferenca <= dezMinutos) {
      setOpenExpiracao(true);
      localStorage.setItem('expirateTime', true);
    } else {
      setTimeout(verificarExpiracao, diferenca - dezMinutos);
    }
  }

  useEffect(() => {
    verificaMenuAberto();
    verificarExpiracao();
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar' style={{ backgroundColor: dadosCoresRedux.headerFooter }}>
          <Link to='#' className='menu-bars-v2'>
            {
              sidebar ? (
                <div className="navbar-icons-2">
                  <div className="navbar-icons-2">
                    <img src={iconeMenu} className="iconeX" onClick={showSidebar} />
                    <img style={{ cursor: 'default' }} src={logoNavbar} className="iconeMenu" />
                  </div>
                </div>
              ) : (
                <div className="navbar-icons">
                  <img src={iconeMenu} className="iconeMenu" onClick={showSidebar} />
                  <img style={{ cursor: 'default' }} src={logoNavbar} className="iconeMenu" />
                </div>
              )
            }
          </Link>
          <div className="right-icons">
            <img className="image-header" onClick={() => { navigate("/ajuda"); }} src={iconeAjuda} alt="iconeAjuda" style={{ maxWidth: "100%", maxHeight: "60%", marginRight: "1%" }} />
            <img className="image-header" onClick={() => { setOpenNotificacao(true); }} src={iconNotification} alt="iconNotification" style={{ maxWidth: "100%", maxHeight: "60%", marginRight: "1%" }} />
            <img className="image-header" onClick={() => { setConfigDropdownOpen(!configDropdownOpen); setUserDropdownOpen(false); }} src={iconConfig} alt="iconConfig" style={{ maxWidth: "100%", maxHeight: "60%", marginRight: "1%" }} />
            {configDropdownOpen && (
              <div className="config-dropdown">
                <p onClick={() => { navigate("/avisos"); }}>Avisos</p>
                <p onClick={() => { navigate("/configuracoes-cores"); }}>Cores</p>
                <p onClick={() => { navigate("/configuracoes-coluna"); }}>Colunas</p>
                <p onClick={() => { navigate("/notificacoes"); }}>Notificações</p>
                <p onClick={() => { navigate("/envia-email"); }}>Enviar Email</p>
              </div>
            )}
            {userDropdownOpen && (
              <div className="config-dropdown">
                <p onClick={() => { navigate("/editar-usuario"); }}>Mudar Senha</p>
                <p onClick={() => { navigate("/dados-usuario"); }}>Editar Usuário</p>
                <p onClick={() => { setOpen(true); }}>Sair</p>
                {/* <b>Expiração: {
                  localStorage.getItem("expiracao") ?
                    localStorage.getItem("expiracao").slice(11, 16) :
                    'Não definida'
                } Hrs</b> */}
              </div>
            )}
            {imageSrc && (
              <div className="image-perfil">
                <img
                  onClick={() => { setUserDropdownOpen(!userDropdownOpen); setConfigDropdownOpen(false); }}
                  src={imageSrc && imageSrc.tipo ? `data:${imageSrc.tipo};base64,${imageSrc.dado}` : imageSrc === 'Arquivo não encontrado!' ? iconeUsuario : null}
                  alt="Uploaded preview" />
              </div>
            )}
          </div>
        </div>
        <nav ref={ref} className={sidebar ? 'nav-menu active' : 'nav-menu'} style={{ backgroundColor: dadosCoresRedux.coluna }}>
          <ul className='nav-menu-items'>
            {
              props.selecionado === 'Home' ? (
                <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                  <li key={1} className='nav-text'>
                    <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to='/'>
                      <img className="icone-selecionado" src={iconeHome} />
                      <span className="botao-menu">Home</span>
                    </Rota>
                  </li>
                </Menu>
              ) : (
                <Menu>
                  <li key={1} className='nav-text'>
                    <Rota to='/'>
                      <img src={iconeHome} />
                      <span className="botao-menu">Home</span>
                    </Rota>
                  </li>
                </Menu>
              )
            }
            <Menu>
              <li className="nav-text" onClick={showDropDowEmbarques}>
                <Rota>
                  <img className="icone-selecionado" src={iconeEmbarque} />
                  <span className="botao-menu">Embarques</span>
                  {
                    dropDownEmbarques ? (
                      <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                    )
                  }
                </Rota>
              </li>
            </Menu>
            {dadosFilialRedux.map((item, index) => {
              if (props.selecionado === item.contexto) {
                return (
                  <Menu onClick={() => { props.setTelaCadastro(false) }} style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                    <li key={index} className={dropDownEmbarques ? 'nav-text-comum' : 'menus-escondidos'}>
                      <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to="/embarque">
                        <span className="botao-menu">{item.nomeFilial}</span>
                      </Rota>
                    </li>
                  </Menu>
                );
              } else {
                return (
                  <Menu onClick={() => { onClick2(item); setSidebar(false); }}>
                    <li key={index} className={dropDownEmbarques ? 'nav-text-comum' : 'menus-escondidos'}>
                      <Rota to="/embarque">
                        <span className="botao-menu">{item.nomeFilial}</span>
                      </Rota>
                    </li>
                  </Menu>
                );
              }
            })}
            {SidebarDataV2.map((item, index) => {
              if (item.usoComum === "usoComumBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDow}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDown ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.veiculos === "veiculosBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowVeiculos}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownVeiculos ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.gerenciamentoFrota === "gerenciamentoFrotaBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowGerenciamentoFrota}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownGerenciamentoFrota ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.rastreamento === "rastreamentoBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowRastreamento}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownRastreamento ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.pessoas === "pessoasBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowPessoas}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownPessoas ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.dashboard === "dashboardBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowDashboard}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownDashboard ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.precificacao === "precificacaoBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowPrecificacao}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownPrecificacao ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.cotacao === "cotacaoBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowCotacao}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownCotacao ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.pedido === "pedidoBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowPedido}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownPedido ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.ontime === "ontimeBotao") {
                return (
                  <Menu>
                    <li key={index} className={item.nomeClasse} onClick={showDropDowOnTime}>
                      <Rota to={item.rota}>
                        <div className="icone-selecionado">{item.icone}</div>
                        <span className="botao-menu">{item.titulo}</span>
                        {
                          dropDownOnTime ? (
                            <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                          ) : (
                            <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else if (item.usoComum === "usoComum") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDown ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDown ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.veiculos === "veiculos") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownVeiculos ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownVeiculos ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.pessoas === "pessoas") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownPessoas ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownPessoas ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.dashboard === "dashboard") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownDashboard ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownDashboard ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.precificacao === "precificacao") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownPrecificacao ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownPrecificacao ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.cotacao === "cotacao") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownCotacao ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownCotacao ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.pedido === "pedido") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownPedido ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                          {
                            item.pendentes ? (
                              <Badge badgeContent={quantidadePedidosAguardando} color="primary">
                                <NotificationsIcon className="icone-notificao" color="inherit" />
                              </Badge>
                            ) : (
                              <></>
                            )
                          }
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownPedido ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                          {
                            item.pendentes ? (
                              <Badge badgeContent={quantidadePedidosAguardando} color="primary">
                                <NotificationsIcon className="icone-notificao" color="inherit" />
                              </Badge>
                            ) : (
                              <></>
                            )
                          }
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.ontime === "ontime") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownOnTime ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownOnTime ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.gerenciamentoFrota === "gerenciamentoFrota") {
                if (item.titulo === 'Configurações') {
                  return (
                    <Menu>
                      <li key={index} className={dropDownGerenciamentoFrota ? item.nomeClasse : 'menus-escondidos'} onClick={showDropDowDiversos}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                          {
                            dropDownDiversos ? (
                              <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                            ) : (
                              <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                            )
                          }
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownGerenciamentoFrota ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownGerenciamentoFrota ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.rastreamento === "rastreamento") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownRastreamento ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownRastreamento ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else if (item.diversos === "diversos") {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={dropDownDiversos ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu >
                      <li key={index} className={dropDownDiversos ? item.nomeClasse : 'menus-escondidos'}>
                        <Rota to={item.rota}>
                          <span className="botao-menu">{item.titulo}</span>
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              } else {
                if (props.selecionado === item.titulo) {
                  return (
                    <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                      <li key={index} className={item.nomeClasse}>
                        <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                          <div className="icone-selecionado">{item.icone}</div>
                          <span className="botao-menu">{item.titulo}</span>
                          {
                            item.notificao ? (
                              <Badge badgeContent={numeroSolicitacoes} color="primary">
                                <NotificationsIcon className="icone-notificao" color="inherit" />
                              </Badge>
                            ) : (
                              <></>
                            )
                          }
                        </Rota>
                      </li>
                    </Menu>
                  );
                } else {
                  return (
                    <Menu>
                      <li key={index} className={item.nomeClasse}>
                        <Rota to={item.rota}>
                          <div className="icone-selecionado">{item.icone}</div>
                          <span className="botao-menu">{item.titulo}</span>
                          {
                            item.notificao ? (
                              <Badge badgeContent={numeroSolicitacoes} color="primary">
                                <NotificationsIcon className="icone-notificao" color="action" />
                              </Badge>
                            ) : (
                              <></>
                            )
                          }
                        </Rota>
                      </li>
                    </Menu>
                  );
                }
              }
            })}
            <Menu>
              <li className="nav-text" onClick={showDropDowUsuario}>
                <Rota>
                  <img className="icone-selecionado" src={iconeUsuario} />
                  <span className="botao-menu">Usuário</span>
                  {
                    dropDownUsuario ? (
                      <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                    )
                  }
                </Rota>
              </li>
            </Menu>
            {MenuUsuario.map((item, index) => {
              if (props.selecionado === item.titulo) {
                return (
                  <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                    <li key={index} className={dropDownUsuario ? 'nav-text-comum' : 'menus-escondidos'}>
                      <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                        <span className="botao-menu">{item.menu}</span>
                        {
                          item.notificao ? (
                            <Badge badgeContent={numeroSolicitacoes} color="primary">
                            </Badge>
                          ) : (
                            <></>
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              } else {
                return (
                  <Menu>
                    <li key={index} className={dropDownUsuario ? 'nav-text-comum' : 'menus-escondidos'}>
                      <Rota to={item.rota}>
                        <span className="botao-menu">{item.menu}</span>
                        {
                          item.notificao ? (
                            <Badge badgeContent={numeroSolicitacoes} color="primary">
                            </Badge>
                          ) : (
                            <></>
                          )
                        }
                      </Rota>
                    </li>
                  </Menu>
                );
              }
            })}
            {/* <Menu>
              <li className="nav-text" onClick={showDropDowConfigs}>
                <Rota>
                  <img className="icone-selecionado" src={iconeAdm} />
                  <span className="botao-menu">Administrativo</span>
                  {
                    dropDownConfigs ? (
                      <MdIcons.MdOutlineKeyboardArrowUp style={{ marginLeft: 'auto' }} color="#000" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown style={{ marginLeft: 'auto' }} color="#000" />
                    )
                  }
                </Rota>
              </li>
            </Menu>
            {MenuConfigs.map((item, index) => {
              if (props.selecionado === item.titulo) {
                return (
                  <Menu style={{ backgroundColor: dadosCoresRedux.botaoSelecionado, color: dadosCoresRedux.textoBotaoSelecionado }}>
                    <li key={index} className={dropDownConfigs ? 'nav-text-comum' : 'menus-escondidos'}>
                      <Rota style={{ color: dadosCoresRedux.textoBotaoSelecionado }} to={item.rota}>
                        <span className="botao-menu">{item.menu}</span>
                      </Rota>
                    </li>
                  </Menu>
                );
              } else {
                return (
                  <Menu>
                    <li key={index} className={dropDownConfigs ? 'nav-text-comum' : 'menus-escondidos'}>
                      <Rota to={item.rota}>
                        <span className="botao-menu">{item.menu}</span>
                      </Rota>
                    </li>
                  </Menu>
                );
              }
            })} */}
            {/* <Menu>
              <li className="nav-text" onClick={() => { setSidebar(false); }}>
                <Rota to="/ajuda">
                  <span className="botao-menu">Ajuda</span>
                </Rota>
              </li>
            </Menu>
            <Menu>
              <li className="nav-text" onClick={() => { setOpen(true); setSidebar(false); }}>
                <Rota>
                  <span className="botao-menu">Sair</span>
                </Rota>
              </li>
            </Menu> */}
            <div>
              <Menu>
                <li className="nav-text" onClick={() => { setSidebar(false); }}>
                  <Rota to="/feedback">
                    <img className="icone-selecionado" src={iconeFeedback} />
                    <span className="botao-menu">Feedback</span>
                  </Rota>
                </li>
              </Menu>
            </div>
          </ul>
        </nav>
      </IconContext.Provider>
      <div>
        <ModalConfirmacao2
          titulo="Sair"
          texto="Deseja Sair do Sistema?"
          tituloConfirmar="Sim"
          tituloCancelar="Não"
          textoBotao="Sair"
          open={open}
          onClose={handleClose}
          onClick={logout}
        />
        <ModalNotificacao
          titulo={props.notificoes}
          tituloConfirmar="OK"
          open={openNotificacao}
          onClose={handleCloseNotificacao}
        />
        <ModalAlerta
          open={openExpiracao}
          onClose={handleCloseExpiracao}
          titulo="Expiração"
        />
      </div>
      <div>
        <ToastSucesso
          texto={textoSucesso}
          open={openToastSucesso}
          onClose={handleCloseToastSucesso}
        />
        <ToastErro
          texto={textoErro}
          open={openToastErro}
          onClose={handleCloseToastErro}
        />
      </div>
    </>
  );
}

export default NavbarV2;

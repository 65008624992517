import React, { useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalListaErros/ModalListaErros.css';
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import CoresRedux from "../../contexts/Cor";

export interface SimpleDialogProps {
    titulo: string;
    texto: string;
    open: boolean;
    textoBotao: string;
    onClose: () => void;
    onClick: () => void;
    mensagensAviso: { codigo: number; mensagem: string; }[];
    mensagensErro: { codigo: number; mensagem: string; }[];
    mensagensSucesso: { codigo: number; mensagem: string; }[];
}

function ModalListaErros(props: SimpleDialogProps) {
    const { onClose, open, onClick, mensagensAviso, mensagensErro, mensagensSucesso } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
        onClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-lista-erros'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-description">
                            {props.texto}
                        </DialogContentText>
                        {/* Mensagens de Aviso */}
                        {mensagensSucesso && mensagensSucesso.length > 0 && (
                            <>
                                <DialogTitle style={{ textAlign: 'center' }}>Sucesso</DialogTitle>
                                {mensagensSucesso.map((aviso, index) => (
                                    <DialogContentText style={{ textAlign: 'center' }} key={`aviso-${index}`}>
                                        {aviso.mensagem}
                                    </DialogContentText>
                                ))}
                            </>
                        )}
                        {mensagensAviso && mensagensAviso.length > 0 && (
                            <>
                                <DialogTitle style={{ textAlign: 'center' }}>Avisos</DialogTitle>
                                {mensagensAviso.map((aviso, index) => (
                                    <DialogContentText style={{ textAlign: 'center' }} key={`aviso-${index}`}>
                                        {aviso.mensagem}
                                    </DialogContentText>
                                ))}
                            </>
                        )}
                        {/* Mensagens de Erro */}
                        {mensagensErro && mensagensErro.length > 0 && (
                            <>
                                <DialogTitle style={{ textAlign: 'center' }}>Erros</DialogTitle>
                                {mensagensErro.map((erro, index) => (
                                    <DialogContentText style={{ textAlign: 'center' }} key={`erro-${index}`}>
                                        {erro.mensagem}
                                    </DialogContentText>
                                ))}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo={props.textoBotao}
                            cor="#DC2D2D"
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalListaErros;

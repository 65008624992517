import React, { useEffect, useState } from "react";
import "./HeaderCadastro.css";
import iconeInfo3 from "../../images/iconeInfo3.png";

function HeaderCadastro({ texto }) {

    return (
        <div className="cadastro-embarque">
            <p className="texto-cadastro-embarque">{texto}</p>
            <img className="icone-info-3" src={iconeInfo3} alt="Info" />
        </div>
    );
}

export default HeaderCadastro;
import { api } from "./UrlPadrao"

export const cadastraValorEmbarque = async (valorEmbarque, token) => {
  return api.post("ValoresEmbarque",
    valorEmbarque
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getValorEmbarque = async (token) => {
  return api.get("ValoresEmbarque",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getValorEmbarquePorId = async (id, token) => {
  return api.get("ValoresEmbarque/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteValorEmbarquePorId = async (id, token) => {
  return api.delete("ValoresEmbarque/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaValorEmbarquePorId = async (valorEmbarque, token) => {
  return api.put("ValoresEmbarque",
  valorEmbarque,
  {
    headers: {
      'Authorization': token
    }
  })
}
import React, { useContext, useState, useEffect, useRef } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import "./Rastrear.css";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { motion } from "framer-motion";
import RastrearRedux from "../../../contexts/Rastreio";
import { getRastreioVeiculo } from "../../../services/ModuloRastreamento/Rastreamento";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useLoadScript } from "@react-google-maps/api";
import caminhaoPin from '../../../images/caminhao_pin.png'
import { useNavigate } from 'react-router-dom';
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import { getEventosEmbarque } from "../../../services/ModuloGerenciador/Eventos";

function Rastrear(props) {

    const navigate = useNavigate();

    const voltar = () => {
        navigate(-1);
    };

    const { dadosRastrearRedux, setDadosRastrearRedux } = useContext(RastrearRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [loading, setLoading] = useState(true);

    const [semAutorizacao, setSemAutorizacao] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [dadosRastreio, setDadosRastreio] = useState([]);

    const [dadosEventos, setDadosEventos] = useState([]);

    const [lat, setLat] = useState(null);

    const [lng, setLng] = useState(null);

    const [marker, setMarker] = useState(false);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCN4pBitoJiDhjeEFW3AnGncUhiV1JE-Z4",
    });

    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const [polygon, setPolygon] = useState({ paths: [] });

    const mapRef = useRef(null);

    const handleMapLoaded = (map) => {
        setTimeout(() => {
            mapRef.current = map;
            setIsMapLoaded(true);
        }, 100);
    };

    const [drawnPolygon, setDrawnPolygon] = useState(null);

    useEffect(() => {
        if (isMapLoaded && polygon.paths.length > 0) {
            setTimeout(() => {
                if (drawnPolygon) {
                    drawnPolygon.setMap(null);
                }

                const newPolygon = new window.google.maps.Polygon({
                    paths: polygon.paths,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                });

                newPolygon.setMap(mapRef.current);
                setDrawnPolygon(newPolygon);
            }, 200);
        }
    }, [polygon, isMapLoaded]);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [textoErro, setTextoErro] = useState("");

    const buscarRastreio = async () => {
        setLoading(true);
        if (dadosRastrearRedux.endereco) {
            if (dadosRastrearRedux.endereco.polygon.length <= 1) {
                setLat(dadosRastrearRedux.endereco.polygon[0].latitude);
                setLng(dadosRastrearRedux.endereco.polygon[0].longitude);
                setMarker(true);
            } else {
                const copiaOrdenada = [...dadosRastrearRedux.endereco.polygon].sort((a, b) => a.id - b.id);
                const coordenadasTransformadas = copiaOrdenada.map(coordenada => ({
                    lat: coordenada.latitude,
                    lng: coordenada.longitude
                }));
                setPolygon({ paths: coordenadasTransformadas });
                setLat(coordenadasTransformadas[0].lat);
                setLng(coordenadasTransformadas[0].lng);
                setMarker(false);
            }
        } else {
            await getRastreioVeiculo(dadosUsuarioRedux.token, dadosRastrearRedux.idVeiculo, dadosRastrearRedux.tipoVeiculo).then((result) => {
                if (result.status === 200) {
                    setDadosRastreio(result.data.dados[0]);
                    const coordinates = result.data.dados[0].coordenadas.split(' ').filter(Boolean).map(coord => parseFloat(coord));
                    setLat(coordinates[0]);
                    setLng(coordinates[1]);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    }

    const buscarEventos = async () => {
        setLoading(true);
        await getEventosEmbarque(dadosUsuarioRedux.token, dadosRastrearRedux.idEmbarque).then((result) => {
            if (result.status === 200) {
                setDadosEventos(result.data);
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    useEffect(() => {
        buscarRastreio();
        if (dadosRastrearRedux.idEmbarque != undefined) {
            buscarEventos();
        }
    }, []);

    useEffect(() => {
        if (lat != null && lng != null && dadosEventos.length > 0) {
            setLoading(false);
        }
    }, [lat, lng]);

    function RastreioCard({ dadosRastreio }) {
        return (
            <div className="rastreio-card">
                <h2>Dados do Rastreio</h2>
                <p><strong>Nome:</strong> {dadosRastreio.nome}</p>
                <p><strong>Modelo:</strong> {dadosRastreio.modelo}</p>
                <p><strong>Placa:</strong> {dadosRastreio.placa}</p>
                <p><strong>Serial:</strong> {dadosRastreio.serial}</p>
                <p><strong>Data:</strong> {dadosRastreio.data}</p>
                <p><strong>GPRS:</strong> {dadosRastreio.gprs}</p>
                <p><strong>GPS:</strong> {dadosRastreio.gps}</p>
                <p><strong>Bloqueio:</strong> {dadosRastreio.bloqueio}</p>
                <p><strong>Ignição:</strong> {dadosRastreio.ignicao}</p>
                <p><strong>Endereço:</strong> {dadosRastreio.endereco}</p>
                <p><strong>Pânico:</strong> {dadosRastreio.panico}</p>
                <p><strong>Voltagem Status:</strong> {dadosRastreio.voltagemStatus}</p>
                <p><strong>Velocidade:</strong> {dadosRastreio.velocidade}</p>
                <p><strong>Odômetro:</strong> {dadosRastreio.odometro}</p>
                <p><strong>Limite de Velocidade:</strong> {dadosRastreio.limiteVelocidade}</p>
                <p><strong>Tempo Sem Comunicar:</strong> {dadosRastreio.tempoSemComunicar}</p>
            </div>
        );
    }

    function EventosCard({ dadosEventos }) {
        if (dadosEventos.length > 0) {
            return (
                <div className="eventos-card">
                    <h2>Eventos</h2>
                    {dadosEventos.map((dado) => (
                        <p><strong>-</strong> {dado.evento.nome}</p>
                    ))}
                </div>
            );
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Cadastro Rastreio" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {props.navbar || <NavbarV2 selecionado="Cadastro Rastreio" />}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <div className="principal-mapa">
                            {loadError && <div>Erro ao carregar o mapa</div>}
                            {!isLoaded ? (
                                <LoadingComponent />
                            ) : (
                                <>
                                    <div className="voltar-card">
                                        <CustomButton
                                            classCss="custom-button"
                                            titulo="Voltar"
                                            cor={dadosCoresRedux.botaoPadrao}
                                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                                            onClick={() => { voltar(); setDadosRastrearRedux({}); }}
                                        />
                                    </div>
                                    {
                                        dadosRastrearRedux.endereco ? (
                                            <>
                                                {
                                                    !loading ? (
                                                        <EventosCard dadosEventos={dadosEventos} />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <RastreioCard dadosRastreio={dadosRastreio} />
                                        )
                                    }
                                    <GoogleMap
                                        mapContainerStyle={{ width: "100%", height: "96vh" }}
                                        center={{ lat: lat, lng: lng }}
                                        zoom={12}
                                        onLoad={handleMapLoaded}
                                    >
                                        {isMapLoaded && lat && lng && marker && (
                                            <>
                                                {
                                                    dadosRastrearRedux.endereco ? (
                                                        isMapLoaded && lat && lng && marker && (
                                                            <Marker
                                                                position={{ lat: lat, lng: lng }}
                                                                icon={{
                                                                    url: dadosRastrearRedux.endereco ? undefined : caminhaoPin,
                                                                    scaledSize: new window.google.maps.Size(50, 50),
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        isMapLoaded && lat && lng && marker && (
                                                            <Marker
                                                                position={{ lat: lat, lng: lng }}
                                                                icon={{
                                                                    url: caminhaoPin,
                                                                    scaledSize: new window.google.maps.Size(50, 50),
                                                                }}
                                                            />
                                                        )
                                                    )
                                                }

                                            </>
                                        )}
                                    </GoogleMap>
                                </>
                            )}
                        </div>
                        <div>
                            <ToastErro
                                texto={textoErro}
                                open={openToastErro}
                                onClose={handleCloseToastErro}
                            />
                        </div>
                    </motion.div>
                </div>
            )}
        </motion.div>
    );
}

export default Rastrear;
import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalMultiplosFiltros/ModalMultiplosFiltros.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import { Box, LinearProgress } from "@mui/material";
import iconeFiltro from "../../images/iconeFiltro.png";
import iconePesquisa from "../../images/iconePesquisa.png";
import iconeFechar from "../../images/iconeFechar.png";
import iconeNovo from "../../images/iconeNovo.png";
import MultiplosFiltrosRedux from "../../contexts/MultiplosFiltros";

function ModalMultiplosFiltros(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    const [filtros, setFiltros] = useState([{ coluna: '', field: '', valor: '' }]);

    const [seletorAbertoIndex, setSeletorAbertoIndex] = useState(null);

    const adicionarFiltro = () => {
        setFiltros([...filtros, { coluna: '', field: '', valor: '' }]);
        setMultiplosFiltrosRedux([...filtros, { coluna: '', field: '', valor: '' }]);
        setSeletorAbertoIndex(null);
    };

    const atualizarFiltro = (index, campo, valor) => {
        const novosFiltros = [...filtros];
        novosFiltros[index][campo] = valor;
        setFiltros(novosFiltros);
        setMultiplosFiltrosRedux(novosFiltros);

    };

    const removerFiltro = (index) => {
        const novosFiltros = filtros.filter((_, i) => i !== index);
        setFiltros(novosFiltros);
        setMultiplosFiltrosRedux(novosFiltros);
    };

    const handleSubmit = () => {
        // Construa a query string com base em `filtros` e envie para a pesquisa
    };

    const abrirSeletor = (index) => {
        setSeletorAbertoIndex(index); // Abre o seletor para o filtro correspondente
    };

    useEffect(() => {
        setSeletorAbertoIndex(null);
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            setFiltros(multiplosFiltrosRedux);
        }
        else {
            setFiltros([{ coluna: '', field: '', valor: '' }]);
        }
    }, [props]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className='conteudo-modal-filtros'>
                        <DialogContentText id="alert-dialog-description">
                            <div className="div-modal-usuario">
                                <div className="multiplos-data">
                                    <div className="div-datas-v2">
                                        <div className="card-cidade-filial" onClick={props.onClickFiltrar}>
                                            <img className="pointer-filtro" src={iconeFiltro} />
                                            {props.openFiltrar && (
                                                <div className={props.classOption}>
                                                    <div className="option2">
                                                        <b>Filtrar</b>
                                                    </div>
                                                    {props.itensFiltro.map((item) => (
                                                        <div className={item.field === props.opcaoSelecionada ? "option selected" : "option"} key={item.field}
                                                            onClick={() => {
                                                                props.onClickOpcaoFiltrar(item.field, item.field, item.nomeColuna);
                                                            }}>
                                                            {item.nomeColuna}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            <b>Filtrar Por: </b>
                                            <b>{props.filtroSelecionado}</b>
                                        </div>
                                    </div>
                                    <div className="div-datas-v2">
                                        {
                                            props.loadingInput ? (
                                                <>
                                                    <Box sx={{ width: '100%' }}>
                                                        <LinearProgress />
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <div style={{ position: 'relative', width: 400 }}>
                                                        <input
                                                            type={'text'}
                                                            className='input-filtro-data-v2'
                                                            value={props.valuesPesquisa.pesquisa}
                                                            placeholder='Pesquisar'
                                                            style={{ width: 400 }}
                                                            onChange={(e) => {
                                                                props.setValuesPesquisa(current => ({ ...current, pesquisa: e.target.value }));
                                                                props.setFiltrosRedux(current => ({ ...current, pesquisa: e.target.value }));
                                                                props.setInputDisable(false);
                                                            }}
                                                            id='embarque'>
                                                        </input>
                                                        {
                                                            // inputDisable ? (
                                                            //     <Tooltip title={<p>Digite para filtrar</p>}>
                                                            //         <img src={iconePesquisa} style={{
                                                            //             position: 'absolute',
                                                            //             left: 365,
                                                            //             top: 5,
                                                            //         }} />
                                                            //     </Tooltip>
                                                            //) : (
                                                            <img src={iconePesquisa} style={{
                                                                position: 'absolute',
                                                                left: 365,
                                                                top: 5,
                                                                cursor: 'pointer'
                                                            }} onClick={(e) => {
                                                                props.buscarSearch();
                                                            }} />
                                                            //)
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                                {filtros.map((filtro, index) => (
                                    <div className="multiplos-data">
                                        <div key={index} className="div-datas-v2">
                                            <div className="card-cidade-filial" onClick={() => abrirSeletor(index)}>
                                                <img className="pointer-filtro" src={iconeFiltro} />
                                                {seletorAbertoIndex === index && (
                                                    <div className={props.classOption}>
                                                        <div className="option2">
                                                            <b>Filtrar</b>
                                                        </div>
                                                        {props.itensFiltro.map((item) => (
                                                            <div
                                                                className={item.field === filtro.field ? "option selected" : "option"}
                                                                key={item.field}
                                                                onClick={() => {
                                                                    atualizarFiltro(index, 'field', item.field);
                                                                    atualizarFiltro(index, 'coluna', item.nomeColuna);
                                                                    setSeletorAbertoIndex(null);
                                                                }}>
                                                                {item.nomeColuna}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                <b>Filtrar Por: </b>
                                                <b>{filtro.coluna || 'Selecione'}</b>
                                            </div>
                                        </div>
                                        <div className="div-datas-v2">
                                            {
                                                props.loadingInput ? (
                                                    <>
                                                        <Box sx={{ width: '100%' }}>
                                                            <LinearProgress />
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div style={{ position: 'relative', width: 400 }}>
                                                            <input
                                                                type={'text'}
                                                                className='input-filtro-data-v2'
                                                                value={filtro.valor}
                                                                placeholder='Pesquisar'
                                                                style={{ width: 400 }}
                                                                onChange={(e) => {
                                                                    //props.setValuesPesquisa(current => ({ ...current, pesquisa: e.target.value }));
                                                                    //props.setFiltrosRedux(current => ({ ...current, pesquisa: e.target.value }));
                                                                    atualizarFiltro(index, 'valor', e.target.value);
                                                                    //props.setInputDisable(false);
                                                                }}
                                                                id='embarque'>
                                                            </input>
                                                            {
                                                                // inputDisable ? (
                                                                //     <Tooltip title={<p>Digite para filtrar</p>}>
                                                                //         <img src={iconePesquisa} style={{
                                                                //             position: 'absolute',
                                                                //             left: 365,
                                                                //             top: 5,
                                                                //         }} />
                                                                //     </Tooltip>
                                                                //) : (
                                                                <img src={iconePesquisa} style={{
                                                                    position: 'absolute',
                                                                    left: 365,
                                                                    top: 5,
                                                                    cursor: 'pointer'
                                                                }} onClick={(e) => {
                                                                    props.buscarSearch();
                                                                }} />
                                                                //)
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                        {index != 0 && (
                                            <img
                                                onClick={() => {
                                                    removerFiltro(index);
                                                }}
                                                className="icone-remover"
                                                src={iconeFechar}
                                                alt="Fechar" />
                                        )}
                                    </div>
                                ))}
                                {/* <CustomButtonV2
                                    classCss="custom-button-cadastrar"
                                    classText="button-texto-cadastrar"
                                    titulo="Adicionar"
                                    cor="#0946B8"
                                    onClick={adicionarFiltro}
                                /> */}
                                <img
                                    onClick={() => {
                                        adicionarFiltro();
                                    }}
                                    className="icone-novo"
                                    src={iconeNovo}
                                    alt="Fechar" />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="Filtrar"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalMultiplosFiltros;
import React, { useContext, useState, useEffect } from "react";
import "./Feedback.css";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import CoresRedux from "../../../contexts/Cor";
import logo from '../../../images/C1010_azul.png'
import { motion } from "framer-motion";

function Feedback() {

    const { dadosCoresRedux, setDadosCoresRedux } = useContext(CoresRedux);

    return (
        <motion.div initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }} className="fundo" style={{ backgroundColor: dadosCoresRedux.fundoPadrao }}>
            <NavbarV2 selecionado='Feedback' />
            <div className="principal">
                <div className="cardFeedback">
                    <h1 style={{ marginBottom: "5vh" }}>Gerenciador de Transportes</h1>

                    <div className="desenvolvido">
                        <h3>Contato para suporte e vendas:</h3>
                        <p>c1010sistemas@gmail.com</p>
                        <p>(45) 9 9959-8011</p>
                    </div>

                    <h3 style={{ marginTop: "5vh" }}>Desenvolvido por:</h3>

                    <div className="imagem">
                        <img src={logo} alt="logo" style={{ maxWidth: "100%", maxHeight: "60%" }}></img>
                    </div>

                    <p className="versao">Versão 1.1.32</p>
                </div>
            </div>
        </motion.div>
    );
}

export default Feedback;

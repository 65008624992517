import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalOnTime/ModalOnTime.css';
import CustomButton from '../CustomButton/CustomButton';
import CoresRedux from "../../contexts/Cor";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import TabelaRedux from "../../contexts/Tabela";
import LoadingBar from "../LoadingBar/LoadingBar";
import NewIcon from "../NewIcon/NewIcon";
import ModalCadastro from "../ModalCadastro/ModalCadastro.tsx";
import RequestControllerRedux from "../../contexts/RequestController.js";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    texto: string;
    open: boolean;
    listaMotivos: any;
    onClose: () => void;
    onClick: () => void;
    setMotivoId: (e) => void;
    setNomeMotivo: (e) => void;
}

function ModalOnTime(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    const [loadingSelectMotivo, setLoadingSelectMotivo] = useState(false);

    useEffect(() => {
        setLoadingSelectMotivo(true);
        setTimeout(() => {
            setLoadingSelectMotivo(false);
        }, 1000);
    }, [props.listaMotivos]);

    const [openCadastroMotivo, setOpenCadastroMotivo] = useState(false);

    const handleCloseCadastroMotivo = () => {
        if (idAtual) {
            setOpenCadastroMotivo(false);
            setDadosRequestControllerRedux("Close Motivo");
        } else {
            setOpenCadastroMotivo(false);
            setDadosRequestControllerRedux("Close Motivo");
        }
    };

    const [openCadastroMotivoOnTime, setOpenCadastroMotivoOnTime] = useState(false);

    const handleCloseCadastroMotivoOnTime = () => {
        if (idAtual) {
            setOpenCadastroMotivoOnTime(false);
            setDadosRequestControllerRedux("Close Motivo");
        } else {
            setOpenCadastroMotivoOnTime(false);
            setDadosRequestControllerRedux("Close Motivo");
        }
    };

    const [idAtual, setIdAtual] = useState(0);

    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (tabelaRedux?.row?.motivoOnTime != null) {
            setValuesSelect(current => ({
                ...current,
                motivo: {
                    value: tabelaRedux.row.motivoOnTime.motivo,
                    label: tabelaRedux.row.motivoOnTime.motivo,
                    id: tabelaRedux.row.motivoOnTime.id
                }
            }));
        } else {
            setValuesSelect(current => ({
                ...current,
                motivo: {
                    value: null,
                    label: null,
                    id: null
                }
            }));
        }
    }, [tabelaRedux]);

    const clearMotivo = () => {
        props.setMotivoId(null);
        props.setNomeMotivo(null);
        setValuesSelect(current => ({
            ...current,
            motivo: ''
        }));
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-ontime'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-ontime2">
                        <DialogContentText id="alert-dialog-description">
                            {
                                loadingSelectMotivo ? (
                                    <div className="coluna-modalmotivo">
                                        <LoadingBar />
                                    </div>
                                ) : (
                                    <div className="coluna-modalmotivo">
                                        <div>
                                            <p className="input-label-emb">Motivo:</p>
                                            <SelectEmbarqueV2
                                                classeCss={250}
                                                onChange={(e) => {
                                                    props.setMotivoId(e.id);
                                                    props.setNomeMotivo(e.value);
                                                    setValuesSelect(current => ({
                                                        ...current,
                                                        motivo: e
                                                    }));
                                                }}
                                                clearSelection={clearMotivo}
                                                value={valuesSelect.motivo}
                                                id={props.titulo === 'Motivo Cancelamento' ? 'idMotivo' : 'id'}
                                                titulo="Empresa"
                                                dadosSelect={props.listaMotivos}
                                                referenciaId="motivo"
                                                referenciaItem="motivo"
                                            />
                                        </div>
                                        <NewIcon
                                            onClick={(e) => {
                                                if (props.titulo === 'Vincular Motivo') {
                                                    setOpenCadastroMotivoOnTime(true); setIdAtual(0); setDisable(false);
                                                }
                                                if (props.titulo === 'Motivo Cancelamento') {
                                                    setOpenCadastroMotivo(true); setIdAtual(0); setDisable(false);
                                                }
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="action-ontime">
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <div>
                <ModalCadastro
                    titulo="Cadastro"
                    tituloConfirmar="OK"
                    cadastroMotivo="sim"
                    open={openCadastroMotivo}
                    idAtual={idAtual}
                    desabilitar={disable}
                    onClose={handleCloseCadastroMotivo}
                />
                <ModalCadastro
                    titulo="Cadastro"
                    tituloConfirmar="OK"
                    cadastroMotivoOnTime="sim"
                    open={openCadastroMotivoOnTime}
                    idAtual={idAtual}
                    desabilitar={disable}
                    onClose={handleCloseCadastroMotivoOnTime}
                />
            </div>
        </div>
    );
}

export default ModalOnTime;
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from "react-slick";
import "./Carrosel.css";
import iconeInfo from '../../images/iconeInfo.png'

const VerticalCarousel = ({ avisos }) => {
  let slidesToShow = avisos.length < 3 ? avisos.length : 3;
  const settings = {
    dots: true,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
  };

  return (
    <div className="carrossel-vertical">
      <Slider {...settings}>
        {avisos.map((aviso, index) => (
          <div key={index} className="aviso-card">
            <img src={iconeInfo}/>
            <p>{aviso.mensagem}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VerticalCarousel;

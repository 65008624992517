import React, { useEffect, useState } from "react";
import "./CloseIcon.css";
import iconeFechar from "../../images/iconeFechar.png";

function CloseIcon({ onClick }) {

    return (
        <div className="hover-emb">
            <div className="tooltip">
                <p className="texto-fechar">Fechar</p>
            </div>
            <img
                onClick={onClick}
                className="icone-fechar"
                src={iconeFechar}
                alt="Fechar" />
        </div>
    );
}

export default CloseIcon;
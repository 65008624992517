import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InputLabel from "@mui/material/InputLabel";
import "../ModalFiltroEmbarque/ModalFiltroEmbarque.css"
import CoresRedux from "../../contexts/Cor";
import CustomButton from "../CustomButton/CustomButton";
import { MenuItem, Select } from "@mui/material";
import FilialCidade from "../../contexts/CidadesFilial";
import Text from "../../components/Text/Text";
import FiltrosRedux from "../../contexts/Filtros";

export interface SimpleDialogProps {
    open: boolean;
    values: any;
    onClose: () => void;
    onClick: () => void;
}

function ModalFiltroEmbarque(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const { setFiltrosRedux } = useContext(FiltrosRedux);

    const [values, setValues] = useState<any>({});

    const [frotaVisivel, setFrotaVisivel] = useState(false);

    const [contextoVisivel, setContextoVisivel] = useState(false);

    const { onClose, open, onClick } = props;

    const handleClick = () => {
        localStorage.setItem('filtros', JSON.stringify(values));
        setFiltrosRedux(values);
        setValues({});
        onClose();
    };

    useEffect(() => {
        if (window.location.href.endsWith("dashboard") || window.location.href.endsWith("segundo-dashboard")
            || window.location.href.endsWith("terceiro-dashboard") || window.location.href.endsWith("quarto-dashboard")
            || window.location.href.endsWith("quinto-dashboard")) {
            setContextoVisivel(true);
        } else {

            setContextoVisivel(false);
        }
    })

    useEffect(() => {
        const valoresFiltros = localStorage.getItem("filtros");
        if (valoresFiltros !== null) {
            const objetoFiltros = JSON.parse(valoresFiltros);
            setValues(current => ({ ...current, contexto: objetoFiltros.contexto }));
            setValues(current => ({ ...current, embarqueInicio: objetoFiltros.embarqueInicio }));
            setValues(current => ({ ...current, embarqueFinal: objetoFiltros.embarqueFinal }));
        } else {
            const dateFinal = props?.values?.dataFinal;
            var ddFinal = String(dateFinal?.getDate()).padStart(2, '0');
            var mmFinal = String(dateFinal?.getMonth() + 1).padStart(2, '0');
            var yyyyFinal = dateFinal?.getFullYear();
            var dateFinalFormated = yyyyFinal + '-' + mmFinal + '-' + ddFinal;

            const dateInicio = props?.values?.dataInicio;
            var ddInicio = String(dateInicio?.getDate()).padStart(2, '0');
            var mmInicio = String(dateInicio?.getMonth() + 1).padStart(2, '0');
            var yyyyInicio = dateInicio?.getFullYear();
            var dateInicioFormated = yyyyInicio + '-' + mmInicio + '-' + ddInicio;

            setValues(current => ({ ...current, contexto: 'todos' }));
            setValues(current => ({ ...current, embarqueInicio: dateInicioFormated }));
            setValues(current => ({ ...current, embarqueFinal: dateFinalFormated }));
        }
        if (window.location.href.endsWith("terceiro-dashboard")) {
            setFrotaVisivel(true);
            const valoresFiltros = localStorage.getItem("filtros");
            if (valoresFiltros !== null) {
                const objetoFiltros = JSON.parse(valoresFiltros);
                setValues(current => ({ ...current, frota: objetoFiltros.frota }));
            }
        } else {
            setFrotaVisivel(false);
        }
    }, [open])

    const handleClose = () => { onClose(); };

    return (
        <div>
            <Dialog
                open={open}
            >
                <div className='div-modal-usuario'>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                contextoVisivel ? (
                                    <div className="div-buscas">
                                        <InputLabel htmlFor="select">Contexto</InputLabel>
                                        <Select
                                            className='select-estado'
                                            variant="standard"
                                            style={{ width: 200 }}
                                            id='select'
                                            value={values.contexto}
                                        >
                                            {dadosFilialRedux.map((item) => {
                                                if (item.cidade != 'Modelo Embarque') {
                                                    return (
                                                        <MenuItem
                                                            key={item}
                                                            value={item.selecionado}
                                                            onClick={(e) => { setValues(current => ({ ...current, contexto: item.selecionado })) }}>
                                                            {item.cidade}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                            <div className="div-buscas">
                                <InputLabel htmlFor="embarque">Embarque Início</InputLabel>
                                <input
                                    type={'date'}
                                    className='input-text'
                                    value={values.embarqueInicio}
                                    style={{ width: 200 }}
                                    onChange={(e) => { setValues(current => ({ ...current, embarqueInicio: e.target.value })) }}
                                    id='embarque'>
                                </input>
                            </div>
                            <div className="div-buscas">
                                <InputLabel htmlFor="embarquef">Embarque Final</InputLabel>
                                <input
                                    type={'date'}
                                    className='input-text'
                                    value={values.embarqueFinal}
                                    style={{ width: 200 }}
                                    onChange={(e) => { setValues(current => ({ ...current, embarqueFinal: e.target.value })) }}
                                    id='embarquef'>
                                </input>
                            </div>
                            {
                                frotaVisivel ? (
                                    <div className="div-buscas">
                                        <input
                                            className="checkcheck"
                                            type="checkbox"
                                            checked={values.frota}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, frota: e.target.checked }));
                                            }}
                                        ></input>
                                        <InputLabel htmlFor="status"><Text texto="Frota" /></InputLabel>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="butao">
                        <CustomButton
                            classCss="custom-button"
                            titulo="Cancelar"
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => {
                                onClose();
                            }}
                        />
                        <CustomButton
                            classCss="custom-button"
                            titulo="OK"
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )

}

export default ModalFiltroEmbarque;
import React, { useEffect, useContext, useState } from "react";
import NavbarV2 from "../../../../components/NavbarV2/NavbarV2";
import Charts from "../../../../components/Charts/Charts.tsx"
import "./QuintoDashboard.css"
import CustomButton from "../../../../components/CustomButton/CustomButton";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import { getEmbarque } from "../../../../services/ModuloGerenciador/Embarque";
import { MenuItem, Select } from "@mui/material";
import { Card } from 'primereact/card';
import InputLabel from "@mui/material/InputLabel";
import CoresRedux from "../../../../contexts/Cor";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { useNavigate } from "react-router-dom";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";

function QuintoDashboard() {
    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [embarque, setEmbarque] = useState([]);

    const [contexto, setContexto] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [valorFiltro, setValorFiltro] = useState([]);

    const [buscando, setBuscando] = useState({ flag: true });

    const [embarqueF, setEmbarqueF] = useState("");

    const [embarqueSegundo, setEmbarqueSegundo] = useState("");

    const [dadosEmbarqueFilial, setDadosEmbarqueFilial] = useState([]);

    const [dadosQuilometroPorFilial, setDadosQuilometroPorFilial] = useState([]);

    const [dadosEmbarquePrazo, setDadosEmbarquePrazo] = useState([]);

    const [dadosPrazoTodos, setDadosPrazoTodos] = useState([]);

    const [dadosEntregaPrazo, setDadosEntregaPrazo] = useState([]);

    const [dadosEmbarqueBRF, setDadosEmbarqueBRF] = useState([]);

    const [dadosEmbarqueBRFFilial, setDadosEmbarqueBRFFilial] = useState([]);

    const [dadosEmbarqueBRFKM, setDadosEmbarqueBRFKM] = useState([]);

    const [dadosEstadoOrigem, setDadosEstadoOrigem] = useState([]);

    const [dadosEstadoDestino, setDadosEstadoDestino] = useState([]);

    const navigate = useNavigate();

    const { dadosCoresRedux } = useContext(CoresRedux);

    const [filiais, setFiliais] = useState(['TODOS', 'Toledo', 'Glória de Dourados', 'Paranvaí', 'Rio Verde']);

    function converteDataPadrao(data) {
        return new Date(data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" });
    }

    function converteData(DataDDMMYY) {
        const dataSplit = DataDDMMYY.split("/");
        const novaData = new Date(parseInt(dataSplit[2], 10),
            parseInt(dataSplit[1], 10) - 1,
            parseInt(dataSplit[0], 10));
        return novaData;
    }

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    useEffect(() => {
        // replace nos dados do redux.
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);

        // busca todos os embarques.
        const buscaEmbarque = async () => {
            const response = await getEmbarque(dadosUsuarioRedux.token);
            if (response.status === 200) {
                let filial = [];
                filial.push('TODOS')
                for (let data of response.data) {
                    filial.push(data.contexto);
                }
                setEmbarque(response.data);
                filial = [...new Set(filial)]
            }
        }
        buscaEmbarque();

        // verifica se está com paginação e set os valores
        let flagPaginando = localStorage.getItem('flagPaginando');
        if (flagPaginando === 'true') {
            let dadosPaginado = localStorage.getItem('dadosDASH');
            if (dadosPaginado) {
                localStorage.setItem('flagDashboard', 'false')
                let dadosPaginadoParsed = JSON.parse(dadosPaginado);
                setContexto(dadosPaginadoParsed.contexto);
                setEmbarqueF(dadosPaginadoParsed.dataInicial);
                setEmbarqueSegundo(dadosPaginadoParsed.dataFinal);
                buscar(dadosPaginadoParsed.contexto)
            }
        }

        // verifica se limpou os dados e seta os antigos.
        let flag = localStorage.getItem('flagDashboard');
        if (flag === 'false') {
            let dados = localStorage.getItem('dadosDASH');
            if (dados) {
                let dadosParsed = JSON.parse(dados);
                setContexto(dadosParsed.contexto);
                setEmbarqueF(dadosParsed.dataInicial);
                setEmbarqueSegundo(dadosParsed.dataFinal);
            }
        }
    }, [])

    const buscar = async (contexto) => {
        let flag = localStorage.getItem('flagDashboard');
        if (flag === 'true') {
            localStorage.removeItem('flagDashboard');
            localStorage.setItem('flagDashboard', 'false')
            let dadosDASH = {
                contexto: contexto,
                dataInicial: embarqueF,
                dataFinal: embarqueSegundo
            }
            setTextoSucesso("Limpando Os Dados! Faça a Busca Novamente.");
            setOpenToastSucesso(true);
            window.location.reload();
            return;
        }
        setBuscando({ flag: true });
        const dataInicial = converteData(converteDataPadrao(embarqueF));
        const dataFinal = converteData(converteDataPadrao(embarqueSegundo));
        if (contexto === "TODOS") {
            let dados = embarque;
            if (dataInicial && dataFinal) {
                dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial && converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal });
                estadoOrigem(dados);
                prazoTodos(dados)
                estadoDestino(dados);
                entregaDentroPrazo(dados);
                embarqueDentroPrazo(dados);
                embarquePorFilial(dados);
                embarqueBRF(dados, dataFinal);
                embarqueBRFKM(dados);
                embarqueBRFFilial(dados);
                quilometroPorFilial(dados);
            } else if (dataInicial && !dataFinal) {
                dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial });
            } else if (dataFinal && !dataInicial) {
                dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal });
            }
        } else {
            let contextoFormatado;
            if (contexto === 'Toledo') contextoFormatado = 'toledo'
            else if (contexto === 'Glória de Dourados') contextoFormatado = 'gloriaDeDourados'
            else if (contexto === 'Paranvaí') contextoFormatado = 'paranavai'
            else if (contexto === 'Rio Verde') contextoFormatado = 'rioVerde'
            let dados = [];
            embarque.forEach((data) => {
                if (data.contexto === contextoFormatado) {
                    dados.push(data);
                }
            });
            if (dataInicial && dataFinal) {
                dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial && converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal });
                estadoOrigem(dados);
                estadoDestino(dados);
                entregaDentroPrazo(dados);
                prazoTodos(dados);
                embarqueDentroPrazo(dados);
                embarquePorFilial(dados);
                embarqueBRF(dados, dataFinal);
                embarqueBRFKM(dados);
                embarqueBRFFilial(dados);
                quilometroPorFilial(dados);
            } else if (dataInicial && !dataFinal) {
                dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial });
            } else if (dataFinal && !dataInicial) {
                dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal });
            }
        }
        setBuscando({ flag: false });
        localStorage.setItem('flagDashboard', 'true')
    }

    const prazoTodos = (dados) => {
        let lista = [];
        for (let dado of dados) {

            if (dado.dataEmbarque === dado.previsaoEmbarque) lista.push('Em Ponto')

            if (dado.dataEntrega === dado.previsaoEntrega) lista.push('Em Ponto')

            if (dado.dataEmbarque > dado.previsaoEmbarque) lista.push('Atrasado')

            if (dado.dataEntrega > dado.previsaoEntrega) lista.push('Atrasado')

            if (dado.dataEmbarque === null) lista.push('Não Finalizado')

            if (dado.dataEntrega === null) lista.push('Não Finalizado')

        }
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            value: count
        }));
        setDadosPrazoTodos(lista);
    }

    const embarqueBRFFilial = (dados) => {
        let lista = [];
        for (let data of dados) {
            if (data.embarcador.includes("BRF") || data.destinatario.includes("BRF") || data.embarcador.includes("brf") || data.destinatario.includes("brf")) {
                lista.push(data.filial);
            }
        }
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            value: count
        }));
        setDadosEmbarqueBRFFilial(lista);
    }

    const quilometroPorFilial = (dados) => {
        let lista = [];
        for (let data of dados) {
            if (data.embarcador.includes("BRF") || data.destinatario.includes("BRF")) {
                lista.push({ name: data.filial, value: parseInt(data.km) });
            }
        }
        setDadosQuilometroPorFilial(lista);
    }

    const embarqueBRF = (dados, dataFinal) => {
        let lista = [];
        for (let data of dados) {
            if (data.destinatario.includes("BRF") || data.destinatario.includes("brf")) lista.push(data.destinatario);

            if (data.embarcador.includes("BRF") || data.embarcador.includes("brf")) lista.push(data.embarcador);

            if ((data.embarcador.includes("BRF")
                || data.destinatario.includes("BRF")
                || data.embarcador.includes("brf")
                || data.destinatario.includes("brf"))
                && converteData(converteDataPadrao(data.dataEntrega)) <= dataFinal) {
                lista.push("BRF ENTREGUE");
            }
        }
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            data: [
                count
            ]
        }));
        setDadosEmbarqueBRF(lista);
    }

    const embarqueBRFKM = (dados) => {
        let lista = [];
        let kmEmbarcador = 0;
        let kmDestinatario = 0;
        let total = 0;
        for (let data of dados) {
            if (data.embarcador.includes("BRF") || data.embarcador.includes("brf")) {
                kmEmbarcador = kmEmbarcador + parseInt(data.km);
            } else if (data.destinatario.includes("BRF") || data.destinatario.includes("brf")) {
                kmDestinatario = kmDestinatario + parseInt(data.km);
            }
        }
        total = kmDestinatario + kmEmbarcador;
        lista.push({ name: "EMBARCADOR", data: [kmEmbarcador] })
        lista.push({ name: "DESTINATARIO", data: [kmDestinatario] })
        lista.push({ name: "TOTAL", data: [total] })
        setDadosEmbarqueBRFKM(lista);
    }

    const embarqueDentroPrazo = (dados) => {
        let lista = [];
        for (let dado of dados) {
            if (dado.dataEmbarque === dado.previsaoEmbarque) {
                lista.push('Em Ponto')
            } else if (dado.dataEmbarque > dado.previsaoEmbarque) {
                lista.push('Atrasado')
            } else if (dado.dataEmbarque === null) {
                lista.push('Não Finalizado')
            }
        }
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            value: count
        }));
        setDadosEmbarquePrazo(lista);
    }

    const entregaDentroPrazo = (dados) => {
        let lista = [];
        for (let dado of dados) {
            if (dado.dataEntrega === dado.previsaoEntrega) {
                lista.push('Em Ponto')
            } else if (dado.dataEntrega > dado.previsaoEntrega) {
                lista.push('Atrasado')
            } else if (dado.dataEntrega === null) {
                lista.push('Não Finalizado')
            }
        }
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            value: count
        }));
        setDadosEntregaPrazo(lista);
    }


    const estadoOrigem = (dados) => {
        let lista = [];
        dados.map((data) => lista.push(data.estadoOrigem));
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            value: count
        }));
        setDadosEstadoOrigem(lista);
    }

    const estadoDestino = (dados) => {
        let lista = [];
        dados.map((data) => lista.push(data.estadoDestino));
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            value: count
        }));
        setDadosEstadoDestino(lista);
    }

    const embarquePorFilial = (dados) => {
        let lista = [];
        dados.map((data) => lista.push(data.filial));
        const countMap = Object.create(null);
        for (const element of lista) {
            countMap[element] = (countMap[element] || 0) + 1;
        }
        lista = Object.entries(countMap).map(([value, count]) => ({
            name: value,
            data: [
                count
            ]
        }));
        setDadosEmbarqueFilial(lista);
    }

    const imprimir = () => {
        window.print();
    }

    const backPage = () => {
        navigate('/quarto-dashboard')
    }

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Dashboard') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Dashboard') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV2 selecionado="Dashboard" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV2 selecionado="Dashboard" />
                    <div className="indicadores">
                        <p className="indicador-principal-2">{"Dashboard - 4 >"}</p>
                        <p className="indicador-secundario">{"Dashboard - 5"}</p>
                    </div>
                    <div className="containerBuscas">
                        <div className="opcoes">
                            <div>
                                <InputLabel htmlFor="select">Contexto</InputLabel>
                                <Select
                                    className='select-estado'
                                    variant="standard"
                                    style={{ width: 200 }}
                                    id='select'
                                    value={contexto}>
                                    {filiais.map((filial) => (
                                        <MenuItem
                                            key={filial}
                                            value={filial}
                                            onClick={() => setContexto(filial)}>
                                            {filial}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <InputLabel htmlFor="embarque">Embarque Início</InputLabel>
                                <input
                                    type={'date'}
                                    className='input-text'
                                    value={embarqueF}
                                    style={{ width: 200 }}
                                    onChange={(e) => { setEmbarqueF(e.target.value); }}
                                    id='embarque'>
                                </input>
                            </div>
                            <div>
                                <InputLabel htmlFor="embarquef">Embarque Final</InputLabel>
                                <input
                                    type={'date'}
                                    className='input-text'
                                    value={embarqueSegundo}
                                    style={{ width: 200 }}
                                    onChange={(e) => { setEmbarqueSegundo(e.target.value); }}
                                    id='embarquef'>
                                </input>
                            </div>
                            <div className="botao">
                                <CustomButton
                                    classCss="custom-button"
                                    titulo="Limpar"
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => { setEmbarqueF(""); setEmbarqueSegundo("") }}
                                />
                                <CustomButton
                                    classCss="custom-button"
                                    titulo="Gerar Relatório"
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => { buscar(contexto) }}
                                />
                                <CustomButton
                                    classCss="custom-button"
                                    titulo="Imprimir"
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => imprimir()}
                                />
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="charts">
                        {
                            !buscando.flag ? (
                                <div>
                                    <div className="graficosCima">
                                        <Card>
                                            <Charts data={dadosEmbarquePrazo} type={"pie"} height="auto" width={300} title={"Embarque Prazo"}></Charts>
                                        </Card>
                                        <Card>
                                            <Charts data={dadosEntregaPrazo} type={"pie"} height="auto" width={300} title={"Entrega Prazo"}></Charts>
                                        </Card>
                                        <Card>
                                            <Charts data={dadosPrazoTodos} type={"pie"} height="auto" width={300} title={"Todos Prazos"}></Charts>
                                        </Card>
                                    </div>
                                    <div className="graficosBaixo">
                                        <Card>
                                            <Charts data={dadosEstadoOrigem} type={"pie"} height="auto" width={300} title={"Estado Origem"}></Charts>
                                        </Card>
                                        <Card>
                                            <Charts data={dadosEstadoDestino} type={"pie"} height="auto" width={300} title={"Estado Destino"}></Charts>
                                        </Card>
                                        <Card>
                                            <Charts data={dadosEmbarqueFilial} type={"bar"} height="auto" width={350} title={"Embarque Por Filial"}></Charts>
                                        </Card>
                                    </div>
                                    <hr></hr>
                                    <div className="graficosBaixoCima">
                                        <Card>
                                            <Charts data={dadosEmbarqueBRF} type={"bar"} height="auto" width={350} title={"Embarques BRF"}></Charts>
                                        </Card>
                                        <Card>
                                            <Charts data={dadosEmbarqueBRFKM} type={"bar"} height="auto" width={350} title={"Embarques BRF KM"}></Charts>
                                        </Card>
                                    </div>
                                    <div className="graficosBaixoBaixo">
                                        <Card>
                                            <Charts data={dadosEmbarqueBRFFilial} type={"pie"} height="auto" width={300} title={"Embarques BRF Filial"}></Charts>
                                        </Card>
                                        <Card>
                                            <Charts data={dadosQuilometroPorFilial} type={"pie"} height="auto" width={300} title={"Embarques BRF Filial"}></Charts>
                                        </Card>
                                    </div>
                                    <div className="paginacao">
                                        <CustomButton
                                            classCss="custom-button"
                                            titulo="Voltar Página"
                                            cor={dadosCoresRedux.botaoPadrao}
                                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                                            onClick={() => backPage()}
                                        />
                                    </div>
                                </div>
                            ) : (<div></div>)}
                    </div>
                    <ToastSucesso
                        texto={textoSucesso}
                        open={openToastSucesso}
                        onClose={handleCloseToastSucesso}
                    />
                </div>
            )
            }
        </motion.div>
    );
}

export default QuintoDashboard;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const buscaKmCoordenadas = async (clientes, token) => {
    return api.post("RotaGoogle/coordenadas",
        clientes
        , {
            headers: {
                'Authorization': token
            }
        })
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraTaxaIcms = async (taxaicms, token) => {
  return api.post("taxaIcms",
    taxaicms
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTaxaIcms = async (token) => {
  return api.get("taxaIcms",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTaxaIcmsPorUf = async (token, ufOrigem, ufDestino) => {
  return api.get("taxaIcms/" + ufOrigem + '/' + ufDestino,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTaxaIcmsPorId = async (id, token) => {
  return api.get("taxaIcms/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTaxaIcmsPorId = async (id, token) => {
  return api.delete("taxaIcms/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTaxaIcmsPorId = async (taxaicms, token) => {
  return api.put("taxaIcms",
    taxaicms,
  {
    headers: {
      'Authorization': token
    }
  })
}
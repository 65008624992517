import { api } from "../ModuloGerenciador/UrlPadrao"

export const getEventosEmbarque = async (token, id) => {
    return api.get("EventosEmbarque/IdEmbarque/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
  }
  
import { api } from "./UrlPadrao"

export const cadastraOcorrenciaEmbarque = async (ocorrenciaEmbarque, token) => {
  return api.post("OcorrenciaEmbarque",
  ocorrenciaEmbarque
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getOcorrenciaEmbarque = async (token) => {
  return api.get("OcorrenciaEmbarque",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getOcorrenciaEmbarquePorId = async (id, token) => {
  return api.get("OcorrenciaEmbarque/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getOcorrenciaEmbarquePorEmbarqueId = async (id, token) => {
  return api.get("OcorrenciaEmbarque/buscarPorEmbarque/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteOcorrenciaEmbarquePorId = async (id, token) => {
  return api.delete("OcorrenciaEmbarque/deletarLogicament/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaOcorrenciaEmbarquePorId = async (ocorrenciaEmbarque, token) => {
  return api.put("OcorrenciaEmbarque",
  ocorrenciaEmbarque,
  {
    headers: {
      'Authorization': token
    }
  })
}
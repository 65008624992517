import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraRelatorio = async (relatorio, token) => {
  return api.post("relatorio",
    relatorio
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getRelatorio = async (token, contexto, dataInicio, dataFinal) => {
  return api.get("relatorio/relatorioValores/" + contexto + "/" + dataInicio + "/" + dataFinal,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getMetaContexto = async (token, contexto) => {
  return api.get("Meta/contexto/" + contexto,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaMeta = async (token, meta) => {
  return api.put("Meta",
    meta,
  {
    headers: {
      'Authorization': token
    }
  })
}
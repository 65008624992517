import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import "./Frota.css";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";
import { getFrota } from "../../../services/ModuloChecklist/GerenciamentoFrota";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import Tabela from "../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";

function FrotaAdiciona(props) {
    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [textoErro, setTextoErro] = useState("");

    const [listaCaminhao, setListaCaminhao] = useState([]);

    const [listaCarreta, setListaCarreta] = useState([]);

    const [loading, setLoading] = useState(true);

    const buscarFrotas = async () => {
        setLoading(true);
        await getFrota(dadosUsuarioRedux.token).then((result) => {
            setListaCaminhao(result.data.caminhaos);
            setListaCarreta(result.data.carreta);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    };

    const navigate = useNavigate();

    const setTelaCheck = () => {
        navigate("/cadastro-check-adiciona");
    };

    useEffect(() => {
        buscarFrotas();
    }, []);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Gerenciamento de Frota Configurações') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Gerenciamento de Frota Configurações') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Adicionar Checklist Veículo" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Adicionar Checklist Veículo" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div className="indicadores-frota">
                                    <p className="indicador-principal-frota">Caminhões Frota</p>
                                    <p className="indicador-principal-frota">Carretas Frota</p>
                                </div>
                                <div className="principal-frotas">
                                    <TabelaV2 setTelaCheck={setTelaCheck} classeSelecionado="idCaminhao" nomeClasse1="outer-wrapper-rota" nomeClasse2="table-wrapper-v2" colunas={dadosTabelaRedux.Caminhao} dados={listaCaminhao} />
                                    <TabelaV2 setTelaCheck={setTelaCheck} classeSelecionado="idCarreta" nomeClasse1="outer-wrapper-rota" nomeClasse2="table-wrapper-v2" colunas={dadosTabelaRedux.Carreta} dados={listaCarreta} />
                                </div>
                                <div>
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default FrotaAdiciona;
import React, { useEffect, useContext, useState } from "react";
import NavbarV2 from "../../../../components/NavbarV2/NavbarV2.js";
import Charts from "../../../../components/Charts/Charts.tsx"
import "./DashboardCotacao.css"
import DadosUsuarioRedux from "../../../../contexts/Usuario.js";
import { Card } from 'primereact/card';
import CoresRedux from "../../../../contexts/Cor.js";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario.js";
import { useLocation, useNavigate } from "react-router-dom";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao.js";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral.js";
import { getCotacaoDash } from "../../../../services/ModuloGerenciador/Cotacao.js";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent.js";
import ModalFiltroEmbarque from "../../../../components/ModalFiltroEmbarque/ModalFiltroEmbarque.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import { motion } from "framer-motion";
import FiltrosRedux from "../../../../contexts/Filtros.js";
import iconeImprimir from "../../../../images/iconeImprimir.png";
import FiltroV2 from "../../../../components/FiltroV2/FiltroV2.js";
import MultiplosFiltrosRedux from "../../../../contexts/MultiplosFiltros.js";
import iconeAnterior from "../../../../images/iconeAnterior.png";
import iconeProximo from "../../../../images/iconeProximo.png";

function DashboardCotacao() {

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const [values, setValues] = useState({});

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [openToastErro, setOpenToastErro] = useState(false);

    const [porcentagemCotacoesAguardando, setPorcentagemCotacoesAguardando] = useState();
    const [porcentagemCotacoesCarregadas, sePorcentagemCotacoesCarregadas] = useState();
    const [porcentagemCotacoesNaoCarregadas, setPorcentagemCotacoesNaoCarregadas] = useState();

    const [textoErro, setTextoErro] = useState("");

    const [dadosCotacao, setDadosCotacao] = useState([]);

    const [dadosCotacao2, setDadosCotacao2] = useState([]);

    const [numeroEmbarque, setNumeroEmbarques] = useState(0);

    const [loading, setLoading] = useState(true);

    const [openFiltroEmbarque, setOpenFiltroEmbarque] = useState(false);

    const handleCloseFiltroEmbarque = async (value) => {
        const valoresFiltros = localStorage.getItem("filtros");
        const objetoFiltros = JSON.parse(valoresFiltros);
        setOpenFiltroEmbarque(false);
        if (valoresFiltros != '{}' && valoresFiltros != null) {
            setLoading(true);
            await getCotacaoDash(dadosUsuarioRedux.token, objetoFiltros.embarqueInicio, objetoFiltros.embarqueFinal).then((result) => {
                setNumeroEmbarques(result.data.numeroEmbarques);
                setPorcentagemCotacoesAguardando(result.data.porcentagemCotacoesAguardando.toFixed(2));
                sePorcentagemCotacoesCarregadas(result.data.porcentagemCotacoesCarregadas.toFixed(2));
                setPorcentagemCotacoesNaoCarregadas(result.data.porcentagemCotacoesNaoCarregadas.toFixed(2));
                setDadosCotacao(result.data.pagina.grafico1.pizza);
                setDadosCotacao2(result.data.pagina.grafico2.pizza);
                setLoading(false);
            }).catch((error) => {

                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                if (error.response.status === 404) {
                    setTextoErro("Nenhuma data foi selecionada");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    };

    const navigate = useNavigate();

    const { dadosCoresRedux } = useContext(CoresRedux);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [])

    const imprimir = () => {
        window.print();
    }

    const proxPage = () => {
        navigate('/segundo-dashboard');
    }

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Dashboard') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Dashboard') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const converterData = (data) => {
        const parts = data.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    const buscarDadosDashboardCotacao = async () => {
        setLoading(true);
        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        const data1 = "01-01-2020";
        const data2 = "01-01-2050";
        if ((Array.isArray(filtrosRedux) && filtrosRedux.length !== 0) ||
            (filtrosRedux && Object.keys(filtrosRedux).length !== 0 && filtrosRedux.constructor === Object)) {
            const pesquisaFormatada = `${filtrosRedux.item}=${filtrosRedux.pesquisa}`
            await getCotacaoDash(dadosUsuarioRedux.token,
                filtrosRedux.embarqueInicio ? filtrosRedux.embarqueInicio : data1,
                filtrosRedux.embarqueFinal ? filtrosRedux.embarqueFinal : data2).then((result) => {
                    setNumeroEmbarques(result.data.numeroEmbarques);
                    setDadosCotacao(result.data.pagina.grafico1.pizza);
                    setDadosCotacao2(result.data.pagina.grafico2.pizza);
                    setPorcentagemCotacoesAguardando(result.data.porcentagemCotacoesAguardando.toFixed(2));
                    sePorcentagemCotacoesCarregadas(result.data.porcentagemCotacoesCarregadas.toFixed(2));
                    setPorcentagemCotacoesNaoCarregadas(result.data.porcentagemCotacoesNaoCarregadas.toFixed(2));
                    setLoading(false);
                    setPesquisaOk(true);
                }).catch((error) => {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                });
        } else {
            await getCotacaoDash(dadosUsuarioRedux.token, data1, data2).then((result) => {
                setNumeroEmbarques(result.data.numeroEmbarques);
                setDadosCotacao(result.data.pagina.grafico1.pizza);
                setDadosCotacao2(result.data.pagina.grafico2.pizza);
                setPorcentagemCotacoesAguardando(result.data.porcentagemCotacoesAguardando.toFixed(2));
                sePorcentagemCotacoesCarregadas(result.data.porcentagemCotacoesCarregadas.toFixed(2));
                setPorcentagemCotacoesNaoCarregadas(result.data.porcentagemCotacoesNaoCarregadas.toFixed(2));
                setLoading(false);
                setPesquisaOk(true);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        buscarDadosDashboardCotacao();
    }, []);

    const itensFiltro = [
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Cidade Origem',
            'field': 'cidadeOrigem'
        },
        {
            'nomeColuna': 'Estado Origem',
            'field': 'estadoOrigem'
        },
        {
            'nomeColuna': 'Cidade Destino',
            'field': 'cidadeDestino'
        },
        {
            'nomeColuna': 'Estado Destino',
            'field': 'estadoDestino'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Embarque',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Data Embarque',
            'field': 'dataEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Embarque',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [pesquisaOk, setPesquisaOk] = useState(false);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        buscarSearchReset();
    }

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getCotacaoDash(dadosUsuarioRedux.token, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal).then((result) => {
            setNumeroEmbarques(result.data.numeroEmbarques);
            setDadosCotacao(result.data.pagina.grafico1.pizza);
            setDadosCotacao2(result.data.pagina.grafico2.pizza);
            setPorcentagemCotacoesAguardando(result.data.porcentagemCotacoesAguardando.toFixed(2));
            sePorcentagemCotacoesCarregadas(result.data.porcentagemCotacoesCarregadas.toFixed(2));
            setPorcentagemCotacoesNaoCarregadas(result.data.porcentagemCotacoesNaoCarregadas.toFixed(2));
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }


    const buscarSearchReset = async () => {
        setLoading(true);
        const pesquisaFormatada = 'undefined=undefined'
        await getCotacaoDash(dadosUsuarioRedux.token, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal).then((result) => {
            setNumeroEmbarques(result.data.numeroEmbarques);
            setDadosCotacao(result.data.pagina.grafico1.pizza);
            setDadosCotacao2(result.data.pagina.grafico2.pizza);
            setPorcentagemCotacoesAguardando(result.data.porcentagemCotacoesAguardando.toFixed(2));
            sePorcentagemCotacoesCarregadas(result.data.porcentagemCotacoesCarregadas.toFixed(2));
            setPorcentagemCotacoesNaoCarregadas(result.data.porcentagemCotacoesNaoCarregadas.toFixed(2));
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV2 selecionado="Dashboard Cotações" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV2 selecionado="Dashboard Cotações" />
                    <div className="dashboards">
                        <div className="header-dashboard1">
                            <div className="flex2">
                                <p className="indicador-principal-faturamento-v2">Cotações</p>
                                <img onClick={imprimir} className="imagens-faturamentos" src={iconeImprimir} />
                            </div>
                        </div>
                        <div className="containerBuscas1">
                            <div className="filtro-embarque">
                                {
                                    !loading ? (
                                        <FiltroV2
                                            classe='div-filtrar-faturamento-v2'
                                            classOption='select-options'
                                            itensFiltro={itensFiltro}
                                            valuesPesquisa={valuesPesquisa}
                                            setValuesPesquisa={setValuesPesquisa}
                                            buscarSearch={buscarSearch}
                                            limpar={limpar}
                                        />
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 120}}>
                            <div className="card-ontime-azul">
                                <div className="card-header-ontime">
                                    Aguardando
                                </div>
                                <div className="card-body">
                                    {porcentagemCotacoesAguardando}%
                                </div>
                            </div>
                            <div className="card-ontime-azul">
                                <div className="card-header-ontime">
                                    Carregadas
                                </div>
                                <div className="card-body">
                                    {porcentagemCotacoesCarregadas}%
                                </div>
                            </div>
                            <div className="card-ontime-azul">
                                <div className="card-header-ontime">
                                    Não Carregadas
                                </div>
                                <div className="card-body">
                                    {porcentagemCotacoesNaoCarregadas}%
                                </div>
                            </div>
                        </div>
                        <div className="charts">
                            {
                                !loading ? (
                                    <div>
                                        <div className="graficosCotacao">
                                            <div class="item-dash-user">
                                                <Card>
                                                    <Charts data={dadosCotacao} type={"pie"} height={480} width={570} title={"Usuário Responsável"}></Charts>
                                                </Card>
                                            </div>
                                            <div class="item-dash-user">
                                                <Card>
                                                    <Charts data={dadosCotacao2} type={"pie"} height={480} width={570} title={"Usuário Responsável"}></Charts>
                                                </Card>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                    </div>
                                ) : (
                                    <LoadingComponent />
                                )
                            }
                        </div>
                    </div>
                    <ToastSucesso
                        texto={textoSucesso}
                        open={openToastSucesso}
                        onClose={handleCloseToastSucesso}
                    />
                    <ToastErro
                        texto={textoErro}
                        open={openToastErro}
                        onClose={handleCloseToastErro}
                    />
                    <ModalFiltroEmbarque
                        open={openFiltroEmbarque}
                        onClose={handleCloseFiltroEmbarque}
                        values={values}
                    />
                </div>
            )
            }
        </motion.div>
    );
}

export default DashboardCotacao;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const getPedidoSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Pedido?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getClienteSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Cliente?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCidadeSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/CidadeEstado?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getVeiculoSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Veiculo?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCaminhaoSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Caminhao?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCarretaSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Carreta?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getMotoristaSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Motorista?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getProprietarioSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Proprietario?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getEmbarqueSearch = async (token, pesquisa, contexto, dataInicio, dataFim, ordenarPor) => {
    return api.get("SearchPredicate/search/Embarque/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + ordenarPor + '?' + pesquisa,
        {
            headers: {
                'Authorization': token,
                'contexto': contexto
            }
        })
}

export const getOcorrenciaSearch = async (token, pesquisa, dataInicio, dataFim, ordenarPor) => {
    return api.get("OcorrenciaEmbarque/ocorrenciasEmbarque/" + dataInicio + '/' + dataFim + '/' + ordenarPor + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getEmbarqueDiariaSearch = async (token, pesquisa, contexto, dataInicio, dataFim, ordenarPor) => {
    return api.get("SearchPredicate/search/EmbarqueDiaria/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + ordenarPor + '?' + pesquisa,
        {
            headers: {
                'Authorization': token,
                'contexto': contexto
            }
        })
}

export const getDashEmbarque1Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina1/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashEmbarque2Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina2/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashEmbarque3Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina3/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashEmbarque4Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina4/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getOnTimeSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/OnTime/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getOnTimeEmbarqueSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/OnTime/Embarque/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getOnTimeEntregaSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/OnTime/Entrega/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashCockpitSearch = async (token, pesquisa, contexto, dataInicio, dataFim, grupoClientes) => {
    return api.get("SearchPredicate/cockpit/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + grupoClientes + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getFaturamento = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/relatorioValores/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashResponsaveisSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/Responsaveis/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}
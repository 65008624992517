import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
    response => response,
    async error => {
        const { status } = error.response;
        if (status === 401) {
            localStorage.removeItem("usuario");
            localStorage.removeItem("token");
            localStorage.removeItem("expiracao");
            localStorage.removeItem("dadosFilial");
            localStorage.removeItem("usuarioTabela");
            localStorage.removeItem("Cookies");
            localStorage.removeItem("flagPassword");
            localStorage.removeItem("expirateTime");
            window.location.reload();
            window.location.replace('login');
        }
        return Promise.reject(error);
    }
);
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraOcorrencia = async (ocorrencia, token) => {
  return api.post("Ocorrencia",
  ocorrencia
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getOcorrencia = async (token) => {
  return api.get("Ocorrencia",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getOcorrenciaPorId = async (id, token) => {
  return api.get("Ocorrencia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteOcorrenciaPorId = async (id, token) => {
  return api.delete("Ocorrencia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaOcorrenciaPorId = async (ocorrencia, token) => {
  return api.put("Ocorrencia",
  ocorrencia,
  {
    headers: {
      'Authorization': token
    }
  })
}
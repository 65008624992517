import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DadosUsuarioRedux } from './contexts/Usuario';
import { CoresRedux } from './contexts/Cor';
import { NomeTabelaRedux } from './contexts/NomeTabela';
import { DadosFilialRedux } from './contexts/CidadesFilial';
import { TabelaRedux } from './contexts/Tabela';
import { DadosContextoEmbarqueRedux } from './contexts/ContextoEmbarque';
import { MenusRedux } from './contexts/Menus';
import { RequestControllerRedux } from './contexts/RequestController';
import { FiltrosRedux } from './contexts/Filtros';
import { RastrearRedux } from './contexts/Rastreio';
import { MultiplosFiltrosRedux } from './contexts/MultiplosFiltros';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RequestControllerRedux>
      <MenusRedux>
        <DadosContextoEmbarqueRedux>
          <TabelaRedux>
            <DadosFilialRedux>
              <DadosUsuarioRedux>
                <CoresRedux>
                  <NomeTabelaRedux>
                    <FiltrosRedux>
                      <RastrearRedux>
                        <MultiplosFiltrosRedux>
                          <App />
                        </MultiplosFiltrosRedux>
                      </RastrearRedux>
                    </FiltrosRedux>
                  </NomeTabelaRedux>
                </CoresRedux>
              </DadosUsuarioRedux>
            </DadosFilialRedux>
          </TabelaRedux>
        </DadosContextoEmbarqueRedux>
      </MenusRedux>
    </RequestControllerRedux>
  </React.StrictMode>
);

reportWebVitals();

import { api } from "./UrlPadrao"

export const cadastraSegmentoCliente = async (segmentoCliente, token) => {
  return api.post("SegmentoCliente",
    segmentoCliente
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getSegmentoCliente = async (token) => {
  return api.get("SegmentoCliente/segmentos",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getSegmentoClientePorId = async (id, token) => {
  return api.get("SegmentoCliente/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteSegmentoClientePorId = async (id, token) => {
  return api.delete("SegmentoCliente/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaSegmentoClientePorId = async (segmentoCliente, token) => {
  return api.put("SegmentoCliente/" + segmentoCliente.id + "/" + segmentoCliente.nome,
    segmentoCliente,
  {
    headers: {
      'Authorization': token
    }
  })
}
import { api } from "./UrlPadrao"

export const cadastraMotivoOnTime = async (motivoOnTime, token) => {
  return api.post("MotivoOnTime",
    motivoOnTime
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotivoOnTime = async (token) => {
  return api.get("MotivoOnTime",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotivoOnTimePorId = async (id, token) => {
  return api.get("MotivoOnTime/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteMotivoOnTimePorId = async (id, token) => {
  return api.delete("MotivoOnTime/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaMotivoOnTimePorId = async (motivoOnTime, token) => {
  return api.put("MotivoOnTime",
    motivoOnTime,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const vincularMotivoPorId = async (token, embarqueId, motivoId, tipoVinculo) => {
  return api.put("MotivoOnTime/vincularEmbarque/" + embarqueId + "/" + motivoId + "/" + tipoVinculo,
  null,
    {
      headers: {
        'Authorization': token
      }
    })
}
import { Model } from "./Base.js";

export class Cidade extends Model {
    get defaults() {
        return {
            cidade: "",
            estado: "",
            usuario: "",
        };
    }
}
import React, { useEffect, useState } from 'react';
import {
  Route, Routes
} from 'react-router-dom';
import { AnimatePresence } from "framer-motion"
import CadastrarUsuario from './pages/ModuloGerenciador/CadastrarUsuario/CadastrarUsuario';
import { useContext } from "react";
import Dashboard from './pages/ModuloGerenciador/Dashboards/Dashboard/Dashboard';
import EditarUsuario from './pages/ModuloGerenciador/EditarUsuario/EditarUsuario';
import Cliente from './pages/ModuloGerenciador/Cliente/Cliente';
import Feedback from './pages/ModuloGerenciador/Feedback/Feedback';
import Embarque from './pages/ModuloGerenciador/Embarque/Embarque';
import Home from './pages/ModuloGerenciador/Home/Home';
import Login from './pages/ModuloGerenciador/Login/Login';
import Motorista from './pages/ModuloGerenciador/Motorista/Motorista';
import Pedido from './pages/ModuloGerenciador/Pedido/Pedido';
import Rota from './pages/ModuloGerenciador/Rota/Rota';
import CidadeEstado from './pages/ModuloGerenciador/UsoComum/CidadeEstado/CidadeEstado';
import Embalagem from './pages/ModuloGerenciador/UsoComum/Embalagem/Embalagem';
import EmpresaSubsidiaria from './pages/ModuloGerenciador/UsoComum/EmpresaSubsidiaria/EmpresaSubsidiaria';
import Filial from './pages/ModuloGerenciador/UsoComum/Filial/Filial';
import MeioPagamento from './pages/ModuloGerenciador/UsoComum/MeioPagamento/MeioPagamento';
import Status from './pages/ModuloGerenciador/UsoComum/Status/Status';
import UnidadeEmbarque from './pages/ModuloGerenciador/UsoComum/UnidadeEmbarque/UnidadeEmbarque';
import Motivo from './pages/ModuloGerenciador/UsoComum/Motivo/Motivo';
import SelecionarEmpresa from './pages/ModuloGerenciador/SelecionarEmpresa/SelecionarEmpresa';
import ConfiguracoesCores from './pages/ModuloGerenciador/Configuracoes/ConfiguracoesCores/ConfiguracoesCores';
import ConfiguracoesColunas from './pages/ModuloGerenciador/Configuracoes/ConfiguracoesColunas/ConfiguracoesColunas';
import NomeTabelaRedux from './contexts/NomeTabela';
import CoresRedux from './contexts/Cor';
import { getEmpresaPorId } from './services/ModuloGerenciador/Administrativo';
import DadosContextoEmbarqueRedux from './contexts/ContextoEmbarque';
import SegundoDashboard from './pages/ModuloGerenciador/Dashboards/SegundoDashboard/SegundoDashboard';
import TerceiroDashboard from './pages/ModuloGerenciador/Dashboards/TerceiroDashboard/TerceiroDashboard';
import QuartoDashboard from './pages/ModuloGerenciador/Dashboards/QuartoDashboard/QuartoDashboard';
import QuintoDashboard from './pages/ModuloGerenciador/Dashboards/QuintoDashboard/QuintoDashboard';
import MenusRedux from "./contexts/Menus";
import TaxaIcms from './pages/ModuloGerenciador/UsoComum/TaxaIcms/TaxaIcms';
import Proprietario from './pages/ModuloGerenciador/UsoComum/Proprietario/Proprietario';
import Mercadoria from './pages/ModuloGerenciador/UsoComum/Mercadoria/Mercadoria';
import Notificacoes from './pages/ModuloGerenciador/Configuracoes/Notificacoes/Notificacoes';
import EnviarEmail from './pages/ModuloGerenciador/Configuracoes/EnviarEmail/EnviarEmail';
import CodigoEmail from './pages/ModuloGerenciador/EsqueciSenha/CodigoEmail/CodigoEmail';
import RecuperarSenha from './pages/ModuloGerenciador/EsqueciSenha/RecuperaSenha/RecuperarSenha';
import AlteraSenha from './pages/ModuloGerenciador/EsqueciSenha/AlteraSenha/AlteraSenha';
import CadastroUsuario from './pages/ModuloGerenciador/CadastrarUsuario/CadastroUsuario/CadastroUsuario';
import SalvaSenha from './pages/ModuloGerenciador/CadastrarUsuario/SalvaSenha/SalvaSenha';
import Solicitacoes from './pages/ModuloGerenciador/Solicitacoes/Solicitacoes';
import Veiculo from './pages/ModuloGerenciador/Veiculo/Veiculo/Veiculo';
import Carreta from './pages/ModuloGerenciador/Veiculo/Carreta/Carreta';
import Caminhao from './pages/ModuloGerenciador/Veiculo/Caminhao/Caminhao';
import Faturamento from './pages/ModuloGerenciador/Faturamento/Faturamento';
import Avisos from './pages/Avisos/Avisos';
import Checkagem from './pages/ModuloChecklist/Checkagem/Checkagem';
import Frota from './pages/ModuloChecklist/Frota/Frota';
import TipoCheckagem from './pages/ModuloChecklist/TipoCheckagem/TipoCheckagem';
import CrudCheck from './pages/ModuloChecklist/Frota/CrudCheck/CrudCheck';
import CrudCheckAdiciona from './pages/ModuloChecklist/AdicionaCheckFrota/CrudCheckAdiciona/CrudCheckAdiciona';
import FrotaAdiciona from './pages/ModuloChecklist/AdicionaCheckFrota/FrotaAdiciona';
import Ajuda from './pages/ModuloGerenciador/Ajuda/Ajuda';
import VeiculosVencidos from './pages/ModuloChecklist/VeiculosVencidos/VeiculosVencidos';
import CrudCheckVencido from './pages/ModuloChecklist/VeiculosVencidos/CrudCheckVencido/CrudCheckVencido';
import TipoRastreamento from './pages/ModuloRastreamento/TipoRastreamento/TipoRastreamento';
import AutoEditar from './pages/ModuloGerenciador/EditarUsuario/AutoEditar/AutoEditar';
import HomeV2 from './pages/ModuloGerenciador/HomeV2/HomeV2';
import Rastreamento from './pages/ModuloRastreamento/Rastreamento/Rastreamento';
import Rastrear from './pages/ModuloRastreamento/Rastrear/Rastrear';
import LoginV2 from './pages/ModuloGerenciador/LoginV2/LoginV2';
import GrupoClientes from './pages/ModuloGerenciador/GrupoClientes/GrupoClientes';
import Cockpit from './pages/ModuloGerenciador/Cockpit/Cockpit';
import CockpitDashboard from './pages/ModuloGerenciador/Dashboards/CockpitDashboard/CockpitDashboard';
import GrupoComClientes from './pages/ModuloGerenciador/GrupoComClientes/GrupoComClientes';
import GrupoPrecificacao from './pages/GrupoPrecificacao/GrupoPrecificacao';
import ValorEmbarque from './pages/ValorEmbarque/ValorEmbarque';
import MotivoOnTime from './pages/ModuloGerenciador/MotivoOnTime/MotivoOnTime';
import OnTime from './pages/ModuloGerenciador/OnTime/OnTime';
import Painel from './pages/ModuloGerenciador/Painel/Painel';
import GrupoCarga from './pages/ModuloGerenciador/GrupoCarga/GrupoCarga';
import GrupoCargaVinculo from './pages/ModuloGerenciador/GrupoCargaVinculo/GrupoCargaVinculo';
import DashboardResponsaveis from './pages/ModuloGerenciador/Dashboards/DashboardResponsaveis/DashboardResponsaveis';
import PoliticaPrivacidade from './pages/ModuloGerenciador/Politicas/PoliticaPrivacidade';
import OnTimeEmbarque from './pages/ModuloGerenciador/OnTimeEmbarque/OnTimeEmbarque';
import OnTimeEntrega from './pages/ModuloGerenciador/OnTimeEntrega/OnTimeEntrega';
import Cotacao from './pages/ModuloGerenciador/Cotacao/Cotacao';
import DashboardCotacao from './pages/ModuloGerenciador/Dashboards/DashboardCotacao/DashboardCotacao';
import TipoVeiculo from './pages/ModuloGerenciador/UsoComum/TipoVeiculo/TipoVeiculo';
import TipoCarroceria from './pages/ModuloGerenciador/UsoComum/TipoCarroceria/TipoCarroceria';
import Alertas from './pages/ModuloGerenciador/Alertas/Alertas';
import MotivoCotacao from './pages/ModuloGerenciador/UsoComum/MotivoCotacao/MotivoCotacao';
import SegmentoCliente from './pages/ModuloGerenciador/UsoComum/SegmentoCliente/SegmentoCliente';
import GerenciamentoPedido from './pages/ModuloGerenciador/GerenciamentoPedido/GerenciamentoPedido';
import Ocorrencia from './pages/ModuloGerenciador/UsoComum/Ocorrencia/Ocorrencia';
import TipoOcorrencia from './pages/ModuloGerenciador/UsoComum/TipoOcorrencia/TipoOcorrencia';
import SubTipoOcorrencia from './pages/ModuloGerenciador/UsoComum/SubTipoOcorrencia/SubTipoOcorrencia';
import DashboardOcorrencias from './pages/ModuloGerenciador/Dashboards/DashboardOcorrencias/DashboardOcorrencias';
import RelatorioProprietarios from './pages/ModuloGerenciador/Dashboards/RelatorioProprietarios/RelatorioProprietarios';

const AnimatedRoutes = () => {

  const { setDadosMenusRedux } = useContext(MenusRedux);

  const { setDadosTabelaRedux } = useContext(NomeTabelaRedux);

  const { setDadosCoresRedux } = useContext(CoresRedux);

  const { setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

  const [login, setLogin] = useState(false);

  const buscarEmpresaId = async () => {
    try {
      await getEmpresaPorId(localStorage.getItem('tenant')).then((result) => {
        if (result?.status === 200) {
          const nomeColuna = result.data.colunas.replace(/'/g, '"');
          const colunas = JSON.parse(nomeColuna);
          setDadosTabelaRedux(colunas);
          const nomeCores = result.data.cores.replace(/'/g, '"');
          const cores = JSON.parse(nomeCores);
          setDadosCoresRedux(cores);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  function Logout() {
    localStorage.removeItem("usuario");
    localStorage.removeItem("token");
    localStorage.removeItem("expiracao");
    localStorage.removeItem("usuarioTabela");
    localStorage.removeItem("Cookies");
    localStorage.removeItem("flagPassword");
    localStorage.removeItem("expirateTime");
    window.location.reload();
  }

  useEffect(() => {
    Private();
    const usuarioTabela = localStorage.getItem("usuarioTabela");
    const usuarioTabelaFormatado = JSON.parse(usuarioTabela);
    setDadosMenusRedux(usuarioTabelaFormatado);
    // if (window.location.href.endsWith("embarque")) {
    //   setDadosContextoEmbarqueRedux("todos");
    // } else if (window.location.href.endsWith("embarque-toledo")) {
    //   setDadosContextoEmbarqueRedux("toledo");
    // } else if (window.location.href.endsWith("embarque-gloria")) {
    //   setDadosContextoEmbarqueRedux("gloriaDeDourados");
    // } else if (window.location.href.endsWith("embarque-paranavai")) {
    //   setDadosContextoEmbarqueRedux("paranavai");
    // } else if (window.location.href.endsWith("embarque-rio-verde")) {
    //   setDadosContextoEmbarqueRedux("rioVerde");
    // } else if (window.location.href.endsWith("embarque-frota")) {
    //   setDadosContextoEmbarqueRedux("frota");
    // } else if (window.location.href.endsWith("embarque-template")) {
    //   setDadosContextoEmbarqueRedux("templateEmbarque");
    // }
    buscarEmpresaId();
    const exper = Date.parse(localStorage.getItem("expiracao"));

    if (Date.now() > exper) {
      Logout();
    }
  }, [])

  const Private = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setLogin(true);
    }
  }

  return (
    <>
      {
        login ? (
          <AnimatePresence>
            <Routes>
              {/* <Route path="/*" element={<Login />} />
              <Route path="/login" element={<Login />} /> */}
              <Route path="/*" element={<LoginV2 />} />
              <Route path="/login" element={<LoginV2 />} />
              <Route path="/emailSenha" element={<CodigoEmail />} />
              <Route path="/recuperarSenha" element={<RecuperarSenha />} />
              <Route path="/cadastroUsuario" element={<CadastroUsuario />} />
              <Route path="/salvarSenha" element={<SalvaSenha />} />
              <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
            </Routes>
          </AnimatePresence>
        ) : (
          <AnimatePresence>
            <Routes>
              <Route path="/cadastrar-usuario" element={<CadastrarUsuario />} />
              <Route path="/solicitacoes" element={<Solicitacoes />} />
              <Route path="/cadastroUsuario" element={<CadastroUsuario />} />
              <Route path="/salvarSenha" element={<SalvaSenha />} />
              <Route path="/alterarSenha" element={<AlteraSenha />} />
              <Route path="/emailSenha" element={<CodigoEmail />} />
              <Route path="/recuperarSenha" element={<RecuperarSenha />} />
              <Route path="/envia-email" element={<EnviarEmail />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/notificacoes" element={<Notificacoes />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/segundo-dashboard" element={<SegundoDashboard />} />
              <Route path="/terceiro-dashboard" element={<TerceiroDashboard />} />
              <Route path="/quarto-dashboard" element={<QuartoDashboard />} />
              <Route path="/quinto-dashboard" element={<QuintoDashboard />} />
              <Route path="/dashboard-cockpit" element={<CockpitDashboard />} />
              <Route path="/editar-usuario" element={<EditarUsuario />} />
              <Route path="/dados-usuario" element={<AutoEditar />} />
              <Route path="/cliente" element={<Cliente />} />
              <Route path="/cockpit" element={<Cockpit />} />
              <Route path="/grupo-clientes" element={<GrupoClientes />} />
              <Route path="/vincular-clientes" element={<GrupoComClientes />} />
              <Route path="/embarque" element={<Embarque />} />
              {/* <Route path="/embarque-toledo" element={<Embarque />} />
              <Route path="/embarque-gloria" element={<Embarque />} />
              <Route path="/embarque-paranavai" element={<Embarque />} />
              <Route path="/embarque-rio-verde" element={<Embarque />} />
              <Route path="/embarque-frota" element={<Embarque />} />
              <Route path="/embarque-template" element={<Embarque />} /> */}
              {/* <Route path="/*" element={<Home />} /> */}
              <Route path="/*" element={<HomeV2 />} />
              <Route path="/motorista" element={<Motorista />} />
              <Route path="/pedido" element={<Pedido />} />
              <Route path="/rota" element={<Rota />} />
              <Route path="/cidade-estado" element={<CidadeEstado />} />
              <Route path="/embalagem" element={<Embalagem />} />
              <Route path="/empresa-subsidiaria" element={<EmpresaSubsidiaria />} />
              <Route path="/filial" element={<Filial />} />
              <Route path="/meio-pagamento" element={<MeioPagamento />} />
              <Route path="/status" element={<Status />} />
              {/* <Route path="/unidade-embarque" element={<UnidadeEmbarque />} /> */}
              <Route path="/motivo" element={<Motivo />} />
              <Route path="/configuracoes-cores" element={<ConfiguracoesCores />} />
              <Route path="/configuracoes-coluna" element={<ConfiguracoesColunas />} />
              {/* <Route path="/selecionar-empresa" element={<SelecionarEmpresa />} /> */}
              <Route path="/taxa-icms" element={<TaxaIcms />} />
              <Route path="/proprietario" element={<Proprietario />} />
              <Route path="/mercadoria" element={<Mercadoria />} />
              <Route path="/caminhoes" element={<Caminhao />} />
              <Route path="/carretas" element={<Carreta />} />
              <Route path="/conjuntos" element={<Veiculo />} />
              <Route path="/faturamento" element={<Faturamento />} />
              <Route path="/avisos" element={<Avisos />} />
              <Route path="/checkagem" element={<Checkagem />} />
              <Route path="/frota" element={<Frota />} />
              <Route path="/adicionar-check-veiculo" element={<FrotaAdiciona />} />
              <Route path="/tipo-checkagem" element={<TipoCheckagem />} />
              <Route path="/cadastro-check" element={<CrudCheck />} />
              <Route path="/cadastro-check-adiciona" element={<CrudCheckAdiciona />} />
              <Route path="/ajuda" element={<Ajuda />} />
              <Route path="/veiculos-vencidos" element={<VeiculosVencidos />} />
              <Route path="/cadastro-check-vencidos" element={<CrudCheckVencido />} />
              <Route path="/tipo-rastreamento" element={<TipoRastreamento />} />
              <Route path="/rastreamento" element={<Rastreamento />} />
              <Route path="/rastrear" element={<Rastrear />} />
              <Route path="/precificacao" element={<ValorEmbarque />} />
              <Route path="/motivo-ontime" element={<MotivoOnTime />} />
              <Route path="/ontime" element={<OnTime />} />
              <Route path="/ontime-embarque" element={<OnTimeEmbarque />} />
              <Route path="/ontime-entrega" element={<OnTimeEntrega />} />
              <Route path="/grupo-precificacao" element={<GrupoPrecificacao />} />
              <Route path="/painel" element={<Painel />} />
              <Route path="/grupo-cargas" element={<GrupoCarga />} />
              <Route path="/grupo-cargas-vinculo" element={<GrupoCargaVinculo />} />
              <Route path="/responsaveis" element={<DashboardResponsaveis />} />
              <Route path="/dashboard-cotacao" element={<DashboardCotacao/>} />
              <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
              <Route path="/cotacao" element={<Cotacao />} />
              <Route path="/tipo-veiculo" element={<TipoVeiculo />} />
              <Route path="/tipo-carroceria" element={<TipoCarroceria />} />
              <Route path="/alertas" element={<Alertas />} />
              <Route path="/motivo-cotacao" element={<MotivoCotacao />} />
              <Route path="/segmento-cliente" element={<SegmentoCliente />} />
              <Route path="/gerenciamento-pedido" element={<GerenciamentoPedido />} />
              <Route path="/ocorrencia" element={<Ocorrencia />} />
              <Route path="/tipo-ocorrencia" element={<TipoOcorrencia />} />
              <Route path="/sub-tipo-ocorrencia" element={<SubTipoOcorrencia />} />
              <Route path="/dashboard-ocorrencias" element={<DashboardOcorrencias />} />
              <Route path="/relatorio-proprietarios" element={<RelatorioProprietarios />} />
            </Routes>
          </AnimatePresence>
        )}
    </>
  );
}

export default AnimatedRoutes;

import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraMeioPagamento = async (meioPagamento, token) => {
  return api.post("MeioPagamento",
    meioPagamento
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getMeioPagamento = async (token) => {
  return api.get("MeioPagamento",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getMeioPagamentoPorId = async (id, token) => {
  return api.get("MeioPagamento/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteMeioPagamentoPorId = async (id, token) => {
  return api.delete("MeioPagamento/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaMeioPagamentoPorId = async (meioPagamento, token) => {
  return api.put("MeioPagamento",
    meioPagamento,
    {
      headers: {
        'Authorization': token
      }
    })
}
import { Model } from "./Base.js";

export class TaxaIcmsModel extends Model {
    get defaults() {
        return {
            taxa: "",
            ufOrigem: "",
            ufDestino: "",
        };
    }
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const loginUser = async (login, password, tenant) => {
  return api.post("usuario/authenticar", { usuario: login, senha: password },
    {
      headers: {
        'X-Tenant': tenant
      }
    }
  );
}

export const saveUser = async (data, token) => {
  return api.post("usuario", data
    , {
      headers: {
        'Authorization': token,
      }
    });
}

export const logoutUser = async (id, token) => {
  return api.post("usuario/logout/" + id
    , {}, {
    headers: {
      'Authorization': token
    }
  });
}

export const editaUser = async (data, token) => {
  return api.put("usuario", data
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const autoEditaUser = async (id, data, token) => {
  return api.put("usuario/auto/editarUsuario/" + id, data
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const atualizaRegra = async (data, id, token) => {
  return api.put("usuario/editaRegras/" + id, data
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const deletaUser = async (id, token) => {
  return api.delete("usuario/" + id
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const resetSenhaUser = async (id, token) => {
  return api.post("usuario/resetaSenha/" + id
    , {}, {
    headers: {
      'Authorization': token
    }
  });
}

export const getUser = async (token) => {
  return api.get("usuario"
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const getUserClientes = async (token) => {
  return api.get("UsuariosClientes"
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const vincularUsuario = async (usuario, token) => {
  return api.post("UsuariosClientes/vincularUsuario", usuario
    , {
      headers: {
        'Authorization': token,
      }
    });
}

export const getUserId = async (id, token) => {
  return api.get(`usuario/${id}`
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const buscaRegras = async (id, token) => {
  return api.get('/regras/regrasUsuario/' + `${id}`
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const alteraSenhaUsuario = async (login, senhaAtual, novaSenha, token) => {
  return api.post(`usuario/alterarSenha/${login}/${senhaAtual}/${novaSenha}`, null
    , {
      headers: {
        'Authorization': token
      }
    });
}

export const verificaDadosUsuarioRedux = async (dados, set) => {
  if (dados?.token === '' && dados?.token != null) {
    const usuario = localStorage.getItem("usuario");
    const token = localStorage.getItem("token");
    const expiracao = localStorage.getItem("expiracao");
    const usuarioFormatado = JSON.parse(usuario);
    dados.usuario = usuarioFormatado;
    dados.token = token;
    dados.expiracao = expiracao;
    if (usuarioFormatado?.permissoes != null) {
      dados.permissoes = usuarioFormatado.permissoes;
    } else {
      dados.permissoes = [''];
    }
    if (usuarioFormatado?.idUsuario) {
      buscaRegras(usuarioFormatado.idUsuario, token).then((res) => {
        const regras = [];
        res.data.forEach((element) => {
          regras.push(element.regras.regra);
        })
        dados.regras = regras;
        set(dados);
      });
    }
  }
}


import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraEmbalagem = async (embalagem, token) => {
  return api.post("embalagem",
    embalagem
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getEmbalagem = async (token) => {
  return api.get("embalagem",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getEmbalagemPorId = async (id, token) => {
  return api.get("embalagem/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteEmbalagemPorId = async (id, token) => {
  return api.delete("embalagem/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaEmbalagemPorId = async (embalagem, token) => {
  return api.put("embalagem",
    embalagem,
  {
    headers: {
      'Authorization': token
    }
  })
}
import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import InputLabel from "@mui/material/InputLabel";
import "./Rastreamento.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getRastreamento, cadastraRastreamento, editaRastreamentoPorId, getRastreamentoPorId, deleteRastreamentoPorId, getRastreamentoEDependencias, getRastreamentoSearch } from "../../../services/ModuloRastreamento/Rastreamento";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { FormControl } from "@mui/material";
import Select from 'react-select';
import SelectEmbarque from "../../../components/SelectEmbarque/SelectEmbarque";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2";
import NewIcon from "../../../components/NewIcon/NewIcon";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2.js";
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import FiltrosRedux from "../../../contexts/Filtros.js";
import { useLocation } from "react-router-dom";

function Rastreamento(props) {

    const opcoes = [
        {
            'opcao': 'Caminhão',
            'valor': 1
        },
        {
            'opcao': 'Carreta',
            'valor': 2
        }
    ];

    const opcoesFabricanteSelect = [
        {
            'opcao': 'AUTOTRAC',
            'valor': 1
        },
        {
            'opcao': 'CONTROLSAT',
            'valor': 2
        },
        {
            'opcao': 'OMNILINK',
            'valor': 3
        },
        {
            'opcao': 'ONIXSAT',
            'valor': 4
        },
        {
            'opcao': 'RODOSIS',
            'valor': 5
        },
        {
            'opcao': 'AUTOCARGO',
            'valor': 6
        },
        {
            'opcao': 'CONTROLLOC',
            'valor': 7
        },
        {
            'opcao': 'TRANSPONDER',
            'valor': 9
        },
        {
            'opcao': 'SASCAR',
            'valor': 10
        },
        {
            'opcao': 'CIELO',
            'valor': 11
        },
        {
            'opcao': 'ALFATEST',
            'valor': 13
        },
        {
            'opcao': 'CARRIERWEB',
            'valor': 15
        },
        {
            'opcao': 'AUTOTRAC_CELULAR',
            'valor': 16
        },
        {
            'opcao': 'AUTOSAT',
            'valor': 17
        },
        {
            'opcao': 'CHIPSAT',
            'valor': 18
        },
        {
            'opcao': 'CRONOS',
            'valor': 22
        },
        {
            'opcao': 'SIGHRA',
            'valor': 23
        },
        {
            'opcao': 'PÓSITRON',
            'valor': 27
        },
        {
            'opcao': 'TALTO',
            'valor': 28
        },
        {
            'opcao': 'CERTASAT',
            'valor': 29
        },
        {
            'opcao': 'WEBTRAC',
            'valor': 30
        },
        {
            'opcao': 'VIGITRACK',
            'valor': 32
        },
        {
            'opcao': 'VELTEC',
            'valor': 33
        },
        {
            'opcao': 'COMMANDERSAT',
            'valor': 34
        },
        {
            'opcao': 'CAMARGOSAT',
            'valor': 35
        },
        {
            'opcao': 'SIMTRACK',
            'valor': 36
        },
        {
            'opcao': 'CARGO_TRACCK',
            'valor': 37
        },
        {
            'opcao': 'S3',
            'valor': 38
        },
        {
            'opcao': 'TRACSYSTEM',
            'valor': 39
        },
        {
            'opcao': 'LINKER',
            'valor': 40
        },
        {
            'opcao': 'SITRACK',
            'valor': 41
        },
        {
            'opcao': 'CEABS',
            'valor': 42
        },
        {
            'opcao': 'TRACKER',
            'valor': 43
        },
        {
            'opcao': 'BRASILTRACK',
            'valor': 44
        },
        {
            'opcao': 'APISULMOB',
            'valor': 45
        },
        {
            'opcao': 'RASTRO',
            'valor': 46
        },
        {
            'opcao': 'POINT_TRACK',
            'valor': 47
        },
        {
            'opcao': 'GSSAT',
            'valor': 48
        },
        {
            'opcao': 'IBOR',
            'valor': 49
        },
        {
            'opcao': 'WORLD_SAT',
            'valor': 50
        },
        {
            'opcao': 'FORT_RASTREAMENTO',
            'valor': 51
        },
        {
            'opcao': 'KMM',
            'valor': 52
        },
        {
            'opcao': 'CREARE',
            'valor': 53
        },
        {
            'opcao': 'TRAC_GOLD ',
            'valor': 54
        },
        {
            'opcao': 'T4S',
            'valor': 55
        },
        {
            'opcao': 'POLITRACKER ',
            'valor': 56
        },
        {
            'opcao': 'WR_SAT ',
            'valor': 57
        },
        {
            'opcao': 'AUTOVISION',
            'valor': 58
        },
        {
            'opcao': 'MILLENNIUM',
            'valor': 59
        },
        {
            'opcao': 'RSAT',
            'valor': 60
        },
        {
            'opcao': 'SIGA',
            'valor': 61
        },
        {
            'opcao': 'TEC_SZ_LORA ',
            'valor': 62
        },
        {
            'opcao': 'RAVEX',
            'valor': 63
        },
        {
            'opcao': 'TBV',
            'valor': 64
        },
        {
            'opcao': 'BLOCKSAT',
            'valor': 65
        }
    ];

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const labelVeiculo = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFabricante = opcoesFabricanteSelect.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const opcoesVeiculo = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloVeiculo = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const estiloVeiculoErro = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid red',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    };

    const [valuesSelect, setValuesSelect] = useState([]);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        senha: "",
        usuario: "",
        tipoVeiculo: 0,
        idTipoRastreamento: 0,
        idVeiculo: 0,
        usuarioRastreamento: "",
        fabricanteRastreamento: 0,
        numeroRastreador: "",
    });

    const [validator, setValidator] = useState([]);

    const [listaCaminhoes, setListaCaminhoes] = useState([]);

    const [listaCarretas, setListaCarretas] = useState([]);

    const [listaTipoRastreamento, setListaTipoRastreamento] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [errors, setErrors] = useState([]);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [disable, setDisable] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const [openCadastroCaminhao, setOpenCadastroCaminhao] = useState(false);

    const handleCloseCadastroCaminhao = (value) => {
        if (idAtual) {
            setOpenCadastroCaminhao(false);
        }
    };

    const [openCadastroCarreta, setOpenCadastroCarreta] = useState(false);

    const handleCloseCadastroCarreta = (value) => {
        if (idAtual) {
            setOpenCadastroCarreta(false);
        }
    };

    const preencherDadosEditando = async (result) => {
        const tipoVeiculoSelecionado = opcoes.find(opcao => opcao.valor === result.tipoVeiculo);
        if (tipoVeiculoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoVeiculo: {
                    label: tipoVeiculoSelecionado.opcao,
                    opcao: tipoVeiculoSelecionado.opcao,
                    valor: tipoVeiculoSelecionado.valor,
                    value: tipoVeiculoSelecionado.opcao,
                }
            }));
        }
        const fabricanteRastreamentoSelecionado = opcoesFabricanteSelect.find(opcao => opcao.valor === result.fabricanteRastreamento);
        if (fabricanteRastreamentoSelecionado) {
            setValuesSelect(current => ({ ...current, fabricanteRastreamento: fabricanteRastreamentoSelecionado }));
        }
        const tipoRastreamentoSelecionado = listaTipoRastreamento.find(option => option.idTipoRastreamento === result.idTipoRastreamento);
        if (tipoRastreamentoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                idTipoRastreamento: {
                    value: tipoRastreamentoSelecionado.nome,
                    label: tipoRastreamentoSelecionado.nome,
                    id: tipoRastreamentoSelecionado.idTipoRastreamento
                }
            }));
        }
        if (result.tipoVeiculo === 1) {
            const caminhaoSelecionado = listaCaminhoes.find(option => option.idCaminhao === result.idVeiculo);
            if (caminhaoSelecionado) {
                setValuesSelect(current => ({
                    ...current,
                    idVeiculo: {
                        value: caminhaoSelecionado.placa,
                        label: caminhaoSelecionado.placa,
                        id: caminhaoSelecionado.idCaminhao
                    }
                }));
            }
        }
        if (result.tipoVeiculo === 2) {
            const carretaSelecionado = listaCarretas.find(option => option.idCarreta === result.idVeiculo);
            if (carretaSelecionado) {
                setValuesSelect(current => ({
                    ...current,
                    idVeiculo: {
                        value: carretaSelecionado.placa,
                        label: carretaSelecionado.placa,
                        id: carretaSelecionado.idCarreta
                    }
                }));
            }
        }
    };

    const buscarRastreamento = async () => {
        setLoading(true);
        await getRastreamentoEDependencias(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data.rastreamentos);
            setDadosTabelaCopia(result.data.rastreamentos);
            setListaCaminhoes(result.data.caminhaos);
            setListaCarretas(result.data.carretas);
            setListaTipoRastreamento(result.data.tipoRastreamentos);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async (botaoCadastrarValidacao) => {
        setLoading(true);
        await cadastraRastreamento(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarRastreamento();
                setDadosRequestControllerRedux("Fechar Modal Rastreamento");
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setValues({
                    senha: "",
                    usuario: "",
                    tipoVeiculo: 0,
                    idTipoRastreamento: 0,
                    idVeiculo: 0,
                    usuarioRastreamento: "",
                    fabricanteRastreamento: 0,
                    numeroRastreador: "",
                });
                setValuesSelect({});

                if (!botaoCadastrarValidacao) {
                    setTelaCadastro(false);
                }
            }
        }).catch((error) => {
            const errorData = error.response.data;
            const newErrors = errorData.reduce((acc, curr) => {
                curr.memberNames.forEach((name) => {
                    acc[name] = curr.errorMessage;
                });
                return acc;
            }, {});
            setErrors(newErrors);
            setTextoErro("Há campos obrigatórios a preencher!");
            setOpenToastErro(true);
            setLoading(false);
        });
    }


    const editar = async () => {
        setLoading(true);
        await editaRastreamentoPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarRastreamento();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setTelaCadastro(false);
                setEditando(false);
                setValues({
                    senha: "",
                    usuario: "",
                    tipoVeiculo: 0,
                    idTipoRastreamento: 0,
                    idVeiculo: 0,
                    usuarioRastreamento: "",
                    fabricanteRastreamento: 0,
                    numeroRastreador: "",
                });
                setValuesSelect({});
            }
        }).catch((error) => {
            const errorData = error.response.data;
            const newErrors = errorData.reduce((acc, curr) => {
                curr.memberNames.forEach((name) => {
                    acc[name] = curr.errorMessage;
                });
                return acc;
            }, {});

            setErrors(newErrors);
            setTextoErro("Há campos obrigatórios a preencher!");
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const buscarRastreamentoPorId = async () => {
        setLoading(true);
        await getRastreamentoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result.data);
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarRastreamentoPorIdModal = async (id) => {
        setLoading(true);
        await getRastreamentoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarRastreamentoPorId = async () => {
        setLoading(true);
        await deleteRastreamentoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarRastreamento();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, usuarioRastreamento: "false" }));
        setValidator(current => ({ ...current, fabricanteRastreamento: "false" }));
        setValidator(current => ({ ...current, numeroRastreador: "false" }));
        setValidator(current => ({ ...current, senha: "false" }));
        setValidator(current => ({ ...current, tipoVeiculo: "false" }));
        setValidator(current => ({ ...current, idTipoRastreamento: "false" }));
        setValidator(current => ({ ...current, idVeiculo: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarRastreamentoPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarRastreamento();
        if (props.idAtual) {
            buscarRastreamentoPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Configurações de Rastreamento') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Configurações de Rastreamento') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    const clearTipoRastreamento = () => {
        setValuesSelect(current => ({ ...current, idTipoRastreamento: '' }));
        setValues(current => ({ ...current, idTipoRastreamento: 0 }));
        setValidator(current => ({ ...current, idTipoRastreamento: "false" }));
        if (errors.idTipoRastreamento) {
            setErrors(current => ({
                ...current, idTipoRastreamento:
                    "O identificador de Tipo de Rastreamento deve ser um valor positivo"
            }));
        }
    };

    const clearVeiculo = () => {
        if (values.tipoVeiculo === 1) {
            setValuesSelect(current => ({ ...current, idVeiculo: '' }));
            setValues(current => ({ ...current, idVeiculo: 0 }));
            setValidator(current => ({ ...current, idVeiculo: "false" }));
            if (errors.TipoVeiculo) {
                setErrors(current => ({ ...current, TipoVeiculo: "Tipo de veículo inválido." }));
            }
        }
        if (values.tipoVeiculo === 2) {
            setValuesSelect(current => ({ ...current, idVeiculo: '' }));
            setValues(current => ({ ...current, idVeiculo: 0 }));
            setValidator(current => ({ ...current, idVeiculo: "false" }));
            if (errors.TipoVeiculo) {
                setErrors(current => ({ ...current, TipoVeiculo: "Tipo de veículo inválido." }));
            }
        }
    };

    const itensFiltro = [
        {
            'nomeColuna': 'Usuário Rastreamento',
            'field': 'usuarioRastreamento'
        },
        {
            'nomeColuna': 'Senha',
            'field': 'senha'
        },
        {
            'nomeColuna': 'Tipo Veículo',
            'field': 'tipoVeiculo'
        },
        {
            'nomeColuna': 'Tipo Rastreamento',
            'field': 'tipoRastreamento',
        },
        {
            'nomeColuna': 'Veículo',
            'field': 'veiculo',
        },
        {
            'nomeColuna': 'Fabricante Rastreamento',
            'field': 'fabricanteRastreamento'
        },
        {
            'nomeColuna': 'Número Rastreador',
            'field': 'numeroRastreador'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getRastreamentoSearch(dadosUsuarioRedux.token, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Cadastro Rastreio" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Cadastro Rastreio" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Rastreio</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="id"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.Rastreamento}
                                                dados={dadosTabela}
                                                listaTipos={opcoes}
                                                listaTipos2={opcoesFabricante} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Rastreamento /"}</p>
                                                        <p className="indicador-emb">{"Cadastro de Rastreamento"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Rastreamento" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({
                                                                    senha: "",
                                                                    usuario: "",
                                                                    tipoVeiculo: 0,
                                                                    idTipoRastreamento: 0,
                                                                    idVeiculo: 0,
                                                                    usuarioRastreamento: "",
                                                                    fabricanteRastreamento: 0,
                                                                    numeroRastreador: "",
                                                                }); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Usuário Rastreamento:</p>
                                                                <input
                                                                    type="text"
                                                                    className={`${errors.UsuarioRastreamento ? 'validacao-erro' : 'input-emb'}`}
                                                                    style={{ width: '265px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, usuarioRastreamento: e.target.value }));
                                                                        setValidator(current => ({ ...current, usuarioRastreamento: "true" }));
                                                                        if (errors.UsuarioRastreamento) {
                                                                            setErrors(current => ({ ...current, UsuarioRastreamento: null }));
                                                                        }
                                                                    }}
                                                                    placeholder="Ex: João"
                                                                    value={values.usuarioRastreamento}
                                                                    disabled={props.desabilitar} />
                                                                {errors.UsuarioRastreamento && <div className="error-text">{errors.UsuarioRastreamento}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Senha:</p>
                                                                <input
                                                                    type="text"
                                                                    style={{ width: '265px' }}
                                                                    className={`${errors.Senha ? 'validacao-erro' : 'input-emb'}`}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, senha: e.target.value }));
                                                                        setValidator(current => ({ ...current, senha: "true" }));
                                                                        if (errors.Senha) {
                                                                            setErrors(current => ({ ...current, Senha: null }));
                                                                        }
                                                                    }}
                                                                    placeholder="******"
                                                                    value={values.senha}
                                                                    disabled={props.desabilitar} />
                                                                {errors.Senha && <div className="error-text">{errors.Senha}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Tipo Veículo:</p>
                                                                <FormControl sx={{ width: 265 }}>
                                                                    <Select
                                                                        value={valuesSelect.tipoVeiculo}
                                                                        options={opcoesVeiculo}
                                                                        formatOptionLabel={labelVeiculo}
                                                                        styles={errors.TipoVeiculo ? estiloVeiculoErro : estiloVeiculo}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    tipoVeiculo: selectedOption.valor,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, tipoVeiculo: selectedOption }));
                                                                                setValidator(current => ({ ...current, tipoVeiculo: "true" }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {errors.TipoVeiculo && <div className="error-text">{errors.TipoVeiculo}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Tipo Rastreamento:</p>
                                                                <SelectEmbarqueV2
                                                                    classeCss={265}
                                                                    onChange={(e) => {
                                                                        setValuesSelect(current => ({ ...current, idTipoRastreamento: e }));
                                                                        setValues(current => ({ ...current, idTipoRastreamento: e.id }));
                                                                        setValidator(current => ({ ...current, idTipoRastreamento: "true" }));
                                                                        if (errors.idTipoRastreamento) {
                                                                            setErrors(current => ({ ...current, idTipoRastreamento: null }));
                                                                        }
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setDisable(true);
                                                                        setOpenCadastroCaminhao(true);
                                                                    }}
                                                                    clearSelection={clearTipoRastreamento}
                                                                    onInfoIconClick={setIdAtual}
                                                                    id="idTipoRastreamento"
                                                                    value={valuesSelect.idTipoRastreamento}
                                                                    titulo="Tipo Rastreamento"
                                                                    dadosSelect={listaTipoRastreamento}
                                                                    grande={true}
                                                                    referenciaId="nome"
                                                                    referenciaItem="nome"
                                                                    disabled={props.desabilitar}
                                                                    erro={errors.idTipoRastreamento} />
                                                                {errors.idTipoRastreamento && <div className="error-text">{errors.idTipoRastreamento}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Fabricante Rastreamento:</p>
                                                                <FormControl sx={{ width: 265 }}>
                                                                    <Select
                                                                        value={valuesSelect.fabricanteRastreamento}
                                                                        options={opcoesFabricante}
                                                                        formatOptionLabel={labelVeiculo}
                                                                        styles={estiloVeiculo}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    fabricanteRastreamento: selectedOption.valor,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, fabricanteRastreamento: selectedOption }));
                                                                                setValidator(current => ({ ...current, fabricanteRastreamento: "true" }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Número Rastreador:</p>
                                                                <input
                                                                    type="text"
                                                                    style={{ width: '265px' }}
                                                                    className={`${errors.NumeroRastreador ? 'validacao-erro' : 'input-emb'}`}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, numeroRastreador: e.target.value }));
                                                                        setValidator(current => ({ ...current, numeroRastreador: "true" }));
                                                                        if (errors.NumeroRastreador) {
                                                                            setErrors(current => ({ ...current, NumeroRastreador: null }));
                                                                        }
                                                                    }}
                                                                    placeholder="Ex: 1111"
                                                                    value={values.numeroRastreador}
                                                                    disabled={props.desabilitar} />
                                                                {errors.NumeroRastreador && <div className="error-text">{errors.NumeroRastreador}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            {
                                                                values.tipoVeiculo != 1 && values.tipoVeiculo != 2 ? (
                                                                    <></>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            values.tipoVeiculo === 1 ? (
                                                                                <>
                                                                                    <div>
                                                                                        <InputLabel htmlFor="ip"><Text texto={valuesSelect.tipoVeiculo.label} /></InputLabel>
                                                                                        <SelectEmbarqueV2
                                                                                            onChange={(e) => {
                                                                                                setValuesSelect(current => ({ ...current, idVeiculo: e }));
                                                                                                setValues(current => ({ ...current, idVeiculo: e.id }));
                                                                                                setValidator(current => ({ ...current, idVeiculo: "true" }));
                                                                                                if (errors.TipoVeiculo) {
                                                                                                    setErrors(current => ({ ...current, TipoVeiculo: null }));
                                                                                                }
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setDisable(true);
                                                                                                setOpenCadastroCaminhao(true);
                                                                                            }}
                                                                                            clearSelection={clearVeiculo}
                                                                                            onInfoIconClick={setIdAtual}
                                                                                            id="idCaminhao"
                                                                                            value={valuesSelect.idVeiculo}
                                                                                            titulo="Placa Cavalo"
                                                                                            dadosSelect={listaCaminhoes}
                                                                                            grande={true}
                                                                                            referenciaId="placa"
                                                                                            referenciaItem="placa"
                                                                                            disabled={props.desabilitar}
                                                                                            erro={errors.TipoVeiculo} />
                                                                                        {errors.TipoVeiculo && <div className="error-text">{errors.TipoVeiculo}</div>}
                                                                                    </div>
                                                                                    <NewIcon
                                                                                        onClick={(e) => {
                                                                                            setOpenCadastroCaminhao(true); setIdAtual(null); setDisable(false);
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div>
                                                                                        <InputLabel htmlFor="ip"><Text texto={valuesSelect.tipoVeiculo.label} /></InputLabel>
                                                                                        <SelectEmbarqueV2
                                                                                            onChange={(e) => {
                                                                                                setValuesSelect(current => ({ ...current, idVeiculo: e }));
                                                                                                setValues(current => ({ ...current, idVeiculo: e.id }));
                                                                                                setValidator(current => ({ ...current, idVeiculo: "true" }));
                                                                                                if (errors.TipoVeiculo) {
                                                                                                    setErrors(current => ({ ...current, TipoVeiculo: null }));
                                                                                                }
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setDisable(true);
                                                                                                setOpenCadastroCarreta(true);
                                                                                            }}
                                                                                            clearSelection={clearVeiculo}
                                                                                            onInfoIconClick={setIdAtual}
                                                                                            id="idCarreta"
                                                                                            value={valuesSelect.idVeiculo}
                                                                                            titulo="Placa Carreta"
                                                                                            dadosSelect={listaCarretas}
                                                                                            grande={true}
                                                                                            referenciaId="placa"
                                                                                            referenciaItem="placa"
                                                                                            disabled={props.desabilitar}
                                                                                            erro={errors.TipoVeiculo} />
                                                                                        {errors.TipoVeiculo && <div className="error-text">{errors.TipoVeiculo}</div>}
                                                                                    </div>
                                                                                    <NewIcon
                                                                                        onClick={(e) => {
                                                                                            setOpenCadastroCarreta(true); setIdAtual(null); setDisable(false);
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-tipoRastreamento">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => { cadastrar(props.botaoCadastrar); }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => { cadastrar(props.botaoCadastrar); }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => {
                                                                setValues({
                                                                    senha: "",
                                                                    usuario: "",
                                                                    tipoVeiculo: 0,
                                                                    idTipoRastreamento: 0,
                                                                    idVeiculo: 0,
                                                                    usuarioRastreamento: "",
                                                                    fabricanteRastreamento: 0,
                                                                    numeroRastreador: "",
                                                                }); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarRastreamentoPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCaminhao="sim"
                                        open={openCadastroCaminhao}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCaminhao}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCarreta="sim"
                                        open={openCadastroCarreta}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCarreta}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Rastreamento;
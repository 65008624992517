import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalLancarMercadoria/ModalLancarMercadoria.css';
import CoresRedux from "../../contexts/Cor.js";
import TabelaRedux from "../../contexts/Tabela.js";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2.js";
import { valorMask } from "../../regex/valorMask.js";
import NewIcon from "../NewIcon/NewIcon.js";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2.js";
import RequestControllerRedux from "../../contexts/RequestController.js";
import ModalCadastro from "../ModalCadastro/ModalCadastro.tsx";
import LoadingBar from "../LoadingBar/LoadingBar.js";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaMercadoria: any[];
    onClose: () => void;
    onClick: () => void;
    setMercadoria: (e) => void;
}

function ModalLancarMercadoria(props: SimpleDialogProps) {

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const [values, setValues] = useState<any>([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    const clearMercadoria = () => {
        setValuesSelect(current => ({ ...current, mercadoria: '' }));
        setValues(current => ({ ...current, mercadoria: '' }));
        setValues(current => ({ ...current, mercadoriaId: null }));
    };

    const [idAtual, setIdAtual] = useState(0);

    const [disable, setDisable] = useState(false);

    const [openCadastroMercadoria, setOpenCadastroMercadoria] = useState(false);

    const [loadingSelectMercadoria, setLoadingSelectMercadoria] = useState(false);

    useEffect(() => {
        setLoadingSelectMercadoria(true);
        setTimeout(() => {
            setLoadingSelectMercadoria(false);
        }, 1000);
    }, [props.listaMercadoria]);

    useEffect(() => {
        const mercadoriaSelecionado = props.listaMercadoria.find(option => option.nome === tabelaRedux?.row?.mercadoria);
        if (mercadoriaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                mercadoria: {
                    value: mercadoriaSelecionado.nome,
                    label: mercadoriaSelecionado.nome,
                    id: mercadoriaSelecionado.idMercadoria
                }
            }));
            setValues(current => ({ ...current, mercadoriaId: mercadoriaSelecionado.idMercadoria }));
        }
    }, [tabelaRedux]);

    const handleCloseCadastroMercadoria = () => {
        if (idAtual) {
            setOpenCadastroMercadoria(false);
            setDadosRequestControllerRedux("Close Mercadoria");
        } else {
            setDadosRequestControllerRedux("Close Mercadoria");
            setOpenCadastroMercadoria(false);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-veiculo">
                        <DialogContentText id="alert-dialog-description">
                            <div className="usuario-data-row">
                                {
                                    loadingSelectMercadoria ? (
                                        <div className="coluna-modalmotivo">
                                            <LoadingBar />
                                        </div>
                                    ) : (
                                        <div className="coluna-modalmotivo">
                                            <div>
                                                <p className="input-label-emb">Mercadoria:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={225}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, mercadoria: e }));
                                                        setValues(current => ({ ...current, mercadoria: e.value }));
                                                        setValues(current => ({ ...current, mercadoriaId: e.id }));
                                                        props.setMercadoria(e.id);
                                                    }}
                                                    clearSelection={clearMercadoria}
                                                    onInfoIconClick={setIdAtual}
                                                    pequeno={true}
                                                    id="idMercadoria"
                                                    value={valuesSelect.mercadoria}
                                                    titulo="Mercadoria"
                                                    dadosSelect={props.listaMercadoria}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroMercadoria(true); setIdAtual(0); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <div>
                <ModalCadastro
                    titulo="Cadastro"
                    tituloConfirmar="OK"
                    cadastroMercadoria="sim"
                    open={openCadastroMercadoria}
                    idAtual={idAtual}
                    desabilitar={disable}
                    onClose={handleCloseCadastroMercadoria}
                />
            </div>
        </div>
    );
}

export default ModalLancarMercadoria;
import React, { useState, createContext } from 'react';

const valorInicial = {
    'headerFooter': '#0946B8',
    'fundoPadrao': '#FFFFFF',
    'botaoSelecionado': '#04192E',
    'botaoPadrao': '#148EFF',
    'textoBotaoSelecionado': '#FFFFFF',
    'textoBotaoPadrao': '#FFFFFF',
    'textosInputs': '#4F4F4F',
    'botaoSecundario': '#A9A9A9',
    'textoBotaoSecundario': '#FFFFFF',
    'tituloColuna': '#FFFFFF',
    'textoTituloColuna': '#232323',
    'bordaTituloColuna': '#000000',
    'coluna': '#FFFFFF',
    'textoColuna': '#4F4F4F',
    'botaoMenu': '#717171',
    'fundoMenu': '#FFFFFF',
    'textoBotaoNormal': '#000000'
}
const Cores = createContext();

export default Cores;

export const CoresRedux = ({ children }) => {
    const [dadosCoresRedux, setDadosCoresRedux] = useState(valorInicial);

    return (
        <Cores.Provider value={{
            dadosCoresRedux,
            setDadosCoresRedux,
        }}>
            {children}
        </Cores.Provider>
    );
};
import React, { useState, createContext } from "react";

const valorInicial = {};

const Filtros = createContext();

export default Filtros;

export const FiltrosRedux = ({ children }) => {
    const [filtrosRedux, setFiltrosRedux] = useState(valorInicial);

    return (
        <Filtros.Provider value={{
            filtrosRedux,
            setFiltrosRedux,
        }}>
            {children}
        </Filtros.Provider>
    );
};
import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Select from 'react-select';
import '../Select/Select.css';
import InfoIcon from '@mui/icons-material/Info';
import '../SelectEmbarque/SelectEmbarque.css';

function SelectEmbarque(props) {

    const [open] = useState(props.open);

    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    const [atual, setAtual] = useState();

    const [disable, setDisable] = useState(false);

    const [filtro, setFiltro] = useState('');

    const filtra = () => {
        var dadosfiltra = props.dadosSelect;
        dadosfiltra.sort(function (a, b) {
            if (a.cidade < b.cidade || a.nome < b.nome || a.nomeFantasia < b.nomeFantasia) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltrados(dadosfiltra);
    }

    useEffect(() => {
        filtra();
        if (props.value) {
            setAtual(props.value.value);
        }
    }, [])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            marginTop: '3px',
            minHeight: '48px',
            border: 'groove',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: '250px',
        }),
    }

    const customStylesGrande = {
        control: (provided) => ({
            ...provided,
            marginTop: '-3px',
            minHeight: '64px',
            border: 'groove',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: '250px',
        }),
    }

    const dadosOrdenados = dadosFiltrados.map(item => ({
        value: item[props.referenciaId],
        label: props.referenciaItem2 ? item[props.referenciaItem][props.referenciaItem2] : item[props.referenciaItem],
        id: item[props.id],
    }));

    const handleChange = option => {
        setAtual(option.value);
        props.onChange(option);
    };

    const formatOptionLabel = ({ label, value, id }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {label}
            {atual !== value &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTimeout(() => {
                            props.onInfoIconClick(id);
                            props.onClick(e);
                        }, 0);
                    }}
                />
            }

        </div>
    );

    return (
        <div className='principal-select'>
            <FormControl sx={{ width: 250 }}>
                <Select
                    value={props.value}
                    onChange={handleChange}
                    styles={props.grande ? customStylesGrande : customStyles}
                    options={dadosOrdenados}
                    isDisabled={props.disabled}
                    formatOptionLabel={formatOptionLabel}
                    placeholder=""
                    noOptionsMessage={() => "Não há mais opções"}
                />
            </FormControl>
        </div>
    );
}

export default SelectEmbarque;
import React, { useContext } from "react";
import styled from "styled-components";
import CoresRedux from "../../contexts/Cor";

function Text(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const TextP = styled.p`     
    color: ${dadosCoresRedux.textosInputs};
    text-align: center;
    `

    return (
        <TextP>{props.texto}</TextP>
    );
}

export default Text;
import React, { useState, createContext } from "react";

const valorInicial = {};

const MultiplosFiltros = createContext();

export default MultiplosFiltros;

export const MultiplosFiltrosRedux = ({ children }) => {
    const [multiplosFiltrosRedux, setMultiplosFiltrosRedux] = useState(valorInicial);

    return (
        <MultiplosFiltros.Provider value={{
            multiplosFiltrosRedux,
            setMultiplosFiltrosRedux,
        }}>
            {children}
        </MultiplosFiltros.Provider>
    );
};
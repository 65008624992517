import React, { useState, createContext, useEffect } from "react";

const valorInicial = [];

const FilialCidade = createContext();

export default FilialCidade;

export const DadosFilialRedux = ({ children }) => {
    const [dadosFilialRedux, setDadosFilialRedux] = useState(() => {
        const dadosSalvos = localStorage.getItem('dadosFilial');
        return dadosSalvos ? JSON.parse(dadosSalvos) : valorInicial;
    });

    // Atualiza o localStorage sempre que `dadosFilialRedux` mudar
    useEffect(() => {
        localStorage.setItem('dadosFilial', JSON.stringify(dadosFilialRedux));
    }, [dadosFilialRedux]);

    return (
        <FilialCidade.Provider value={{
            dadosFilialRedux,
            setDadosFilialRedux,
        }}>
            {children}
        </FilialCidade.Provider>
    );
};
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraStatus = async (status, token) => {
  return api.post("status",
    status
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getStatus = async (token) => {
  return api.get("status",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getStatusPorId = async (id, token) => {
  return api.get("status/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteStatusPorId = async (id, token) => {
  return api.delete("status/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaStatusPorId = async (status, token) => {
  return api.put("status",
    status,
  {
    headers: {
      'Authorization': token
    }
  })
}
import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalEntregas/ModalEntregas.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButton from "../CustomButton/CustomButton";
import Tabela from "../Tabela/Tabela";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    dadosEntregas: any;
    onClose: () => void;
    onClick: () => void;
}

function ModalEntregas(props: SimpleDialogProps) {
    
    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClose();
    };

    const colunas = {
        'Entregas': [
            {
                'nomeColuna': 'Rota',
                'field': 'nomeRota'
            },
            {
                'nomeColuna': 'Cidade Origem',
                'field': 'cidadeOrigemRota'
            },
            {
                'nomeColuna': 'Cidade Destino',
                'field': 'cidadeDestinoRota'
            },
            {
                'nomeColuna': 'Estado Origem',
                'field': 'estadoOrigemRota'
            },
            {
                'nomeColuna': 'Estado Destino',
                'field': 'estadoDestinoRota'
            },
            {
                'nomeColuna': 'Valor',
                'field': 'valor'
            },
            {
                'nomeColuna': 'Tarifa',
                'field': 'tarifa'
            },
            {
                'nomeColuna': 'Peso',
                'field': 'peso'
            },
            {
                'nomeColuna': 'Destinatario',
                'field': 'destinatario'
            },
        ],
    }

    const dadosTabela = [
        {
            nomeRota: "teste",
            cidadeOrigem: "tes2te",
            estadoOrigem: "2324",
            cidadeDestino: "33",
            estadoDestino: "33",
            valor: "te22st",
            tarifa: "123",
            peso: "teste",
        }
    ]

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <Tabela classeSelecionado="idEntrega" nomeClasse1="outer-wrapper-rota" nomeClasse2="table-wrapper"
                        colunas={colunas.Entregas} nome="entrega" dados={props.dadosEntregas} />
                    </DialogContent>
                    <DialogActions>
                        <CustomButton
                            classCss="custom-button"
                            titulo="FECHAR"
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalEntregas;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraRastreamento = async (rastreamento, token) => {
  return api.post("Rastreamento",
    rastreamento
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getRastreamento = async (token) => {
  return api.get("Rastreamento/todos",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getRastreioVeiculo = async (token, idVeiculo, tipoVeiculo) => {
  return api.get("Rastrear/" + idVeiculo + "/" + tipoVeiculo,
  {
    headers: {
      'Authorization': token
    }
  })
}


export const getRastreamentoEDependencias = async (token) => {
  return api.get("GeralRastreamento/rastreioDependencias",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getRastreamentoPorId = async (id, token) => {
  return api.get("Rastreamento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteRastreamentoPorId = async (id, token) => {
  return api.delete("Rastreamento/deletarLogicamente/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaRastreamentoPorId = async (rastreamento, token) => {
  return api.put("Rastreamento/editar",
    rastreamento,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getRastreamentoSearch = async (token, pesquisa) => {
  return api.get("SearchPredicate/search/Rastreamento?" + pesquisa,
      {
          headers: {
              'Authorization': token
          }
      })
}
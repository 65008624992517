import React, { useContext, useEffect, useState } from "react";
import "./TabelaEstatisticas.css";
import iconDeleteLow from "../../images/iconDeleteLow.png";
import iconOpcoesLow from "../../images/iconOpcoesLow.png";
import iconeCalendario from "../../images/iconeCalendario.png";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

function TabelaEstatisticas(props) {

    const meses = Object.keys(props.dados[0].embarquesMes);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const [selecioneOpen, setSelecioneOpen] = useState(false);
    const [opcoesOpenIndex, setOpcoesOpenIndex] = useState(null);

    const handleMouseLeave = (index) => {
        setOpcoesOpenIndex(null);
    };

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };

    useEffect(() => {
        if (props.dados && props.dados.length > 0 && props.dados[0] && props.dados[0].idEmbarque) {
            const tableWrapper = document.getElementById("tableWrapper");
            if (tableWrapper && (window.location.href.endsWith("embarque") && dadosContextoEmbarqueRedux != 'templateEmbarque')) {
                tableWrapper.scrollTop = tableWrapper.scrollHeight;
            }
        }
    }, []);

    return (
        <div class="outer-wrapper-terceiros">
            <div class="table-wrapper-terceiros" id="tableWrapper">
                <p style={{ textAlign: 'center', marginBottom: 10 }}>Relatorio</p>
                <table className="tabela-terceiros">
                    <thead>
                        <tr>
                            <th onClick={() => props.buscarSearch('frota')} className="th-terceiros">Frota</th>
                            <th onClick={() => props.buscarSearch('proprietario')} className="th-terceiros">Proprietário</th>
                            {meses.map(mes => (
                                <th className="th-terceiros" key={mes}>{mes}</th>
                            ))}
                            <th onClick={() => props.buscarSearch('media')} className="th-terceiros">Média</th>
                            <th onClick={() => props.buscarSearch('total')} className="th-terceiros">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.dados.map((linha, index) => (
                            <>
                                <tr
                                    className="tr-hover"
                                    key={index}>
                                    <td className="td-terceiros">
                                        {linha.frota}
                                    </td>
                                    <td className="td-terceiros">
                                        {linha.proprietario}
                                    </td>
                                    {meses.map(mes => (
                                        <td className="td-terceiros" key={mes}>{linha.embarquesMes[mes]}</td>
                                    ))}
                                    <td className="td-terceiros">
                                        {linha.media}
                                    </td>
                                    <td className="td-terceiros">
                                        {linha.total}
                                    </td>
                                </tr >
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export default TabelaEstatisticas;
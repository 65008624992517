import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalInformacao/ModalInformacao.css';
import CustomButton from "../CustomButton/CustomButton";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}

function ModalInformacao(props: SimpleDialogProps) {
    const [open, setOpen] = useState(props.open);


    return (
        <div>
            <Dialog
                open={open}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogActions>
                        <CustomButton
                            titulo={props.tituloBotao}
                            cor={props.tituloBotao === 'OK' ? 'primary' : 'secondary'}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
export default ModalInformacao;
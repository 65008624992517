import { api } from "../ModuloGerenciador/UrlPadrao"

export const getCotacao = async (token) => {
    return api.get("Cotacao/cotacoes",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCotacaoDash = async (token, dataInicio, dataFinal) => {
    return api.get("Cotacao/cotacoes/statusCarregamento/" + dataInicio + "/" + dataFinal,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCotacaoHistoricoPorId = async (id, token) => {
    return api.get("Cotacao/historico/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCotacaoPorId = async (id, token) => {
    return api.get("Cotacao/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const deleteCotacaoPorId = async (id, token) => {
    return api.delete("Cotacao/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCotacaoDependencias = async (token) => {
    return api.get("Cotacao/dependencias",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCotacaoPorUsuarioId = async (id, token) => {
    return api.get("Cotacao/usuario/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const cadastraCotacao = async (Cotacao, token) => {
    return api.post("Cotacao/salvar",
        Cotacao
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const editaCotacaoPorId = async (Cotacao, token) => {
    return api.put("Cotacao/editar",
        Cotacao,
        {
            headers: {
                'Authorization': token
            }
        })
}
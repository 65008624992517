import React, { useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalStatusSMP/ModalStatusSMP.css';
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import CoresRedux from "../../contexts/Cor";
import Veiculo from "../../pages/ModuloGerenciador/Veiculo/Veiculo/Veiculo";

export interface SimpleDialogProps {
    titulo: string;
    texto: string;
    open: boolean;
    textoBotao: string;
    onClose: () => void;
    onClick: () => void;
    statusSMP: any[];
}

function ModalStatusSMP(props: SimpleDialogProps) {

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };

    const { onClose, open, onClick, statusSMP } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
        onClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-status-smp'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-description">
                            {props.texto}
                        </DialogContentText>
                        {/* Mensagens de Aviso */}
                        {statusSMP && statusSMP.length > 0 && (
                            <>
                                {statusSMP.map((status, index) => (
                                    <DialogContentText style={{ textAlign: 'center' }} key={`aviso-${index}`}>
                                        <p>Ano SMP: <span className="p-negrito">{status.anoSMP}</span></p>
                                        <p>CNPJ Embarcador: <span className="p-negrito">{status.cnpjEmbarcador}</span></p>
                                        <p>CNPJ Transportadora: <span className="p-negrito">{status.cnpjTransportadora}</span></p>
                                        <p>Data Início da Viagem: <span className="p-negrito">{formatarData(status.dataInicioViagem)}</span></p>
                                        <p>Documento Controle: <span className="p-negrito">{status.documentoControle}</span></p>
                                        <p>Número SMP: <span className="p-negrito">{status.numeroSMP}</span></p>
                                        <p>Status: <span className="p-negrito">{status.status}</span></p>
                                    </DialogContentText>
                                ))}
                            </>
                        )}
                        {statusSMP && statusSMP.length > 0 && (
                            <>
                                <DialogTitle style={{ textAlign: 'center' }}>Veículo</DialogTitle>
                                {statusSMP[0].veiculos.map((veiculo, index) => (
                                    <DialogContentText style={{ textAlign: 'center' }} key={`aviso-${index}`}>
                                        <p>Placa: <span className="p-negrito">{veiculo.placa}</span></p>
                                    </DialogContentText>
                                ))}
                            </>
                        )}
                        {statusSMP && statusSMP.length > 0 && (
                            <>
                                <DialogTitle style={{ textAlign: 'center' }}>Pontos</DialogTitle>
                                {statusSMP[0].pontos.map((ponto, index) => (
                                    <DialogContentText style={{ textAlign: 'center' }} key={`aviso-${index}`}>
                                        <h5>Ponto <span className="p-negrito">{index + 1}</span></h5>
                                        <p>Identificador: <span className="p-negrito">{ponto.identificador}</span></p>
                                        <p>Rota: <span className="p-negrito">{ponto.apelido}</span></p>
                                        <p>CEP: <span className="p-negrito">{ponto.cep}</span></p>
                                        <p>Cidade: <span className="p-negrito">{ponto.cidade}</span></p>
                                        <p>Estado: <span className="p-negrito">{ponto.estado}</span></p>
                                        <p>Endereço: <span className="p-negrito">{ponto.endereco}</span></p>
                                        <p>CNPJ: <span className="p-negrito">{ponto.cnpj}</span></p>
                                        <p>Previsão Chegada: <span className="p-negrito">{formatarData(ponto.previsaoChegada)}</span></p>
                                    </DialogContentText>
                                ))}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo={props.textoBotao}
                            cor="#DC2D2D"
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalStatusSMP;

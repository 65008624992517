import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InputLabel from "@mui/material/InputLabel";
import ClearIcon from '@mui/icons-material/Clear';
import "../ModalFiltroCidade/ModalFiltroCidade.css"
import CoresRedux from "../../contexts/Cor";
import CustomButton from "../CustomButton/CustomButton";

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}

function ModalFiltroCidade(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const [cidade, setCidade] = useState("");

    const [estado, setEstado] = useState("");

    const { onClose, open, onClick } = props;

    const handleClick = () => {
        const listaBuscas = { cidade: cidade, estado: estado };
        localStorage.setItem('filtros', JSON.stringify(listaBuscas));
        onClose();
    };

    const handleClose = () => { onClose(); };

    const limparFiltros = () => {
        setCidade("");
        setEstado("");
    }


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}>
                <div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className="div-buscas">
                                <div>
                                    <InputLabel htmlFor="cidade">Cidade</InputLabel>
                                    <input
                                        type={'text'}
                                        className='input-text'
                                        value={cidade}
                                        onChange={(e) => { setCidade(e.target.value); }}
                                        id='cidade'>
                                    </input>
                                </div>
                                <div>
                                    <InputLabel htmlFor="estado">Estado</InputLabel>
                                    <input
                                        type={'text'}
                                        className='input-text'
                                        value={estado}
                                        onChange={(e) => { setEstado(e.target.value); }}
                                        id='estado'>
                                    </input>
                                </div>
                                <div className="icone">
                                    <ClearIcon></ClearIcon>
                                    <p className="click-configurar" onClick={() => { limparFiltros() }}>Limpar</p>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="butao">
                        <CustomButton
                            classCss="custom-button"
                            titulo="Cancelar"
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={() => onClose()}
                        />
                        <CustomButton
                            classCss="custom-button"
                            titulo="OK"
                            cor={dadosCoresRedux.botaoPadrao}
                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )

}

export default ModalFiltroCidade;
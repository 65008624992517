import React, { useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalProposta/ModalProposta.css';
import CoresRedux from "../../contexts/Cor";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    texto: string;
    open: boolean;
    listaMotoristas: any;
    listaProprietarios: any;
    listaGrupoCargas: any;
    onClose: () => void;
    onClick: () => void;
    setGrupoCargaId: (e) => void;
    setMotoristasId: (e) => void;
    setProprietariosId: (e) => void;
}

function ModalProposta(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-proposta'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-proposta">
                        <DialogContentText id="alert-dialog-description">
                            <div className="proposta-container">
                                <div className="flex-column">
                                    <p className="input-label-emb">Grupo Carga:</p>
                                    <SelectEmbarqueV2
                                        classeCss={250}
                                        onChange={(e) => {
                                            props.setGrupoCargaId(e.id);
                                        }}
                                        id="id"
                                        titulo="Grupo Carga"
                                        dadosSelect={props.listaGrupoCargas}
                                        referenciaId="nomeGrupo"
                                        referenciaItem="nomeGrupo"
                                        multi={false}
                                    />
                                </div>
                                <div className="flex-column">
                                    <p className="input-label-emb">Motoristas:</p>
                                    <SelectEmbarqueV2
                                        classeCss={250}
                                        onChange={(e: any) => {
                                            let listaIds: any[] = [];
                                            e.forEach((element: any) => {
                                                listaIds.push(element.id);
                                            });
                                            props.setMotoristasId(listaIds);
                                        }}
                                        id="idMotorista"
                                        titulo="Grupo Carga"
                                        dadosSelect={props.listaMotoristas}
                                        referenciaId="nome"
                                        referenciaItem="nome"
                                        multi={true}
                                        info={false}
                                    />
                                </div>
                                <div className="flex-column">
                                    <p className="input-label-emb">Proprietário:</p>
                                    <SelectEmbarqueV2
                                        classeCss={250}
                                        onChange={(e: any) => {
                                            let listaIds: any[] = [];
                                            e.forEach((element: any) => {
                                                listaIds.push(element.id);
                                            });
                                            props.setProprietariosId(listaIds);
                                        }}
                                        id="idProprietario"
                                        titulo="Grupo Carga"
                                        dadosSelect={props.listaProprietarios}
                                        referenciaId="nome"
                                        referenciaItem="nome"
                                        multi={true}
                                        info={false}
                                    />
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="action-proposta">
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="Enviar"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalProposta;
import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import "./Cotacao.css";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getCotacao, cadastraCotacao, editaCotacaoPorId, getCotacaoPorId, deleteCotacaoPorId, getCotacaoDependencias, getCotacaoHistoricoPorId } from "../../../services/ModuloGerenciador/Cotacao";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2.js";
import LoadingBar from "../../../components/LoadingBar/LoadingBar.js";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import NewIcon from "../../../components/NewIcon/NewIcon.js";
import { valorMask } from "../../../regex/valorMask.js";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import Select from 'react-select';
import ModalHistoricoEdicao from "../../../components/ModalHistoricoEdicao/ModalHistoricoEdicao.tsx";
import { telefoneMask } from "../../../regex/telefoneMask.js";

function Cotacao(props) {

    const colunasHistorico = [
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidadeEmbarque',
            'objetoUnidadeEmbarqueNome': 'true'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'rota',
            'objetoRota': 'true'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'objetoCliente': 'true'
        },
        {
            'nomeColuna': 'Valor',
            'field': 'valor',
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms',
        },
        {
            'nomeColuna': 'Pedágio',
            'field': 'pedagio',
        },
        {
            'nomeColuna': 'Distância em KM',
            'field': 'distanciaKm',
        },
        {
            'nomeColuna': 'Valor por KM',
            'field': 'valorPorKm',
        },
        {
            'nomeColuna': 'Valor Desejavel Motorista',
            'field': 'valorDesejavelMotorista',
        },
        {
            'nomeColuna': 'Descrição Rota',
            'field': 'descricaoRota',
        },
        {
            'nomeColuna': 'Foi Carregado',
            'field': 'foiCarregado',
        },
        {
            'nomeColuna': 'Tipo Veiculo',
            'field': 'tipoVeiculo',
            'objetoTipoVeiculo': 'true'
        },
        {
            'nomeColuna': 'Tipo Carroceria',
            'field': 'tipoCarroceria',
            'objetoTipoCarroceria': 'true'
        },
    ];

    const [unidadeEmbarqueValido, setUnidadeEmbarqueValido] = useState(true);

    const [rotaValido, setRotaValido] = useState(true);

    const [clienteValido, setClienteValido] = useState(true);

    const [dataCotacaoValido, setDataCotacaoValido] = useState(true);

    const [valorValido, setValorValido] = useState(true);

    const [icmsValido, setIcmsValido] = useState(true);

    const [pedagioValido, setPedagioValido] = useState(true);

    const [distanciaKmValido, setDistanciaKmValido] = useState(true);

    const [valorKmValido, setValorKmValido] = useState(true);

    const [valorDesejavelMotoristaValido, setValorDesejavelMotoristaValido] = useState(true);

    const [tipoVeiculoValido, setTipoVeiculoValido] = useState(true);

    const [tipoCarroceriaValido, setTipoCarroceriaValido] = useState(true);

    const [segmentoClienteValido, setSegmentoClienteValido] = useState(true);

    const [descricaoRotaValido, setDescricaoRotaValido] = useState(true);

    const [usuarioResponsavelValido, setUsuarioResponsavelValido] = useState(true);

    const [observacaoValido, setObservacaoValido] = useState(true);

    const [foiCarregadoValido, setFoiCarregadoValido] = useState(true);

    const [motivoValido, setMotivoValido] = useState(true);

    const [nomeValido, setNomeValido] = useState(true);

    const [telefoneValido, setTelefoneValido] = useState(true);

    const [emailValido, setEmailValido] = useState(true);

    const limpaValoresValidos = () => {
        setUnidadeEmbarqueValido(true);
        setRotaValido(true);
        setClienteValido(true);
        setDataCotacaoValido(true);
        setValorValido(true);
        setIcmsValido(true);
        setPedagioValido(true);
        setDistanciaKmValido(true);
        setValorKmValido(true);
        setValorDesejavelMotoristaValido(true);
        setTipoVeiculoValido(true);
        setTipoCarroceriaValido(true);
        setSegmentoClienteValido(true);
        setDescricaoRotaValido(true);
        setUsuarioResponsavelValido(true);
        setObservacaoValido(true);
        setFoiCarregadoValido(true);
        setNomeValido(true);
        setTelefoneValido(true);
        setEmailValido(true);
    }

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [listaClientes, setListaClientes] = useState([]);

    const [listaRotas, setListaRotas] = useState([]);

    const [listaTipoCarrocerias, setListaTipoCarrocerias] = useState([]);

    const [listaTipoVeiculo, setListaTipoVeiculo] = useState([]);

    const [listaUnidadeEmbarque, setListaUnidadeEmbarque] = useState([]);

    const [listaUsuarios, setListaUsuarios] = useState([]);

    const [listaMotivos, setListaMotivos] = useState([]);

    const [listaSegmentosCliente, setListaSegmentosCliente] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const [idAtual, setIdAtual] = useState();

    const [disable, setDisable] = useState(false);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [openCadastroUnidadeEmbarque, setOpenCadastroUnidadeEmbarque] = useState(false);

    const [loadingSelectUnidadeEmbarque, setLoadingSelectUnidadeEmbarque] = useState(false);

    const handleCloseCadastroUnidadeEmbarque = (value) => {
        if (idAtual) {
            setOpenCadastroUnidadeEmbarque(false);
        } else {
            setLoadingSelectUnidadeEmbarque(true);
            buscarDependenciasSemLoading();
            setOpenCadastroUnidadeEmbarque(false);
        }
    };

    const [openCadastroRota, setOpenCadastroRota] = useState(false);

    const [loadingSelectRota, setLoadingSelectRota] = useState(false);

    const handleCloseCadastroRota = (value) => {
        if (idAtual) {
            setOpenCadastroRota(false);
        } else {
            setLoadingSelectRota(true);
            buscarDependenciasSemLoading();
            setOpenCadastroRota(false);
        }
    };

    const [openCadastroMotivo, setOpenCadastroMotivo] = useState(false);

    const [loadingSelectMotivo, setLoadingSelectMotivo] = useState(false);

    const handleCloseCadastroMotivo = (value) => {
        if (idAtual) {
            setOpenCadastroMotivo(false);
        } else {
            setLoadingSelectMotivo(true);
            buscarDependenciasSemLoading();
            setOpenCadastroMotivo(false);
        }
    };

    const [openCadastroSegmentoCliente, setOpenCadastroSegmentoCliente] = useState(false);

    const [loadingSelectSegmentoCliente, setLoadingSelectSegmentoCliente] = useState(false);

    const handleCloseCadastroSegmentoCliente = (value) => {
        if (idAtual) {
            setOpenCadastroSegmentoCliente(false);
        } else {
            setLoadingSelectSegmentoCliente(true);
            buscarDependenciasSemLoading();
            setOpenCadastroSegmentoCliente(false);
        }
    };

    const [openCadastroCliente, setOpenCadastroCliente] = useState(false);

    const [loadingSelectCliente, setLoadingSelectCliente] = useState(false);

    const handleCloseCadastroCliente = (value) => {
        if (idAtual) {
            setOpenCadastroCliente(false);
        } else {
            setLoadingSelectCliente(true);
            buscarDependenciasSemLoading();
            setOpenCadastroCliente(false);
        }
    };

    const [openCadastroTipoVeiculo, setOpenCadastroTipoVeiculo] = useState(false);

    const [loadingSelectTipoVeiculo, setLoadingSelectTipoVeiculo] = useState(false);

    const handleCloseCadastroTipoVeiculo = (value) => {
        if (idAtual) {
            setOpenCadastroTipoVeiculo(false);
        } else {
            setLoadingSelectTipoVeiculo(true);
            buscarDependenciasSemLoading();
            setOpenCadastroTipoVeiculo(false);
        }
    };

    const [openCadastroTipoCarroceria, setOpenCadastroTipoCarroceria] = useState(false);

    const [loadingSelectTipoCarroceria, setLoadingSelectTipoCarroceria] = useState(false);

    const handleCloseCadastroTipoCarroceria = (value) => {
        if (idAtual) {
            setOpenCadastroTipoCarroceria(false);
        } else {
            setLoadingSelectTipoCarroceria(true);
            buscarDependenciasSemLoading();
            setOpenCadastroTipoCarroceria(false);
        }
    };

    const opcoes = [
        {
            'opcao': 'Sim',
        },
        {
            'opcao': 'Não',
        },
        {
            'opcao': 'Aguardando',
        }
    ];

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const customStylesErro = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid red',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const preencherDadosEditando = async (result) => {
        let foiCarregado;
        if (result.foiCarregado === true) {
            foiCarregado = "Sim";
        } else if (foiCarregado === false) {
            foiCarregado = "Não"
        } else {
            foiCarregado = "Aguardando"
        }
        const foiCarregadoSelecionado = opcoes.find(option =>
            option.opcao === foiCarregado
        );
        if (foiCarregadoSelecionado) {
            setValuesSelect(current => ({ ...current, foiCarregado: foiCarregadoSelecionado }));
        }
        const unidadeSelecionado = listaUnidadeEmbarque.find(option => option.idUnidadeEmbarque === result.unidadeEmbarqueId);
        if (unidadeSelecionado) {
            setValuesSelect(current => ({
                ...current,
                unidadeEmbarqueId: {
                    id: unidadeSelecionado.idUnidadeEmbarque,
                    label: unidadeSelecionado.nome,
                    value: unidadeSelecionado.nome
                }
            }));
            setValues(current => ({ ...current, unidadeEmbarqueId: unidadeSelecionado.idUnidadeEmbarque }));
        }
        const segmentoClienteSelecionado = listaSegmentosCliente.find(option => option.id === result.segmentoClienteId);
        if (segmentoClienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                segmentoClienteId: {
                    id: segmentoClienteSelecionado.id,
                    label: segmentoClienteSelecionado.nome,
                    value: segmentoClienteSelecionado.nome
                }
            }));
            setValues(current => ({ ...current, segmentoClienteId: segmentoClienteSelecionado.id }));
        }
        const motivoCotacaoSelecionado = listaMotivos.find(option => option.id === result.motivoCotacaoId);
        if (motivoCotacaoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                motivoCotacaoId: {
                    id: motivoCotacaoSelecionado.id,
                    label: motivoCotacaoSelecionado.motivo,
                    value: motivoCotacaoSelecionado.motivo
                }
            }));
            setValues(current => ({ ...current, motivoCotacaoId: motivoCotacaoSelecionado.id }));
        }
        const rotaSelecionado = listaRotas.find(option => option.idRota === result.rotaId);
        if (rotaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                rotaId: {
                    id: rotaSelecionado.idRota,
                    label: rotaSelecionado.nome,
                    value: rotaSelecionado.nome
                }
            }));
            setValues(current => ({ ...current, rotaId: rotaSelecionado.idRota }));
        }
        const clienteSelecionado = listaClientes.find(option => option.idCliente === result.clienteId);
        if (clienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                clienteId: {
                    id: clienteSelecionado.idCliente,
                    label: clienteSelecionado.nomeFantasia,
                    value: clienteSelecionado.nomeFantasia
                }
            }));
            setValues(current => ({ ...current, clienteId: clienteSelecionado.idCliente }));
        }
        const tipoVeiculoSelecionado = listaTipoVeiculo.find(option => option.id === result.tipoVeiculoId);
        if (tipoVeiculoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoVeiculoId: {
                    id: tipoVeiculoSelecionado.id,
                    label: tipoVeiculoSelecionado.nome,
                    value: tipoVeiculoSelecionado.nome
                }
            }));
            setValues(current => ({ ...current, tipoVeiculoId: tipoVeiculoSelecionado.id }));
        }
        let listaIds = [];
        result.cotacaoCarroceria.forEach((element) => {
            listaIds.push(element.carroceriaId);
        });
        let listaObjetos = [];
        result.cotacaoCarroceria.forEach((element) => {
            listaObjetos.push({ value: element.carroceria.nome, label: element.carroceria.nome, id: element.carroceria.id });
        });
        setValues(current => ({ ...current, tipoCarroceriaId: listaIds }));
        setValuesSelect(current => ({ ...current, tipoCarroceriaId: listaObjetos }));
        const usuarioResponsavelSelecionado = listaUsuarios.find(option => option.usuario === result.usuarioResponsavel);
        if (usuarioResponsavelSelecionado) {
            setValuesSelect(current => ({
                ...current,
                usuarioResponsavel: {
                    value: usuarioResponsavelSelecionado.usuario,
                    label: usuarioResponsavelSelecionado.usuario,
                    id: usuarioResponsavelSelecionado.idUsuario
                }
            }));
            setValues(current => ({ ...current, usuarioResponsavelId: usuarioResponsavelSelecionado.idUsuario }));
        }
    };

    const preencherDatas = async (result) => {
        var todayEm = new Date(result.dataCotacao);
        var ddEm = String(todayEm.getDate()).padStart(2, '0');
        var mmEm = String(todayEm.getMonth() + 1).padStart(2, '0');
        var yyyyEm = todayEm.getFullYear();
        todayEm = yyyyEm + '-' + mmEm + '-' + ddEm;
        setValues(current => ({ ...current, dataCotacao: todayEm }));
    };

    const clearUnidadeEmbarque = () => {
        setValuesSelect(current => ({ ...current, unidadeEmbarqueId: '' }));
        setValues(current => ({ ...current, unidadeEmbarqueId: null }));
        setValidator(current => ({ ...current, unidadeEmbarqueId: "false" }));
        setUnidadeEmbarqueValido(false);
    };

    const clearRota = () => {
        setValuesSelect(current => ({ ...current, rotaId: '' }));
        setValues(current => ({ ...current, rotaId: null }));
        setValidator(current => ({ ...current, rotaId: "false" }));
    };

    const clearCliente = () => {
        setValuesSelect(current => ({ ...current, clienteId: '' }));
        setValues(current => ({ ...current, clienteId: null }));
        setValidator(current => ({ ...current, clienteId: "false" }));
    };

    const clearMotivo = () => {
        setValuesSelect(current => ({ ...current, motivoCotacaoId: '' }));
        setValues(current => ({ ...current, motivoCotacaoId: null }));
        setValidator(current => ({ ...current, motivoCotacaoId: "false" }));
    };

    const clearSegmentoCliente = () => {
        setValuesSelect(current => ({ ...current, segmentoClienteId: '' }));
        setValues(current => ({ ...current, segmentoClienteId: null }));
        setValidator(current => ({ ...current, segmentoClienteId: "false" }));
        setSegmentoClienteValido(false);
    };

    const clearTipoVeiculo = () => {
        setValuesSelect(current => ({ ...current, tipoVeiculoId: '' }));
        setValues(current => ({ ...current, tipoVeiculoId: null }));
        setValidator(current => ({ ...current, tipoVeiculoId: "false" }));
    };

    const clearTipoCarroceria = () => {
        setValuesSelect(current => ({ ...current, tipoCarroceriaId: '' }));
        setValues(current => ({ ...current, tipoCarroceriaId: null }));
        setValidator(current => ({ ...current, tipoCarroceriaId: "false" }));
    };

    const buscarCotacao = async () => {
        setLoading(true);
        await getCotacao(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.unidadeEmbarqueId !== "") && (values.rotaId !== "") && (values.clienteId !== "") && (values.dataCotacao !== "") &&
            (values.valor !== "") && (values.icms !== "") && (values.pedagio !== "") && (values.distanciaKm !== "") &&
            (values.valorPorKm !== "") && (values.valorDesejavelMotorista !== "") && (values.tipoVeiculoId !== "") && (values.tipoCarroceriaId !== "") && (values.usuarioResponsavel !== "")
            && (values.nomeContato !== "") && (values.telefoneContato !== "") && (values.emailContato !== "") &&
            (validator.unidadeEmbarqueId === "true") && (validator.rotaId === "true") && (validator.clienteId === "true") && (validator.dataCotacao === "true") &&
            (validator.valor === "true") && (validator.icms === "true") && (validator.pedagio === "true") && (validator.distanciaKm === "true") &&
            (validator.valorPorKm === "true") && (validator.valorDesejavelMotorista === "true") && (validator.tipoVeiculoId === "true") && (validator.tipoCarroceriaId === "true") && (validator.usuarioResponsavel === "true")
            && (validator.nomeContato === "true") && (validator.telefoneContato === "true") && (validator.emailContato === "true")) {
            if ((values.foiCarregado === false && validator.motivoCotacaoId === "false")) {
                setTextoErro('Há campos sem preencher!');
                setOpenToastErro(true);
            } else if ((values.foiCarregado !== false && validator.motivoCotacaoId === "false")) {
                setLoading(true);
                if (!values.dataCotacao) {
                    values.dataCotacao = "2000-01-01";
                }
                await cadastraCotacao(values, dadosUsuarioRedux.token).then((result) => {
                    if (result.status === 200) {
                        buscarCotacao();
                        setDadosRequestControllerRedux("Fechar Modal Cotacao");
                        setTabelaRedux({});
                        setTextoSucesso("Cadastrado Com Sucesso");
                        setOpenToastSucesso(true);
                        setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({});
                    }
                }).catch((error) => {
                    if (typeof error.response.data === 'string') {
                        setTextoErro(error.response.data);
                    } else {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                });
            } else if ((values.foiCarregado === false && validator.motivoCotacaoId === "true")) {
                setLoading(true);
                if (!values.dataCotacao) {
                    values.dataCotacao = "2000-01-01";
                }
                await cadastraCotacao(values, dadosUsuarioRedux.token).then((result) => {
                    if (result.status === 200) {
                        buscarCotacao();
                        setDadosRequestControllerRedux("Fechar Modal Cotacao");
                        setTabelaRedux({});
                        setTextoSucesso("Cadastrado Com Sucesso");
                        setOpenToastSucesso(true);
                        setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({});
                    }
                }).catch((error) => {
                    if (typeof error.response.data === 'string') {
                        setTextoErro(error.response.data);
                    } else {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                });
            }
        } else {
            let texto = "O(s) campos a seguir são obrigatorios: ";
            if (validator.motivoCotacaoId === "false") {
                setMotivoValido(false);
                texto += "Motivo -";
            }
            if (validator.unidadeEmbarqueId === "false") {
                setUnidadeEmbarqueValido(false);
                texto += "Empresa -";
            }
            if (validator.rotaId === "false") {
                setRotaValido(false);
                texto += "Rota -";
            }
            if (validator.clienteId === "false") {
                setClienteValido(false);
                texto += "Cliente -";
            }
            if (validator.dataCotacao === "false") {
                setDataCotacaoValido(false);
                texto += "Data Cotação -";
            }
            if (validator.valor === "false" || values.valor === "") {
                setValorValido(false);
                texto += "Valor -";
            }
            if (validator.icms === "false" || values.icms === "") {
                setIcmsValido(false);
                texto += "ICMS -";
            }
            if (validator.pedagio === "false" || values.pedagio === "") {
                setPedagioValido(false);
                texto += "Pedágio -";
            }
            if (validator.distanciaKm === "false" || values.distanciaKm === "") {
                setDistanciaKmValido(false);
                texto += "Distância KM -";
            }
            if (validator.valorPorKm === "false" || values.valorPorKm === "") {
                setValorKmValido(false);
                texto += "Valor por KM -";
            }
            if (validator.valorDesejavelMotorista === "false" || values.valorDesejavelMotorista === "") {
                setValorDesejavelMotoristaValido(false);
                texto += "Valor Desejável Motorista -";
            }
            if (validator.tipoVeiculoId === "false") {
                setTipoVeiculoValido(false);
                texto += "Tipo Veículo -";
            }
            if (validator.tipoCarroceriaId === "false") {
                setTipoCarroceriaValido(false);
                texto += "Tipo Carroceria -";
            }
            if (validator.segmentoClienteId === "false") {
                setSegmentoClienteValido(false);
                texto += "Segmento Cliente -";
            }
            if (validator.descricaoRota === "false" || values.descricaoRota === "") {
                setDescricaoRotaValido(false);
                texto += "Descrição Rota -";
            }
            if (validator.usuarioResponsavel === "false") {
                setUsuarioResponsavelValido(false);
                texto += "Usuário Responsavel -";
            }
            if (validator.observacao === "false" || values.observacao === "") {
                setObservacaoValido(false);
                texto += "Observação -";
            }
            if (validator.foiCarregado === "false") {
                setFoiCarregadoValido(false);
                texto += "Foi Carregado -";
            }
            if (validator.nomeContato === "false" || values.nomeContato === "") {
                setNomeValido(false);
                texto += "Nome -";
            }
            if (validator.telefoneContato === "false" || values.telefoneContato === "") {
                setTelefoneValido(false);
                texto += "Telefone -";
            }
            if (validator.emailContato === "false" || values.emailContato === "") {
                setEmailValido(false);
                texto += "Email -";
            }
            if (texto.length > 200) {
                texto = texto.substring(0, 200) + "...";
            }
            setLoading(false);
            setOpenToastErro(true);
            setTextoErro(texto);
        }
    }

    const editar = async () => {
        if ((values.unidadeEmbarqueId !== "") && (values.rotaId !== "") && (values.clienteId !== "") && (values.dataCotacao !== "") &&
            (values.valor !== "") && (values.icms !== "") && (values.pedagio !== "") && (values.distanciaKm !== "") &&
            (values.valorPorKm !== "") && (values.valorDesejavelMotorista !== "") && (values.tipoVeiculoId !== "") && (values.tipoCarroceriaId !== "") && (values.usuarioResponsavel !== "")
            && (values.nomeContato !== "") && (values.telefoneContato !== "") && (values.emailContato !== "")) {
            if ((values.foiCarregado === false && validator.motivoCotacaoId === "false")) {
                setTextoErro('Há campos sem preencher!');
                setOpenToastErro(true);
            } else if ((values.foiCarregado !== false && validator.motivoCotacaoId === "false")) {
                setLoading(true);
                if (!values.dataCotacao) {
                    values.dataCotacao = "2000-01-01T00:00";
                }
                await editaCotacaoPorId(values, dadosUsuarioRedux.token).then((result) => {
                    if (result.status === 200) {
                        buscarCotacao();
                        setTabelaRedux({});
                        setTextoSucesso("Cadastrado Com Sucesso");
                        setOpenToastSucesso(true);
                        setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({});
                    }
                }).catch((error) => {
                    if (typeof error.response.data === 'string') {
                        setTextoErro(error.response.data);
                        setOpenToastErro(true);
                        setLoading(false);
                    } else {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                        setOpenToastErro(true);
                        setLoading(false);
                    }
                    setLoading(false);
                });
            } else if ((values.foiCarregado === false && validator.motivoCotacaoId === "true")) {
                setLoading(true);
                if (!values.dataCotacao) {
                    values.dataCotacao = "2000-01-01T00:00";
                }
                await editaCotacaoPorId(values, dadosUsuarioRedux.token).then((result) => {
                    if (result.status === 200) {
                        buscarCotacao();
                        setTabelaRedux({});
                        setTextoSucesso("Cadastrado Com Sucesso");
                        setOpenToastSucesso(true);
                        setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({});
                    }
                }).catch((error) => {
                    if (typeof error.response.data === 'string') {
                        setTextoErro(error.response.data);
                        setOpenToastErro(true);
                        setLoading(false);
                    } else {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                        setOpenToastErro(true);
                        setLoading(false);
                    }
                    setLoading(false);
                });
            }
        } else {
            let texto = "O(s) campos a seguir são obrigatorios: ";
            if (values.unidadeEmbarqueId === "" || values.unidadeEmbarqueId === null) {
                setUnidadeEmbarqueValido(false);
                texto += "Empresa -";
            }
            if (values.rotaId === "" || values.rotaId === null) {
                setRotaValido(false);
                texto += "Rota -";
            }
            if (values.clienteId === "" || values.clienteId === null) {
                setClienteValido(false);
                texto += "Cliente -";
            }
            if (values.dataCotacao === "" || values.dataCotacao === null) {
                setDataCotacaoValido(false);
                texto += "Data Cotação -";
            }
            if (values.valor === "" || values.valor === null) {
                setValorValido(false);
                texto += "Valor -";
            }
            if (values.icms === "" || values.icms === null) {
                setIcmsValido(false);
                texto += "ICMS -";
            }
            if (values.pedagio === "" || values.pedagio === null) {
                setPedagioValido(false);
                texto += "Pedágio -";
            }
            if (values.distanciaKm === "" || values.distanciaKm === null) {
                setDistanciaKmValido(false);
                texto += "Distância KM -";
            }
            if (values.valorPorKm === "" || values.valorPorKm === null) {
                setValorKmValido(false);
                texto += "Valor por KM -";
            }
            if (values.valorDesejavelMotorista === "" || values.valorDesejavelMotorista === null) {
                setValorDesejavelMotoristaValido(false);
                texto += "Valor Desejável Motorista -";
            }
            if (values.tipoVeiculoId === "" || values.tipoVeiculoId === null) {
                setTipoVeiculoValido(false);
                texto += "Tipo Veículo -";
            }
            if (values.tipoCarroceriaId === "" || values.tipoCarroceriaId === null) {
                setTipoCarroceriaValido(false);
                texto += "Tipo Carroceria -";
            }
            if (values.segmentoClienteId === "" || values.segmentoClienteId === null) {
                setSegmentoClienteValido(false);
                texto += "Segmento Cliente -";
            }
            if (values.descricaoRota === "" || values.descricaoRota === null) {
                setDescricaoRotaValido(false);
                texto += "Descrição Rota -";
            }
            if (values.usuarioResponsavel === "" || values.usuarioResponsavel === null) {
                setUsuarioResponsavelValido(false);
                texto += "Usuário Responsavel -";
            }
            if (values.observacao === "" || values.observacao === null) {
                setObservacaoValido(false);
                texto += "Observação -";
            }
            if (values.foiCarregado === "" || values.foiCarregado === null) {
                setFoiCarregadoValido(false);
                texto += "Foi Carregado -";
            }
            if (values.nomeContato === "" || values.nomeContato === null) {
                setNomeValido(false);
                texto += "Nome -";
            }
            if (values.telefoneContato === "" || values.telefoneContato === null) {
                setTelefoneValido(false);
                texto += "Telefone -";
            }
            if (values.emailContato === "" || values.emailContato === null) {
                setEmailValido(false);
                texto += "Email -";
            }
            if (texto.length > 200) {
                texto = texto.substring(0, 200) + "...";
            }
            setLoading(false);
            setOpenToastErro(true);
            setTextoErro(texto);
        }
    }

    function formatarValor(valor) {
        // Função para verificar se o valor é um número inteiro (como string ou número)
        const ehInteger = (value) => {
            // Checa se é um número inteiro ou uma string que representa um número inteiro
            return (
                (typeof value === 'number' && Math.floor(value) === value) ||
                (typeof value === 'string' && /^\d+$/.test(value))
            );
        };

        // Verifica se o valor é um número inteiro sem vírgula
        if (ehInteger(valor)) {
            // Formata o valor adicionando ,00 ao final
            return `${valor},00`;
        }

        // Retorna o valor original caso não seja um inteiro
        return valor;
    }

    const buscarCotacaoPorId = async () => {
        setLoading(true);
        await getCotacaoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    setValuesSelect(current => ({ ...current, valorDesejavelMotorista2: formatarValor(result.data.valorDesejavelMotorista) }));
                    setValuesSelect(current => ({ ...current, icms2: formatarValor(result.data.icms) }));
                    setValuesSelect(current => ({ ...current, valor2: formatarValor(result.data.valor) }));
                    setValuesSelect(current => ({ ...current, pedagio2: formatarValor(result.data.pedagio) }));
                    setValuesSelect(current => ({ ...current, distanciaKm2: result.data.distanciaKm }));
                    preencherDadosEditando(result.data);
                    preencherDatas(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarCotacaoPorIdModal = async (id) => {
        setLoading(true);
        await getCotacaoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarCotacaoPorId = async () => {
        setLoading(true);
        await deleteCotacaoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCotacao();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarDependencias = async () => {
        setLoading(true);
        await getCotacaoDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data.clientes);
            setListaRotas(result.data.rotas);
            setListaTipoCarrocerias(result.data.tipoCarrocerias);
            setListaTipoVeiculo(result.data.tipoVeiculos);
            setListaUnidadeEmbarque(result.data.unidadeEmbarques);
            setListaUsuarios(result.data.usuarios);
            setListaMotivos(result.data.motivoCotacaos);
            setListaSegmentosCliente(result.data.segmentoClientes);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarDependenciasSemLoading = async () => {
        await getCotacaoDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data.clientes);
            setListaRotas(result.data.rotas);
            setListaTipoCarrocerias(result.data.tipoCarrocerias);
            setListaTipoVeiculo(result.data.tipoVeiculos);
            setListaUnidadeEmbarque(result.data.unidadeEmbarques);
            setListaUsuarios(result.data.usuarios);
            setListaMotivos(result.data.motivoCotacaos);
            setListaSegmentosCliente(result.data.segmentoClientes);
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectTipoCarroceria(false);
            setLoadingSelectTipoVeiculo(false);
            setLoadingSelectUnidadeEmbarque(false);
            setLoadingSelectMotivo(false);
            setLoadingSelectSegmentoCliente(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
            }
        });
    }

    const [valoresHistorico, setValoresHistorico] = useState([]);

    const [openHistoricoEdicao, setOpenHistoricoEdicao] = useState(false);

    const handleCloseHistoricoEdicao = (value) => {
        setOpenHistoricoEdicao(false);
    }

    const buscarHistoricoEdicao = async () => {
        await getCotacaoHistoricoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setValoresHistorico(result.data);
                setOpenHistoricoEdicao(true);
            }
        }).catch((error) => {
            setTextoErro(error.response.data);
            setOpenToastErro(true);
        })
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, unidadeEmbarqueId: "false" }));
        setValidator(current => ({ ...current, rotaId: "false" }));
        setValidator(current => ({ ...current, clienteId: "false" }));
        setValidator(current => ({ ...current, dataCotacao: "false" }));
        setValidator(current => ({ ...current, valor: "false" }));
        setValidator(current => ({ ...current, pedagio: "false" }));
        setValidator(current => ({ ...current, icms: "false" }));
        setValidator(current => ({ ...current, distanciaKm: "false" }));
        setValidator(current => ({ ...current, valorPorKm: "false" }));
        setValidator(current => ({ ...current, valorDesejavelMotorista: "false" }));
        setValidator(current => ({ ...current, tipoVeiculoId: "false" }));
        setValidator(current => ({ ...current, tipoCarroceriaId: "false" }));
        setValidator(current => ({ ...current, segmentoClienteId: "false" }));
        setValidator(current => ({ ...current, descricaoRota: "false" }));
        setValidator(current => ({ ...current, observacao: "false" }));
        setValidator(current => ({ ...current, usuarioResponsavel: "false" }));
        setValidator(current => ({ ...current, nomeContato: "false" }));
        setValidator(current => ({ ...current, telefoneContato: "false" }));
        setValidator(current => ({ ...current, emailContato: "false" }));
        setValidator(current => ({ ...current, motivoCotacaoId: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarCotacaoPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarDependencias();
        buscarCotacao();
        if (props.idAtual) {
            buscarCotacaoPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        let foiCarregado = "Aguardando";
        const foiCarregadoSelecionado = opcoes.find(option =>
            option.opcao === foiCarregado
        );
        if (foiCarregadoSelecionado) {
            setValuesSelect(current => ({ ...current, foiCarregado: foiCarregadoSelecionado }));
        }
    }, [telaCadastro]);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (values.dataCotacao === "2000-01-01T00:00:00" || values.dataCotacao === "2000-01-01T00:00") {
            values.dataCotacao = "";
        }
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Cotação') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Cotação') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Cotações" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Cotações" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Cotação</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="id"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Cotacao}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-historico"
                                                        classText="button-texto-user"
                                                        titulo="Histórico"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                buscarHistoricoEdicao(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Cotacao / "}</p>
                                                        <p className="indicador-emb">{"Cadastro de Cotacao"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Cotacao" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); limpaValoresValidos();
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        {
                                                            loadingSelectUnidadeEmbarque ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={unidadeEmbarqueValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Unidade de Embarque:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={200}
                                                                            onChange={(e) => {
                                                                                setUnidadeEmbarqueValido(true);
                                                                                setValuesSelect(current => ({ ...current, unidadeEmbarqueId: e }));
                                                                                setValues(current => ({ ...current, unidadeEmbarqueId: e.id }));
                                                                                setValidator(current => ({ ...current, unidadeEmbarqueId: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroUnidadeEmbarque(true);
                                                                            }}
                                                                            clearSelection={clearUnidadeEmbarque}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            erro={!unidadeEmbarqueValido}
                                                                            id="idUnidadeEmbarque"
                                                                            value={valuesSelect.unidadeEmbarqueId}
                                                                            titulo="Unidade de Embarque"
                                                                            dadosSelect={listaUnidadeEmbarque}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome" />
                                                                        {!unidadeEmbarqueValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroUnidadeEmbarque(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectRota ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={rotaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Rota:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={200}
                                                                            onChange={(e) => {
                                                                                setRotaValido(true);
                                                                                setValuesSelect(current => ({ ...current, rotaId: e }));
                                                                                setValues(current => ({ ...current, rotaId: e.id }));
                                                                                setValidator(current => ({ ...current, rotaId: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroRota(true);
                                                                            }}
                                                                            clearSelection={clearRota}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            erro={!rotaValido}
                                                                            id="idRota"
                                                                            value={valuesSelect.rotaId}
                                                                            titulo="Rota"
                                                                            dadosSelect={listaRotas}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome" />
                                                                        {!rotaValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroRota(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectCliente ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={clienteValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Cliente:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={200}
                                                                            onChange={(e) => {
                                                                                setClienteValido(true);
                                                                                setValuesSelect(current => ({ ...current, clienteId: e }));
                                                                                setValues(current => ({ ...current, clienteId: e.id }));
                                                                                setValidator(current => ({ ...current, clienteId: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroCliente(true);
                                                                            }}
                                                                            clearSelection={clearCliente}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            id="idCliente"
                                                                            erro={!clienteValido}
                                                                            value={valuesSelect.clienteId}
                                                                            titulo="Cliente"
                                                                            dadosSelect={listaClientes}
                                                                            referenciaId="nomeFantasia"
                                                                            referenciaItem="nomeFantasia" />
                                                                        {!clienteValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCliente(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Data da Cotação:</p>
                                                                <input
                                                                    type="date"
                                                                    className={dataCotacaoValido ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '200px' }}
                                                                    onChange={(e) => {
                                                                        setDataCotacaoValido(true);
                                                                        setValues(current => ({ ...current, dataCotacao: e.target.value }));
                                                                        setValidator(current => ({ ...current, dataCotacao: "true" }));
                                                                    }}
                                                                    value={values.dataCotacao} />
                                                                {!dataCotacaoValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Valor:</p>
                                                                <input
                                                                    type="text"
                                                                    className={valorValido ? "input-emb" : "input-emb-erro"}
                                                                    placeholder='0,00'
                                                                    style={{ width: '180px' }}
                                                                    onChange={(e) => {
                                                                        setValorValido(true);
                                                                        setValorKmValido(true);
                                                                        const valorInput = e.target.value.replace(',', '.');
                                                                        setValues(current => ({ ...current, valor: valorInput }));
                                                                        setValuesSelect(current => ({ ...current, valor2: e.target.value }));
                                                                        const valorNumerico = parseFloat(valorInput);
                                                                        const distanciaKmNumerica = parseFloat(values.distanciaKm);
                                                                        if (!isNaN(valorNumerico) && !isNaN(distanciaKmNumerica) && distanciaKmNumerica > 0) {
                                                                            const valorPorKm = valorNumerico / distanciaKmNumerica;
                                                                            setValues(current => ({ ...current, valorPorKm: valorPorKm.toFixed(2) }));
                                                                        }
                                                                        setValidator(current => ({ ...current, valor: "true" }));
                                                                        setValidator(current => ({ ...current, valorPorKm: "true" }));
                                                                    }}
                                                                    value={valorMask(valuesSelect.valor2)}
                                                                    disabled={false} />
                                                                {!valorValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">ICMS:</p>
                                                                <input
                                                                    type="text"
                                                                    className={icmsValido ? "input-emb" : "input-emb-erro"}
                                                                    placeholder='0,00'
                                                                    style={{ width: '180px' }}
                                                                    onChange={(e) => {
                                                                        setIcmsValido(true);
                                                                        const icms = e.target.value.replace(',', '.');
                                                                        setValues(current => ({ ...current, icms: icms }));
                                                                        setValuesSelect(current => ({ ...current, icms2: e.target.value }));
                                                                        setValidator(current => ({ ...current, icms: "true" }));
                                                                    }}
                                                                    value={valorMask(valuesSelect.icms2)}
                                                                    disabled={false} />
                                                                {!icmsValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Pedágio:</p>
                                                                <input
                                                                    type="text"
                                                                    className={pedagioValido ? "input-emb" : "input-emb-erro"}
                                                                    placeholder='0,00'
                                                                    style={{ width: '180px' }}
                                                                    onChange={(e) => {
                                                                        setPedagioValido(true);
                                                                        const pedagio = e.target.value.replace(',', '.');
                                                                        setValues(current => ({ ...current, pedagio: pedagio }));
                                                                        setValuesSelect(current => ({ ...current, pedagio2: e.target.value }));
                                                                        setValidator(current => ({ ...current, pedagio: "true" }));
                                                                    }}
                                                                    value={valorMask(valuesSelect.pedagio2)}
                                                                    disabled={false} />
                                                                {!pedagioValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Distância em KM:</p>
                                                                <input
                                                                    type="text"
                                                                    className={distanciaKmValido ? "input-emb" : "input-emb-erro"}
                                                                    placeholder='0,00'
                                                                    style={{ width: '180px' }}
                                                                    onChange={(e) => {
                                                                        setDistanciaKmValido(true);
                                                                        setValorKmValido(true);
                                                                        const distanciaInput = e.target.value.replace(',', '.');
                                                                        setValues(current => ({ ...current, distanciaKm: distanciaInput }));
                                                                        setValuesSelect(current => ({ ...current, distanciaKm2: e.target.value }));
                                                                        const distanciaNumerica = parseFloat(distanciaInput);
                                                                        const valorNumerico = parseFloat(values.valor);
                                                                        if (!isNaN(valorNumerico) && !isNaN(distanciaNumerica) && distanciaNumerica > 0) {
                                                                            const valorPorKm = valorNumerico / distanciaNumerica;
                                                                            setValues(current => ({ ...current, valorPorKm: valorPorKm.toFixed(2) }));
                                                                        }
                                                                        setValidator(current => ({ ...current, valorPorKm: "true" }));
                                                                        setValidator(current => ({ ...current, distanciaKm: "true" }));
                                                                    }}
                                                                    value={valorMask(valuesSelect.distanciaKm2)}
                                                                    disabled={false} />
                                                                {!distanciaKmValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Valor por KM:</p>
                                                                <input
                                                                    type="text"
                                                                    className={valorKmValido ? "input-emb" : "input-emb-erro"}
                                                                    placeholder='0,00'
                                                                    style={{ width: '185px' }}
                                                                    onChange={(e) => {
                                                                        setValorKmValido(true);
                                                                        setValues(current => ({ ...current, valorPorKm: e.target.value }));
                                                                        setValidator(current => ({ ...current, valorPorKm: "true" }));
                                                                    }}
                                                                    value={valorMask(values.valorPorKm)}
                                                                    disabled={true} />
                                                                {!valorKmValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Valor Desejavel Motorista:</p>
                                                                <input
                                                                    type="text"
                                                                    className={valorDesejavelMotoristaValido ? "input-emb" : "input-emb-erro"}
                                                                    placeholder='0,00'
                                                                    style={{ width: '180px' }}
                                                                    onChange={(e) => {
                                                                        setValorDesejavelMotoristaValido(true);
                                                                        const valorDesejavelMotorista = e.target.value.replace(',', '.');
                                                                        setValues(current => ({ ...current, valorDesejavelMotorista: valorDesejavelMotorista }));
                                                                        setValuesSelect(current => ({ ...current, valorDesejavelMotorista2: e.target.value }));
                                                                        setValidator(current => ({ ...current, valorDesejavelMotorista: "true" }));
                                                                    }}
                                                                    value={valorMask(valuesSelect.valorDesejavelMotorista2)}
                                                                    disabled={false} />
                                                                {!valorDesejavelMotoristaValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        {
                                                            loadingSelectTipoVeiculo ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={tipoVeiculoValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Tipo de Veículo:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={210}
                                                                            onChange={(e) => {
                                                                                setTipoVeiculoValido(true);
                                                                                setValuesSelect(current => ({ ...current, tipoVeiculoId: e }));
                                                                                setValues(current => ({ ...current, tipoVeiculoId: e.id }));
                                                                                setValidator(current => ({ ...current, tipoVeiculoId: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroTipoVeiculo(true);
                                                                            }}
                                                                            clearSelection={clearTipoVeiculo}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            erro={!tipoVeiculoValido}
                                                                            id="id"
                                                                            value={valuesSelect.tipoVeiculoId}
                                                                            titulo="TipoVeiculo"
                                                                            dadosSelect={listaTipoVeiculo}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome" />
                                                                        {!tipoVeiculoValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroTipoVeiculo(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectTipoCarroceria ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={tipoCarroceriaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Tipo de Carroceria:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={210}
                                                                            onChange={(e) => {
                                                                                setTipoCarroceriaValido(true);
                                                                                let listaIds = [];
                                                                                e.forEach((element) => {
                                                                                    listaIds.push(element.id);
                                                                                });
                                                                                setValues(current => ({ ...current, tipoCarroceriaId: listaIds }));
                                                                                setValuesSelect(current => ({ ...current, tipoCarroceriaId: e }));
                                                                                setValidator(current => ({ ...current, tipoCarroceriaId: "true" }));
                                                                            }}
                                                                            clearSelection={clearTipoCarroceria}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroTipoCarroceria(true);
                                                                            }}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            id="id"
                                                                            erro={!tipoCarroceriaValido}
                                                                            value={valuesSelect.tipoCarroceriaId}
                                                                            titulo="TipoCarroceria"
                                                                            dadosSelect={listaTipoCarrocerias}
                                                                            multi={true}
                                                                            info={false}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome" />
                                                                        {!tipoCarroceriaValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroTipoCarroceria(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectSegmentoCliente ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={segmentoClienteValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Segmento Cliente:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={200}
                                                                            onChange={(e) => {
                                                                                setSegmentoClienteValido(true);
                                                                                setValuesSelect(current => ({ ...current, segmentoClienteId: e }));
                                                                                setValues(current => ({ ...current, segmentoClienteId: e.id }));
                                                                                setValidator(current => ({ ...current, segmentoClienteId: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroSegmentoCliente(true);
                                                                            }}
                                                                            clearSelection={clearSegmentoCliente}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            erro={!segmentoClienteValido}
                                                                            id="id"
                                                                            value={valuesSelect.segmentoClienteId}
                                                                            titulo="Segmento Cliente"
                                                                            dadosSelect={listaSegmentosCliente}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome" />
                                                                        {!segmentoClienteValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroSegmentoCliente(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Descrição Rota:</p>
                                                                <input
                                                                    type="text"
                                                                    className={descricaoRotaValido ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '775px' }}
                                                                    id="descricaoRota"
                                                                    onChange={(e) => {
                                                                        setDescricaoRotaValido(true);
                                                                        setValues(current => ({ ...current, descricaoRota: e.target.value }));
                                                                        setValidator(current => ({ ...current, descricaoRota: "true" }));
                                                                    }}
                                                                    value={values.descricaoRota}
                                                                    disabled={props.desabilitar}>
                                                                </input>
                                                                {!descricaoRotaValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className={usuarioResponsavelValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Usuário Responsável:</p>
                                                                <SelectEmbarqueV2
                                                                    classeCss={175}
                                                                    onChange={(e) => {
                                                                        setUsuarioResponsavelValido(true);
                                                                        setValuesSelect(current => ({ ...current, usuarioResponsavel: e }));
                                                                        setValues(current => ({ ...current, usuarioResponsavel: e.value }));
                                                                        setValidator(current => ({ ...current, usuarioResponsavel: "true" }));
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setDisable(true);
                                                                    }}
                                                                    onInfoIconClick={setIdAtual}
                                                                    pequeno={true}
                                                                    id="idUsuario"
                                                                    erro={!usuarioResponsavelValido}
                                                                    value={valuesSelect.usuarioResponsavel}
                                                                    titulo="Usuario"
                                                                    dadosSelect={listaUsuarios}
                                                                    referenciaId="usuario"
                                                                    referenciaItem="usuario"
                                                                    info={false} />
                                                                {!usuarioResponsavelValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                                <input
                                                                    type="text"
                                                                    className={observacaoValido ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '515px' }}
                                                                    id="observacao"
                                                                    onChange={(e) => {
                                                                        setObservacaoValido(true);
                                                                        setValidator(current => ({ ...current, observacao: "true" }));
                                                                        setValues(current => ({ ...current, observacao: e.target.value }))
                                                                    }}
                                                                    value={values.observacao}
                                                                    disabled={props.desabilitar}>
                                                                </input>
                                                                {!observacaoValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Foi Carregado:</p>
                                                                <FormControl sx={{ width: 180 }}>
                                                                    <Select
                                                                        value={valuesSelect.foiCarregado}
                                                                        options={opcoesFrota}
                                                                        formatOptionLabel={labelFrota}
                                                                        styles={estiloFrota}
                                                                        placeholder=""
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                if (selectedOption.opcao === 'Sim') {
                                                                                    setValues((current) => ({
                                                                                        ...current,
                                                                                        foiCarregado: true,
                                                                                    }));
                                                                                    setMotivoValido(true);
                                                                                } else if (selectedOption.opcao === 'Não') {
                                                                                    setValues((current) => ({
                                                                                        ...current,
                                                                                        foiCarregado: false,
                                                                                    }));
                                                                                    setMotivoValido(false);
                                                                                } else if (selectedOption.opcao === 'Aguardando') {
                                                                                    setValues((current) => ({
                                                                                        ...current,
                                                                                        foiCarregado: null,
                                                                                    }));
                                                                                    setMotivoValido(true);
                                                                                }
                                                                                setValuesSelect(current => ({ ...current, foiCarregado: selectedOption }));
                                                                                setValidator((current) => ({
                                                                                    ...current,
                                                                                    foiCarregado: "true",
                                                                                }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        {values.foiCarregado === false && (
                                                            loadingSelectMotivo ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={motivoValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Motivo:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={200}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, motivoCotacaoId: e }));
                                                                                setValues(current => ({ ...current, motivoCotacaoId: e.id }));
                                                                                setValidator(current => ({ ...current, motivoCotacaoId: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroMotivo(true);
                                                                            }}
                                                                            erro={!motivoValido}
                                                                            clearSelection={clearMotivo}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            id="id"
                                                                            value={valuesSelect.motivoCotacaoId}
                                                                            titulo="Motivo"
                                                                            dadosSelect={listaMotivos}
                                                                            referenciaId="motivo"
                                                                            referenciaItem="motivo" />
                                                                        {!motivoValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroMotivo(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <p className="dados-embarque-text">Dados de Contato:</p>
                                                    </div>
                                                    <div className="rota-div-2" style={{ marginTop: 5 }}>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                <input
                                                                    type="text"
                                                                    className={nomeValido ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '312px' }}
                                                                    id="nomeContato"
                                                                    onChange={(e) => {
                                                                        setNomeValido(true);
                                                                        setValues(current => ({ ...current, nomeContato: e.target.value }))
                                                                        setValidator(current => ({ ...current, nomeContato: "true" }));
                                                                    }}
                                                                    placeholder="João"
                                                                    value={values.nomeContato}
                                                                    disabled={props.desabilitar}>
                                                                </input>
                                                                {!nomeValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                                                <input
                                                                    type="text"
                                                                    className={telefoneValido ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '312px' }}
                                                                    id="telefoneContato"
                                                                    onChange={(e) => {
                                                                        setTelefoneValido(true);
                                                                        setValues(current => ({ ...current, telefoneContato: e.target.value }))
                                                                        setValidator(current => ({ ...current, telefoneContato: "true" }));
                                                                    }}
                                                                    placeholder="(99)99999-9999"
                                                                    value={telefoneMask(values.telefoneContato)}
                                                                    disabled={props.desabilitar}>
                                                                </input>
                                                                {!telefoneValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Email:</p>
                                                                <input
                                                                    type="text"
                                                                    className={emailValido ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '312px' }}
                                                                    id="emailContato"
                                                                    onChange={(e) => {
                                                                        setEmailValido(true);
                                                                        setValues(current => ({ ...current, emailContato: e.target.value }))
                                                                        setValidator(current => ({ ...current, emailContato: "true" }));
                                                                    }}
                                                                    placeholder="meuemail@gmail.com"
                                                                    value={values.emailContato}
                                                                    disabled={props.desabilitar}>
                                                                </input>
                                                                {!emailValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                cadastrar();
                                                                                if ((validator.unidadeEmbarqueId === "true") && (validator.rotaId === "true") && (validator.clienteId === "true") && (validator.dataCotacao === "true") &&
                                                                                    (validator.valor === "true") && (validator.icms === "true") && (validator.pedagio === "true") && (validator.distanciaKm === "true") &&
                                                                                    (validator.valorPorKm === "true") && (validator.valorDesejavelMotorista === "true") && (validator.tipoVeiculoId === "true") && (validator.tipoCarroceriaId === "true") && (validator.usuarioResponsavel === "true")
                                                                                    && (validator.nomeContato === "true") && (validator.telefoneContato === "true") && (validator.emailContato === "true")) {
                                                                                    if ((values.foiCarregado === false && validator.motivoCotacaoId === "false")) {
                                                                                        setTextoErro('Há campos sem preencher!');
                                                                                        setOpenToastErro(true);
                                                                                    } else if ((values.foiCarregado !== false && validator.motivoCotacaoId === "false")) {
                                                                                        setValues({});
                                                                                        setValuesSelect({});
                                                                                    } else if ((values.foiCarregado === false && validator.motivoCotacaoId === "true")) {
                                                                                        setValues({});
                                                                                        setValuesSelect({});
                                                                                    }
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                cadastrar();
                                                                                if ((validator.unidadeEmbarqueId === "true") && (validator.rotaId === "true") && (validator.clienteId === "true") && (validator.dataCotacao === "true") &&
                                                                                    (validator.valor === "true") && (validator.icms === "true") && (validator.pedagio === "true") && (validator.distanciaKm === "true") &&
                                                                                    (validator.valorPorKm === "true") && (validator.valorDesejavelMotorista === "true") && (validator.tipoVeiculoId === "true") && (validator.tipoCarroceriaId === "true") && (validator.usuarioResponsavel === "true")
                                                                                    && (validator.nomeContato === "true") && (validator.telefoneContato === "true") && (validator.emailContato === "true")) {
                                                                                    if ((values.foiCarregado === false && validator.motivoCotacaoId === "false")) {
                                                                                        setTextoErro('Há campos sem preencher!');
                                                                                        setOpenToastErro(true);
                                                                                    } else if ((values.foiCarregado !== false && validator.motivoCotacaoId === "false")) {
                                                                                        setValues({});
                                                                                        setValuesSelect({});
                                                                                    } else if ((values.foiCarregado === false && validator.motivoCotacaoId === "true")) {
                                                                                        setValues({});
                                                                                        setValuesSelect({});
                                                                                    }
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); limpaValoresValidos(); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarCotacaoPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroEmpresaSub="sim"
                                        open={openCadastroUnidadeEmbarque}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroUnidadeEmbarque}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroRota="sim"
                                        open={openCadastroRota}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroRota}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCliente="sim"
                                        open={openCadastroCliente}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCliente}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroMotivoCotacao="sim"
                                        open={openCadastroMotivo}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroMotivo}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroSegmentoCliente="sim"
                                        open={openCadastroSegmentoCliente}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroSegmentoCliente}
                                    />
                                    <ModalHistoricoEdicao
                                        valueTabela={tabelaRedux}
                                        valueDados={valoresHistorico}
                                        valueUnico={values}
                                        open={openHistoricoEdicao}
                                        onClose={handleCloseHistoricoEdicao}
                                        titulo="Histórico"
                                        classeSelecionado="id"
                                        colunas={colunasHistorico}
                                        nome="cotacao"
                                        nomeTable="Cotacao"
                                    />
                                    {/* <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroTipoVeiculo="sim"
                                        open={openCadastroTipoVeiculo}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroTipoVeiculo}
                                    /> */}
                                    {/* <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroTipoCarroceria="sim"
                                        open={openCadastroTipoCarroceria}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroTipoCarroceria}
                                    /> */}
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Cotacao;
import React, { useState, createContext, useEffect } from "react";

const valorInicial = {
    usuario: {},
    token: "",
    expiracao: "",
    usuarioTabela: {},
    regras: []
};

const DadosUsuario = createContext();

export default DadosUsuario;

export const DadosUsuarioRedux = ({ children }) => {

    const [dadosUsuarioRedux, setDadosUsuarioRedux] = useState(valorInicial);

    useEffect(() => {
        async function loadStorageData() {
            const storageUsuario = localStorage.getItem("usuario");
            const token = localStorage.getItem("token");
            const expiracao = localStorage.getItem("expiracao");
            if (storageUsuario && token && expiracao) {
                const storageParse = JSON.parse(storageUsuario);
                let body = {
                    usuario: storageParse,
                    token: token,
                    expiracao: expiracao,
                    permissoes: storageParse.permissoes
                }
                setDadosUsuarioRedux(body);
            }
        }
        loadStorageData();
    }, [])

    useEffect(() => {
        
    })

    return (
        <DadosUsuario.Provider value={{
            dadosUsuarioRedux,
            setDadosUsuarioRedux,
        }}>
            {children}
        </DadosUsuario.Provider>
    );
};
import React, { useContext, useState, useEffect } from "react";
import "./Ajuda.css";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import CoresRedux from "../../../contexts/Cor";
import logo from '../../../images/C1010_azul.png'
import { motion } from "framer-motion";
import { getVideosAjuda } from "../../../services/ModuloGerenciador/Ajuda";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";

function Ajuda() {

    const { dadosCoresRedux, setDadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [videos, setVideos] = useState([]);

    const [textoErro, setTextoErro] = useState("");

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const buscarVideos = async () => {
        setLoading(true);
        await getVideosAjuda(dadosUsuarioRedux.token).then((result) => {
            setVideos(result.data);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
            setLoading(false);
        });
    }

    useEffect(() => {
        buscarVideos();
    }, []);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [loading]);


    return (
        <motion.div initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }} className="fundo" style={{ backgroundColor: dadosCoresRedux.fundoPadrao }}>
            <NavbarV2 selecionado='Ajuda' />
            <div className="principal">
                <div className="cardAjuda">
                    {videos.map((video, index) => (
                        <div key={index} className="videoItem">
                            <h3>{video.titulo}</h3>
                            <iframe
                                className="youtube-video"
                                src={video.link}
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>

                        </div>
                    ))}
                </div>
            </div>
            <div>
                <ToastErro
                    texto={textoErro}
                    open={openToastErro}
                    onClose={handleCloseToastErro}
                />
            </div>
        </motion.div>
    );
}

export default Ajuda;

import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraFilial = async (filial, token) => {
  return api.post("Filial",
    filial
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getFilial = async (token) => {
  return api.get("Filial",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getFilialPorId = async (id, token) => {
  return api.get("Filial/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteFilialPorId = async (id, token) => {
  return api.delete("Filial/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaFilialPorId = async (filial, token) => {
  return api.put("Filial",
    filial,
    {
      headers: {
        'Authorization': token
      }
    })
}

export default api;
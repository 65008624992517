import React, { useState, createContext } from 'react';

const valorInicial = ""
const RequestController = createContext();

export default RequestController;

export const RequestControllerRedux = ({ children }) => {
    const [dadosRequestControllerRedux, setDadosRequestControllerRedux] = useState(valorInicial);

    return (
        <RequestController.Provider value={{
            dadosRequestControllerRedux,
            setDadosRequestControllerRedux,
        }}>
            {children}
        </RequestController.Provider>
    );
};
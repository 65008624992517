import { Model } from "./Base.js";

export class VeiculoModel extends Model {
    get defaults() {
        return {
            placaCavalo: "",
            placacarreta: "",
            placaCarreta2: "",
            proprietario: "",
            motorista12: "",
            motorista11: "",
            motorista10: "",
            motorista9: "",
            motorista8: "",
            motorista7: "",
            motorista6: "",
            motorista5: "",
            motorista4: "",
            motorista3: "",
            motorista2: "",
            motorista: "",
            usuario: "",
            data: "",
        };
    }
}
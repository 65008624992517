import React, { useState, useContext } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import "./CustomButton.css";
import styled2 from "styled-components";

function CustomButton(props) {
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#fb0000'),
        backgroundColor: props.cor,
        '&:hover': {
            backgroundColor: props.cor,
        },
    }));

    const TextP = styled2.p`     
    color: ${props.corTexto};
    `

    return (
        <ColorButton onClick={props.onClick} className={props.classCss} variant="contained"><TextP className='titlebutton'>{props.titulo}</TextP></ColorButton>
    )
}

export default CustomButton;
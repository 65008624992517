import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraGrupoComClientes = async (grupoComClientes, token) => {
  return api.post("GrupoComClienteVinculo",
    grupoComClientes
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoComClientes = async (token) => {
  return api.get("GrupoComClienteVinculo",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoComClientesPorId = async (id, token) => {
  return api.get("GrupoComClienteVinculo/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteGrupoComClientesPorId = async (id, token) => {
  return api.delete("GrupoComClienteVinculo/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaGrupoComClientesPorId = async (grupoComClientes, token) => {
  return api.put("GrupoComClienteVinculo",
    grupoComClientes,
  {
    headers: {
      'Authorization': token
    }
  })
}
import { api } from "../ModuloGerenciador/UrlPadrao"

export const VerificaExistenciaVeiculo = async (token, placa) => {
  return api.get("VerificadorExistencia/veiculo/" + placa,
    {
    headers: {
      'Authorization': token
    }
  })
}

export const VerificaExistenciaMotorista = async (token, nome) => {
    return api.get("VerificadorExistencia/motorista/" + nome,
      {
      headers: {
        'Authorization': token
      }
    })
  }

  export const VerificaExistenciaCaminhao = async (token, placa) => {
    return api.get("VerificadorExistencia/caminhao/" + placa,
      {
      headers: {
        'Authorization': token
      }
    })
  }

  export const VerificaExistenciaCarreta = async (token, placa) => {
    return api.get("VerificadorExistencia/carreta/" + placa,
      {
      headers: {
        'Authorization': token
      }
    })
  }
import { api } from "./UrlPadrao"

export const cadastraTipoOcorrencia = async (tipoOcorrencia, token) => {
  return api.post("TipoOcorrencia",
    tipoOcorrencia
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getTipoOcorrencia = async (token) => {
  return api.get("TipoOcorrencia",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getTipoOcorrenciaPorId = async (id, token) => {
  return api.get("TipoOcorrencia/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteTipoOcorrenciaPorId = async (id, token) => {
  return api.delete("TipoOcorrencia/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaTipoOcorrenciaPorId = async (tipoOcorrencia, token) => {
  return api.put("TipoOcorrencia",
    tipoOcorrencia,
    {
      headers: {
        'Authorization': token
      }
    })
}
import React, { useContext, useEffect, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon2 from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import "./Filtro.css";
import FiltrosRedux from "../../contexts/Filtros";
import FilialCidade from "../../contexts/CidadesFilial";
import { WindowSharp } from "@mui/icons-material";
import { Box, LinearProgress } from "@mui/material";

function Filtro({ classe, itensFiltro, valuesPesquisa, setValuesPesquisa, buscarSearch, limpar }) {

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const converterData = (data) => {
        const parts = data.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    const [valuesSelect, setValuesSelect] = useState([]);

    const [inputDisable, setInputDisable] = useState(false);

    const [loadingInput, setLoadingInput] = useState(false);

    useEffect(() => {
        setLoadingInput(true);
        if (valuesPesquisa.item === 'previsaoEmbarque' || valuesPesquisa.item === 'dataEmbarque'
            || valuesPesquisa.item === 'dataEntrega' || valuesPesquisa.item === 'previsaoEntrega') {
            setInputDisable(true);
            var date = new Date();
            var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
            setValuesPesquisa(current => ({ ...current, pesquisa: primeiroDia }));
            setLoadingInput(false);
        } else {
            setInputDisable(false);
            setValuesPesquisa(current => ({ ...current, pesquisa: '' }));
            setLoadingInput(false);
        }
    }, [valuesPesquisa.item]);

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, embarqueInicio: '01-01-2020' }));
        setValuesPesquisa(current => ({ ...current, embarqueFinal: '01-01-2050' }));
        setValuesPesquisa(current => ({ ...current, contexto: 'todos' }));
        if (filtrosRedux.embarqueFinal != "01-01-2050") {
            setValuesSelect(current => ({ ...current, embarqueFinal: filtrosRedux.embarqueFinal }));
        }
        if (filtrosRedux.embarqueInicio != "01-01-2020") {
            setValuesSelect(current => ({ ...current, embarqueInicio: filtrosRedux.embarqueInicio }));
        }
        if (window.location.href.endsWith('dashboard')) {
            setValuesPesquisa(current => ({ ...current, item: filtrosRedux.item }));
            setValuesPesquisa(current => ({ ...current, pesquisa: filtrosRedux.pesquisa }));
            if (filtrosRedux.embarqueFinal != "01-01-2050") {
                if (filtrosRedux.embarqueFinal === undefined) {
                    var date = new Date();
                    var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
                    const data2 = converterData(ultimoDia);
                    setValuesSelect(current => ({ ...current, embarqueFinal: data2 }));
                    setValuesPesquisa(current => ({ ...current, embarqueFinal: data2 }));
                } else {
                    const data1 = converterData(filtrosRedux.embarqueFinal);
                    setValuesSelect(current => ({ ...current, embarqueFinal: data1 }));
                    setValuesPesquisa(current => ({ ...current, embarqueFinal: data1 }));
                }
            } else {
                var date = new Date();
                var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
                const data2 = converterData(ultimoDia);
                setValuesSelect(current => ({ ...current, embarqueFinal: data2 }));
                setValuesPesquisa(current => ({ ...current, embarqueFinal: data2 }));
            }
            if (filtrosRedux.embarqueInicio != "01-01-2020") {
                if (filtrosRedux.embarqueInicio === undefined) {
                    var date = new Date();
                    var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
                    const data1 = converterData(primeiroDia);
                    setValuesSelect(current => ({ ...current, embarqueInicio: data1 }));
                    setValuesPesquisa(current => ({ ...current, embarqueInicio: data1 }));
                } else {
                    const data1 = converterData(filtrosRedux.embarqueInicio);
                    setValuesSelect(current => ({ ...current, embarqueInicio: data1 }));
                    setValuesPesquisa(current => ({ ...current, embarqueInicio: data1 }));
                }
            } else {
                var date = new Date();
                var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
                const data1 = converterData(primeiroDia);
                setValuesSelect(current => ({ ...current, embarqueInicio: data1 }));
                setValuesPesquisa(current => ({ ...current, embarqueInicio: data1 }));
            }
        }
        if ((window.location.href.endsWith('faturamento') || window.location.href.endsWith("embarque")
            || window.location.href.endsWith("embarque-toledo") || window.location.href.endsWith("embarque-gloria")
            || window.location.href.endsWith("embarque-paranavai") || window.location.href.endsWith("embarque-rio-verde")
            || window.location.href.endsWith("embarque-frota")) && !filtrosRedux.embarqueInicio && !filtrosRedux.embarqueFinal) {
            var date = new Date();
            var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
            var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
            const data1 = converterData(primeiroDia);
            const data2 = converterData(ultimoDia);
            setValuesSelect(current => ({ ...current, embarqueInicio: data1 }));
            setValuesSelect(current => ({ ...current, embarqueFinal: data2 }));
            setValuesPesquisa(current => ({ ...current, embarqueInicio: data1 }));
            setValuesPesquisa(current => ({ ...current, embarqueFinal: data2 }));
        } else if (filtrosRedux.embarqueInicio && filtrosRedux.embarqueFinal) {
            const data1 = converterData(filtrosRedux.embarqueInicio);
            const data2 = converterData(filtrosRedux.embarqueFinal);
            setValuesSelect(current => ({ ...current, embarqueInicio: data1 }));
            setValuesSelect(current => ({ ...current, embarqueFinal: data2 }));
            setValuesPesquisa(current => ({ ...current, embarqueInicio: data1 }));
            setValuesPesquisa(current => ({ ...current, embarqueFinal: data2 }));
        }
    }, []);

    useEffect(() => {
        if (window.location.href.endsWith('faturamento')) {
            setFiltrosRedux({});
            setFiltrosRedux(current => ({ ...current, menu: 'faturamento' }));
        }
        if (window.location.href.endsWith("dashboard")
            || window.location.href.endsWith("dashboard-segundo")
            || window.location.href.endsWith("dashboard-terceiro")) {
            setFiltrosRedux(current => ({ ...current, menu: 'dashboard' }));
        }
        if (window.location.href.endsWith("embarque")
            || window.location.href.endsWith("embarque-toledo")
            || window.location.href.endsWith("embarque-gloria")
            || window.location.href.endsWith("embarque-paranavai")
            || window.location.href.endsWith("embarque-rio-verde")
            || window.location.href.endsWith("embarque-frota")) {
            setFiltrosRedux({});
            setFiltrosRedux(current => ({ ...current, menu: 'embarque' }));
        }
    }, []);

    return (
        <div className={classe}>
            <div className="div-datas">
                <p htmlFor="embarque">Filtrar Por:</p>
                <select
                    className='input-filtro-data'
                    value={valuesPesquisa.item}
                    style={{ width: 150 }}
                    onChange={(e) => {
                        setValuesPesquisa(current => ({ ...current, item: e.target.value }));
                        setFiltrosRedux(current => ({ ...current, item: e.target.value }));
                    }}
                    id='embarque'>
                    <option disabled={valuesPesquisa.item} value="">Selecione</option>
                    {itensFiltro.map((item) => (
                        <option value={item.field}>
                            {item.nomeColuna}
                        </option>
                    ))}
                </select>
            </div>
            <div className="div-datas">
                <p htmlFor="embarque">Pesquisa:</p>
                {
                    loadingInput ? (
                        <>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        </>
                    ) : (
                        <>
                            <input
                                disabled={inputDisable}
                                type={'text'}
                                className='input-filtro-data'
                                value={valuesPesquisa.pesquisa}
                                style={{ width: 150 }}
                                onChange={(e) => {
                                    setValuesPesquisa(current => ({ ...current, pesquisa: e.target.value }));
                                    setFiltrosRedux(current => ({ ...current, pesquisa: e.target.value }));
                                }}
                                id='embarque'>
                            </input>
                        </>
                    )
                }
            </div>
            {(window.location.href.endsWith("dashboard")
                || window.location.href.endsWith("dashboard-segundo")
                || window.location.href.endsWith("dashboard-terceiro")
                || window.location.href.endsWith("faturamento")) && (
                    <>
                        <div className="div-datas">
                            <p htmlFor="embarque">Cidade</p>
                            <select
                                className='input-filtro-data'
                                value={valuesPesquisa.contexto}
                                style={{ width: 120 }}
                                onChange={(e) => {
                                    setValuesPesquisa(current => ({ ...current, contexto: e.target.value }));
                                    setFiltrosRedux(current => ({ ...current, contexto: e.target.value }));
                                }}
                                id='embarque'>
                                {dadosFilialRedux.map((item) => {
                                    if (item.cidade != 'Modelo Embarque') {
                                        return (
                                            <option value={item.selecionado}>
                                                {item.cidade}
                                            </option>
                                        );
                                    }
                                })}
                            </select>
                        </div>
                    </>
                )}
            {(window.location.href.endsWith("embarque")
                || window.location.href.endsWith("embarque-toledo")
                || window.location.href.endsWith("embarque-gloria")
                || window.location.href.endsWith("embarque-paranavai")
                || window.location.href.endsWith("embarque-rio-verde")
                || window.location.href.endsWith("embarque-frota")
                || window.location.href.endsWith("dashboard")
                || window.location.href.endsWith("dashboard-segundo")
                || window.location.href.endsWith("dashboard-terceiro")
                || window.location.href.endsWith("faturamento")) && (
                    <>
                        <div className="div-datas">
                            <p htmlFor="embarque">Embarque Início</p>
                            <input
                                type={'date'}
                                className='input-filtro-data'
                                value={valuesSelect.embarqueInicio}
                                style={{ width: 120 }}
                                onChange={(e) => {
                                    const formattedDate = converterData(e.target.value);
                                    setValuesPesquisa(current => ({ ...current, embarqueInicio: formattedDate }));
                                    setFiltrosRedux(current => ({ ...current, embarqueInicio: formattedDate }));
                                    setValuesSelect(current => ({ ...current, embarqueInicio: e.target.value }));
                                }}
                                id='embarque'>
                            </input>
                        </div>
                        <div className="div-datas">
                            <p htmlFor="embarquef">Embarque Final</p>
                            <input
                                type={'date'}
                                className='input-filtro-data'
                                value={valuesSelect.embarqueFinal}
                                style={{ width: 120 }}
                                onChange={(e) => {
                                    const formattedDate = converterData(e.target.value);
                                    setValuesPesquisa(current => ({ ...current, embarqueFinal: formattedDate }));
                                    setFiltrosRedux(current => ({ ...current, embarqueFinal: formattedDate }));
                                    setValuesSelect(current => ({ ...current, embarqueFinal: e.target.value }));
                                }}
                                id='embarquef'>
                            </input>
                        </div>
                    </>
                )}
            <div className="div-datas">
                <Tooltip title={<p>Filtrar</p>}>
                    <FilterAltIcon2 className="icon-filtro"
                        onClick={(e) => {
                            buscarSearch();
                            // setFiltrosRedux(valuesSelect);
                        }} />
                </Tooltip>
            </div>
            <div className="div-datas">
                <Tooltip title={<p>Limpar Filtro</p>}>
                    <ClearIcon className="icon-filtro"
                        onClick={(e) => {
                            limpar();
                            setValuesPesquisa([]);
                            setFiltrosRedux([]);
                        }}
                    />
                </Tooltip>
            </div>
        </div>
    )
}

export default Filtro;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraMotivo = async (motivo, token) => {
  return api.post("motivo",
    motivo
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getMotivo = async (token) => {
  return api.get("motivo",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getMotivoPorId = async (id, token) => {
  return api.get("motivo/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteMotivoPorId = async (id, token) => {
  return api.delete("motivo/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaMotivoPorId = async (motivo, token) => {
  return api.put("motivo",
  motivo,
    {
      headers: {
        'Authorization': token
      }
    })
}
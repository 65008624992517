import { api } from "./UrlPadrao"

export const cadastraGrupoCargaVinculo = async (grupoCargaVinculo, token) => {
  return api.post("GrupoCargaMotoProp",
    grupoCargaVinculo
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoCargaVinculo = async (token) => {
  return api.get("GrupoCargaMotoProp",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getGrupoCargaVinculoPorId = async (id, token) => {
  return api.get("GrupoCargaMotoProp/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteGrupoCargaVinculoPorId = async (id, token) => {
  return api.delete("GrupoCargaMotoProp/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaGrupoCargaVinculoPorId = async (grupoCargaVinculo, token) => {
  return api.put("GrupoCargaMotoProp",
    grupoCargaVinculo,
  {
    headers: {
      'Authorization': token
    }
  })
}
import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../../components/NavbarV2/NavbarV2";
import InputLabel from "@mui/material/InputLabel";
import "./EnviarEmail.css";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../../contexts/Cor";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import Text from "../../../../components/Text/Text";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras, getUsuarioDependencias } from "../../../../services/ModuloGerenciador/Geral";
import { cadastraListaEmail } from "../../../../services/ModuloGerenciador/Email";
import { useNavigate } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { motion } from "framer-motion";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";

function EnviarEmail() {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [dadosUsuarios, setDadosUsuarios] = useState([]);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const navigate = useNavigate();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ];

    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
        setValues(current => ({ ...current, destinatarios: personName }));
    };

    const buscarDependencias = async () => {
        setLoading(true);
        await getUsuarioDependencias(dadosUsuarioRedux.token).then((result) => {
            setDadosUsuarios(result.data.usuarios);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        values.destinatarios = personName;
        setLoading(true);
        await cadastraListaEmail(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setValues({});
                setPersonName([]);
                setTextoSucesso(result.data);
                setOpenToastSucesso(true);
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    useEffect(() => {
        buscarDependencias();
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Enviar Email') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra == 'Enviar Email') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV2 selecionado="EnviarEmail" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV2 selecionado="EnviarEmail" />
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div>
                                    <div className="header-configuracoes-v2">
                                        <div className="flex">
                                            <p className="indicador-principal-embarque-v2">Enviar Email</p>
                                        </div>
                                    </div>
                                    <div className="principal-enviaremail">
                                        <div className="grid-container">
                                            <div className="grid-item-start">
                                                <p className="input-label-emb" htmlFor="descricao">Lista Usuários:</p>
                                                <FormControl className="testeform" sx={{ width: 250 }}>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        className='select-multiple'
                                                        multiple
                                                        value={personName}
                                                        onChange={handleChange}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {dadosUsuarios.map((user) => (
                                                            <MenuItem key={user.idUsuario} value={user.email}>
                                                                <Checkbox checked={personName.indexOf(user.email) > -1} />
                                                                <ListItemText primary={user.usuario} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="grid-item-start">
                                                <p className="input-label-emb" htmlFor="descricao">Título:</p>
                                                <textarea
                                                    type="text"
                                                    className="input-emb-avisos"
                                                    style={{ width: '100vh' }}
                                                    rows="1"
                                                    onChange={(e) => setValues(current => ({ ...current, assunto: e.target.value }))}
                                                    value={values.assunto}
                                                ></textarea>
                                            </div>
                                            <div className="grid-item-start">
                                                <p className="input-label-emb" htmlFor="descricao">Mensagem:</p>
                                                <textarea
                                                    type="text"
                                                    className="input-emb-avisos"
                                                    style={{ width: '100vh' }}
                                                    rows="20"
                                                    onChange={(e) => setValues(current => ({ ...current, corpo: e.target.value }))}
                                                    value={values.corpo}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="botoes-enviaremail">
                                        <CustomButtonV2
                                            classCss="custom-button-cancelar"
                                            classText="button-texto-cancelar"
                                            titulo="Cancelar"
                                            cor="#0946B8"
                                            valor={values}
                                            onClick={() => { setValues({}); navigate("/") }}
                                        />
                                        <CustomButtonV2
                                            classCss="custom-button-cadastrar"
                                            classText="button-texto-cadastrar"
                                            icone="iconeSave"
                                            titulo="Cadastrar"
                                            cor="#0946B8"
                                            valor={values}
                                            onClick={() => { cadastrar(); }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default EnviarEmail;
import { api } from "./UrlPadrao"

export const cadastraMotivoCotacao = async (motivoCotacao, token) => {
  return api.post("MotivoCotacao",
    motivoCotacao
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotivoCotacao = async (token) => {
  return api.get("MotivoCotacao/motivos",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotivoCotacaoPorId = async (id, token) => {
  return api.get("MotivoCotacao/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteMotivoCotacaoPorId = async (id, token) => {
  return api.delete("MotivoCotacao/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaMotivoCotacaoPorId = async (motivoCotacao, token) => {
  return api.put("MotivoCotacao/" + motivoCotacao.id + "/" + motivoCotacao.motivo,
    motivoCotacao,
  {
    headers: {
      'Authorization': token
    }
  })
}
import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalNotificacao/ModalNotificacao.css';
import CoresRedux from "../../contexts/Cor";
import DadosUsuarioRedux from "../../contexts/Usuario";
import TabelaRedux from "../../contexts/Tabela";
import CustomButton from "../CustomButton/CustomButton";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { visualizaNotificacao } from "../../services/ModuloGerenciador/Notificacoes";
import { verificaDadosUsuarioRedux } from "../../services/ModuloGerenciador/Usuario";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";

export interface SimpleDialogProps {
    titulo: any;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}

function ModalNotificacao(props: SimpleDialogProps) {
    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [values, setValues] = useState({});

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const logout = () => {
        localStorage.removeItem("usuario");
        localStorage.removeItem("token");
        localStorage.removeItem("dadosFilial");
        localStorage.removeItem("expiracao");
        localStorage.removeItem("usuarioTabela");
        localStorage.removeItem("Cookies");
        localStorage.removeItem("flagPassword");
        localStorage.removeItem("expirateTime");
        window.location.reload();
    };

    const visualizarNotificao = async () => {
        const id = localStorage.getItem("notificacaoId");
        await visualizaNotificacao(dadosUsuarioRedux.usuario.idUsuario, props.titulo.notificacaoId, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                onClose();
                localStorage.removeItem("notificacaoId");
                if (props.titulo.acao === "logout") {
                    logout();
                } else {
                    const url = props.titulo.acao.split(' ');
                    if (url[0] == 'redirecionar')
                        window.location.href = url[1];
                }
            }
        }).catch((error) => {
        });
    }

    const handleClick = () => {
        visualizarNotificao();
    };

    const handleClick2 = () => {
        onClose();
    };

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, []);

    return (
        <div>
            <Dialog
                open={open}
            >
                <div className='div-modal-notificacao'>
                    <div>
                        <NotificationsIcon className="icone-notificacao"></NotificationsIcon>
                    </div>
                    {
                        props.titulo === undefined ? (
                            <>
                                <DialogTitle id="alert-dialog-title">
                                    <h2 className="texto-notificacao">Notificação</h2>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <h3 className="texto-notificacao">Nenhuma notificação a ser visualizada</h3>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <CustomButton
                                        classCss="custom-button"
                                        titulo="FECHAR"
                                        cor={dadosCoresRedux.botaoPadrao}
                                        corTexto={dadosCoresRedux.textoBotaoPadrao}
                                        onClick={handleClick2}
                                    />
                                </DialogActions>
                            </>
                        ) : (
                            <>
                                <DialogTitle id="alert-dialog-title">
                                    <h2 className="texto-notificacao">{props?.titulo?.titulo}</h2>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <h3 className="texto-notificacao">{props?.titulo?.mensagem}</h3>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <CustomButtonV2
                                        classCss="custom-button-cadastrar"
                                        classText="button-texto-cadastrar"
                                        titulo="VISUALIZAR"
                                        cor="#0946B8"
                                        onClick={handleClick}
                                    />
                                </DialogActions>
                            </>
                        )
                    }
                </div>
            </Dialog>
        </div>
    );
}

export default ModalNotificacao;
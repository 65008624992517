import React, { useEffect } from 'react';
import { useState, useContext } from "react";
import { loginUser } from "../../../services/ModuloGerenciador/Usuario";
import './LoginV2.css'
import { Card } from 'primereact/card';
import Button from '@mui/material/Button';
import DadosUsuarioRedux from "../../../contexts/Usuario";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Input } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import ToastErro from '../../../components/Toast/ToastErro/ToastErro.tsx'
import Text from "../../../components/Text/Text";
import NomeTabelaRedux from '../../../contexts/NomeTabela';
import CoresRedux from "../../../contexts/Cor";
import MenusRedux from "../../../contexts/Menus";
import RequestControllerRedux from "../../../contexts/RequestController";
import { getEmpresaPorId } from "../../../services/ModuloGerenciador/Administrativo";
import { editaUsuarioTabelaPorId } from '../../../services/ModuloGerenciador/UsuarioTabela';
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import TextLink from '../../../components/TextLink/TextLink';
import { motion } from "framer-motion";
import logoLogin from '../../../images/logoLogin.png'
import iconLock from '../../../images/iconLock.png'
import iconLock2 from '../../../images/iconLock2.png'
import iconeEntrar from '../../../images/iconeEntrar.png'
import TextLinkV2 from '../../../components/TextLink/TextLinkV2';
import { tenantData } from '../../../components/EmpresasTenant';

function LoginV2() {

    const { dadosTabelaRedux, setDadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosCoresRedux, setDadosCoresRedux } = useContext(CoresRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { menusRedux, setDadosMenusRedux } = useContext(MenusRedux);

    const [empresaValue, setEmpresaValue] = useState(dadosTabelaRedux.Embarque[0]);

    const [filialValue, setFilialValue] = useState(dadosTabelaRedux.Embarque[1]);

    const [unidEmbarqueValue, setUnidEmbarqueValue] = useState(dadosTabelaRedux.Embarque[2]);

    const [cidadeOrigemValue, setCidadeOrigemValue] = useState(dadosTabelaRedux.Embarque[3]);

    const [estadoOrigemValue, setEstadoOrigemValue] = useState(dadosTabelaRedux.Embarque[4]);

    const [cidadeDestinoValue, setCidadeDestinoValue] = useState(dadosTabelaRedux.Embarque[5]);

    const [estadoDestinoValue, setEstadoDestinoValue] = useState(dadosTabelaRedux.Embarque[6]);

    const [pedidoValue, setPedidoValue] = useState(dadosTabelaRedux.Embarque[7]);

    const [previsaoEmbarqueValue, setPrevisaoEmbarqueValue] = useState(dadosTabelaRedux.Embarque[8]);

    const [dataEmbarqueValue, setDataEmbarqueValue] = useState(dadosTabelaRedux.Embarque[9]);

    const [previsaoEntregaValue, setPrevisaoEntregaValue] = useState(dadosTabelaRedux.Embarque[10]);

    const [dataEntregaValue, setDataEntregaValue] = useState(dadosTabelaRedux.Embarque[11]);

    // const [dataHoraChegadaEmbarqueValue, setDataHoraChegadaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[12]);

    // const [dataHoraEmbarcadoValue, setDataHoraEmbarcadoValue] = useState(dadosTabelaRedux.Embarque[13]);

    // const [dataHoraChegadaEntregaValue, setDataHoraChegadaEntregaValue] = useState(dadosTabelaRedux.Embarque[14]);

    // const [dataHoraEntregueValue, setDataHoraEntregueValue] = useState(dadosTabelaRedux.Embarque[15]);

    const [aceiteValue, setAceiteValue] = useState(dadosTabelaRedux.Embarque[12]);

    const [statusValue, setStatusValue] = useState(dadosTabelaRedux.Embarque[13]);

    const [placaCavaloValue, setPlacaCavaloValue] = useState(dadosTabelaRedux.Embarque[14]);

    const [placaCarretaValue, setPlacaCarretaValue] = useState(dadosTabelaRedux.Embarque[15]);

    const [placaCarreta2Value, setPlacaCarreta2Value] = useState(dadosTabelaRedux.Embarque[16]);

    const [proprietarioValue, setProprietarioValue] = useState(dadosTabelaRedux.Embarque[17]);

    const [motoristaValue, setMotoristaValue] = useState(dadosTabelaRedux.Embarque[18]);

    const [icmsValue, setIcmsValue] = useState(dadosTabelaRedux.Embarque[19]);

    const [freteMotoristaValue, setFreteMotoristaValue] = useState(dadosTabelaRedux.Embarque[20]);

    const [freteFiscalValue, setFreteFiscalValue] = useState(dadosTabelaRedux.Embarque[21]);

    const [adicionalValue, setAdicionalValue] = useState(dadosTabelaRedux.Embarque[22]);

    const [pedagioValue, setPedagioValue] = useState(dadosTabelaRedux.Embarque[23]);

    const [classificacaoValue, setClassificacaoValue] = useState(dadosTabelaRedux.Embarque[24]);

    const [margemValue, setMargemValue] = useState(dadosTabelaRedux.Embarque[25]);

    const [adiantamentoValue, setAdiamentoValue] = useState(dadosTabelaRedux.Embarque[26]);

    const [meioPagamentoValue, setMeioPagamentoValue] = useState(dadosTabelaRedux.Embarque[27]);

    const [meioPagamento2Value, setMeioPagamento2Value] = useState(dadosTabelaRedux.Embarque[28]);

    const [kmValue, setKmValue] = useState(dadosTabelaRedux.Embarque[29]);

    const [clienteValue, setClienteValue] = useState(dadosTabelaRedux.Embarque[30]);

    const [embarcadorEmbValue, setEmbarcadorEmbValue] = useState(dadosTabelaRedux.Embarque[31]);

    const [destinatarioEmbValue, setDestinatarioEmbValue] = useState(dadosTabelaRedux.Embarque[32]);

    const [loadValue, setLoadValue] = useState(dadosTabelaRedux.Embarque[33]);

    const [mercadoriaValue, setMercadoriaValue] = useState(dadosTabelaRedux.Embarque[34]);

    const [pesoValue, setPesoValue] = useState(dadosTabelaRedux.Embarque[35]);

    const [embalagemEmbValue, setEmbalagemEmbValue] = useState(dadosTabelaRedux.Embarque[36]);

    const [observacaoValue, setObservacaoValue] = useState(dadosTabelaRedux.Embarque[37]);

    const [frotaValue, setFrotaValue] = useState(dadosTabelaRedux.Embarque[38]);

    const [canceladoValue, setCanceladoValue] = useState(dadosTabelaRedux.Embarque[39]);

    const [motivoEmbarqueValue, setMotivoEmbarqueValue] = useState(dadosTabelaRedux.Embarque[40]);

    const [pedidoPValue, setPedidoPValue] = useState(dadosTabelaRedux.Pedido[0]);

    const [tipoRotaValue, setTipoRotaValue] = useState(dadosTabelaRedux.Pedido[1]);

    const [freteFiscalPValue, setFreteFiscalPValue] = useState(dadosTabelaRedux.Pedido[2]);

    const [icmsPValue, setIcmsPValue] = useState(dadosTabelaRedux.Pedido[3]);

    const [pedagioPValue, setPedagioPValue] = useState(dadosTabelaRedux.Pedido[4]);

    const [classificacaoPValue, setClassificacaoPValue] = useState(dadosTabelaRedux.Pedido[5]);

    const [kmPValue, setKmPValue] = useState(dadosTabelaRedux.Pedido[6]);

    const [clientePValue, setClientePValue] = useState(dadosTabelaRedux.Pedido[7]);

    const [embarcadorPValue, setEmbarcadorPValue] = useState(dadosTabelaRedux.Pedido[8]);

    const [embarcadorEstadoValue, setEmbarcadorEstadoValue] = useState(dadosTabelaRedux.Pedido[9]);

    const [destinatarioPValue, setDestinatarioPValue] = useState(dadosTabelaRedux.Pedido[10]);

    const [destinatarioEstadoValue, setDestinatarioEstadoValue] = useState(dadosTabelaRedux.Pedido[11]);

    const [freteMotoristaPValue, setFreteMotoristaPValue] = useState(dadosTabelaRedux.Pedido[12]);

    const [cidadeOrigemPValue, setCidadeOrigemPValue] = useState(dadosTabelaRedux.Pedido[13]);

    const [cidadeDestinoPValue, setcidadeDestinoPValue] = useState(dadosTabelaRedux.Pedido[14]);

    const [observacaoPValue, setObservacaoPValue] = useState(dadosTabelaRedux.Pedido[17]);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // const valorInicialVisivel = {
    //     'Embarque': [
    //         {
    //             'nomeColuna': empresaValue.nomeColuna,
    //             'field': 'empresa',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': filialValue.nomeColuna,
    //             'field': 'filial',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': unidEmbarqueValue.nomeColuna,
    //             'field': 'unidEmbarque',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': cidadeOrigemValue.nomeColuna,
    //             'field': 'cidadeOrigem',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': estadoOrigemValue.nomeColuna,
    //             'field': 'estadoOrigem',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': cidadeDestinoValue.nomeColuna,
    //             'field': 'cidadeDestino',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': estadoDestinoValue.nomeColuna,
    //             'field': 'estadoDestino',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': pedidoValue.nomeColuna,
    //             'field': 'pedido',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': previsaoEmbarqueValue.nomeColuna,
    //             'field': 'previsaoEmbarque',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': dataEmbarqueValue.nomeColuna,
    //             'field': 'dataEmbarque',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': previsaoEntregaValue.nomeColuna,
    //             'field': 'previsaoEntrega',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': dataEntregaValue.nomeColuna,
    //             'field': 'dataEntrega',
    //             'visivel': 'true'
    //         },
    //         // {
    //         //     'nomeColuna': dataHoraChegadaEmbarqueValue.nomeColuna,
    //         //     'field': 'dataHoraChegadaEmbarque',
    //         //     'visivel': 'true'
    //         // },
    //         // {
    //         //     'nomeColuna': dataHoraChegadaEntregaValue.nomeColuna,
    //         //     'field': 'dataHoraChegadaEntrega',
    //         //     'visivel': 'true'
    //         // },
    //         // {
    //         //     'nomeColuna': dataHoraEntregueValue.nomeColuna,
    //         //     'field': 'dataHoraEntregue',
    //         //     'visivel': 'true'
    //         // },
    //         // {
    //         //     'nomeColuna': dataHoraEmbarcadoValue.nomeColuna,
    //         //     'field': 'dataHoraEmbarcado',
    //         //     'visivel': 'true'
    //         // },
    //         {
    //             'nomeColuna': aceiteValue.nomeColuna,
    //             'field': 'aceite',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': statusValue.nomeColuna,
    //             'field': 'status',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': placaCavaloValue.nomeColuna,
    //             'field': 'placaCavalo',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': placaCarretaValue.nomeColuna,
    //             'field': 'placaCarreta',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': placaCarreta2Value.nomeColuna,
    //             'field': 'placaCarreta2',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': proprietarioValue.nomeColuna,
    //             'field': 'proprietario',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': motoristaValue.nomeColuna,
    //             'field': 'motorista',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': icmsValue.nomeColuna,
    //             'field': 'icms',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': freteMotoristaValue.nomeColuna,
    //             'field': 'freteMotorista',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': freteFiscalValue.nomeColuna,
    //             'field': 'freteFiscal',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': adicionalValue.nomeColuna,
    //             'field': 'adicional',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': pedagioValue.nomeColuna,
    //             'field': 'pedagio',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': classificacaoValue.nomeColuna,
    //             'field': 'classificacao',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': margemValue.nomeColuna,
    //             'field': 'margem',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': adiantamentoValue.nomeColuna,
    //             'field': 'adiantamento',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': meioPagamentoValue.nomeColuna,
    //             'field': 'meioPagamento',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': meioPagamento2Value.nomeColuna,
    //             'field': 'meioPagamento2',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': kmValue.nomeColuna,
    //             'field': 'km',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': clienteValue.nomeColuna,
    //             'field': 'cliente',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': embarcadorEmbValue.nomeColuna,
    //             'field': 'embarcador',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': destinatarioEmbValue.nomeColuna,
    //             'field': 'destinatario',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': loadValue.nomeColuna,
    //             'field': 'load',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': mercadoriaValue.nomeColuna,
    //             'field': 'mercadoria',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': pesoValue?.nomeColuna,
    //             'field': 'peso',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': embalagemEmbValue?.nomeColuna,
    //             'field': 'embalagem',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': observacaoValue?.nomeColuna,
    //             'field': 'observacao',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': frotaValue.nomeColuna,
    //             'field': 'frota',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': canceladoValue?.nomeColuna,
    //             'field': 'cancelado',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': motivoEmbarqueValue?.nomeColuna,
    //             'field': 'motivo',
    //             'visivel': 'true'
    //         },
    //     ],
    //     'Pedido': [
    //         {
    //             'nomeColuna': pedidoPValue.nomeColuna,
    //             'field': 'pedido',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': tipoRotaValue.nomeColuna,
    //             'field': 'tipoRota',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': freteFiscalPValue.nomeColuna,
    //             'field': 'freteFiscal',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': icmsPValue.nomeColuna,
    //             'field': 'icms',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': pedagioPValue.nomeColuna,
    //             'field': 'pedagio',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': classificacaoPValue.nomeColuna,
    //             'field': 'classificacao',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': kmPValue.nomeColuna,
    //             'field': 'km',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': clientePValue.nomeColuna,
    //             'field': 'cliente',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': embarcadorPValue.nomeColuna,
    //             'field': 'embarcador',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': embarcadorEstadoValue.nomeColuna,
    //             'field': 'embarcadorEstado',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': destinatarioPValue.nomeColuna,
    //             'field': 'destinatario',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': destinatarioEstadoValue.nomeColuna,
    //             'field': 'destinatarioEstado',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': freteMotoristaPValue.nomeColuna,
    //             'field': 'freteMotorista',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': cidadeOrigemPValue.nomeColuna,
    //             'field': 'cidadeOrigem',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': cidadeDestinoPValue.nomeColuna,
    //             'field': 'cidadeDestino',
    //             'visivel': 'true'
    //         },
    //         {
    //             'nomeColuna': observacaoPValue.nomeColuna,
    //             'field': 'observacao',
    //             'visivel': 'true'
    //         },
    //     ],
    // };

    const buscarEmpresaId = async () => {
        try {
            await getEmpresaPorId(localStorage.getItem('tenant')).then((result) => {
                if (result.status === 200) {
                    const nomeColuna = result.data.colunas.replace(/'/g, '"');
                    const colunas = JSON.parse(nomeColuna);
                    setDadosTabelaRedux(colunas);
                    const nomeCores = result.data.cores.replace(/'/g, '"');
                    const cores = JSON.parse(nomeCores);
                    setDadosCoresRedux(cores);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [values, setValues] = useState({
        login: '',
        password: '',
        tenant: '',
        showPassword: false,
    });

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const [textoErro, setTextoErro] = useState("");

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    useEffect(() => {
        if (localStorage.getItem('tenant')) {
            setValues(current => ({ ...current, tenant: localStorage.getItem('tenant') }));
        }
    }, [])

    useEffect(() => {
        if (values.tenant != '') {
            localStorage.setItem('tenant', values.tenant);
        }
    }, [values.tenant]);

    const logar = async () => {
        setLoading(true);
        buscarEmpresaId();
        try {
            await loginUser(values.login, values.password, values.tenant).then((result) => {
                if (result.status === 200) {
                    window.location.reload(false);
                    let regras = [];
                    regras.push("Home");
                    result.data.regras.forEach((dado) => {
                        regras.push(dado.regras.regra)
                    });
                    dadosUsuarioRedux.usuario = result.data.usuario;
                    dadosUsuarioRedux.token = result.data.token;
                    dadosUsuarioRedux.expiracao = result.data.expiracao;
                    dadosUsuarioRedux.usuarioTabela = result.data.tabelas;
                    dadosUsuarioRedux.regras = regras;
                    setDadosUsuarioRedux(dadosUsuarioRedux);
                    const nomeColuna = result.data.tabelas.replace(/'/g, '"');
                    const usuarioTabelaColunas = JSON.parse(nomeColuna);
                    localStorage.setItem('usuario', JSON.stringify(result.data.usuario));
                    if (result.data.usuario === "") {
                        localStorage.setItem('usuarioTabela', menusRedux);
                    } else {
                        localStorage.setItem('usuarioTabela', JSON.stringify(usuarioTabelaColunas));
                    }
                    localStorage.setItem('token', result.data.token);
                    localStorage.setItem('expiracao', result.data.expiracao);
                    if (values.password === "12345678") {
                        localStorage.setItem("flagPassword", true);
                    } else {
                        window.location.replace('/');
                        navigate("/");
                    }
                    const camposFormatados = JSON.stringify(usuarioTabelaColunas).replace(/"/g, `'`)
                    localStorage.setItem('usuarioTabela', JSON.stringify(usuarioTabelaColunas));
                    const usuarioTabela = {
                        usuarioGerenId: dadosUsuarioRedux.usuario.idUsuario,
                        campoVisiveis: camposFormatados
                    }
                    editaUsuarioTabelaPorId(usuarioTabela, dadosUsuarioRedux.token).then((result) => {
                        setLoading(false);
                        setDadosMenusRedux(usuarioTabelaColunas);
                    }).catch((error) => {
                        if (error.response.status != 200) {
                            setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                        } else {
                            setTextoErro(error.response.data);
                        }
                        setLoading(false);
                    });
                }
            });
        } catch (error) {
            console.error(error);
            if (error.response.data.length < 200) {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (dadosRequestControllerRedux === "Email Codigo") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Seu código foi enviado para o seu email");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Senha Alterada") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Senha alterada com sucesso!");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Salva Senha") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Senha salva com sucesso! Aguarde sua solicitação ser aceita.");
            setOpenToastSucesso(true);
        }
    }, []);

    return (
        <>
            <div className="container-login">
                <img src={logoLogin} alt="logo" className='image-login' />
                <div className="card-login">
                    {!loading && (
                        <>
                            <h1 className="title-login">Login</h1>
                            <form onSubmit={logar}>
                                <div className="input">
                                    <p className='label-login'>Usuário</p>
                                    <input
                                        type="text"
                                        className="input-login"
                                        onChange={handleChange('login')} value={values.login} />
                                    <p className='label-login'>Senha</p>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            className="input-login"
                                            style={{
                                                paddingRight: '40px',
                                                width: '100%',
                                                boxSizing: 'border-box'
                                            }}
                                        />
                                        <div
                                            onClick={handleClickShowPassword}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '10px',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </div>
                                    </div>
                                    <p className='label-login'>Empresa</p>
                                    <select
                                        className="input-login"
                                        value={values.tenant}
                                        onChange={handleChange('tenant')}
                                        id='embarque'>
                                        <option disabled={values.tenant} value="">Selecione</option>
                                        {tenantData.map((item) => (
                                            <option value={item.tenant}>
                                                {item.titulo}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="botao">
                                    <button
                                        type="submit"
                                        className={values.tenant === '' ? "botao-entrar-disabled" : "botao-entrar"}
                                        disabled={values.tenant === ''}
                                    >
                                        <img src={iconeEntrar} />
                                        <p className='text-entrar'>Entrar</p>
                                    </button>
                                    <div className="cadastrese">
                                        <p style={{ marginRight: '5px' }}>Ou </p>
                                        <InputLabel className="titulo-select-cadastro" onClick={() => { navigate("/cadastroUsuario"); }} htmlFor="status">
                                            <TextLinkV2 className="decoration" texto="Cadastrar-se" />
                                        </InputLabel>
                                    </div>
                                </div>
                                {/* <div className="esqueci-senha">
                                    <InputLabel className="titulo-select-cadastro" onClick={() => { navigate("/emailSenha"); }} htmlFor="status">
                                        <TextLinkV2 className="decoration" texto="Esqueci minha senha" />
                                    </InputLabel>
                                    <img className="lock-icon" src={iconLock} />
                                </div> */}
                            </form>
                        </>
                    )}
                    {loading && (
                        <div className="loading"><CircularProgress size={50} /></div>
                    )}
                    <ToastSucesso
                        texto={textoSucesso}
                        open={openToastSucesso}
                        onClose={handleCloseToastSucesso}
                    />
                    <ToastErro
                        texto={textoErro}
                        open={openToastErro}
                        onClose={handleCloseToastErro}
                    />
                </div>
            </div>
        </>
    );
}

export default LoginV2;
import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import InputLabel from "@mui/material/InputLabel";
import "./GrupoComClientes.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getGrupoComClientes, cadastraGrupoComClientes, editaGrupoComClientesPorId, getGrupoComClientesPorId, deleteGrupoComClientesPorId } from "../../../services/ModuloGerenciador/GrupoComCliente";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { Box, LinearProgress } from "@mui/material";
import SelectEmbarque from "../../../components/SelectEmbarque/SelectEmbarque";
import TextLink from "../../../components/TextLink/TextLink";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import { getGrupoClientes } from "../../../services/ModuloGerenciador/GrupoClientes";
import { getCliente } from "../../../services/ModuloGerenciador/Cliente";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import LoadingBar from "../../../components/LoadingBar/LoadingBar";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2";
import NewIcon from "../../../components/NewIcon/NewIcon";

function GrupoComClientes(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [listaGrupos, setListaGrupos] = useState([]);

    const [disable, setDisable] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const [loadingSelectGrupoCliente, setLoadingSelectGrupoCliente] = useState(false);

    const [openCadastroGrupoCliente, setOpenCadastroGrupoCliente] = useState(false);

    const handleCloseCadastroGrupoCliente = (value) => {
        if (idAtual) {
            setOpenCadastroGrupoCliente(false);
        } else {
            setLoadingSelectGrupoCliente(true);
            buscarGrupoClientesSemLoading();
            setOpenCadastroGrupoCliente(false);
        }
    };

    const buscarGrupoClientes = async () => {
        setLoading(true);
        await getGrupoClientes(dadosUsuarioRedux.token).then((result) => {
            setListaGrupos(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarGrupoClientesSemLoading = async () => {
        await getGrupoClientes(dadosUsuarioRedux.token).then((result) => {
            setListaGrupos(result.data);
            setLoadingSelectGrupoCliente(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [listaClientes, setListaClientes] = useState([]);

    const [loadingSelectClientes, setLoadingSelectClientes] = useState(false);

    const [openCadastroClientes, setOpenCadastroClientes] = useState(false);

    const handleCloseCadastroClientes = (value) => {
        if (idAtual) {
            setOpenCadastroClientes(false);
        } else {
            setLoadingSelectClientes(true);
            buscarClientesSemLoading();
            setOpenCadastroClientes(false);
        }
    };

    const buscarClientes = async () => {
        setLoading(true);
        await getCliente(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarClientesSemLoading = async () => {
        await getCliente(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data);
            setLoadingSelectClientes(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [valuesSelect, setValuesSelect] = useState([]);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const preencherDadosEditando = async (result) => {
        const grupoClienteSelecionado = listaGrupos.find(option => option.idGrupoCliente === result.data.grupoClienteId);
        if (grupoClienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                grupoCliente: {
                    value: grupoClienteSelecionado.nome,
                    label: grupoClienteSelecionado.nome,
                    id: grupoClienteSelecionado.idGrupoCliente
                }
            }));
        }
        const clienteSelecionado = listaClientes.find(option => option.idCliente === result.data.clienteId);
        if (clienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                cliente: {
                    value: clienteSelecionado.nomeFantasia,
                    label: clienteSelecionado.nomeFantasia,
                    id: clienteSelecionado.idCliente
                }
            }));
        }
    };

    const buscarGrupoComClientes = async () => {
        setLoading(true);
        await getGrupoComClientes(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.cliente !== "") && (validator.cliente === "true") && (values.grupoCliente !== "") && (validator.grupoCliente === "true")) {
            setLoading(true);
            await cadastraGrupoComClientes(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    setTelaCadastro(false);
                    setValues({});
                    setValuesSelect({});
                    buscarGrupoComClientes();
                    setDadosRequestControllerRedux("Fechar Modal GrupoComClientes");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        if ((values.cliente !== "") && (validator.cliente === "true") && (values.grupoCliente !== "") && (validator.grupoCliente === "true")) {
            setLoading(true);
            await editaGrupoComClientesPorId(values, dadosUsuarioRedux.token).then(async (result) => {
                if (result.status === 200) {
                    buscarGrupoComClientes();
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setTelaCadastro(false);
                    setEditando(false);
                    setValues({});
                    setValuesSelect({});
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const buscarGrupoComClientesPorId = async () => {
        setLoading(true);
        await getGrupoComClientesPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result);
                    setValues(result.data);
                    setValues(current => ({ ...current, idCliente: result.data.clienteId }));
                    setValues(current => ({ ...current, idGrupoCliente: result.data.grupoClienteId }));
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarGrupoComClientesPorIdModal = async (id) => {
        setLoading(true);
        await getGrupoComClientesPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
                await preencherDadosEditando(result);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarGrupoComClientesPorId = async () => {
        setLoading(true);
        await deleteGrupoComClientesPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarGrupoComClientes();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarGrupoComClientesPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarGrupoComClientes();
        buscarGrupoClientes();
        buscarClientes();
        if (props.idAtual) {
            buscarGrupoComClientesPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Fechar Modal GrupoCliente") {
            handleCloseCadastroGrupoCliente();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
    }, [dadosRequestControllerRedux]);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Vincular Clientes') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Vincular Clientes') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    const clearCliente = () => {
        setValuesSelect(current => ({ ...current, cliente: '' }));
        setValues(current => ({ ...current, idCliente: null }));
        setValidator(current => ({ ...current, cliente: "false" }));
    };

    const clearGrupoCliente = () => {
        setValuesSelect(current => ({ ...current, grupoCliente: '' }));
        setValues(current => ({ ...current, idGrupoCliente: null }));
        setValidator(current => ({ ...current, grupoCliente: "false" }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Vincular Clientes" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Vincular Clientes" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Vincular Clientes</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idVinculo"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.GrupoComClientes}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Vincular Clientes >"}</p>
                                                        <p className="indicador-emb">{"Cadastro de Vincular Clientes"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Vincular Clientes" />
                                                            <CloseIcon onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {
                                                                    loadingSelectClientes ? (
                                                                        <LoadingBar />
                                                                    ) : (
                                                                        <div className="coluna-emb">
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Cliente:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={270}
                                                                                    onChange={(e) => {
                                                                                        setValuesSelect(current => ({ ...current, cliente: e }));
                                                                                        setValues(current => ({ ...current, idCliente: e.id }));
                                                                                        setValidator(current => ({ ...current, cliente: "true" }));
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroClientes(true);
                                                                                    }}
                                                                                    clearSelection={clearCliente}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="idCliente"
                                                                                    grande={true}
                                                                                    value={valuesSelect.cliente}
                                                                                    titulo="Grupo Cliente"
                                                                                    dadosSelect={listaClientes}
                                                                                    referenciaId="nomeFantasia"
                                                                                    referenciaItem="nomeFantasia" />
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroClientes(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {
                                                                    loadingSelectGrupoCliente ? (
                                                                        <LoadingBar />
                                                                    ) : (
                                                                        <div className="coluna-emb">
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Grupo Cliente:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={270}
                                                                                    onChange={(e) => {
                                                                                        setValuesSelect(current => ({ ...current, grupoCliente: e }));
                                                                                        setValues(current => ({ ...current, idGrupoCliente: e.id }));
                                                                                        setValidator(current => ({ ...current, grupoCliente: "true" }));
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroGrupoCliente(true);
                                                                                    }}
                                                                                    clearSelection={clearGrupoCliente}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="idGrupoCliente"
                                                                                    grande={true}
                                                                                    value={valuesSelect.grupoCliente}
                                                                                    titulo="Grupo Cliente"
                                                                                    dadosSelect={listaGrupos}
                                                                                    referenciaId="nome"
                                                                                    referenciaItem="nome" />
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroGrupoCliente(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.cliente !== "") && (validator.cliente === "true") && (validator.grupoCliente === "true") && (validator.grupoCliente === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.cliente !== "") && (validator.cliente === "true") && (validator.grupoCliente === "true") && (validator.grupoCliente === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarGrupoComClientesPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroGrupoCliente="sim"
                                        open={openCadastroGrupoCliente}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroGrupoCliente}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCliente="sim"
                                        open={openCadastroClientes}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroClientes}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default GrupoComClientes;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraTemplateEmbarque = async (templateEmbarque, token) => {
  return api.post("embarquetemplete",
    templateEmbarque
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTemplateEmbarque = async (token) => {
  return api.get("embarquetemplete",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTemplateEmbarquePorId = async (id, token) => {
  return api.get("embarquetemplete/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTemplateEmbarquePorId = async (id, token) => {
  return api.delete("embarquetemplete/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTemplateEmbarquePorId = async (templateEmbarque, token) => {
  return api.put("embarquetemplete/",
    templateEmbarque,
  {
    headers: {
      'Authorization': token
    }
  })
}
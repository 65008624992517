import React, { useEffect, useContext, useState } from "react";
import CoresRedux from "../../../contexts/Cor";
import RequestControllerRedux from "../../../contexts/RequestController";
import { useNavigate } from "react-router-dom";
import './HomeV2.css'
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import pdf from '../../../images/Politica_de_Privacidade_-_Gerenciado_de_Transportes.pdf';
import Button from '@mui/material/Button';
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ModalNotificacao from "../../../components/ModalNotificacao/ModalNotificacao.tsx";
import { getNotificacaoPorUsuario } from "../../../services/ModuloGerenciador/Notificacoes";
import { motion } from "framer-motion";
import VerticalCarousel from "../../../components/Carrosel/Carrosel";
import { getAvisosTelaInicial } from "../../../services/ModuloGerenciador/Avisos";
import { getHome } from "../../../services/ModuloGerenciador/Home";
import ModalFilial from "../../../components/ModalFilial/ModalFilial.tsx";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import iconeDash from '../../../images/iconeDash.png'
import iconeEmbarque from '../../../images/iconeEmbarque.png'
import iconeFaturamento from '../../../images/iconeFaturamento.png'
import iconeNavegar from '../../../images/iconeNavegar.png'
import FilialCidade from "../../../contexts/CidadesFilial";
import { height } from "@mui/system";
import DadosContextoEmbarqueRedux from "../../../contexts/ContextoEmbarque";

function HomeV2() {

    const [openCookies, setOpenCookies] = useState(false);

    const handleCloseCookies = (value) => {
        setOpenCookies(false);
    };

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosFilialRedux, setDadosFilialRedux } = useContext(FilialCidade);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);


    const { setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const navigate = useNavigate();

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [textoErro, setTextoErro] = useState("");

    const [textoSucesso, setTextoSucesso] = useState("");

    const [notificacao, setNotificacao] = useState([]);

    const [nomeUsuario, setNomeUsuario] = useState([]);

    const [avisos, setAvisos] = useState([]);

    const [imageSrc, setImageSrc] = useState(null);

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openNotificacao, setOpenNotificacao] = useState(false);

    const handleCloseNotificacao = (value) => {
        setOpenNotificacao(false);
    };

    const [openFilial, setOpenFilial] = useState(false);

    const handleCloseFilial = (value) => {
        setOpenFilial(false);
    };

    const buscarNotificacoes = async () => {
        await getNotificacaoPorUsuario(dadosUsuarioRedux.usuario.idUsuario, dadosUsuarioRedux.token).then((result) => {
            if (result.data != "Nenhuma notificação encontrada.") {
                setNotificacao(result.data);
            }
        });
    }

    const buscaHome = async () => {
        if (!window.location.href.endsWith("login")) {
            await getHome(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                setAvisos(result.data.avisos);
                setNotificacao(result.data.notificacoesUsuario);
                setDadosFilialRedux(result.data.filiais);
                localStorage.setItem("notificacaoId", result.data[0]?.notificacaoId);
                setNomeUsuario(result.data.nome);
                setImageSrc(result.data.logo);
            });
        }
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        // buscarAvisos();
        // buscarNotificacoes();
        buscaHome();
    }, []);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra);
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            });
        }
    })

    useEffect(() => {
        if (localStorage.getItem("flagPassword")) {
            navigate("/editar-usuario");
        }
        // verifica se o usuário tem token!
        const user = localStorage.getItem("token");
        if (user === null) {
            navigate("/login");
        }

        // retorna mensagem de erro de sem acesso!
        const semAcesso = localStorage.getItem("semAcesso");
        if (semAcesso) {
            let semAcessoParsed = JSON.parse(semAcesso)
            if (semAcessoParsed) {
                setOpenToastErro(true);
                setTextoErro("Sem Acesso!")
            }
            localStorage.removeItem("semAcesso");
        }
    })

    useEffect(() => {
        if (!localStorage.getItem("Cookies")) {
            setTimeout(() => {
                setOpenCookies(false);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Email Senha Codigo") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Senha alterada com sucesso!");
            setOpenToastSucesso(true);
        }
    }, []);

    const onClick2 = (item) => {
        setDadosContextoEmbarqueRedux(item.contexto);
        navigate("/embarque");
    }

    const [filialDropdownOpen, setFilialDropdownOpen] = useState(false);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ backgroundColor: dadosCoresRedux.fundoPadrao, height: '100vh' }}
        >
            <NavbarV2 selecionado="Home" notificacoes={notificacao} />
            <div className="central">
                {/* <Sidebar /> */}
                <div className="divImagemV2">
                    <div className="container-bem-vindo">
                        <p className="texto-bem-vindo">Bem-vindo, <span lassName="texto-usuario">{nomeUsuario}</span></p>
                        {imageSrc && (
                            <img className="imagemHomeV2"
                                src={imageSrc && imageSrc.tipo ? `data:${imageSrc.tipo};base64,${imageSrc.dado}` : imageSrc === 'Nenhum arquivo encontrado' ? '' : null}
                                alt="Uploaded preview" />
                        )}
                    </div>
                    <div class="container-home">
                        <div class="card-home" onClick={() => { setFilialDropdownOpen(!filialDropdownOpen); }}>
                            <img src={iconeEmbarque} style={{ gridArea: '1 / 1 / 2 / 2 ' }} />
                            <span className="text-card-home" style={{ gridArea: '1 / 1 / 2 / 2' }}>Embarques</span>
                            <span style={{ gridArea: '2 / 1 / 3 / 2' }}>Acesse e confira a listagem de todos os embarques.</span>
                            <img src={iconeNavegar} style={{ gridArea: '2 / 2 / 3 / 3' }} />
                        </div>
                        <div class="card-home" onClick={() => { navigate("/faturamento"); }}>
                            <img src={iconeFaturamento} style={{ gridArea: '1 / 1 / 2 / 2' }} />
                            <span className="text-card-home" style={{ gridArea: '1 / 1 / 2 / 2' }}>Faturamento</span>
                            <span style={{ gridArea: '2 / 1 / 3 / 2' }}>Filtre e confira os relatórios de faturamento em detalhes.</span>
                            <img src={iconeNavegar} style={{ gridArea: '2 / 2 / 3 / 3' }} />
                        </div>
                        <div class="card-home" onClick={() => { navigate("/dashboard"); }}>
                            <img src={iconeDash} style={{ gridArea: '1 / 1 / 2 / 2' }} />
                            <span className="text-card-home" style={{ gridArea: '1 / 1 / 2 / 2' }}>Dashboards</span>
                            <span style={{ gridArea: '2 / 1 / 3 / 2' }}>Analise os gráficos do seu negócio no modulo de Dashboards.</span>
                            <img src={iconeNavegar} style={{ gridArea: '2 / 2 / 3 / 3' }} />
                        </div>
                    </div>
                    <div className="card-carrosel-2">
                        <p className="texto-avisos">Avisos</p>
                        <VerticalCarousel avisos={avisos} />
                    </div>
                    {filialDropdownOpen && (
                        <div className="filial-dropdown">
                            {dadosFilialRedux.map((item) => {
                                if (item.nomeFilial != 'Modelo Embarque') {
                                    return (
                                        <p onClick={() => { onClick2(item); }}>{item.nomeFilial}</p>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </div>
            <ToastErro
                texto={textoErro}
                open={openToastErro}
                onClose={handleCloseToastErro}
            />
            <ToastSucesso
                texto={textoSucesso}
                open={openToastSucesso}
                onClose={handleCloseToastSucesso}
            />
            <Dialog className="corpoModalCookies" open={openCookies}>
                <DialogTitle>Esse site usa cookies</DialogTitle>
                <p className='texto-cookies'>Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.</p>
                <div className="botaoConcluir">
                    <a className="texto-link" href={pdf} target="_blank">Política de Privacidade</a>
                    <Button
                        sx={{ width: 180 }}
                        variant="contained"
                        onClick={() => { handleCloseCookies(); localStorage.setItem("Cookies", "Aceito") }}>
                        Aceitar
                    </Button>
                </div>
            </Dialog>
            <ModalFilial
                titulo={'Embarques'}
                tituloConfirmar="OK"
                open={openFilial}
                onClose={handleCloseFilial}
            />
            <ModalNotificacao
                titulo={notificacao}
                tituloConfirmar="OK"
                open={openNotificacao}
                onClose={handleCloseNotificacao}
            />
        </motion.div>
    );
}

export default HomeV2;
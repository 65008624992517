import { Button, CircularProgress, Input, InputLabel } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Card } from 'primereact/card';
import "./SalvaSenha.css";
import { salvaSenha } from "../../../../services/ModuloGerenciador/Solicitacao";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import Text from "../../../../components/Text/Text";
import { Navigate, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RequestControllerRedux from "../../../../contexts/RequestController";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextLink from "../../../../components/TextLink/TextLink";
import { motion } from "framer-motion";

function SalvaSenha(props) {

    const navigate = useNavigate();

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [searchParams, setSearchParams] = useSearchParams();

    const searchTerm = searchParams.get('codigo') || '';

    const handleSearch = (value) => {
        const codigo = value;

        if (codigo) {
            setSearchParams({ codigo });
        } else {
            setSearchParams({});
        }
    }

    useEffect(() => {
        setValues(current => ({ ...current, codigo: searchTerm }));
    }, [searchTerm]);

    const [values, setValues] = useState({});

    const [loading, setLoading] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    }

    const enviar = async () => {
        setLoading(true);
        if (values.senha === values.confirmarSenha) {
            if (values.senha != '12345678' && values.confirmarSenha != '12345678') {
                if (values.senha.length >= 8 && values.confirmarSenha.length >= 8) {
                    await salvaSenha(values.senha, values.codigo).then((result) => {
                        if (result.status === 200) {
                            setLoading(false);
                            setTextoSucesso("Senha Salva Com Sucesso");
                            setOpenToastSucesso(true);
                            navigate("/login")
                            setDadosRequestControllerRedux("Salva Senha");
                        }
                    }).catch((error) => {
                        if (error.response.status != 200) {
                            setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                        } else {
                            setTextoErro(error.response.data);
                        }
                        setOpenToastErro(true);
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                    setTextoErro("Senha deve ser maior que 8 caracteres");
                    setOpenToastErro(true);
                }
            } else {
                setLoading(false);
                setTextoErro("Senha deve ser diferente que 12345678");
                setOpenToastErro(true);
            }
        } else {
            setLoading(false);
            setTextoErro("Senhas devem ser iguais");
            setOpenToastErro(true);

        }
    }

    useEffect(() => {
        if (dadosRequestControllerRedux === "Cadastro Sucesso") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Seu código foi enviado para o seu email");
            setOpenToastSucesso(true);
        }
    }, [])


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="image">
            </div>
            <div className="container">
                <Card className="card" title="Defina sua Senha">
                    {!loading && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <div className="input">
                                <InputLabel htmlFor="email"><Text texto="Código" /></InputLabel>
                                <Input id="email" onChange={(e) => {
                                    handleSearch(e.target.value);
                                    setValues(current => ({ ...current, codigo: e.target.value }));
                                }} value={searchTerm}></Input>
                                <InputLabel htmlFor="email"><Text texto="Senha" /></InputLabel>
                                <Input id="standard-adornment-password" onChange={(e) => setValues(current => ({ ...current, senha: e.target.value }))}
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.senha}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toque Para Ver"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>}></Input>
                                <InputLabel htmlFor="email"><Text texto="Confirmar Senha" /></InputLabel>
                                <Input id="standard-adornment-password" onChange={(e) => setValues(current => ({ ...current, confirmarSenha: e.target.value }))}
                                    type={values.showConfirmPassword ? 'text' : 'password'}
                                    value={values.confirmarSenha}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toque Para Ver"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownConfirmPassword}
                                                edge="end"
                                            >
                                                {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>}></Input>
                            </div>
                            <div className="botao-senhas">
                                <Button
                                    sx={{ width: 180 }}
                                    variant="contained"
                                    onClick={() => { enviar(); }}>
                                    ENVIAR
                                </Button>
                                <InputLabel className="titulo-select-cadastro" onClick={() => { navigate("/login"); }} htmlFor="status">
                                    <TextLink className="decoration" texto="Voltar para o login" />
                                </InputLabel>
                            </div>
                        </motion.div>
                    )}
                    {loading && (
                        <div className="loading"><CircularProgress size={50} /></div>
                    )}
                </Card>
            </div>
            <div>
                <ToastSucesso
                    texto={textoSucesso}
                    open={openToastSucesso}
                    onClose={handleCloseToastSucesso}
                />
                <ToastErro
                    texto={textoErro}
                    open={openToastErro}
                    onClose={handleCloseToastErro}
                />
            </div>
        </motion.div>
    );
}

export default SalvaSenha;
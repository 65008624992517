import { api } from "../ModuloGerenciador/UrlPadrao"

export const editaEmpresa = async (token, objeto) => {
  return api.put("empresa/tabelasCores",
    objeto
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmpresaPorId = async (tenant) => {
  if (tenant != null) {
    return api.get("empresa/tenant/" + tenant,
      {
        headers: {
          'X-Tenant': tenant
        }
      }
    );
  }
}
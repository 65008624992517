import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraTipoCheckagem = async (tipoCheckagem, token) => {
  return api.post("tipoCheck",
    tipoCheckagem
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoCheckagem = async (token) => {
  return api.get("tipoCheck",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoCheckagemPorId = async (id, token) => {
  return api.get("tipoCheck/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTipoCheckagemPorId = async (id, token) => {
  return api.delete("tipoCheck/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTipoCheckagemPorId = async (tipoCheckagem, token) => {
  return api.put("tipoCheck",
    tipoCheckagem,
  {
    headers: {
      'Authorization': token
    }
  })
}
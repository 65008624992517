import { api } from "../ModuloGerenciador/UrlPadrao"

export const getEmbarqueDependencias = async (token, contexto) => {
  return api.get("geral/embarqueEDependencias/" + contexto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmbarqueDependenciasData = async (token, contexto, data) => {
  return api.get("geral/embarqueEDependencias/" + contexto + "/" + data,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmbarqueDependenciasCadastroData = async (token, contexto) => {
  return api.get("geral/dependenciasDosEmbarques",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmbarqueGeral= async (token, contexto, data) => {
  return api.get("geral/embarques/" + contexto + "/" + data,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getRegras = async (token, id) => {
  return api.get("regras/regrasUsuario/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getFaturamentoMotorista = async (token, dataInicial, dataFinal) => {
  return api.get(`geral/graficoFaturalmentoMotorista/${dataInicial}/${dataFinal}`,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getPedidoDependencias = async (token) => {
  return api.get("geral/pedidoEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getDependenciasPedidos = async (token) => {
  return api.get("geral/dependenciasPedidos",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getPedidosGeral = async (token) => {
  return api.get("geral/pedidos",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getVeiculoDependencias = async (token) => {
  return api.get("geral/veiculoEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getGrupoCargaDependencias = async (token) => {
  return api.get("geral/grupoCargaEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getUsuarioDependencias = async (token) => {
  return api.get("geral/usuarioEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getRotaDependencias = async (token) => {
  return api.get("geral/rotaEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getUnidadeEmbarqueDependencias = async (token) => {
  return api.get("geral/unidadeEmbarqueEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getFilialDependencias = async (token) => {
  return api.get("geral/filialEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmpresaSubDependencias = async (token) => {
  return api.get("geral/empresaSubEDependencias",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getValorEmbarqueDependencias = async (token) => {
  return api.get("geral/dependenciasValoresEmbarques",
    {
      headers: {
        'Authorization': token
      }
    })
}
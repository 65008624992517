import React from "react";
import logo from '../../images/C1010_azul.png'
import '../SemAutorizacao/SemAutorizacao.css';

function SemAutorizacao() {
    return (
        <>
            <div className="container-sem-autorizacao">
                <h2 className="tituloSemAuto">Ops! você esta sem autorização pra essa pagina!</h2>
                <h4>Em caso de duvidas entre em contato!</h4>
                <p>suporte@c1010.tech</p>
                <p>(45) 9 9959-8011</p>

                <div className="imagem">
                    <img src={logo} alt="logo" style={{ maxWidth: "100%", maxHeight: "60%" }}></img>
                </div>
            </div>
        </>
    );
}

export default SemAutorizacao;
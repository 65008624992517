import { Button, CircularProgress, FormControl, Input, InputLabel } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Card } from 'primereact/card';
import "./RecuperarSenha.css";
import { salvaSenhaEsqueci } from "../../../../services/ModuloGerenciador/Solicitacao";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import Text from "../../../../components/Text/Text";
import { Navigate, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RequestControllerRedux from "../../../../contexts/RequestController";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextLink from "../../../../components/TextLink/TextLink";
import { motion } from "framer-motion";
import ReactSelect from "react-select";
import { tenantData } from "../../../../components/EmpresasTenant";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2.js";
import logoLogin from '../../../../images/logoLogin.png'

function RecuperarSenha(props) {

    const navigate = useNavigate();

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [searchParams, setSearchParams] = useSearchParams();

    const searchTerm = searchParams.get('codigo') || '';

    const handleSearch = (value) => {
        const codigo = value;

        if (codigo) {
            setSearchParams({ codigo });
        } else {
            setSearchParams({});
        }
    }

    useEffect(() => {
        setValues(current => ({ ...current, codigo: searchTerm }));
    }, [searchTerm]);

    const [values, setValues] = useState({});

    const [valuesSelect, setValuesSelect] = useState([]);

    const [loading, setLoading] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    }

    const labelFrota = (data) => (
        <div>
            {data.titulo}
        </div>
    );

    const opcoesFrota = tenantData.map((data) => ({
        value: data.tenant,
        label: data.titulo,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '295px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    const enviar = async () => {
        setLoading(true);
        if (values.senha === values.confirmarSenha) {
            if (values.senha != '12345678' && values.confirmarSenha != '12345678') {
                if (values.senha.length >= 8 && values.confirmarSenha.length >= 8) {
                    await salvaSenhaEsqueci(values.senha, values.codigo, values.tenant).then((result) => {
                        if (result.status === 200) {
                            setLoading(false);
                            setTextoSucesso("Senha Alterada Com Sucesso");
                            setOpenToastSucesso(true);
                            navigate("/login")
                            setDadosRequestControllerRedux("Senha Alterada");
                        }
                    }).catch((error) => {
                        if (error.response.status != 200) {
                            setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                        } else {
                            setTextoErro(error.response.data);
                        }
                        setOpenToastErro(true);
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                    setTextoErro("Senha deve ser maior que 8 caracteres");
                    setOpenToastErro(true);
                }
            } else {
                setLoading(false);
                setTextoErro("Senha deve ser diferente que 12345678");
                setOpenToastErro(true);
            }
        } else {
            setLoading(false);
            setTextoErro("Senhas devem ser iguais");
            setOpenToastErro(true);

        }
    }

    useEffect(() => {
        if (searchParams) {
            setValues(current => ({ ...current, codigo: searchParams }));
        }
    }, [])

    useEffect(() => {
        if (dadosRequestControllerRedux === "Email Codigo") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Seu código foi enviado para o seu email");
            setOpenToastSucesso(true);
        }
    }, []);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
        setShowPassword(!showPassword);
    };

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleClickShowPasswordConfirm = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <img src={logoLogin} alt="logo" className='imageCad' />
            <div className="container">
                <form className="card">
                    {!loading && (
                        <div className="div-card">
                            <h1>Recuperação de Senha</h1>
                            <div className="input-recuperar">
                                <InputLabel htmlFor="email"><Text texto="Código" /></InputLabel>
                                <input
                                    type="text"
                                    className="input-emb"
                                    style={{ width: '100%' }}
                                    id="email"
                                    onChange={(e) => {
                                        handleSearch(e.target.value);
                                        setValues(current => ({ ...current, codigo: e.target.value }));
                                    }}
                                    value={searchTerm} />
                                <InputLabel htmlFor="email"><Text texto="Nova Senha" /></InputLabel>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <input
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.senha}
                                        onChange={(e) => setValues(current => ({ ...current, senha: e.target.value }))}
                                        className="input-emb"
                                        style={{
                                            paddingRight: '40px',
                                            width: '100%',
                                            height: '40px',
                                            boxSizing: 'border-box'
                                        }}
                                    />
                                    <div
                                        onClick={handleClickShowPassword}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                </div>
                                <InputLabel htmlFor="email"><Text texto="Confirmar Senha" /></InputLabel>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <input
                                        type={values.showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirmarSenha}
                                        onChange={(e) => setValues(current => ({ ...current, confirmarSenha: e.target.value }))}
                                        className="input-emb"
                                        style={{
                                            paddingRight: '40px',
                                            width: '100%',
                                            height: '40px',
                                            boxSizing: 'border-box'
                                        }}
                                    />
                                    <div
                                        onClick={handleClickShowPasswordConfirm}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                </div>
                                <InputLabel htmlFor="senha"><Text texto="Empresa" /></InputLabel>
                                <FormControl sx={{ width: '100%'}}>
                                    <ReactSelect
                                        value={valuesSelect.frota}
                                        options={opcoesFrota}
                                        formatOptionLabel={labelFrota}
                                        styles={estiloFrota}
                                        placeholder="Selecione"
                                        noOptionsMessage={() => "Não há mais opções"}
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                setValues((current) => ({
                                                    ...current,
                                                    tenant: selectedOption.tenant,
                                                }));
                                            }
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="botao-senhas">
                                <CustomButtonV2
                                    classCss="custom-button-cadastrar"
                                    classText="button-texto-cadastrar"
                                    titulo="ENVIAR"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => { enviar(); }}
                                />
                                <InputLabel className="titulo-select-cadastro" onClick={() => { navigate("/login"); }} htmlFor="status">
                                    <p className="decoration">Voltar para o login</p>
                                </InputLabel>
                            </div>
                        </div>
                    )}
                    {loading && (
                        <div className="loading"><CircularProgress size={50} /></div>
                    )}
                </form>
            </div>
            <div>
                <ToastSucesso
                    texto={textoSucesso}
                    open={openToastSucesso}
                    onClose={handleCloseToastSucesso}
                />
                <ToastErro
                    texto={textoErro}
                    open={openToastErro}
                    onClose={handleCloseToastErro}
                />
            </div>
        </motion.div>
    );
}

export default RecuperarSenha;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraCheckagem = async (checkagem, token) => {
  return api.post("checklist",
    checkagem
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getCheckagem = async (token) => {
  return api.get("checklist",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCheckagemPorId = async (id, token) => {
  return api.get("checklist/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTodosCarreta = async (id, token) => {
  return api.get("checklist/todosCarretas/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTodosCaminhao = async (id, token) => {
  return api.get("checklist/todosCaminhoes/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteCheckagemPorId = async (id, token) => {
  return api.delete("checklist/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCheckagemPorId= async (checkagem, token) => {
  return api.put("checklist",
    checkagem,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCheckagemPorIdCarreta = async (checkagem, id, token) => {
  return api.put("checklist/carreta/" + id,
    checkagem,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCheckagemPorIdCaminhao = async (checkagem, id, token) => {
  return api.put("checklist/caminhao/" + id,
    checkagem,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCheckagemPorIdVinculo = async (checkagem, token) => {
  return api.put("checkList/vinculo",
    checkagem,
  {
    headers: {
      'Authorization': token
    }
  })
}
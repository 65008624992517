import { api } from "../ModuloGerenciador/UrlPadrao"

export const getDashboard1 = async (token, contexto, dataInicio, dataFim) => {
  return api.get("dashboard/pagina1/" + contexto + "/" + dataInicio + "/" + dataFim,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getDashboard2 = async (token, contexto, dataInicio, dataFim) => {
  return api.get("dashboard/pagina2/" + contexto + "/" + dataInicio + "/" + dataFim,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getDashboard3 = async (token, contexto, dataInicio, dataFim, frota) => {
  return api.get("dashboard/pagina3/" + contexto + "/" + dataInicio + "/" + dataFim + "/" + frota,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getDashboard4 = async (token, contexto, dataInicio, dataFim, frota) => {
  return api.get("dashboard/pagina4/" + contexto + "/" + dataInicio + "/" + dataFim + "/" + frota,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getDashboardCockpit = async (token, contexto, dataInicio, dataFim, idGrupoClientes) => {
  return api.get("dashboard/cockpit/" + contexto + "/" + dataInicio + "/" + dataFim + "/" + idGrupoClientes,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getDashboardResponsaveis = async (token, contexto, dataInicio, dataFim) => {
  return api.get("dashboard/Responsaveis/" + contexto + "/" + dataInicio + "/" + dataFim,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getDashboardResponsaveisData = async (token, contexto, data) => {
  return api.get("dashboard/Responsaveis/Mes/" + contexto + "/" + data,
    {
      headers: {
        'Authorization': token
      }
    })
}

export default api;
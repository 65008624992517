import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import "./Embarque.css";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import DadosContextoEmbarqueRedux from "../../../contexts/ContextoEmbarque";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getEmbarque, getEmbarquePorId, deleteEmbarquePorId, getHistoricoEmbarque, cancelarEmbarque, lancarCte, lancarStatus, comprovanteEntregue, lancarCheckin, lancarEmbalagem, lancarMercadoria, lancarVeiculo, definirMeiosPagamentos } from "../../../services/ModuloGerenciador/Embarque";
import { getEmbarqueDependencias, getEmbarqueDependenciasCadastroData, getEmbarqueGeral, getGrupoCargaDependencias } from "../../../services/ModuloGerenciador/Geral";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import ModalHistoricoEdicao from "../../../components/ModalHistoricoEdicao/ModalHistoricoEdicao.tsx";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import FiltrosRedux from "../../../contexts/Filtros";
import { deleteTemplateEmbarquePorId, getTemplateEmbarque, getTemplateEmbarquePorId } from "../../../services/ModuloGerenciador/TemplateEmbarque";
import { getEmbarqueDiariaSearch, getEmbarqueSearch } from "../../../services/ModuloGerenciador/Search";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2";
import iconeAnterior from "../../../images/iconeAnterior.png";
import iconeProximo from "../../../images/iconeProximo.png";
import iconeInfo from "../../../images/iconeInfo2.png";
import Tooltip from '@mui/material/Tooltip';
import EmbarqueStepperV2 from "./StepperV2/EmbarqueStepperV2";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import ModalProposta from "../../../components/ModalProposta/ModalProposta.tsx";
import { enviarProposta } from "../../../services/ModuloGerenciador/Proposta";
import FilialCidade from "../../../contexts/CidadesFilial.js";
import ModalEmbarque from "../../../components/ModalEmbarque/ModalEmbarque.tsx";
import ModalOnTime from "../../../components/ModalOnTime/ModalOnTime.tsx";
import { getMotivoOnTime, vincularMotivoPorId } from "../../../services/ModuloGerenciador/MotivoOnTime.js";
import { getMotivo } from "../../../services/ModuloGerenciador/Motivo.js";
import ModalLancarCTE from "../../../components/ModalLancarCTE/ModalLancarCTE.tsx";
import ModalLancarStatus from "../../../components/ModalLancarStatus/ModalLancarStatus.tsx";
import { getStatus } from "../../../services/ModuloGerenciador/Status.js";
import { getEmbalagem } from "../../../services/ModuloGerenciador/Embalagem.js";
import { getMercadoria } from "../../../services/ModuloGerenciador/Mercadoria.js";
import ModalLancarEmbalagem from "../../../components/ModalLancarEmbalagem/ModalLancarEmbalagem.tsx";
import ModalLancarMercadoria from "../../../components/ModalLancarMercadoria/ModalLancarMercadoria.tsx";
import ModalLancarVeiculo from "../../../components/ModalLancarVeiculo/ModalLancarVeiculo.tsx";
import { getVeiculo } from "../../../services/ModuloGerenciador/Veiculo.js";
import { getMeioPagamento } from "../../../services/ModuloGerenciador/MeioPagamento.js";
import ModalLancarMeioPagamento from "../../../components/ModalLancarMeioPagamento/ModalLancarMeioPagamento.tsx";
import { buscaSMP, insereSMP } from "../../../services/ModuloGerenciador/ApiSul.js";
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import TabelaLow from "../../../components/TabelaLow/TabelaLow.js";
import ModalListaErros from "../../../components/ModalListaErros/ModalListaErros.tsx";
import ModalStatusSMP from "../../../components/ModalStatusSMP/ModalStatusSMP.tsx";
import iconeDownload from "../../../images/iconeDownload.png";
import iconeConfig from "../../../images/iconeConfig.png";
import { CSVLink } from "react-csv";
import { IconButton } from "@mui/material";
import ModalConfigTabela from "../../../components/ModalConfigTabela/ModalConfigTabela.tsx";
import { useLocation } from "react-router-dom";

function Embarque() {

    const acoesData = [
        {
            titulo: 'Buscar Status SMP',
            onClick: () => {
                setEmbarqueIdSMP(tabelaRedux.colunaSelecionada);
            },
        },
        {
            titulo: 'Inserir SMP',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenConfirmar(true);
            },
        },
        {
            titulo: 'Definir Meios de Pagamento',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenMeioPagamento(true);
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Informar Comprovante',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                definirComprovante();
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Informar Checkin Concluído',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                definirCheck();
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Lançar Status',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenStatus(true);
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Lançar Mercadoria',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenMercadoria(true);
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Lançar Embalagem',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenEmbalagem(true);
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Lançar Veículo',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenVeiculo(true);
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Lançar CTE',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenCTE(true);
                setOpcoesOpen(false);
            },
        },
        {
            titulo: 'Motivo Embarque',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                if (tabelaRedux?.row?.necessitaMotivoEmbarque === true) {
                    setOpcoesOpen(false);
                    setIsEmbarque(true);
                    setIsEntrega(false);
                    setOpenMotivoOnTime(true);
                } else {
                    setTextoErro('Carga não necessita de motivo');
                    setOpenToastErro(true);
                }
            },
        },
        {
            titulo: 'Motivo Entrega',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                if (tabelaRedux?.row?.necessitaMotivoEntrega === true) {
                    setOpcoesOpen(false);
                    setIsEmbarque(false);
                    setIsEntrega(true);
                    setOpenMotivoOnTime(true);
                } else {
                    setTextoErro('Carga não necessita de motivo');
                    setOpenToastErro(true);
                }
            },
        },
        {
            titulo: 'Cancelar Carga',
            onClick: () => {
                setEmbarqueId(tabelaRedux.colunaSelecionada);
                setOpenMotivoCancelado(true);
                setOpcoesOpen(false);
            },
        },
    ];

    const [openCTE, setOpenCTE] = useState(false);

    const handleCloseCTE = (value) => {
        setCTE(null);
        setOpenCTE(false);
    };

    const [openStatus, setOpenStatus] = useState(false);

    const handleCloseStatus = (value) => {
        setStatus(null);
        setOpenStatus(false);
    };

    const [openEmbalagem, setOpenEmbalagem] = useState(false);

    const handleCloseEmbalagem = (value) => {
        setEmbalagem(null);
        setOpenEmbalagem(false);
    };

    const [openMercadoria, setOpenMercadoria] = useState(false);

    const handleCloseMercadoria = (value) => {
        setMercadoria(null);
        setOpenMercadoria(false);
    };

    const [openVeiculo, setOpenVeiculo] = useState(false);

    const handleCloseVeiculo = (value) => {
        setVeiculo(null);
        setOpenVeiculo(false);
    };

    const [openMeioPagamento, setOpenMeioPagamento] = useState(false);

    const handleCloseMeioPagamento = (value) => {
        setMeioPagamento1(null);
        setMeioPagamento2(null);
        setOpenMeioPagamento(false);
    };

    const [openMotivoOnTime, setOpenMotivoOnTime] = useState(false);

    const handleCloseMotivoOnTime = (value) => {
        setOpenMotivoOnTime(false);
    };

    const [openMotivoCancelado, setOpenMotivoCancelado] = useState(false);

    const handleCloseMotivoCancelado = (value) => {
        setOpenMotivoCancelado(false);
    };

    const [listaMotivos, setListaMotivos] = useState([]);

    const [listaMotivosOnTime, setListaMotivosOnTime] = useState([]);

    const [motivoId, setMotivoId] = useState();

    const [motivoId2, setMotivoId2] = useState();

    const [embarqueId, setEmbarqueId] = useState();

    const [embarqueIdSMP, setEmbarqueIdSMP] = useState();

    useEffect(() => {
        if (embarqueIdSMP != undefined) {
            buscarSMP();
        }
    }, [embarqueIdSMP]);

    const [cte, setCTE] = useState();

    const [status, setStatus] = useState();

    const [embalagem, setEmbalagem] = useState();

    const [mercadoria, setMercadoria] = useState();

    const [veiculo, setVeiculo] = useState();

    const [meioPagamento1, setMeioPagamento1] = useState();

    const [meioPagamento2, setMeioPagamento2] = useState();

    const [motorista, setMotorista] = useState();

    const [nomeMotivo, setNomeMotivo] = useState();

    const [isEmbarque, setIsEmbarque] = useState(false);

    const [isEntrega, setIsEntrega] = useState(false);

    const buscarMeioPagamento = async () => {
        setLoadingSelectMeioPagamento(true);
        await getMeioPagamento(dadosUsuarioRedux.token).then((result) => {
            setListaMeioPagamento(result.data);
            setLoadingSelectMeioPagamento(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarVeiculos = async () => {
        setLoadingSelectVeiculo(true);
        await getVeiculo(dadosUsuarioRedux.token).then((result) => {
            setListaVeiculos(result.data);
            setLoadingSelectVeiculo(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarStatus = async () => {
        setLoadingSelectStatus(true);
        await getStatus(dadosUsuarioRedux.token).then((result) => {
            setListaStatus(result.data);
            setLoadingSelectStatus(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarMercadoria = async () => {
        setLoadingSelectMercadoria(true);
        await getMercadoria(dadosUsuarioRedux.token).then((result) => {
            setListaMercadoria(result.data);
            setLoadingSelectMercadoria(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarEmbalagem = async () => {
        setLoadingSelectEmbalagem(true);
        await getEmbalagem(dadosUsuarioRedux.token).then((result) => {
            setListaEmbalagens(result.data);
            setLoadingSelectEmbalagem(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarMotivo = async () => {
        setLoadingSelectMotivo(true);
        await getMotivoOnTime(dadosUsuarioRedux.token).then((result) => {
            setListaMotivosOnTime(result.data);
            setLoadingSelectMotivo(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarMotivos = async () => {
        setLoadingSelectMotivo(true);
        await getMotivo(dadosUsuarioRedux.token).then((result) => {
            setListaMotivos(result.data);
            setLoadingSelectMotivo(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarDependenciasGeral = async () => {
        if (dadosContextoEmbarqueRedux) {
            await getEmbarqueDependenciasCadastroData(tokenApi, dadosContextoEmbarqueRedux).then((result) => {
                setListaMotivos(result.data.motivo);
                setListaEmbalagens(result.data.embalagem);
                setListaStatus(result.data.status);
                setListaMeioPagamento(result.data.meioPagamento);
                setListaMercadoria(result.data.mercadoria);
                setListaVeiculos(result.data.veiculo);
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
            });
        }
    }

    useEffect(() => {
        buscarDependenciasGeral();
        buscarMotivo();
    }, []);

    const itensFiltro = [
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Embarque',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Data Embarque',
            'field': 'dataEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Embarque',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
        {
            'nomeColuna': 'Usuário Responsável',
            'field': 'usuarioResponsavel'
        },
    ];

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const [contextoCadastro, setContextoCadastro] = useState('');

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    // useEffect(() => {
    //     if (filtrosRedux.length === 0) {
    //         if (dataPesquisa !== undefined) {
    //             buscarDependenciasDataInicial(dataPesquisa);
    //         }
    //     } else {
    //         const dataInicio = filtrosRedux.embarqueInicio;
    //         const dataFinal = filtrosRedux.embarqueFinal;
    //         if (dataInicio != '' && dataFinal != '') {
    //             setLoading(true);
    //             // Filtrar os dados com base nas datas
    //             const dadosFiltrados = dadosTabelaCopia.filter((item) => {
    //                 const dateFinal = new Date(item.dataEmbarque);
    //                 var ddFinal = String(dateFinal?.getDate()).padStart(2, '0');
    //                 var mmFinal = String(dateFinal?.getMonth() + 1).padStart(2, '0');
    //                 var yyyyFinal = dateFinal?.getFullYear();
    //                 var dateFinalFormated = yyyyFinal + '-' + mmFinal + '-' + ddFinal;
    //                 return dateFinalFormated >= dataInicio && dateFinalFormated <= dataFinal;
    //             });
    //             // Atualizar o estado dadosTabela com os dados filtrados
    //             setDadosTabela(dadosFiltrados);
    //             setTimeout(() => {
    //                 setLoading(false);
    //             }, 200);
    //         }
    //     }
    // }, [filtrosRedux]);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Cadastro False") {
            setTelaCadastro(false);
            setEditando(false);
            setDadosRequestControllerRedux("");
        }
        if (dadosRequestControllerRedux === "Cadastro False Sucesso") {
            setTelaCadastro(false);
            setEditando(false);
            if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                buscarTemplates();
            } else {
                handleSearch();
            }
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Close Motivo") {
            buscarMotivo();
            buscarMotivos();
            setDadosRequestControllerRedux("");
        }
        if (dadosRequestControllerRedux === "Close Status") {
            buscarStatus();
            setDadosRequestControllerRedux("");
        }
        if (dadosRequestControllerRedux === "Close Mercadoria") {
            buscarMercadoria();
            setDadosRequestControllerRedux("");
        }
        if (dadosRequestControllerRedux === "Close Embalagem") {
            buscarEmbalagem();
            setDadosRequestControllerRedux("");
        }
        if (dadosRequestControllerRedux === "Close Veiculo") {
            buscarVeiculos();
            setDadosRequestControllerRedux("");
        }
    }, [dadosRequestControllerRedux]);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [somatorioFreteIcms, setSomatorioFreteIcms] = useState([]);

    const [somatorioFreteIcmsCancelados, setSomatorioFreteIcmsCancelados] = useState([]);

    const [cardSomatorio, setCardSomatorio] = useState(false);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const [dadosTabelaCopiaFiltro, setDadosTabelaCopiaFiltro] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [verificaMotivo, setVerificaMotivo] = useState(true);

    const [verificaMotorista2, setVerificaMorista2] = useState(true);

    const [numeroEmbarque, setNumeroEmbarques] = useState(0);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [pedido, setPedido] = useState([]);

    const [listaUnidades, setListaUnidades] = useState([]);

    const [listaEmpresas, setListaEmpresas] = useState([]);

    const [listaFilial, setListaFilial] = useState([]);

    const [listaVeiculos, setListaVeiculos] = useState([]);

    const [listaEmbalagens, setListaEmbalagens] = useState([]);

    const [listaStatus, setListaStatus] = useState([]);

    const [listaMeioPagamento, setListaMeioPagamento] = useState([]);

    const [listaMotivo, setListaMotivo] = useState([]);

    const [listaMercadoria, setListaMercadoria] = useState([]);

    const [valoresHistorico, setValoresHistorico] = useState([]);

    const [openHistoricoEdicao, setOpenHistoricoEdicao] = useState(false);

    const [openCadastroPedido, setOpenCadastroPedido] = useState(false);

    const [loadingSelectPedido, setLoadingSelectPedido] = useState(false);

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [selecioneOpen, setSelecioneOpen] = useState(false);

    const [opcoesOpen, setOpcoesOpen] = useState(false);

    const [viewTable, setViewTable] = useState(true);

    useEffect(() => {
        const storedViewTable = localStorage.getItem('viewTable');
        if (storedViewTable === null) {
            setViewTable(true);
        } else {
            setViewTable(storedViewTable === 'true');
        }
    }, []);

    const onClickOpenProjecao = () => {
        const newViewTable = !viewTable;
        localStorage.setItem('viewTable', newViewTable);
        setViewTable(newViewTable);
    };

    useEffect(() => {
        const storedCardSomatorio = localStorage.getItem('cardSomatorio');
        if (storedCardSomatorio === null) {
            setCardSomatorio(false);
        } else {
            setCardSomatorio(storedCardSomatorio === 'true');
        }
    }, []);

    const onClickOpenSomatorio = () => {
        const newCardSomatorio = !cardSomatorio;
        localStorage.setItem('cardSomatorio', newCardSomatorio);
        setCardSomatorio(newCardSomatorio);
    };

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopiaFiltro);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const handleSearch = (ordenarPor) => {
        if (estadia) {
            buscarSearchDiaria(ordenarPor);
        } else {
            buscarSearch(ordenarPor)
        }
    }

    const buscarOrdenacao = (ordenarPor) => {
        handleSearch(ordenarPor)
    }

    const buscarSearch = async (ordenarPor) => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getEmbarqueSearch(dadosUsuarioRedux.token, pesquisaFormatada, dadosContextoEmbarqueRedux, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal, ordenarPor).then((result) => {
            setDadosTabela(result.data);
            const listaEmbarques = [];
            result.data.forEach((embarque) => {
                if (embarque.cancelado === false) {
                    listaEmbarques.push(embarque);
                }
            })
            setNumeroEmbarques(listaEmbarques.length)
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const buscarSearchDiaria = async (ordenarPor) => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getEmbarqueDiariaSearch(dadosUsuarioRedux.token, pesquisaFormatada, dadosContextoEmbarqueRedux, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal, ordenarPor).then((result) => {
            setDadosTabela(result.data);
            const listaEmbarques = [];
            result.data.forEach((embarque) => {
                if (embarque.cancelado === false) {
                    listaEmbarques.push(embarque);
                }
            })
            setNumeroEmbarques(listaEmbarques.length)
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const [dataPesquisa, setDataPesquisa] = useState();

    const [mesAtual, setMesAtual] = useState();

    const [anoAtual, setAnoAtual] = useState();

    const [periodoCompleto, setCompleto] = useState();

    const [estadia, setEstadia] = useState();

    const mesAnterior = () => {
        setOpcoesOpen(false);
        setCompleto(false);
        const date = new Date(dataPesquisa);
        date.setMonth(date.getMonth() - 1);
        const mesPorExtenso = date.toLocaleString('pt-BR', { month: 'long' });
        setMesAtual(mesPorExtenso);
        var ddFinal = String(date.getDate()).padStart(2, '0');
        var mmFinal = String(date.getMonth() + 1).padStart(2, '0');
        var yyyyFinal = date.getFullYear();
        setAnoAtual(yyyyFinal);
        var dateFinalFormated = `${yyyyFinal}-${mmFinal}-${ddFinal}`;
        setDataPesquisa(dateFinalFormated);
    };

    const mesSeguinte = () => {
        setCompleto(false);
        setOpcoesOpen(false);
        const date = new Date(dataPesquisa);
        date.setMonth(date.getMonth() + 1);
        const mesPorExtenso = date.toLocaleString('pt-BR', { month: 'long' });
        setMesAtual(mesPorExtenso);
        var ddFinal = String(date.getDate()).padStart(2, '0');
        var mmFinal = String(date.getMonth() + 1).padStart(2, '0');
        var yyyyFinal = date.getFullYear();
        setAnoAtual(yyyyFinal);
        var dateFinalFormated = `${yyyyFinal}-${mmFinal}-${ddFinal}`;
        setDataPesquisa(dateFinalFormated);
    };

    useEffect(() => {
        if (dataPesquisa != undefined) {
            buscarDependenciasData();
        }
    }, [dataPesquisa]);

    const setPeriodoCompleto = (boolean) => {
        if (boolean === true) {
            buscarDependencias();
        } else {
            buscarDependenciasData();
        }
    }

    const setFiltroEstadia = (boolean) => {
        if (boolean === true) {
            buscarSearchDiaria();
        } else {
            buscarDependenciasData();
        }
    }

    const handleCloseCadastroPedido = (value) => {
        setLoading(true);
        setLoadingSelectPedido(true);
        buscarDependenciasSemLoading();
        setOpenCadastroPedido(false);
    };

    const [openCadastroUnidadeEmbarque, setOpenCadastroUnidadeEmbarque] = useState(false);

    const [loadingSelectUnidadeEmbarque, setLoadingSelectUnidadeEmbarque] = useState(false);

    const handleCloseCadastroUnidadeEmbarque = (value) => {
        setLoadingSelectUnidadeEmbarque(true);
        buscarDependenciasSemLoading();
        setOpenCadastroUnidadeEmbarque(false);
    };

    const [openCadastroFilial, setOpenCadastroFilial] = useState(false);

    const [loadingSelectFilial, setLoadingSelectFilial] = useState(false);

    const handleCloseCadastroFilial = (value) => {
        setLoadingSelectFilial(true);
        buscarDependenciasSemLoading();
        setOpenCadastroFilial(false);
    };

    const [openCadastroEmpresa, setOpenCadastroEmpresa] = useState(false);

    const [loadingSelectEmpresa, setLoadingSelectEmpresa] = useState(false);

    const handleCloseCadastroEmpresa = (value) => {
        setLoadingSelectEmpresa(true);
        buscarDependenciasSemLoading();
        setOpenCadastroEmpresa(false);
    };

    const [openCadastroVeiculo, setOpenCadastroVeiculo] = useState(false);

    const [loadingSelectVeiculo, setLoadingSelectVeiculo] = useState(false);

    const handleCloseCadastroVeiculo = (value) => {
        setLoading(true);
        setLoadingSelectVeiculo(true);
        buscarDependenciasSemLoading();
        setOpenCadastroVeiculo(false);
    };

    const [openCadastroMercadoria, setOpenCadastroMercadoria] = useState(false);

    const [loadingSelectMercadoria, setLoadingSelectMercadoria] = useState(false);

    const handleCloseCadastroMercadoria = (value) => {
        setLoadingSelectMercadoria(true);
        buscarDependenciasSemLoading();
        setOpenCadastroMercadoria(false);
    };

    const [openCadastroEmbalagem, setOpenCadastroEmbalagem] = useState(false);

    const [loadingSelectEmbalagem, setLoadingSelectEmbalagem] = useState(false);

    const handleCloseCadastroEmbalagem = (value) => {
        setLoadingSelectEmbalagem(true);
        buscarDependenciasSemLoading();
        setOpenCadastroEmbalagem(false);
    };

    const [openCadastroStatus, setOpenCadastroStatus] = useState(false);

    const [loadingSelectStatus, setLoadingSelectStatus] = useState(false);

    const handleCloseCadastroStatus = (value) => {
        setLoadingSelectStatus(true);
        buscarDependenciasSemLoading();
        setOpenCadastroStatus(false);
    };

    const [openCadastroMotivo, setOpenCadastroMotivo] = useState(false);

    const [loadingSelectMotivo, setLoadingSelectMotivo] = useState(false);

    const handleCloseCadastroMotivo = (value) => {
        setLoadingSelectMotivo(true);
        buscarDependenciasSemLoading();
        setOpenCadastroMotivo(false);
    };

    const [openCadastroMeioPagamento, setOpenCadastroMeioPagamento] = useState(false);

    const [loadingSelectMeioPagamento, setLoadingSelectMeioPagamento] = useState(false);

    const handleCloseCadastroMeioPagamento = (value) => {
        setLoadingSelectMeioPagamento(true);
        buscarDependenciasSemLoading();
        setOpenCadastroMeioPagamento(false);
    };

    const [openConfirmar, setOpenConfirmar] = useState(false);

    const handleCloseConfirmar = (value) => {
        setOpenConfirmar(false);
    };

    const [openModalInformacoes, setOpenModalInformacoes] = useState(false);

    const handleCloseModalInformacoes = (value) => {
        setOpenModalInformacoes(false);
    };
    const [statusSMP, setStatusSMP] = useState(null);

    const [mensagensErro, setMensagensErro] = useState([]);
    const [mensagensAviso, setMensagensAviso] = useState([]);
    const [mensagensSucesso, setMensagensSucesso] = useState([]);

    const [openListaErro, setOpenListaErro] = useState(false);

    const handleCloseListaErro = (value) => {
        setOpenListaErro(false);
        handleSearch();
    };

    const handleCloseHistoricoEdicao = (value) => {
        setOpenHistoricoEdicao(false);
    }

    const [todos, setTodos] = useState(false);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [openProposta, setOpenProposta] = useState(false);

    const handleCloseProposta = (value) => {
        setOpenProposta(false);
    };

    const [grupoCargaId, setGrupoCargaId] = useState();

    const [motoristasId, setMotoristasId] = useState([]);

    const [proprietariosId, setProprietariosId] = useState([]);

    const [listaGrupoCargas, setListaGrupoCarga] = useState([]);

    const [listaMotoristas, setListaMotorista] = useState([]);

    const [listaProprietarios, setListaProprietario] = useState([]);

    const buscarGrupoCargaVinculoDependencias = async () => {
        await getGrupoCargaDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaGrupoCarga(result.data.grupoCarga);
            setListaMotorista(result.data.motoristas);
            setListaProprietario(result.data.proprietarios);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const enviaProposta = async () => {
        let listaProposta = [];
        listaProposta.push({ motoristasId: motoristasId, proprietariosId: proprietariosId, propostaId: null, embarqueId: tabelaRedux.colunaSelecionada, grupoCargaId: grupoCargaId });
        await enviarProposta(listaProposta[0], dadosUsuarioRedux.token).then((result) => {
            handleCloseProposta();
            setTabelaRedux({});
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const tokenApi = localStorage.getItem("token");

    const calcular = (taxa) => {
        const taxaCalculada = ((parseFloat(values.freteFiscal) - parseFloat(taxa)) / parseFloat(values.freteFiscal)) * 100;
        const taxaFormatada = taxaCalculada.toFixed(2).toString();
        setValues(current => ({ ...current, margem: taxaFormatada }));
    }

    const buscarEmbarque = async () => {
        await getEmbarque(tokenApi).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setDadosTabelaCopiaFiltro(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarDependencias = async () => {
        if (dadosContextoEmbarqueRedux) {
            setLoading(true);
            await getEmbarqueDependencias(tokenApi, dadosContextoEmbarqueRedux).then((result) => {
                setListaUnidades(result.data.unidadeEmbarque);
                setListaEmpresas(result.data.empresaSub);
                setListaFilial(result.data.filial);
                setPedido(result.data.pedido);
                setListaVeiculos(result.data.veiculo);
                setListaEmbalagens(result.data.embalagem);
                setListaStatus(result.data.status);
                setListaMeioPagamento(result.data.meioPagamento);
                setListaMotivo(result.data.motivo);
                setListaMercadoria(result.data.mercadoria);
                setDadosTabela(result.data.embarque);
                setDadosTabelaCopia(result.data.embarque);
                setDadosTabelaCopiaFiltro(result.data.embarque);
                setTabelaRedux({});
                setLoading(false);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
        // else if (dadosContextoEmbarqueRedux === 'templateEmbarque') {

        // }
    }

    const buscarTemplates = async () => {
        setLoading(true);
        await getTemplateEmbarque(tokenApi).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarDependenciasDataInicial = async (data) => {
        if (dadosContextoEmbarqueRedux != 'templateEmbarque') {
            setLoading(true);
            await getEmbarqueGeral(tokenApi, dadosContextoEmbarqueRedux, data).then((result) => {
                const listaEmbarques = [];
                result.data.embarques.forEach((embarque) => {
                    if (embarque.cancelado === false) {
                        listaEmbarques.push(embarque);
                    }
                })
                setSomatorioFreteIcms(result.data.somatorioFreteIcms);
                setSomatorioFreteIcmsCancelados(result.data.somatorioFreteIcmsCancelados);
                setNumeroEmbarques(listaEmbarques.length)
                setDadosTabela(result.data.embarques);
                setDadosTabelaCopia(result.data.embarques);
                setDadosTabelaCopiaFiltro(result.data.embarques);
                setTabelaRedux({});
                setLoading(false);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
            buscarTemplates();
        }
    }

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}-${mes}-${ano}`;
    };

    const buscarDependenciasData = async () => {
        if (dadosContextoEmbarqueRedux) {
            setLoading(true);
            await getEmbarqueGeral(tokenApi, dadosContextoEmbarqueRedux, dataPesquisa).then((result) => {
                const listaEmbarques = [];
                result.data.embarques.forEach((embarque) => {
                    if (embarque.cancelado === false) {
                        listaEmbarques.push(embarque);
                    }
                });
                setSomatorioFreteIcms(result.data.somatorioFreteIcms);
                setSomatorioFreteIcmsCancelados(result.data.somatorioFreteIcmsCancelados);
                setEstadia(false);
                setFiltrosRedux(current => ({ ...current, embarqueFinal: formatarData(result.data.fim) }));
                setFiltrosRedux(current => ({ ...current, embarqueInicio: formatarData(result.data.inicio) }));
                setNumeroEmbarques(listaEmbarques.length)
                setDadosTabela(result.data.embarques);
                setDadosTabelaCopia(result.data.embarques);
                setTabelaRedux({});
                setLoading(false);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    }

    const buscarDependenciasSemLoading = async () => {
        const date = new Date();
        var ddFinal = String(date?.getDate()).padStart(2, '0');
        var mmFinal = String(date?.getMonth() + 1).padStart(2, '0');
        var yyyyFinal = date?.getFullYear();
        var dateFinalFormated = yyyyFinal + '-' + mmFinal + '-' + ddFinal;
        await getEmbarqueGeral(tokenApi, dadosContextoEmbarqueRedux, dateFinalFormated).then((result) => {
            setDadosTabela(result.data.embarques);
            setDadosTabelaCopia(result.data.embarques);
            setSomatorioFreteIcms(result.data.somatorioFreteIcms);
            setSomatorioFreteIcmsCancelados(result.data.somatorioFreteIcmsCancelados);
            setTabelaRedux({});
            setLoadingSelectPedido(false);
            setLoadingSelectUnidadeEmbarque(false);
            setLoadingSelectFilial(false);
            setLoadingSelectEmpresa(false);
            setLoadingSelectVeiculo(false);
            setLoadingSelectEmbalagem(false);
            setLoadingSelectStatus(false);
            setLoadingSelectMercadoria(false);
            setLoadingSelectMotivo(false);
            setLoadingSelectMeioPagamento(false);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoadingSelectPedido(false);
            setLoadingSelectFilial(false);
            setLoadingSelectEmpresa(false);
            setLoadingSelectVeiculo(false);
            setLoadingSelectEmbalagem(false);
            setLoadingSelectStatus(false);
            setLoadingSelectUnidadeEmbarque(false);
            setLoadingSelectMercadoria(false);
            setLoadingSelectMotivo(false);
            setLoadingSelectMeioPagamento(false);
            setLoading(false);
        });
    }

    const buscarHistoricoEmbarque = async (codigo) => {
        if (codigo === null) {
            setTextoErro("Nada Encontrado!");
            setOpenToastErro(true);
        } else {
            await getHistoricoEmbarque(codigo, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    setValoresHistorico(result.data);
                    setOpenHistoricoEdicao(true);
                }
            }).catch((error) => {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
            })
        }
    }

    const buscarTemplatePorId = async (flag) => {
        if (editando) setLoading(true);
        await getTemplateEmbarquePorId(tabelaRedux.colunaSelecionada, tokenApi).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);

                    var todayEm = new Date(result.data.dataEmbarque);
                    var ddEm = String(todayEm.getDate()).padStart(2, '0');
                    var mmEm = String(todayEm.getMonth() + 1).padStart(2, '0');
                    var yyyyEm = todayEm.getFullYear();
                    todayEm = yyyyEm + '-' + mmEm + '-' + ddEm;
                    setValues(current => ({ ...current, dataEmbarque: todayEm }));

                    var todayEn = new Date(result.data.dataEntrega);
                    var ddEn = String(todayEn.getDate()).padStart(2, '0');
                    var mmEn = String(todayEn.getMonth() + 1).padStart(2, '0');
                    var yyyyEn = todayEn.getFullYear();
                    todayEn = yyyyEn + '-' + mmEn + '-' + ddEn;
                    setValues(current => ({ ...current, dataEntrega: todayEn }));

                    var todayPrEm = new Date(result.data.previsaoEmbarque);
                    var ddPrEm = String(todayPrEm.getDate()).padStart(2, '0');
                    var mmPrEm = String(todayPrEm.getMonth() + 1).padStart(2, '0');
                    var yyyyPrEm = todayPrEm.getFullYear();
                    todayPrEm = yyyyPrEm + '-' + mmPrEm + '-' + ddPrEm;
                    setValues(current => ({ ...current, previsaoEmbarque: todayPrEm }));

                    var todayPrEn = new Date(result.data.previsaoEntrega);
                    var ddPrEn = String(todayPrEn.getDate()).padStart(2, '0');
                    var mmPrEn = String(todayPrEn.getMonth() + 1).padStart(2, '0');
                    var yyyyPrEn = todayPrEn.getFullYear();
                    todayPrEn = yyyyPrEn + '-' + mmPrEn + '-' + ddPrEn;
                    setValues(current => ({ ...current, previsaoEntrega: todayPrEn }));
                    setLoading(false);
                } else if (flag) {
                    buscarHistoricoEmbarque(result.data.embarqueHistoricoId);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    function formatarValores(resultData) {
        // Define as chaves (colunas) que devem ser ignoradas no processo de formatação
        const colunasIgnorar = ['idEmbarque', 'nEntregas', 'nEntregasFeitas', 'pedido', 'km', 'usuarioResponsavelId', 'embarqueHistoricoId', 'load', 'cte', 'peso'];
        // Função para verificar se o valor é um número inteiro (como string ou número)
        const ehInteger = (value) => {
            // Checa se é um número inteiro ou uma string que representa um número inteiro
            return (
                (typeof value === 'number' && Math.floor(value) === value) ||
                (typeof value === 'string' && /^\d+$/.test(value))
            );
        };
        // Itera sobre as propriedades do objeto
        Object.keys(resultData).forEach(key => {
            if (colunasIgnorar.includes(key)) {
                return;
            }
            const value = resultData[key];
            // Verifica se o valor é um número inteiro sem vírgula
            if (ehInteger(value)) {
                // Formata o valor adicionando ,00 ao final
                resultData[key] = `${value},00`;
            }
        });

        return resultData;
    }

    const buscarEmbarquePorId = async (flag) => {
        if (editando) setLoading(true);
        await getEmbarquePorId(tabelaRedux.colunaSelecionada, tokenApi).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    const formattedData = formatarValores(result.data);
                    setValues(formattedData);

                    // var todayEm = new Date(result.data.dataEmbarque);
                    // var ddEm = String(todayEm.getDate()).padStart(2, '0');
                    // var mmEm = String(todayEm.getMonth() + 1).padStart(2, '0');
                    // var yyyyEm = todayEm.getFullYear();
                    // todayEm = yyyyEm + '-' + mmEm + '-' + ddEm;
                    // setValues(current => ({ ...current, dataEmbarque: todayEm }))

                    // var todayEn = new Date(result.data.dataEntrega);
                    // var ddEn = String(todayEn.getDate()).padStart(2, '0');
                    // var mmEn = String(todayEn.getMonth() + 1).padStart(2, '0');
                    // var yyyyEn = todayEn.getFullYear();
                    // todayEn = yyyyEn + '-' + mmEn + '-' + ddEn;
                    // setValues(current => ({ ...current, dataEntrega: todayEn }))

                    // var todayPrEm = new Date(result.data.previsaoEmbarque);
                    // var ddPrEm = String(todayPrEm.getDate()).padStart(2, '0');
                    // var mmPrEm = String(todayPrEm.getMonth() + 1).padStart(2, '0');
                    // var yyyyPrEm = todayPrEm.getFullYear();
                    // todayPrEm = yyyyPrEm + '-' + mmPrEm + '-' + ddPrEm;
                    // setValues(current => ({ ...current, previsaoEmbarque: todayPrEm }))

                    // var todayPrEn = new Date(result.data.previsaoEntrega);
                    // var ddPrEn = String(todayPrEn.getDate()).padStart(2, '0');
                    // var mmPrEn = String(todayPrEn.getMonth() + 1).padStart(2, '0');
                    // var yyyyPrEn = todayPrEn.getFullYear();
                    // todayPrEn = yyyyPrEn + '-' + mmPrEn + '-' + ddPrEn;
                    // setValues(current => ({ ...current, previsaoEntrega: todayPrEn }))
                    setLoading(false);
                } else if (flag) {
                    buscarHistoricoEmbarque(result.data.embarqueHistoricoId);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarTemplatePorId = async () => {
        setLoading(true);
        await deleteTemplateEmbarquePorId(tabelaRedux.colunaSelecionada, tokenApi).then((result) => {
            if (result.status === 200) {
                buscarTemplates();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const deletarEmbarquePorId = async () => {
        if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
            deletarTemplatePorId();
        } else {
            setLoading(true);
            await deleteEmbarquePorId(tabelaRedux.colunaSelecionada, tokenApi).then((result) => {
                if (result.status === 200) {
                    // if (dadosContextoEmbarqueRedux === "toledo") {
                    //     setTodos(false);
                    //     setValues(current => ({ ...current, contexto: 'toledo' }));
                    // } else if (dadosContextoEmbarqueRedux === "paranavai") {
                    //     setTodos(false);
                    //     setValues(current => ({ ...current, contexto: 'paranavai' }));
                    // } else if (dadosContextoEmbarqueRedux === "rioVerde") {
                    //     setTodos(false);
                    //     setValues(current => ({ ...current, contexto: 'rioVerde' }));
                    // } else if (dadosContextoEmbarqueRedux === "gloriaDeDourados") {
                    //     setTodos(false);
                    //     setValues(current => ({ ...current, contexto: 'gloriaDeDourados' }));
                    // } else if (dadosContextoEmbarqueRedux === 'frota') {
                    //     setTodos(false);
                    //     setValues(current => ({ ...current, contexto: 'frota' }));
                    // } else if (dadosContextoEmbarqueRedux === 'todos') {
                    //     setTodos(true);
                    //     // buscarEmbarque();
                    // }
                    if (dadosContextoEmbarqueRedux === 'todos') {
                        setTodos(true);
                    } else {
                        setTodos(false);
                        setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
                    }
                    buscarDependenciasData();
                    setTabelaRedux({});
                    setOpenToastSucesso(true);
                    setLoading(false);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        if (editando && dadosContextoEmbarqueRedux != 'templateEmbarque') {
            buscarEmbarquePorId();
        } else if (editando && dadosContextoEmbarqueRedux === 'templateEmbarque') {
            buscarTemplatePorId();
        }
    }, [editando]);

    useEffect(() => {
        setValuesPesquisa([]);
        setTabelaRedux({});
        setValues({});
        setDadosRequestControllerRedux("Cadastro False");
        setEditando(false);
        setTelaCadastro(false);
        buscarGrupoCargaVinculoDependencias();
        dadosFilialRedux.map((item) => {
            if (dadosContextoEmbarqueRedux === item.contexto) {
                setContextoCadastro(item.nomeFilial);
            }
        });
        // if (dadosContextoEmbarqueRedux === "toledo") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'toledo' }));
        // } else if (dadosContextoEmbarqueRedux === "paranavai") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'paranavai' }));
        // } else if (dadosContextoEmbarqueRedux === "rioVerde") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'rioVerde' }));
        // } else if (dadosContextoEmbarqueRedux === "gloriaDeDourados") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'gloriaDeDourados' }));
        // } else if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'templateEmbarque' }));
        //     buscarTemplates();
        // } else if (dadosContextoEmbarqueRedux === 'frota') {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'frota' }));
        // } else if (dadosContextoEmbarqueRedux === 'todos') {
        //     setTodos(true);
        //     // buscarEmbarque();
        // }
        if (dadosContextoEmbarqueRedux === 'todos') {
            setTodos(true);
        } else {
            setTodos(false);
            setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
        }
        setValidator(current => ({ ...current, validatorEmpresa: "false" }));
        setValidator(current => ({ ...current, validatorFilial: "false" }));
        setValidator(current => ({ ...current, validatorUnidEmbarque: "false" }));
        setValidator(current => ({ ...current, validatorCidadeOrigem: "false" }));
        setValidator(current => ({ ...current, validatorEstadoOrigem: "false" }));
        setValidator(current => ({ ...current, validatorCidadeDestino: "false" }));
        setValidator(current => ({ ...current, validatorEstadoDestino: "false" }));
        setValidator(current => ({ ...current, validatorPedido: "false" }));
        setValidator(current => ({ ...current, validatorAceite: "false" }));
        setValidator(current => ({ ...current, validatorStatus: "false" }));
        setValidator(current => ({ ...current, validatorPlacaCavalo: "false" }));
        setValidator(current => ({ ...current, validatorPlacaCarreta: "false" }));
        setValidator(current => ({ ...current, validatorPlacaCarreta2: "false" }));
        setValidator(current => ({ ...current, validatorProprietario: "false" }));
        setValidator(current => ({ ...current, validatorMotorista: "false" }));
        setValidator(current => ({ ...current, validatorIcms: "false" }));
        setValidator(current => ({ ...current, validatorFreteFiscal: "false" }));
        setValidator(current => ({ ...current, validatorMeioPagamento: "false" }));
        setValidator(current => ({ ...current, validatorEmbarcador: "false" }));
        setValidator(current => ({ ...current, validatorDestinatario: "false" }));
        setValidator(current => ({ ...current, validatorMercadoria: "false" }));
        setValidator(current => ({ ...current, validatorFrota: "false" }));
        const date = new Date();
        const mesPorExtenso = date.toLocaleString('pt-BR', { month: 'long' });
        setMesAtual(mesPorExtenso);
        var ddFinal = String(date?.getDate()).padStart(2, '0');
        var mmFinal = String(date?.getMonth() + 1).padStart(2, '0');
        var yyyyFinal = date?.getFullYear();
        setAnoAtual(yyyyFinal);
        var dateFinalFormated = yyyyFinal + '-' + mmFinal + '-' + ddFinal;
        setDataPesquisa(dateFinalFormated);
        buscarDependenciasDataInicial(dateFinalFormated);
    }, [dadosContextoEmbarqueRedux]);

    useEffect(() => {
        if (!editando) setValues(current => ({ ...current, cancelado: false }));
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [loading]);


    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    const filtraPedido = () => {
        var dadosfiltra = pedido;
        dadosfiltra.sort(function (a, b) {
            if (a.pedido < b.pedido) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltrados(dadosfiltra);
    }

    const dadosOrdenados = dadosFiltrados;

    const [dadosFiltrados2, setDadosFiltrados2] = useState([]);

    const filtraVeiculo = () => {
        var dadosfiltra = listaVeiculos;
        dadosfiltra.sort(function (a, b) {
            if (a.placaCavalo < b.placaCavalo) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltrados2(dadosfiltra);
    }

    const dadosOrdenados2 = dadosFiltrados2;

    useEffect(() => {
        filtraPedido();
    }, [loadingSelectPedido]);

    useEffect(() => {
        if (!loading) {
            filtraPedido();
            filtraVeiculo();
        }
    }, [loading]);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoGerarLogin, setSemAutorizacaoGerarLogin] = useState(true);

    const [openEmbarque, setOpenEmbarque] = useState(true);

    const handleCloseEmbarque = (value) => {
        setOpenEmbarque(false);

    }

    useEffect(() => {
        setOpenEmbarque(true)
        setSemAutorizacao(true);
        if (values.dataEmbarque === "2000-01-01") {
            values.dataEmbarque = "";
        }
        if (values.dataEntrega === "2000-01-01") {
            values.dataEntrega = "";
        }
        if (values.previsaoEmbarque === "2000-01-01") {
            values.previsaoEmbarque = "";
        }
        if (values.previsaoEntrega === "2000-01-01") {
            values.previsaoEntrega = "";
        }
        if (values.dataHoraChegadaEmbarque === "2000-01-01T00:00:00" || values.dataHoraChegadaEmbarque === "2000-01-01T00:00") {
            values.dataHoraChegadaEmbarque = "";
        }
        if (values.dataHoraChegadaEntrega === "2000-01-01T00:00:00" || values.dataHoraChegadaEntrega === "2000-01-01T00:00") {
            values.dataHoraChegadaEntrega = "";
        }
        if (values.dataHoraEntregue === "2000-01-01T00:00:00" || values.dataHoraEntregue === "2000-01-01T00:00") {
            values.dataHoraEntregue = "";
        }
        if (values.dataHoraEmbarcado === "2000-01-01T00:00:00" || values.dataHoraEmbarcado === "2000-01-01T00:00") {
            values.dataHoraEmbarcado = "";
        }
        let regraPagina;
        // if (dadosContextoEmbarqueRedux === 'toledo') {
        //     regraPagina = 'Toledo';
        // }

        // if (dadosContextoEmbarqueRedux === 'paranavai') {
        //     regraPagina = 'Paranavai';
        // }

        // if (dadosContextoEmbarqueRedux === 'gloriaDeDourados') {
        //     regraPagina = 'Gloria de Dourados';
        // }

        // if (dadosContextoEmbarqueRedux === 'rioVerde') {
        //     regraPagina = 'Rio Verde';
        // }

        // if (dadosContextoEmbarqueRedux === 'frota') {
        //     regraPagina = 'Frota';
        // }

        // if (dadosContextoEmbarqueRedux === 'todos') {
        //     regraPagina = 'Todos';
        // }

        // if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
        //     regraPagina = 'Template Embarque';
        // }
        dadosFilialRedux.map((item) => {
            if (dadosContextoEmbarqueRedux === item.contexto) {
                regraPagina = item.nomeRegra;
            }
        });
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
            let regras = [];
            result.data.forEach((dado) => {
                regras.push(dado.regras.regra)
                if (dado.regras.regra === regraPagina) {
                    setSemAutorizacao(false);
                }
                if (dado.regras.regra === 'Enviar Proposta' && dadosContextoEmbarqueRedux != "frota") {
                    setSemAutorizacaoGerarLogin(false);
                }
            });
            dadosUsuarioRedux.regras = regras;
            setDadosUsuarioRedux(dadosUsuarioRedux);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }, [dadosContextoEmbarqueRedux]);

    useEffect(() => {
        if (selecioneOpen === true) {
            setTimeout(() => {
                setSelecioneOpen(false);
            }, 1000);
        }
    }, [selecioneOpen]);

    useEffect(() => {
        if (opcoesOpen === true) {
            setTimeout(() => {
                setOpcoesOpen(false);
            }, 100);
        }
    }, [tabelaRedux.colunaSelecionada]);

    const inserirSMP = async () => {
        setOpcoesOpen(false);
        await insereSMP(embarqueId, dadosUsuarioRedux.token).then((result) => {
            setMensagensAviso(result.data.result.mensagensAviso);
            setMensagensErro(result.data.result.mensagensErro);
            setMensagensSucesso(result.data.result.mensagensSucesso);
            setOpenListaErro(true);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const buscarSMP = async () => {
        await buscaSMP(embarqueIdSMP, dadosUsuarioRedux.token).then((result) => {
            const lista = [];
            lista.push(result.data.smp);
            setStatusSMP(lista);
            setOpcoesOpen(false);
            setEmbarqueIdSMP(undefined);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (statusSMP != null || statusSMP != undefined) {
            setOpenModalInformacoes(true);
        }
    }, [statusSMP]);

    const definirCte = async () => {
        const embarqueCancelado = {
            "cte": cte,
            "embarqueId": embarqueId,
        };
        await lancarCte(embarqueCancelado, dadosUsuarioRedux.token).then((result) => {
            handleCloseCTE();
            setTextoSucesso("CTE Definido com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const definirStatus = async () => {
        const embarqueCancelado = {
            "statusId": status,
            "embarqueId": embarqueId,
        };
        await lancarStatus(embarqueCancelado, dadosUsuarioRedux.token).then((result) => {
            handleCloseStatus();
            setTextoSucesso("Status Definido com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const definirMeioPagameto = async () => {
        const embarqueCancelado = {
            "embarqueId": embarqueId,
            "meioPagamentoId": meioPagamento1,
            "meioPagamento2Id": meioPagamento2
        };
        await definirMeiosPagamentos(embarqueCancelado, dadosUsuarioRedux.token).then((result) => {
            handleCloseMeioPagamento();
            setMeioPagamento1(null);
            setMeioPagamento2(null);
            setTextoSucesso("Meios de Pagamento Definido com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const definirVeiculo = async () => {
        const embarqueCancelado = {
            "embarqueId": embarqueId,
            "veiculoId": veiculo,
            "motorista": motorista
        };
        await lancarVeiculo(embarqueCancelado, dadosUsuarioRedux.token).then((result) => {
            handleCloseVeiculo();
            setMotorista(null);
            setVeiculo(null);
            setTextoSucesso("Veiculo Definido com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const definirMercadoria = async () => {
        const embarqueCancelado = {
            "mercadoriaId": mercadoria,
            "embarqueId": embarqueId,
        };
        await lancarMercadoria(embarqueCancelado, dadosUsuarioRedux.token).then((result) => {
            handleCloseMercadoria();
            setTextoSucesso("Mercadoria Definida com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const definirEmbalagem = async () => {
        const embarqueCancelado = {
            "embalagemId": embalagem,
            "embarqueId": embarqueId,
        };
        await lancarEmbalagem(embarqueCancelado, dadosUsuarioRedux.token).then((result) => {
            handleCloseEmbalagem();
            setTextoSucesso("Embalagem Definida com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const definirComprovante = async () => {
        await comprovanteEntregue(embarqueId, dadosUsuarioRedux.token).then((result) => {
            setTextoSucesso("Comprovante Informado com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const definirCheck = async () => {
        await lancarCheckin(embarqueId, dadosUsuarioRedux.token).then((result) => {
            setTextoSucesso("Checkin Concluído com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const cancelarCarga = async () => {
        const embarqueCancelado = {
            "motivoId": motivoId2,
            "embarqueId": embarqueId,
        };
        await cancelarEmbarque(embarqueCancelado, dadosUsuarioRedux.token).then((result) => {
            setOpenMotivoCancelado(false);
            setNomeMotivo(null);
            setMotivoId(null);
            setTextoSucesso("Carga Cancelada com Sucesso!");
            setOpenToastSucesso(true);
            handleSearch();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const vincularMotivoOnTime = async () => {
        let bool = false;
        if (isEmbarque === true) {
            bool = true
        }
        if (isEntrega === true) {
            bool = false;
        }
        await vincularMotivoPorId(dadosUsuarioRedux.token, embarqueId, motivoId, bool).then((result) => {
            if (result.status === 200) {
                handleCloseMotivoOnTime();
                buscarMotivo();
                setNomeMotivo(null);
                setMotivoId(null);
                setTextoSucesso("Cadastrado com Sucesso!");
                setOpenToastSucesso(true);
                setEmbarqueId(null);
                handleSearch();
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const headers = [
        { label: "ID", key: "idEmbarque" },
        { label: "Empresa", key: "empresa" },
        { label: "Filial", key: "filial" },
        { label: "Unidade Embarque", key: "unidEmbarque" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Estado Origem", key: "estadoOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Estado Destino", key: "estadoDestino" },
        { label: "Pedido", key: "pedido" },
        { label: "Previsão Embarque", key: "previsaoEmbarque" },
        { label: "Data Embarque", key: "dataEmbarque" },
        { label: "Previsão Entrega", key: "previsaoEntrega" },
        { label: "Data Entrega", key: "dataEntrega" },
        { label: "Aceite", key: "aceite" },
        { label: "Status", key: "status" },
        { label: "Placa Cavalo", key: "placaCavalo" },
        { label: "Placa Carreta", key: "placaCarreta" },
        { label: "Placa Carreta 2", key: "placaCarreta2" },
        { label: "Proprietário", key: "proprietario" },
        { label: "Motorista", key: "motorista" },
        { label: "Frete Fiscal", key: "freteFiscal" },
        { label: "ICMS", key: "icms" },
        { label: "Frete Motorista", key: "freteMotorista" },
        { label: "Adicional Empresa", key: "adicionalEmpresa" },
        { label: "Adicional Motorista", key: "adicionalMotorista" },
        { label: "Pedagio", key: "pedagio" },
        { label: "Classificacao", key: "classificacao" },
        { label: "Adiantamento", key: "adiantamento" },
        { label: "Saldo", key: "saldo" },
        { label: "Meio Pagamento", key: "meioPagamento" },
        { label: "Meio Pagamento 2", key: "meioPagamento2" },
        { label: "KM", key: "km" },
        { label: "Cliente", key: "cliente" },
        { label: "Embarcador", key: "embarcador" },
        { label: "Destinatário", key: "destinatario" },
        { label: "Load", key: "load" },
        { label: "Mercadoria", key: "mercadoria" },
        { label: "Peso", key: "peso" },
        { label: "Embalagem", key: "embalagem" },
        { label: "Observação", key: "observacao" },
        { label: "Frota", key: "frota" },
        { label: "Cancelado", key: "cancelado" },
        { label: "Motivo", key: "motivo" },
    ];

    let novaArray = [];

    if (!Array.isArray(dadosTabela)) {
        novaArray.push(dadosTabela);
    } else {
        novaArray = dadosTabela;
    }

    const chavesRemover = ["deletado", "embarqueHistoricoId", "contexto", "dataHoraChegadaEmbarque", "dataHoraEmbarcado", "dataHoraChegadaEntrega", "dataHoraEntregue", "data", "usuario"];
    const listaFiltradaObjetos = novaArray.map((obj) => {
        const novoObjeto = Object.keys(obj)
            .filter(key => !chavesRemover.includes(key))
            .reduce((novoObjeto, key) => {
                novoObjeto[key] = obj[key];
                return novoObjeto;
            }, {});
        return novoObjeto;
    });

    const chavesFormatar = ["previsaoEmbarque", "dataEmbarque", "previsaoEntrega", "dataEntrega"];
    const listaFormatada = listaFiltradaObjetos.map((obj) => {
        const novoObj = {};
        Object.keys(obj).forEach(key => {
            if (chavesFormatar.includes(key) && obj[key]) {
                novoObj[key] = obj[key].replace(/T00:00:00/, "");
            } else {
                novoObj[key] = obj[key];
            }
        });
        return novoObj;
    });

    const [odenConfig, setOpenConfig] = useState(false);

    const handleCloseConfig = (value) => {
        if (dadosRequestControllerRedux === "Sucesso") {
            setTextoSucesso("Alterado Com Sucesso");
            setOpenToastSucesso(true);
            setOpenConfig(false);
            handleSearch();
        }
        setOpenConfig(false);
        handleSearch();
    };

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <>
                {!loading ? (
                    <>
                        {semAutorizacao ? (
                            <>
                                <NavbarV2 selecionado={dadosContextoEmbarqueRedux} setTelaCadastro={setTelaCadastro} /><SemAutorizacao></SemAutorizacao>
                                <ModalEmbarque
                                    open={openEmbarque}
                                    onClose={handleCloseEmbarque}
                                />
                            </>
                        ) : (
                            <div>
                                <NavbarV2 selecionado={dadosContextoEmbarqueRedux} setTelaCadastro={setTelaCadastro} />
                                {
                                    !loading ? (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            {
                                                !telaCadastro ? (
                                                    <div>
                                                        {
                                                            !todos ? (
                                                                <div className="embarques">
                                                                    <div className="header-embarque-v2">
                                                                        <div className="flex">
                                                                            {
                                                                                dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                                    <p className="indicador-principal-embarque-v2">Embarques - {contextoCadastro}</p>
                                                                                ) : (
                                                                                    <p className="indicador-principal-embarque-v2">Modelo Embarque</p>
                                                                                )
                                                                            }
                                                                            <Tooltip title={mesAtual}>
                                                                                <img className="mes-atual" src={iconeInfo} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className="flex">
                                                                            {dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                                <>
                                                                                    {
                                                                                        numeroEmbarque ? (
                                                                                            <div className="numero-embarques">
                                                                                                <h3>Número de Embarques: {numeroEmbarque}</h3>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                    <CSVLink className="csv-botao" data={listaFormatada} headers={headers} filename={`Embarques`}>
                                                                                        <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                                                            <IconButton className="butao-csv">
                                                                                                <img src={iconeDownload} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </CSVLink>
                                                                                </>
                                                                            ) : (<></>)}
                                                                            <div className="filtro">
                                                                                <Tooltip title={<p>Abrir configurações colunas</p>}>
                                                                                    <IconButton className="butao-csv">
                                                                                        <img src={iconeConfig} className="click-configurar" onClick={() => setOpenConfig(true)} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="filtro-embarque-v2">
                                                                        <FiltroV2
                                                                            classe='div-filtrar-embarque-v2'
                                                                            classOption='select-options'
                                                                            itensFiltro={itensFiltro}
                                                                            valuesPesquisa={valuesPesquisa}
                                                                            setValuesPesquisa={setValuesPesquisa}
                                                                            buscarSearch={buscarSearch}
                                                                            limpar={limpar}
                                                                            setCompleto={setCompleto}
                                                                            setPeriodoCompleto={setPeriodoCompleto}
                                                                            periodoCompleto={periodoCompleto}
                                                                            mesAnterior={mesAnterior}
                                                                            mesSeguinte={mesSeguinte}
                                                                            setEstadia={setEstadia}
                                                                            setFiltroEstadia={setFiltroEstadia}
                                                                            estadia={estadia}
                                                                        />
                                                                        <div className="fat-botoes">
                                                                            <CustomButtonV2
                                                                                classCss="custom-button-faturamento"
                                                                                classText="button-texto-faturamento"
                                                                                titulo={"Exibir Somatório"}
                                                                                cor="#FFFFFF"
                                                                                onClick={onClickOpenSomatorio}
                                                                            />
                                                                            <CustomButtonV2
                                                                                classCss="custom-button-faturamento"
                                                                                classText="button-texto-faturamento"
                                                                                titulo={"Alterar Tabela"}
                                                                                cor="#FFFFFF"
                                                                                onClick={onClickOpenProjecao}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {cardSomatorio && (
                                                                        <div>
                                                                            <div className="container-embarque-card">
                                                                                <div className="card-embarque">
                                                                                    <div className="card-header-embarque">
                                                                                        Somatório Frete Fiscal Icms Frete Motorista
                                                                                    </div>
                                                                                    <div className="card-body-embarque">
                                                                                        {somatorioFreteIcms.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-embarque">
                                                                                    <div className="card-header-embarque">
                                                                                        Somatório Frete Fiscal Icms Frete Motorista Cancelados
                                                                                    </div>
                                                                                    <div className="card-body-embarque">
                                                                                        {somatorioFreteIcmsCancelados.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        viewTable ? (
                                                                            <TabelaV2 setTelaCadastro={setTelaCadastro} setEditando={setEditando}
                                                                                classeSelecionado={dadosContextoEmbarqueRedux != 'templateEmbarque' ? 'idEmbarque' : 'idEmbarqueTemplete'} nomeClasse1="outer-wrapper" nomeClasse2={cardSomatorio ? "table-wrapper-v2-low" : "table-wrapper-v2"}
                                                                                colunas={dadosTabelaRedux.Embarque} dados={dadosTabela} nome="embarque" nomeTable="Embarque"
                                                                                numeroEmbarque={numeroEmbarque} buscarOrdenacao={buscarOrdenacao} />
                                                                        ) : (
                                                                            <TabelaLow setTelaCadastro={setTelaCadastro} setEditando={setEditando} setTextoErro={setTextoErro}
                                                                                setOpenToastErro={setOpenToastErro} colunas={dadosTabelaRedux.Embarque} dados={dadosTabela}
                                                                                buscarEmbarquePorId={buscarEmbarquePorId} setOpenUsuario={setOpenUsuario} setOpen={setOpen}
                                                                                setTextoSucesso={setTextoSucesso} setOpcoesOpen={setOpcoesOpen} setSelecioneOpen={setSelecioneOpen}
                                                                                opcoesOpen={opcoesOpen} acoesData={acoesData} numeroEmbarque={numeroEmbarque} />
                                                                        )
                                                                    }
                                                                    {
                                                                        dadosTabela.length === 0 && dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                            <p className="texto-mes">Nenhum embarque cadastrado neste mês</p>
                                                                        ) : (
                                                                            <span></span>
                                                                        )
                                                                    }
                                                                    {
                                                                        viewTable ? (
                                                                            <div className="container-botoes-emb">
                                                                                <div>
                                                                                    <CustomButtonV2
                                                                                        classCss="custom-button-cadastrar"
                                                                                        classText="button-texto-cadastrar"
                                                                                        icone="iconeSave"
                                                                                        titulo="Cadastrar"
                                                                                        cor="#0946B8"
                                                                                        valor={values}
                                                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                                                    />
                                                                                    <CustomButtonV2
                                                                                        classCss="custom-button-editar"
                                                                                        classText="button-texto-editar"
                                                                                        titulo="Editar"
                                                                                        icone="iconeEdit"
                                                                                        cor="#CDCDCD"
                                                                                        valor={values}
                                                                                        onClick={() => {
                                                                                            if (tabelaRedux.colunaSelecionada) {
                                                                                                setTelaCadastro(true);
                                                                                                setEditando(true);
                                                                                            } else {
                                                                                                setTextoErro("Selecione Uma Coluna");
                                                                                                setOpenToastErro(true);
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    <CustomButtonV2
                                                                                        classCss="custom-button-deletar"
                                                                                        classText="button-texto-cadastrar"
                                                                                        titulo="Deletar"
                                                                                        icone="iconeDelete"
                                                                                        cor="#DC2D2D"
                                                                                        valor={values}
                                                                                        onClick={() => {
                                                                                            if (tabelaRedux.colunaSelecionada) {
                                                                                                setOpen(true);
                                                                                                setTextoSucesso("Deletado Com Sucesso");
                                                                                            } else {
                                                                                                setTextoErro("Selecione Uma Coluna");
                                                                                                setOpenToastErro(true);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="flex-row">
                                                                                    <div className="hover-emb">
                                                                                        {opcoesOpen && (
                                                                                            <div className="config-dropdown-home">
                                                                                                {acoesData.map((acao, index) => {
                                                                                                    if (acao.titulo === 'Motivo Embarque' && tabelaRedux?.row?.necessitaMotivoEmbarque) {
                                                                                                        return (
                                                                                                            <p key={index} onClick={acao.onClick}>
                                                                                                                {acao.titulo}
                                                                                                            </p>
                                                                                                        );
                                                                                                    } else if (acao.titulo === 'Motivo Entrega' && tabelaRedux?.row?.necessitaMotivoEntrega) {
                                                                                                        return (
                                                                                                            <p key={index} onClick={acao.onClick}>
                                                                                                                {acao.titulo}
                                                                                                            </p>
                                                                                                        );
                                                                                                    } else if (acao.titulo !== 'Motivo Embarque' && acao.titulo !== 'Motivo Entrega') {
                                                                                                        return (
                                                                                                            <p key={index} onClick={acao.onClick}>
                                                                                                                {acao.titulo}
                                                                                                            </p>
                                                                                                        );
                                                                                                    }
                                                                                                    return null;
                                                                                                })}
                                                                                            </div>
                                                                                        )}
                                                                                        {selecioneOpen &&
                                                                                            <div className="config-dropdown-none">
                                                                                                <p>Selecione Uma Coluna</p>
                                                                                            </div>
                                                                                        }
                                                                                        <CustomButtonV2
                                                                                            classCss="custom-button-user"
                                                                                            classText="button-texto-user"
                                                                                            titulo="Ações"
                                                                                            cor="#FFFFFF"
                                                                                            valor={values}
                                                                                            onClick={() => {
                                                                                                if (tabelaRedux.colunaSelecionada) {
                                                                                                    setOpcoesOpen(!opcoesOpen);
                                                                                                } else {
                                                                                                    setSelecioneOpen(!selecioneOpen);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {
                                                                                        semAutorizacaoGerarLogin ? (
                                                                                            <></>
                                                                                        ) : (
                                                                                            <CustomButtonV2
                                                                                                classCss="custom-button-proposta"
                                                                                                classText="button-texto-user"
                                                                                                titulo="Enviar Proposta"
                                                                                                cor="#FFFFFF"
                                                                                                valor={values}
                                                                                                onClick={() => {
                                                                                                    if (tabelaRedux.usuarioAlteracao) {
                                                                                                        setOpenProposta(true);
                                                                                                    } else {
                                                                                                        setTextoErro("Selecione Uma Coluna");
                                                                                                        setOpenToastErro(true);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    <CustomButtonV2
                                                                                        classCss="custom-button-user"
                                                                                        classText="button-texto-user"
                                                                                        titulo="Obter Usuário"
                                                                                        cor="#FFFFFF"
                                                                                        valor={values}
                                                                                        onClick={() => {
                                                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                                                setOpenUsuario(true);
                                                                                            } else {
                                                                                                setTextoErro("Selecione Uma Coluna");
                                                                                                setOpenToastErro(true);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                                            <CustomButtonV2
                                                                                                classCss="custom-button-historico"
                                                                                                classText="button-texto-user"
                                                                                                titulo="Histórico"
                                                                                                cor="#FFFFFF"
                                                                                                valor={values}
                                                                                                onClick={() => {
                                                                                                    if (tabelaRedux.colunaSelecionada) {
                                                                                                        buscarEmbarquePorId(true);
                                                                                                    } else {
                                                                                                        setTextoErro("Selecione Uma Coluna");
                                                                                                        setOpenToastErro(true);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <span></span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="embarques">
                                                                    <div className="header-embarque-v2">
                                                                        <div className="flex">
                                                                            <p className="indicador-principal-embarque-v2">Embarques - {contextoCadastro}</p>
                                                                            <Tooltip title={mesAtual}>
                                                                                <img className="mes-atual" src={iconeInfo} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className="flex">
                                                                            {dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                                <>
                                                                                    {
                                                                                        numeroEmbarque ? (
                                                                                            <div className="numero-embarques">
                                                                                                <h3>Número de Embarques: {numeroEmbarque}</h3>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                    <CSVLink className="csv-botao" data={listaFormatada} headers={headers} filename={`Embarques`}>
                                                                                        <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                                                            <IconButton className="butao-csv">
                                                                                                <img src={iconeDownload} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </CSVLink>
                                                                                </>
                                                                            ) : (<></>)}
                                                                            <div className="filtro">
                                                                                <Tooltip title={<p>Abrir configurações colunas</p>}>
                                                                                    <IconButton className="butao-csv">
                                                                                        <img src={iconeConfig} className="click-configurar" onClick={() => setOpenConfig(true)} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="filtro-embarque-v2">
                                                                        <FiltroV2
                                                                            classe='div-filtrar-embarque-v2'
                                                                            classOption='select-options'
                                                                            itensFiltro={itensFiltro}
                                                                            valuesPesquisa={valuesPesquisa}
                                                                            setValuesPesquisa={setValuesPesquisa}
                                                                            buscarSearch={buscarSearch}
                                                                            limpar={limpar}
                                                                            setCompleto={setCompleto}
                                                                            setPeriodoCompleto={setPeriodoCompleto}
                                                                            periodoCompleto={periodoCompleto}
                                                                            mesAnterior={mesAnterior}
                                                                            mesSeguinte={mesSeguinte}
                                                                        />
                                                                        <div className="fat-botoes">
                                                                            <CustomButtonV2
                                                                                classCss="custom-button-faturamento"
                                                                                classText="button-texto-faturamento"
                                                                                titulo={"Exibir Somatório"}
                                                                                cor="#FFFFFF"
                                                                                onClick={onClickOpenSomatorio}
                                                                            />
                                                                            <CustomButtonV2
                                                                                classCss="custom-button-faturamento"
                                                                                classText="button-texto-faturamento"
                                                                                titulo={"Alterar Tabela"}
                                                                                cor="#FFFFFF"
                                                                                onClick={onClickOpenProjecao}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        viewTable ? (
                                                                            <TabelaV2 setTelaCadastro={setTelaCadastro} setEditando={setEditando}
                                                                                classeSelecionado={dadosContextoEmbarqueRedux != 'templateEmbarque' ? 'idEmbarque' : 'idEmbarqueTemplete'} nomeClasse1="outer-wrapper" nomeClasse2={cardSomatorio ? "table-wrapper-v2-low" : "table-wrapper-v2"}
                                                                                colunas={dadosTabelaRedux.Embarque} dados={dadosTabela} nome="embarque" nomeTable="Embarque"
                                                                                numeroEmbarque={numeroEmbarque} buscarOrdenacao={buscarOrdenacao} />
                                                                        ) : (
                                                                            <TabelaLow setTelaCadastro={setTelaCadastro} setEditando={setEditando} setTextoErro={setTextoErro}
                                                                                setOpenToastErro={setOpenToastErro} colunas={dadosTabelaRedux.Embarque} dados={dadosTabela}
                                                                                buscarEmbarquePorId={buscarEmbarquePorId} setOpenUsuario={setOpenUsuario}
                                                                                setTextoSucesso={setTextoSucesso} setOpcoesOpen={setOpcoesOpen} setSelecioneOpen={setSelecioneOpen}
                                                                                opcoesOpen={opcoesOpen} acoesData={acoesData} loading={loading} numeroEmbarque={numeroEmbarque} />
                                                                        )
                                                                    }
                                                                    {
                                                                        dadosTabela.length === 0 && dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                            <p className="texto-mes">Nenhum embarque cadastrado neste mês</p>
                                                                        ) : (
                                                                            <span></span>
                                                                        )
                                                                    }
                                                                    {
                                                                        viewTable ? (
                                                                            <div className="container-botoes-emb">
                                                                                <div>
                                                                                    <CustomButtonV2
                                                                                        classCss="custom-button-user"
                                                                                        classText="button-texto-user"
                                                                                        titulo="Obter Usuário"
                                                                                        cor="#FFFFFF"
                                                                                        valor={values}
                                                                                        onClick={() => {
                                                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                                                setOpenUsuario(true);
                                                                                            } else {
                                                                                                setTextoErro("Selecione Uma Coluna");
                                                                                                setOpenToastErro(true);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                                            <CustomButtonV2
                                                                                                classCss="custom-button-historico"
                                                                                                classText="button-texto-user"
                                                                                                titulo="Histórico"
                                                                                                cor="#FFFFFF"
                                                                                                valor={values}
                                                                                                onClick={() => {
                                                                                                    if (tabelaRedux.colunaSelecionada) {
                                                                                                        buscarEmbarquePorId(true);
                                                                                                    } else {
                                                                                                        setTextoErro("Selecione Uma Coluna");
                                                                                                        setOpenToastErro(true);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <span></span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <div style={{ height: '170vh' }}>
                                                        <div className="indicadores">
                                                            {
                                                                dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                    <>
                                                                        <p className="indicador-emb">{"Dados de Embarque / "}</p>
                                                                        <p className="indicador-emb">Cadastro de Embarque</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p className="indicador-emb">{"Dados do Modelo / "}</p>
                                                                        <p className="indicador-emb">Cadastro de Modelo de Embarque</p>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="flex">
                                                            <div className="flex-emb">
                                                                <HeaderCadastro texto={`Cadastro de Embarque - ${contextoCadastro}`} />
                                                                <CloseIcon onClick={() => {
                                                                    setValues({});
                                                                    setDadosRequestControllerRedux("Cadastro False");
                                                                    setEditando(false);
                                                                    setTabelaRedux({});
                                                                }} />
                                                            </div>
                                                        </div>
                                                        {
                                                            editando ? (
                                                                <EmbarqueStepperV2 dados={values} editando={editando} />
                                                            ) : (
                                                                <EmbarqueStepperV2 editando={editando} />
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            <div>
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroPedido="sim"
                                                    open={openCadastroPedido}
                                                    onClose={handleCloseCadastroPedido}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroUnidadeEmbarque="sim"
                                                    open={openCadastroUnidadeEmbarque}
                                                    onClose={handleCloseCadastroUnidadeEmbarque}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroFilial="sim"
                                                    open={openCadastroFilial}
                                                    onClose={handleCloseCadastroFilial}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroEmpresaSub="sim"
                                                    open={openCadastroEmpresa}
                                                    onClose={handleCloseCadastroEmpresa}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroVeiculo="sim"
                                                    open={openCadastroVeiculo}
                                                    onClose={handleCloseCadastroVeiculo}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroMercadoria="sim"
                                                    open={openCadastroMercadoria}
                                                    onClose={handleCloseCadastroMercadoria}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroEmbalagem="sim"
                                                    open={openCadastroEmbalagem}
                                                    onClose={handleCloseCadastroEmbalagem}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroStatus="sim"
                                                    open={openCadastroStatus}
                                                    onClose={handleCloseCadastroStatus}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroMotivo="sim"
                                                    open={openCadastroMotivo}
                                                    onClose={handleCloseCadastroMotivo}
                                                />
                                                <ModalCadastro
                                                    titulo="Cadastro"
                                                    tituloConfirmar="OK"
                                                    cadastroMeioPagamento="sim"
                                                    open={openCadastroMeioPagamento}
                                                    onClose={handleCloseCadastroMeioPagamento}
                                                />
                                                <ModalConfirmacao
                                                    titulo="Deletar"
                                                    texto="Deseja Deletar?"
                                                    tituloConfirmar="Confirmar"
                                                    tituloCancelar="Cancelar"
                                                    open={open}
                                                    onClose={handleClose}
                                                    onClick={deletarEmbarquePorId}
                                                />
                                                <ModalConfirmacao2
                                                    titulo="SMP"
                                                    texto="Deseja Inserir SMP?"
                                                    tituloConfirmar="Sim"
                                                    tituloCancelar="Não"
                                                    textoBotao="Sim"
                                                    open={openConfirmar}
                                                    onClose={handleCloseConfirmar}
                                                    onClick={inserirSMP}
                                                />
                                                <ModalStatusSMP
                                                    titulo="Status do SMP"
                                                    textoBotao="OK"
                                                    onClose={handleCloseModalInformacoes}
                                                    onClick={handleCloseModalInformacoes}
                                                    open={openModalInformacoes}
                                                    statusSMP={statusSMP}
                                                />
                                                <ModalListaErros
                                                    titulo="Listagem Sucessos/Aviso/Erros"
                                                    texto="Foram retornadas as seguintes ocorrências na tentaviva de inserir o SMP."
                                                    tituloConfirmar="Sim"
                                                    tituloCancelar="Não"
                                                    textoBotao="Ok"
                                                    open={openListaErro}
                                                    onClose={handleCloseListaErro}
                                                    onClick={handleCloseListaErro}
                                                    mensagensAviso={mensagensAviso}
                                                    mensagensErro={mensagensErro}
                                                    mensagensSucesso={mensagensSucesso}
                                                />
                                                <ModalUsuario
                                                    titulo="Usuário"
                                                    tituloConfirmar="OK"
                                                    open={openUsuario}
                                                    onClose={handleCloseUsuario}
                                                />
                                                <ModalHistoricoEdicao
                                                    valueTabela={tabelaRedux}
                                                    valueDados={valoresHistorico}
                                                    valueUnico={values}
                                                    open={openHistoricoEdicao}
                                                    onClose={handleCloseHistoricoEdicao}
                                                    titulo="Histórico"
                                                    classeSelecionado="idEmbarque"
                                                    colunas="Embarque"
                                                    nome="embarque"
                                                    nomeTable="Embarque"
                                                />
                                                <ModalProposta
                                                    titulo="Envio de Proposta"
                                                    tituloConfirmar="Confirmar"
                                                    tituloCancelar="Cancelar"
                                                    open={openProposta}
                                                    onClose={handleCloseProposta}
                                                    onClick={enviaProposta}
                                                    listaMotoristas={listaMotoristas}
                                                    listaProprietarios={listaProprietarios}
                                                    listaGrupoCargas={listaGrupoCargas}
                                                    setGrupoCargaId={setGrupoCargaId}
                                                    setMotoristasId={setMotoristasId}
                                                    setProprietariosId={setProprietariosId}
                                                />
                                                <ModalOnTime
                                                    titulo="Vincular Motivo"
                                                    tituloConfirmar="Confirmar"
                                                    tituloCancelar="Cancelar"
                                                    open={openMotivoOnTime}
                                                    onClose={handleCloseMotivoOnTime}
                                                    onClick={vincularMotivoOnTime}
                                                    listaMotivos={listaMotivosOnTime}
                                                    setMotivoId={setMotivoId}
                                                    setNomeMotivo={setNomeMotivo}
                                                />
                                                <ModalOnTime
                                                    titulo="Motivo Cancelamento"
                                                    tituloConfirmar="Confirmar"
                                                    tituloCancelar="Cancelar"
                                                    open={openMotivoCancelado}
                                                    onClose={handleCloseMotivoCancelado}
                                                    onClick={cancelarCarga}
                                                    listaMotivos={listaMotivos}
                                                    setMotivoId={setMotivoId2}
                                                    setNomeMotivo={setNomeMotivo}
                                                />
                                                <ModalLancarCTE
                                                    titulo="Lançar CTE"
                                                    tituloConfirmar="OK"
                                                    open={openCTE}
                                                    onClick={definirCte}
                                                    onClose={handleCloseCTE}
                                                    setCTE={setCTE}
                                                />
                                                <ModalLancarStatus
                                                    titulo="Lançar Status"
                                                    tituloConfirmar="OK"
                                                    open={openStatus}
                                                    onClick={definirStatus}
                                                    onClose={handleCloseStatus}
                                                    setStatus={setStatus}
                                                    listaStatus={listaStatus}
                                                />
                                                <ModalLancarEmbalagem
                                                    titulo="Lançar Embalagem"
                                                    tituloConfirmar="OK"
                                                    open={openEmbalagem}
                                                    onClick={definirEmbalagem}
                                                    onClose={handleCloseEmbalagem}
                                                    setEmbalagem={setEmbalagem}
                                                    listaEmbalagens={listaEmbalagens}
                                                />
                                                <ModalLancarMercadoria
                                                    titulo="Lançar Mercadoria"
                                                    tituloConfirmar="OK"
                                                    open={openMercadoria}
                                                    onClick={definirMercadoria}
                                                    onClose={handleCloseMercadoria}
                                                    setMercadoria={setMercadoria}
                                                    listaMercadoria={listaMercadoria}
                                                />
                                                <ModalLancarVeiculo
                                                    titulo="Lançar Veículo"
                                                    tituloConfirmar="OK"
                                                    open={openVeiculo}
                                                    onClick={definirVeiculo}
                                                    onClose={handleCloseVeiculo}
                                                    setVeiculo={setVeiculo}
                                                    setMotorista={setMotorista}
                                                    listaVeiculos={listaVeiculos}
                                                />
                                                <ModalLancarMeioPagamento
                                                    titulo="Definir Meios de Pagameto"
                                                    tituloConfirmar="OK"
                                                    open={openMeioPagamento}
                                                    onClick={definirMeioPagameto}
                                                    onClose={handleCloseMeioPagamento}
                                                    setMeioPagamento1={setMeioPagamento1}
                                                    setMeioPagamento2={setMeioPagamento2}
                                                    listaMeioPagamento={listaMeioPagamento}
                                                />
                                                <ModalConfigTabela
                                                    titulo="Colunas Visíveis"
                                                    tituloConfirmar="OK"
                                                    open={odenConfig}
                                                    onClose={handleCloseConfig}
                                                    checks={dadosTabelaRedux.Embarque}
                                                    nome={"embarque"}
                                                />
                                            </div>
                                            <div>
                                                <ToastSucesso
                                                    texto={textoSucesso}
                                                    open={openToastSucesso}
                                                    onClose={handleCloseToastSucesso}
                                                />
                                                <ToastErro
                                                    texto={textoErro}
                                                    open={openToastErro}
                                                    onClose={handleCloseToastErro}
                                                />
                                            </div>
                                        </motion.div>
                                    ) : (
                                        <LoadingComponent />
                                    )
                                }
                            </div>
                        )
                        }
                    </>
                ) : (
                    <>
                        <NavbarV2 selecionado={dadosContextoEmbarqueRedux} />
                        <LoadingComponent />
                    </>
                )}
            </>
        </motion.div>
    );
}
export default Embarque;
import { Box, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./LoadingBar.css";
import loading from '../../images/loading.gif'

function LoadingBar(props) {

    return (
        <div className="coluna-loading">
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={loading} alt="Carregando" style={{ maxWidth: "30%", maxHeight: "10%" }}></img>
            </Box>
        </div>
    );
}

export default LoadingBar;
import React, { useContext } from "react";
import styled from "styled-components";

function TextLinkV2(props) {


    const TextP = styled.p`     
    color: #0946B8;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &:hover {
        color: #072A80;
    }
    `

    return (
        <TextP>{props.texto}</TextP>
    );
}

export default TextLinkV2;
import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraEmpresaSub = async (empresaSub, token) => {
  return api.post("EmpresaSub",
    empresaSub
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmpresaSub = async (token) => {
  return api.get("EmpresaSub",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmpresaSubPorId = async (id, token) => {
  return api.get("EmpresaSub/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteEmpresaSubPorId = async (id, token) => {
  return api.delete("EmpresaSub/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaEmpresaSubPorId = async (empresaSub, token) => {
  return api.put("EmpresaSub",
    empresaSub,
    {
      headers: {
        'Authorization': token
      }
    })
}

export default api;
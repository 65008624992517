import { Button, CircularProgress, FormControl, Input, InputLabel } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Card } from 'primereact/card';
import "./CodigoEmail.css";
import { mandarCodigoEsqueciSenha } from "../../../../services/ModuloGerenciador/Solicitacao";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import { useNavigate } from "react-router-dom";
import RequestControllerRedux from "../../../../contexts/RequestController";
import TextLink from "../../../../components/TextLink/TextLink";
import { motion } from "framer-motion";
import ReactSelect from "react-select";
import { tenantData } from "../../../../components/EmpresasTenant";
import Text from "../../../../components/Text/Text";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2.js";
import logoLogin from '../../../../images/logoLogin.png'

function CodigoEmail(props) {

    const navigate = useNavigate();

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [values, setValues] = useState({});

    const [loading, setLoading] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [valuesSelect, setValuesSelect] = useState([]);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const mandaCodigo = async () => {
        setLoading(true);
        await mandarCodigoEsqueciSenha(values.email, values.tenant).then((result) => {
            if (result.status === 200) {
                navigate("/recuperarSenha");
                setDadosRequestControllerRedux("Email Codigo");
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const labelFrota = (data) => (
        <div>
            {data.titulo}
        </div>
    );

    const opcoesFrota = tenantData.map((data) => ({
        value: data.tenant,
        label: data.titulo,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '295px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <img src={logoLogin} alt="logo" className='imageCad' />
            <div className="container">
                <form className="card">
                    {!loading && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <div className="input-codigoemail">
                                <h1>Recuperação de Senha</h1>
                                <p className="texto-informe">Para recuperar a sua senha, informe seu endereço de email, será enviando um código para a alteração da senha.</p>
                                <input
                                    type="text"
                                    className="input-emb"
                                    placeholder="Email"
                                    style={{ width: '295px' }}
                                    id="email"
                                    onChange={(e) => setValues(current => ({ ...current, email: e.target.value }))}
                                    value={values.email} />
                                <div className="coluna-emb">
                                    <div className="principal">
                                        <p className="input-label-emb" htmlFor="descricao">Empresa:</p>
                                        <FormControl sx={{ width: 295 }}>
                                            <ReactSelect
                                                value={valuesSelect.tenant}
                                                options={opcoesFrota}
                                                formatOptionLabel={labelFrota}
                                                styles={estiloFrota}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Não há mais opções"}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        setValues((current) => ({
                                                            ...current,
                                                            tenant: selectedOption.tenant,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="botao-senhas">
                                <CustomButtonV2
                                    classCss="custom-button-cadastrar"
                                    classText="button-texto-cadastrar"
                                    titulo="ENVIAR"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => { mandaCodigo(); }}
                                />
                                <InputLabel className="titulo-select-cadastro" onClick={() => { navigate("/recuperarSenha"); }} htmlFor="status">
                                    <p className="decoration">Já tenho o código</p>
                                </InputLabel>
                                <InputLabel className="titulo-select-cadastro" onClick={() => { navigate("/login"); }} htmlFor="status">
                                    <p className="decoration">Voltar para o login</p>
                                </InputLabel>
                            </div>
                        </motion.div>
                    )}
                    {loading && (
                        <div className="loading"><CircularProgress size={50} /></div>
                    )}
                </form>
            </div>
            <div>
                <ToastSucesso
                    texto={textoSucesso}
                    open={openToastSucesso}
                    onClose={handleCloseToastSucesso}
                />
                <ToastErro
                    texto={textoErro}
                    open={openToastErro}
                    onClose={handleCloseToastErro}
                />
            </div>
        </motion.div>
    );
}

export default CodigoEmail;
import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../components/NavbarV2/NavbarV2";
import InputLabel from "@mui/material/InputLabel";
import "./Cockpit.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getCockpit, cadastraCockpit, editaCockpitPorId, getCockpitPorId, deleteCockpitPorId } from "../../../services/ModuloGerenciador/Cockpit";
import { getGrupoClientes } from "../../../services/ModuloGerenciador/GrupoClientes";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import SelectEstado from "../../../components/SelectEstado/SelectEstado";
import SelectEmbarque from "../../../components/SelectEmbarque/SelectEmbarque";
import TextLink from "../../../components/TextLink/TextLink";
import { Box, LinearProgress } from "@mui/material";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";

function Cockpit(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [listaGrupos, setListaGrupos] = useState([]);

    const [disable, setDisable] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const [valuesSelect, setValuesSelect] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [loadingSelectGrupoCliente, setLoadingSelectGrupoCliente] = useState(false);

    const [openCadastroGrupoCliente, setOpenCadastroGrupoCliente] = useState(false);

    const handleCloseCadastroGrupoCliente = (value) => {
        if (idAtual) {
            setOpenCadastroGrupoCliente(false);
        } else {
            setLoadingSelectGrupoCliente(true);
            buscarGrupoClientesSemLoading();
            setOpenCadastroGrupoCliente(false);
        }
    };

    const navigate = useNavigate();

    const buscarCockpit = async () => {
        setLoading(true);
        await getCockpit(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarGrupoClientes = async () => {
        setLoading(true);
        await getGrupoClientes(dadosUsuarioRedux.token).then((result) => {
            setListaGrupos(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarGrupoClientesSemLoading = async () => {
        await getGrupoClientes(dadosUsuarioRedux.token).then((result) => {
            setListaGrupos(result.data);
            setLoadingSelectGrupoCliente(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.projetado !== "" && values.estadoDestino !== "" && values.estadoOrigem !== "" && values.grupoClienteId !== "")
            && (validator.projetado === "true" && validator.estadoDestino === "true" && validator.estadoOrigem === "true" && validator.grupoCliente === "true")) {
            await cadastraCockpit(values, dadosUsuarioRedux.token).then((result) => {
                setLoading(true);
                if (result.status === 200) {
                    setTelaCadastro(false);
                    setValues({});
                    setValuesSelect({});
                    buscarCockpit();
                    setDadosRequestControllerRedux("Fechar Modal Cockpit");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaCockpitPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCockpit();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const preencherDadosEditando = async (result) => {
        const grupoClienteSelecionado = listaGrupos.find(option => option.idGrupoCliente === result.data.grupoClienteId);
        if (grupoClienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                grupoCliente: {
                    value: grupoClienteSelecionado.nome,
                    label: grupoClienteSelecionado.nome,
                    id: grupoClienteSelecionado.idGrupoCliente
                }
            }));
        }
    };

    const buscarCockpitPorId = async () => {
        setLoading(true);
        await getCockpitPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    await preencherDadosEditando(result);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarCockpitPorIdModal = async (id) => {
        setLoading(true);
        await getCockpitPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
                await preencherDadosEditando(result);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarCockpitPorId = async () => {
        setLoading(true);
        await deleteCockpitPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCockpit();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, projetado: "false" }));
        setValidator(current => ({ ...current, estadoOrigem: "false" }));
        setValidator(current => ({ ...current, estadoDestino: "false" }));
        setValidator(current => ({ ...current, grupoCliente: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarCockpitPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarCockpit();
        buscarGrupoClientes();
        if (props.idAtual) {
            buscarCockpitPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Fechar Modal GrupoCliente") {
            handleCloseCadastroGrupoCliente();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
    }, [dadosRequestControllerRedux]);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Cadastrar Cockpit') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Cadastrar Cockpit') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Cockpit" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV2 selecionado="Cockpit" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div>
                                            <p className="indicador-principal">Dados de Cockpit</p>
                                            <TabelaV2 setTelaCadastro={setTelaCadastro} setEditando={setEditando} classeSelecionado="idCockpit" nomeClasse1="outer-wrapper-cidade" nomeClasse2="table-wrapper-cidade" colunas={dadosTabelaRedux.Cockpit} dados={dadosTabela} />
                                            <div className="botoes-footer">
                                                <CustomButton
                                                    classCss="custom-button-margin"
                                                    titulo="Cadastrar"
                                                    cor={dadosCoresRedux.botaoPadrao}
                                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                    valor={values}
                                                    onClick={() => { setTelaCadastro(true); }}
                                                />
                                                <CustomButton
                                                    classCss="custom-button-margin"
                                                    titulo="Editar"
                                                    cor={dadosCoresRedux.botaoPadrao}
                                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                    valor={values}
                                                    onClick={() => {
                                                        if (tabelaRedux.colunaSelecionada) {
                                                            setTelaCadastro(true);
                                                            setEditando(true);
                                                        } else {
                                                            setTextoErro("Selecione Uma Coluna");
                                                            setOpenToastErro(true);
                                                        }
                                                    }
                                                    }
                                                />
                                                <CustomButton
                                                    classCss="custom-button-margin"
                                                    titulo="Deletar"
                                                    cor={dadosCoresRedux.botaoPadrao}
                                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                    valor={values}
                                                    onClick={() => {
                                                        if (tabelaRedux.colunaSelecionada) {
                                                            setOpen(true);
                                                            setTextoSucesso("Deletado Com Sucesso");
                                                        } else {
                                                            setTextoErro("Selecione Uma Coluna");
                                                            setOpenToastErro(true);
                                                        }
                                                    }}
                                                />
                                                <CustomButton
                                                    classCss="custom-button-margin"
                                                    titulo="Obter Usuário"
                                                    cor={dadosCoresRedux.botaoPadrao}
                                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                    valor={values}
                                                    onClick={() => {
                                                        if (tabelaRedux.usuarioAlteracao) {
                                                            setOpenUsuario(true);
                                                        } else {
                                                            setTextoErro("Selecione Uma Coluna");
                                                            setOpenToastErro(true);
                                                        }
                                                    }}
                                                />
                                                <CustomButton
                                                    classCss="custom-button-margin"
                                                    titulo="Voltar"
                                                    cor={dadosCoresRedux.botaoPadrao}
                                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                    valor={values}
                                                    onClick={() => { navigate("/dashboard-cockpit"); }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-principal-2">{"Dados de Cockpit >"}</p>
                                                        <p className="indicador-secundario">{"Cadastro de Cockpit"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="principal">
                                                <InputLabel htmlFor="cockpit"><Text texto="Cockpit" /></InputLabel>
                                                <input
                                                    type="number"
                                                    className="input-text"
                                                    onChange={(e) => {
                                                        setValues(current => ({ ...current, projetado: e.target.value }));
                                                        setValidator(current => ({ ...current, projetado: "true" }));
                                                    }}
                                                    value={values.projetado}
                                                    disabled={props.desabilitar} />
                                                <SelectEstado
                                                    onChange={(e) => {
                                                        setValues(current => ({ ...current, estadoOrigem: e.value }));
                                                        setValidator(current => ({ ...current, estadoOrigem: "true" }));
                                                    }}
                                                    titulo="Estado Origem"
                                                    value={values.estadoOrigem} />
                                                <SelectEstado
                                                    onChange={(e) => {
                                                        setValues(current => ({ ...current, estadoDestino: e.value }));
                                                        setValidator(current => ({ ...current, estadoDestino: "true" }));
                                                    }}
                                                    titulo="Estado Destino"
                                                    value={values.estadoDestino} />
                                                {
                                                    loadingSelectGrupoCliente ? (
                                                        <div className="grid-item">
                                                            <Box sx={{ width: '100%', marginTop: '70px' }}>
                                                                <LinearProgress />
                                                            </Box>
                                                        </div>
                                                    ) : (
                                                        <div className="grid-item-pedido">
                                                            <InputLabel className="titulo-select-cadastro" onClick={() => {
                                                                setOpenCadastroGrupoCliente(true); setIdAtual(null); setDisable(false);
                                                            }} htmlFor="cockpit">
                                                                <TextLink className="decoration" texto="Grupo Cliente" />
                                                            </InputLabel>
                                                            <SelectEmbarque
                                                                onChange={(e) => {
                                                                    setValuesSelect(current => ({ ...current, grupoCliente: e }));
                                                                    setValues(current => ({ ...current, grupoClienteId: e.id }));
                                                                    setValidator(current => ({ ...current, grupoCliente: "true" }));
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setDisable(true);
                                                                    setOpenCadastroGrupoCliente(true);
                                                                }}
                                                                onInfoIconClick={setIdAtual}
                                                                id="idGrupoCliente"
                                                                grande={true}
                                                                value={valuesSelect.grupoCliente}
                                                                titulo="Grupo Cliente"
                                                                dadosSelect={listaGrupos}
                                                                referenciaId="nome"
                                                                referenciaItem="nome" />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="botoes-cockpit">
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButton
                                                            classCss="custom-button"
                                                            titulo="Voltar"
                                                            cor={dadosCoresRedux.botaoSecundario}
                                                            corTexto={dadosCoresRedux.textoBotaoSecundario}
                                                            value={values.cockpit}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                                {
                                                    editando ? (
                                                        <CustomButton
                                                            classCss="custom-button"
                                                            titulo="Cadastrar"
                                                            cor={dadosCoresRedux.botaoPadrao}
                                                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({}); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButton
                                                                            classCss="custom-button"
                                                                            titulo="Cadastrar"
                                                                            cor={dadosCoresRedux.botaoPadrao}
                                                                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.projetado !== "" && values.estadoDestino !== "" && values.estadoOrigem !== "" && values.grupoClienteId !== "")
                                                                                    && (validator.projetado === "true" && validator.estadoDestino === "true" && validator.estadoOrigem === "true" && validator.grupoCliente === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButton
                                                                            classCss="custom-button"
                                                                            titulo="Cadastrar"
                                                                            cor={dadosCoresRedux.botaoPadrao}
                                                                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.projetado !== "" && values.estadoDestino !== "" && values.estadoOrigem !== "" && values.grupoClienteId !== "")
                                                                                    && (validator.projetado === "true" && validator.estadoDestino === "true" && validator.estadoOrigem === "true" && validator.grupoCliente === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarCockpitPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroGrupoCliente="sim"
                                        open={openCadastroGrupoCliente}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroGrupoCliente}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Cockpit;
import { Model } from "./Base.js";

export class RotaModel extends Model {
    get defaults() {
        return {
            nome: "",
            cidadeOrigem: "",
            estadoOrigem: "",
            cidadeDestino: "",
            estadoDestino: "",
            usuario: "",
            data: "",
        };
    }
}
import { api } from "./UrlPadrao"

export const cadastraGrupoPrecificacao = async (grupoPrecificacao, token) => {
  return api.post("GrupoPrecificacao",
    grupoPrecificacao
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getGrupoPrecificacao = async (token) => {
  return api.get("GrupoPrecificacao",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getGrupoPrecificacaoPorId = async (id, token) => {
  return api.get("GrupoPrecificacao/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteGrupoPrecificacaoPorId = async (id, token) => {
  return api.delete("GrupoPrecificacao/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaGrupoPrecificacaoPorId = async (grupoPrecificacao, token) => {
  return api.put("GrupoPrecificacao",
    grupoPrecificacao,
    {
      headers: {
        'Authorization': token
      }
    })
}
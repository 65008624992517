import React, { useState, createContext } from 'react';

const valorInicial = {}
const Rastrear = createContext();

export default Rastrear;

export const RastrearRedux = ({ children }) => {
    const [dadosRastrearRedux, setDadosRastrearRedux] = useState(valorInicial);

    return (
        <Rastrear.Provider value={{
            dadosRastrearRedux,
            setDadosRastrearRedux,
        }}>
            {children}
        </Rastrear.Provider>
    );
};
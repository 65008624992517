import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalLancarMeioPagamento/ModalLancarMeioPagamento.css';
import CoresRedux from "../../contexts/Cor.js";
import TabelaRedux from "../../contexts/Tabela.js";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2.js";
import { valorMask } from "../../regex/valorMask.js";
import NewIcon from "../NewIcon/NewIcon.js";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2.js";
import RequestControllerRedux from "../../contexts/RequestController.js";
import ModalCadastro from "../ModalCadastro/ModalCadastro.tsx";
import LoadingBar from "../LoadingBar/LoadingBar.js";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaMeioPagamento: any[];
    onClose: () => void;
    onClick: () => void;
    setMeioPagamento1: (e) => void;
    setMeioPagamento2: (e) => void;
}

function ModalLancarMeioPagamento(props: SimpleDialogProps) {

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const [values, setValues] = useState<any>([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    const clearMeioPagamento = () => {
        setValuesSelect(current => ({ ...current, meioPagamento: '' }));
        setValues(current => ({ ...current, meioPagamento: '' }));
        setValues(current => ({ ...current, meioPagamentoId: null }));
    };

    const clearMeioPagamento2 = () => {
        setValuesSelect(current => ({ ...current, meioPagamento2: '' }));
        setValues(current => ({ ...current, meioPagamento2: '' }));
        setValues(current => ({ ...current, meioPagamento2Id: null }));
    };


    const [idAtual, setIdAtual] = useState(0);

    const [disable, setDisable] = useState(false);

    const [openCadastroMeioPagamento, setOpenCadastroMeioPagamento] = useState(false);

    const [loadingSelectMeioPagamento, setLoadingSelectMeioPagamento] = useState(false);

    useEffect(() => {
        const meioPagamentoSelecionado = props.listaMeioPagamento.find(option => option.nome === tabelaRedux?.row?.meioPagamento);
        if (meioPagamentoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                meioPagamento: {
                    value: meioPagamentoSelecionado.nome,
                    label: meioPagamentoSelecionado.nome,
                    id: meioPagamentoSelecionado.idMeioPagamento
                }
            }));
            setValues(current => ({ ...current, meioPagamentoId: meioPagamentoSelecionado.idMeioPagamento }));
            props.setMeioPagamento1(meioPagamentoSelecionado.idMeioPagamento);
        }
        const meioPagamento2Selecionado = props.listaMeioPagamento.find(option => option.nome === tabelaRedux?.row?.meioPagamento2);
        if (meioPagamento2Selecionado) {
            setValuesSelect(current => ({
                ...current,
                meioPagamento2: {
                    value: meioPagamento2Selecionado.nome,
                    label: meioPagamento2Selecionado.nome,
                    id: meioPagamento2Selecionado.idMeioPagamento2
                }
            }));
            setValues(current => ({ ...current, meioPagamento2Id: meioPagamento2Selecionado.idMeioPagamento2 }));
            props.setMeioPagamento2(meioPagamento2Selecionado.idMeioPagamento2);
        }
    }, [tabelaRedux]);

    useEffect(() => {
        setLoadingSelectMeioPagamento(true);
        setTimeout(() => {
            setLoadingSelectMeioPagamento(false);
        }, 1000);
    }, [props.listaMeioPagamento]);

    const handleCloseCadastroMeioPagamento = () => {
        if (idAtual) {
            setOpenCadastroMeioPagamento(false);
            setDadosRequestControllerRedux("Close MeioPagamento");
        } else {
            setDadosRequestControllerRedux("Close MeioPagamento");
            setOpenCadastroMeioPagamento(false);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-pagamento">
                        <DialogContentText id="alert-dialog-description">
                            <div className="usuario-data-row">
                                <div className="usuario-data-row">
                                    {
                                        loadingSelectMeioPagamento ? (
                                            <LoadingBar />
                                        ) : (
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb">Meio Pagamento:</p>
                                                    <SelectEmbarqueV2
                                                        classeCss={161}
                                                        onChange={(e) => {
                                                            setValuesSelect(current => ({ ...current, meioPagamento: e }));
                                                            setValues(current => ({ ...current, meioPagamento: e.value }));
                                                            setValues(current => ({ ...current, meioPagamentoId: e.id }));
                                                            props.setMeioPagamento1(e.id);
                                                        }}
                                                        clearSelection={clearMeioPagamento}
                                                        onInfoIconClick={setIdAtual}
                                                        pequeno={true}
                                                        id="idMeioPagamento"
                                                        value={valuesSelect.meioPagamento}
                                                        titulo="Meio de Pagamento"
                                                        dadosSelect={props.listaMeioPagamento}
                                                        referenciaId="nome"
                                                        referenciaItem="nome" />
                                                </div>
                                                <NewIcon
                                                    onClick={(e) => {
                                                        setOpenCadastroMeioPagamento(true); setIdAtual(0); setDisable(false);
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        loadingSelectMeioPagamento ? (
                                            <LoadingBar />
                                        ) : (
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb">Meio Pagamento 2:</p>
                                                    <SelectEmbarqueV2
                                                        classeCss={161}
                                                        onChange={(e) => {
                                                            setValuesSelect(current => ({ ...current, meioPagamento2: e }));
                                                            setValues(current => ({ ...current, meioPagamento2: e.value }));
                                                            setValues(current => ({ ...current, meioPagamento2Id: e.id }));
                                                            props.setMeioPagamento2(e.id);
                                                        }}
                                                        clearSelection={clearMeioPagamento2}
                                                        onInfoIconClick={setIdAtual}
                                                        pequeno={true}
                                                        id="idMeioPagamento"
                                                        value={valuesSelect.meioPagamento2}
                                                        titulo="Meio de Pagamento 2"
                                                        dadosSelect={props.listaMeioPagamento}
                                                        referenciaId="nome"
                                                        referenciaItem="nome" />
                                                </div>
                                                <NewIcon
                                                    onClick={(e) => {
                                                        setOpenCadastroMeioPagamento(true); setIdAtual(0); setDisable(false);
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <div>
                <ModalCadastro
                    titulo="Cadastro"
                    tituloConfirmar="OK"
                    cadastroMeioPagamento="sim"
                    open={openCadastroMeioPagamento}
                    idAtual={idAtual}
                    desabilitar={disable}
                    onClose={handleCloseCadastroMeioPagamento}
                />
            </div>
        </div>
    );
}

export default ModalLancarMeioPagamento;
import { api } from "./UrlPadrao"

export const cadastraTipoVeiculo = async (tipoVeiculo, token) => {
  return api.post("tipoVeiculo",
    tipoVeiculo
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoVeiculo = async (token) => {
  return api.get("tipoVeiculo",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoVeiculoPorId = async (id, token) => {
  return api.get("tipoVeiculo/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTipoVeiculoPorId = async (id, token) => {
  return api.delete("tipoVeiculo/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTipoVeiculoPorId = async (tipoVeiculo, token) => {
  return api.put("tipoVeiculo",
    tipoVeiculo,
  {
    headers: {
      'Authorization': token
    }
  })
}
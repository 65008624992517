import { api } from "../ModuloGerenciador/UrlPadrao"

export const getSolicitacao = async (token) => {
    return api.get("solicitacao/buscarSolicitacoes",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getNumeroSolicitacoes = async (token) => {
    return api.get("solicitacao/numeroSolicitacoes",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const salvaSolicitacao = async (solicitacao, tenant) => {
    return api.post("solicitacao/salvaSolicitacao", solicitacao,
        {
            headers: {
                'X-Tenant': tenant
            }
        })
}

export const salvaSenha = async (senha, codigo) => {
    return api.patch("solicitacao/salvaSenha/" + senha + '/' + codigo)
}

export const recusaSolicitacao = async (solicitacao, token) => {
    return api.post("solicitacao/recusaSolicitacao",
        solicitacao
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const aceitaSolicitacao = async (solicitacao, token) => {
    return api.post("solicitacao/aceitaSolicitacao",
        solicitacao
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const mandarCodigoEsqueciSenha = async (email, tenant) => {
    return api.post("solicitacao/mandarCodigoEsqueciSenha/" + email, null,
        {
            headers: {
                'X-Tenant': tenant
            }
        })
}

export const salvaSenhaEsqueci = async (senha, codigo, tenant) => {
    return api.patch("solicitacao/salvaSenhaEsqueci/" + senha + '/' + codigo), null,
    {
        headers: {
            'X-Tenant': tenant
        }
    }
}

export const mandarCodigoAlterarSenha = async (email, token) => {
    return api.post("solicitacao/alterarSenha/" + email,
        null, {
        headers: {
            'Authorization': token
        }
    })
}

export const alterarSenha = async (senha, codigo, token) => {
    return api.patch("solicitacao/alteraSenha/" + senha + '/' + codigo,
        null, {
        headers: {
            'Authorization': token
        }
    })
}

export const alterarSenhaDentroSistema = async (usuario, senhaAtual, novaSenha, token) => {
    return api.post("usuario/alterarSenha/" + usuario + '/' + senhaAtual + '/' + novaSenha,
        null, {
        headers: {
            'Authorization': token
        }
    })
}
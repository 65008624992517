import { Model } from "./Base.js";

export class MotoristaModel extends Model {
    get defaults() {
        return {
            nome: "",
            telefone: "",
            nomeEmergencia: "",
            telefoneEmergencia: "",
            frota: false,
            usuario: ""
        };
    }
}
import React, { useContext, useState, useEffect } from "react";
import NavbarV2 from "../../../../components/NavbarV2/NavbarV2";
import InputLabel from "@mui/material/InputLabel";
import "./Notificacoes.css";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import Tabela from "../../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import { getNotificacao, cadastraNotificacao, visualizaNotificacao, getNotificacaoPorId, getNotificacaoPorUsuario } from "../../../../services/ModuloGerenciador/Notificacoes";
import Text from "../../../../components/Text/Text";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon";

function Notificacoes() {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [frota, setFrota] = useState(['Sim', 'Não']);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const tokenApi = dadosUsuarioRedux.token;

    const buscarNotificacoes = async () => {
        setLoading(true);
        await getNotificacao(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        setLoading(true);
        await cadastraNotificacao(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarNotificacoes();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarNotificacoesPorId = async () => {
        setLoading(true);
        await getNotificacaoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }


    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarNotificacoesPorId();
        }
    }, [editando]);


    useEffect(() => {
        buscarNotificacoes();
    }, []);
    
    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Notificações') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra == 'Notificações') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV2 selecionado="Notificacoes" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV2 selecionado="Notificacoes" />
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Notificações</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                classeSelecionado="notificacaoid"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.Notificacao}
                                                dados={dadosTabela}
                                                breakTabela="table-break"
                                                breakTd="td-break" />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="indicadores">
                                                <p className="indicador-emb">{"Dados de Notificacoes / "}</p>
                                                <p className="indicador-emb">{"Cadastro de Notificacoes"}</p>
                                            </div>
                                            <div className="flex">
                                                <div className="flex-emb">
                                                    <HeaderCadastro texto="Cadastro de Notificações" />
                                                    <CloseIcon onClick={() => {
                                                        setValues({});
                                                        setEditando(false);
                                                        setTabelaRedux({});
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="principal-notificacoes">
                                                <div className="grid-container">
                                                    <div className="grid-item-start">
                                                        <p className="input-label-emb" htmlFor="descricao">Título:</p>
                                                        <input
                                                            type="text"
                                                            className="input-emb"
                                                            style={{ width: '250px' }}
                                                            onChange={(e) => setValues(current => ({ ...current, titulo: e.target.value }))}
                                                            value={values.titulo} />
                                                    </div>
                                                    <div className="grid-item-start">
                                                        <p className="input-label-emb" htmlFor="descricao">Mensagem:</p>
                                                        <input
                                                            type="text"
                                                            className="input-emb"
                                                            style={{ width: '250px' }}
                                                            onChange={(e) => setValues(current => ({ ...current, mensagem: e.target.value }))}
                                                            value={values.mensagem} />
                                                    </div>
                                                    <div className="grid-item-start">
                                                        <p className="input-label-emb" htmlFor="descricao">Ação:</p>
                                                        <input
                                                            type="text"
                                                            className="input-emb"
                                                            style={{ width: '250px' }}
                                                            onChange={(e) => setValues(current => ({ ...current, acao: e.target.value }))}
                                                            value={values.acao} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-notificacoes">
                                                <CustomButtonV2
                                                    classCss="custom-button-cancelar"
                                                    classText="button-texto-cancelar"
                                                    titulo="Cancelar"
                                                    cor="#0946B8"
                                                    valor={values}
                                                    onClick={() => { setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                />
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setTelaCadastro(false); setEditando(false); setValues({}); }}
                                                        />
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { cadastrar(); setTelaCadastro(false); setValues({}); }}
                                                        />
                                                    )
                                                }

                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Notificacoes;
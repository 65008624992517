import React, { useState, createContext } from "react";

const valorInicial = {};

const ContextoEmbarque = createContext();

export default ContextoEmbarque;

export const DadosContextoEmbarqueRedux = ({ children }) => {
    const [dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux] = useState(valorInicial);

    return (
        <ContextoEmbarque.Provider value={{
            dadosContextoEmbarqueRedux,
            setDadosContextoEmbarqueRedux,
        }}>
            {children}
        </ContextoEmbarque.Provider>
    );
};
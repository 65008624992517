import { api } from "../ModuloGerenciador/UrlPadrao"

export const getFrota = async (token) => {
    return api.get("gerenciamentoFrota/frota",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getVeiculosVencidos = async (token) => {
    return api.get("gerenciamentoFrota/veiculosVencidos",
        {
            headers: {
                'Authorization': token
            }
        })
}

export const checkListCaminhao = async (id, token) => {
    return api.get("gerenciamentoFrota/checkListCaminhao/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const checkListCaminhaoTemplete = async (id, token) => {
    return api.get("gerenciamentoFrota/checkListCaminhaoTemplete/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const checkListCarretaTemplete = async (id, token) => {
    return api.get("gerenciamentoFrota/checkListCarretaTemplete/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const checkListCarreta = async (id, token) => {
    return api.get("gerenciamentoFrota/checkListCarreta/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const checkListCaminhaoVencido = async (id, token) => {
    return api.get("gerenciamentoFrota/checkListCaminhaoVencido/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const checkListCarretaVencido = async (id, token) => {
    return api.get("gerenciamentoFrota/checkListCarretaVencido/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const vinculaCaminhao = async (vinculo, id, token) => {
    return api.post("gerenciamentoFrota/salvaVinculoCaminhao/" + id,
        vinculo,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const vinculaCarreta = async (vinculo, id, token) => {
    return api.post("gerenciamentoFrota/salvaVinculoCarreta/" + id,
        vinculo,
        {
            headers: {
                'Authorization': token
            }
        })
}

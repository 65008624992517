import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraUsuarioTabela = async (usuarioTabela, token) => {
  return api.post("usuarioTabela",
    usuarioTabela
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getUsuarioTabela = async (token) => {
  return api.get("usuarioTabela",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getUsuarioTabelaPorId = async (id, token) => {
  return api.get("usuarioTabela/buscaUsuarioId/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteUsuarioTabelaPorId = async (id, token) => {
  return api.delete("usuarioTabela/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaUsuarioTabelaPorId = async (usuarioTabela, token) => {
  return api.put("usuarioTabela",
    usuarioTabela,
  {
    headers: {
      'Authorization': token
    }
  })
}
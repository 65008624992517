import React, { useContext, useEffect, useState } from "react";
import "./TabelaLow.css";
import iconDeleteLow from "../../images/iconDeleteLow.png";
import iconOpcoesLow from "../../images/iconOpcoesLow.png";
import iconeCalendario from "../../images/iconeCalendario.png";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import Tooltip from '@mui/material/Tooltip';

function TabelaLow(props) {
    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const [selecioneOpen, setSelecioneOpen] = useState(false);
    const [opcoesOpenIndex, setOpcoesOpenIndex] = useState(null);

    const [expandedRow, setExpandedRow] = useState(null);

    const toggleExpandRow = (index) => {
        if (expandedRow === index) {
            setExpandedRow(null);
        } else {
            setExpandedRow(index);
        }
    };

    const handleMouseLeave = (index) => {
        setOpcoesOpenIndex(null);
    };

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };

    const colunasFiltradas = props.colunas.filter(coluna =>
        ["cliente", "empresa", "filial", "origem", "destino", "pedido", "cte", "previsaoEmbarque", "previsaoEntrega", "status"].includes(coluna.field)
    );

    useEffect(() => {
        if (props.dados && props.dados.length > 0 && props.dados[0] && props.dados[0].idEmbarque) {
            const tableWrapper = document.getElementById("tableWrapper");
            if (tableWrapper && (window.location.href.endsWith("embarque") && dadosContextoEmbarqueRedux != 'templateEmbarque')) {
                tableWrapper.scrollTop = tableWrapper.scrollHeight;
            }
        }
    }, []);

    return (
        <div class="outer-wrapper-low">
            <div class="table-wrapper-low" id="tableWrapper">
                <table className="tabela-low">
                    <thead>
                        <tr>
                            {colunasFiltradas.map((coluna, index) => (
                                <th className="th-low" key={index}>{coluna.nomeColuna}</th>
                            ))}
                            <th className="th-low">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.dados.map((linha, index) => (
                            <>
                                <tr onMouseLeave={() => { handleMouseLeave(index); props.setOpcoesOpen(false); }}
                                    onClick={() => toggleExpandRow(index)}
                                    onDoubleClick={() => {
                                        setTabelaRedux({ colunaSelecionada: linha.idEmbarque, usuarioAlteracao: linha.usuario, dataAlteracao: linha.data, row: linha });
                                        props.setTelaCadastro(true);
                                        props.setEditando(true);
                                    }}
                                    className="tr-hover"
                                    key={index}> {/* Linha de dados com hover */}
                                    {colunasFiltradas.map((coluna) => (
                                        <td className="td-low" key={coluna.field}>
                                            {coluna.field === "previsaoEmbarque" || coluna.field === "previsaoEntrega" ? formatarData(linha[coluna.field]) : linha[coluna.field]}
                                        </td>
                                    ))}
                                    <td className="td-low">
                                        <div style={{ gap: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Tooltip title="Deletar">
                                                <img className="pointer" src={iconDeleteLow} alt="Delete"
                                                    onClick={() => {
                                                        setTabelaRedux({ colunaSelecionada: linha.idEmbarque, usuarioAlteracao: linha.usuario, dataAlteracao: linha.data, row: linha });
                                                        props.setOpen(true);
                                                        props.setTextoSucesso("Deletado Com Sucesso");
                                                    }} />
                                            </Tooltip>
                                            <div className="hover-emb">
                                                {opcoesOpenIndex === index && (
                                                    <div className="config-dropdown-low">
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            width='150px'
                                                            marginBottom='5px'
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            onClick={() => { props.setTelaCadastro(true); props.setEditando(false); }}
                                                        />
                                                        <CustomButtonV2
                                                            classCss="custom-button-editar"
                                                            width='150px'
                                                            marginBottom='5px'
                                                            classText="button-texto-editar"
                                                            titulo="Editar"
                                                            icone="iconeEdit"
                                                            cor="#CDCDCD"
                                                            onClick={() => {
                                                                if (tabelaRedux.colunaSelecionada) {
                                                                    props.setTelaCadastro(true);
                                                                    props.setEditando(true);
                                                                } else {
                                                                    props.setTextoErro("Selecione Uma Coluna");
                                                                    props.setOpenToastErro(true);
                                                                }
                                                            }
                                                            }
                                                        />
                                                        <CustomButtonV2
                                                            classCss="custom-button-user"
                                                            width='150px'
                                                            marginBottom='5px'
                                                            classText="button-texto-user"
                                                            titulo="Obter Usuário"
                                                            cor="#FFFFFF"
                                                            onClick={() => {
                                                                if (tabelaRedux.usuarioAlteracao) {
                                                                    props.setOpenUsuario(true);
                                                                } else {
                                                                    props.setTextoErro("Selecione Uma Coluna");
                                                                    props.setOpenToastErro(true);
                                                                }
                                                            }}
                                                        />
                                                        {
                                                            dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-historico"
                                                                    width='150px'
                                                                    marginBottom='5px'
                                                                    classText="button-texto-user"
                                                                    titulo="Histórico"
                                                                    cor="#FFFFFF"
                                                                    onClick={() => {
                                                                        if (tabelaRedux.colunaSelecionada) {
                                                                            props.buscarEmbarquePorId(true);
                                                                        } else {
                                                                            props.setTextoErro("Selecione Uma Coluna");
                                                                            props.setOpenToastErro(true);
                                                                        }
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span></span>
                                                            )
                                                        }
                                                        <CustomButtonV2
                                                            classCss="custom-button-user"
                                                            classText="button-texto-user"
                                                            titulo="Ações"
                                                            cor="#FFFFFF"
                                                            onClick={() => {
                                                                if (tabelaRedux.colunaSelecionada) {
                                                                    props.setOpcoesOpen(!props.opcoesOpen);
                                                                } else {
                                                                    props.setSelecioneOpen(!selecioneOpen);
                                                                }
                                                            }}
                                                        />
                                                        <div className="hover-emb">
                                                            {props.opcoesOpen && (
                                                                <div className="config-dropdown-low2">
                                                                    {props.acoesData.map((acao, index) => {
                                                                        if (acao.titulo === 'Motivo Embarque' && tabelaRedux?.row?.necessitaMotivoEmbarque) {
                                                                            return (
                                                                                <p key={index} onClick={acao.onClick}>
                                                                                    {acao.titulo}
                                                                                </p>
                                                                            );
                                                                        } else if (acao.titulo === 'Motivo Entrega' && tabelaRedux?.row?.necessitaMotivoEntrega) {
                                                                            return (
                                                                                <p key={index} onClick={acao.onClick}>
                                                                                    {acao.titulo}
                                                                                </p>
                                                                            );
                                                                        } else if (acao.titulo !== 'Motivo Embarque' && acao.titulo !== 'Motivo Entrega') {
                                                                            return (
                                                                                <p key={index} onClick={acao.onClick}>
                                                                                    {acao.titulo}
                                                                                </p>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                                </div>
                                                            )}
                                                            {selecioneOpen &&
                                                                <div className="config-dropdown-none">
                                                                    <p>Selecione Uma Coluna</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                                <img
                                                    onClick={() => {
                                                        setOpcoesOpenIndex(opcoesOpenIndex === index ? null : index);
                                                        setTabelaRedux({ colunaSelecionada: linha.idEmbarque, usuarioAlteracao: linha.usuario, dataAlteracao: linha.data, row: linha });
                                                    }} src={iconOpcoesLow} alt="Opções" />
                                            </div>
                                        </div>
                                    </td>
                                </tr >
                                {expandedRow === index && (
                                    <tr className="info-row" key={`info-${index}`}> {/* Linha para informações adicionais */}
                                        <td colSpan={colunasFiltradas.length + 1}> {/* Ajuste o colSpan conforme o número de colunas */}
                                            <div className="info-div">
                                                <span className="title-low">Dados de Embarque:</span>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Empresa:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.empresa}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Filial:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.filial}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Unidade de Embarque:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.unidEmbarque}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Peso:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.peso}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Frota:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.frota}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Cte:</p>
                                                            <input disabled={true} style={{ width: '252px' }} className="input-low" value={linha.cte}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                            <input disabled={true} style={{ width: '775px' }} className="input-low" value={linha.observacao}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Status:</p>
                                                            <input disabled={true} style={{ width: '252px' }} className="input-low" value={linha.status}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="title-low">Dados Pedido:</span>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Pedido:</p>
                                                            <input disabled={true} style={{ width: '223px' }} className="input-low" value={linha.pedido}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Classificação:</p>
                                                            <input disabled={true} style={{ width: '223px' }} className="input-low" value={linha.classificacao}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Cliente:</p>
                                                            <input disabled={true} style={{ width: '223px' }} className="input-low" value={linha.cliente}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Destinatário:</p>
                                                            <input disabled={true} style={{ width: '222.111px' }} className="input-low" value={linha.destinatario}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Embarcador:</p>
                                                            <input disabled={true} style={{ width: '223px' }} className="input-low" value={linha.embarcador}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Km:</p>
                                                            <input disabled={true} style={{ width: '133.894px' }} className="input-low" value={linha.km}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Frete Motorista:</p>
                                                            <input disabled={true} style={{ width: '166px' }} className="input-low" value={linha.freteMotorista}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Pedágio:</p>
                                                            <input disabled={true} style={{ width: '160px' }} className="input-low" value={linha.pedagio}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Frete Fiscal:</p>
                                                            <input disabled={true} style={{ width: '160px' }} className="input-low" value={linha.freteFiscal}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">ICMS:</p>
                                                            <input disabled={true} style={{ width: '160px' }} className="input-low" value={linha.icms}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Rota:</p>
                                                            <input disabled={true} style={{ width: '610px' }} className="input-low" value={linha.rota}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="title-low">Datas:</span>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Data e Hora Chegada Embarque:</p>
                                                            <input disabled={true} style={{ width: '307.25px' }} className="input-low" value={formatarData(linha.dataHoraChegadaEmbarque)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Data e Hora Embarcado::</p>
                                                            <input disabled={true} style={{ width: '307.25px' }} className="input-low" value={formatarData(linha.dataHoraEmbarcado)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <img src={iconeCalendario} alt="Calendar" />
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Data e Hora Chegada Entrega:</p>
                                                            <input disabled={true} style={{ width: '307.25px' }} className="input-low" value={formatarData(linha.dataHoraChegadaEntrega)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Data e Hora Entregue:</p>
                                                            <input disabled={true} style={{ width: '307.25px' }} className="input-low" value={formatarData(linha.dataHoraEntregue)}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="title-low">Dados do Veículo:</span>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Placa do Cavalo:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.placaCavalo}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Proprietário:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.proprietario}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Placa da Carreta:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.placaCarreta}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Motorista:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.motorista}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Placa Segunda Carreta:</p>
                                                            <input disabled={true} style={{ width: '251.8px' }} className="input-low" value={linha.placaCarreta2}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="title-low">Dados Financeiros:</span>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Adicional Empresa:</p>
                                                            <input disabled={true} style={{ width: '208.333px' }} className="input-low" value={linha.adicionalEmpresa}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Adicional Motorista:</p>
                                                            <input disabled={true} style={{ width: '208.333px' }} className="input-low" value={linha.adicionalMotorista}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Adiantamento:</p>
                                                            <input disabled={true} style={{ width: '208.333px' }} className="input-low" value={linha.adiantamento}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Aceite:</p>
                                                            <input disabled={true} style={{ width: '208.333px' }} className="input-low" value={linha.aceite}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Meio de Pagamento:</p>
                                                            <input disabled={true} style={{ width: '208.333px' }} className="input-low" value={linha.meioPagamento}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Meio de Pagamento 2:</p>
                                                            <input disabled={true} style={{ width: '207.333px' }} className="input-low" value={linha.meioPagamento2}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="title-low">Geral:</span>
                                                <div className="row-low">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Embalagem:</p>
                                                            <input disabled={true} style={{ width: '210px' }} className="input-low" value={linha.embalagem}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Mercadoria:</p>
                                                            <input disabled={true} style={{ width: '210px' }} className="input-low" value={linha.mercadoria}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Load:</p>
                                                            <input disabled={true} style={{ width: '210px' }} className="input-low" value={linha.load}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Margem:</p>
                                                            <input disabled={true} style={{ width: '100px' }} className="input-low" value={linha.margem}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Comprovante:</p>
                                                            <input disabled={true} style={{ width: '100px' }} className="input-low" value={linha.comprovante === true ? 'Sim' : 'Não'}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Check-in:</p>
                                                            <input disabled={true} style={{ width: '100px' }} className="input-low" value={linha.checkInFeito === true ? 'Sim' : 'Não'}></input>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Motivo Cancelamento da Carga:</p>
                                                            <input disabled={true} style={{ width: '311px' }} className="input-low" value={linha.motivo}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))}
                    </tbody>

                </table>
            </div>
        </div >
    );
}

export default TabelaLow;
import "./PoliticaPrivacidade.css";

function PoliticaPrivacidade() {
    return (
        <>
            <div className="header-footer"></div>
            <div className="div-politicas-privacidade">
                <h1>Privacidade</h1>


                <h6>Ultima atualização November 28, 2023</h6>
                <h1>Termos De Uso e Política de privacidade</h1>


                <h2>VISÃO GERAL</h2>

                Esse aplicativo é operado pelo GR Localizador Em todo o aplicativo, os termos “nós”, “nos” e “nosso” se referem ao GR Localizador. O GR Localizador.net proporciona esse , incluindo todas as informações, ferramentas e serviços disponíveis deste aplicativo para você, o usuário, com a condição da sua aceitação de todos os termos, condições, políticas e avisos declarados aqui.

                Ao visitar nosso aplicativo e/ou comprar alguma coisa no nosso aplicativo, você está utilizando nossos “Serviços”. Consequentemente, você concorda com os seguintes Termos de Uso e Política de privacidade (“Termos de Uso”, “Política de privacidade”), incluindo os termos e políticas adicionais mencionados neste documento e/ou disponíveis por hyperlink. Essa Política de privacidade se aplicam a todos os usuários do aplicativo, incluindo, sem limitação, os usuários que são navegadores, fornecedores, clientes, lojistas e/ou contribuidores de conteúdo.

                Por favor, leia esses Termos de Uso e Política de privacidade cuidadosamente antes de acessar ou utilizar o nosso Aplicativo. Ao acessar ou usar qualquer parte do Aplicativo, você concorda com os Termos de Uso e Política de privacidade. Se você não concorda com todos os termos de uso e política de privacidade desse acordo, então você não pode usar o aplicativo ou usar quaisquer serviços. Se esses termos de uso e política de privacidade são considerados uma oferta, a aceitação é expressamente limitada a esses Termos de Uso e Política de privacidade.

                Quaisquer novos recursos ou ferramentas que forem adicionados ao aplicativo também devem estar sujeitos aos Termos de Uso e Política de privacidade. Você pode revisar a versão mais atual dos Termos de Uso e Política de privacidade quando quiser nesta página. Reservamos o direito de atualizar, alterar ou trocar qualquer parte desses Termos de Uso e Política de privacidade ao publicar atualizações e/ou alterações no nosso aplicatico. É sua responsabilidade verificar as alterações feitas nesta página periodicamente. Seu uso contínuo ou acesso ao aplicativo após a publicação de quaisquer alterações constitui aceitação de tais alterações.

                Nosso Aplicativo não foi publicado na PlayStore. e estará a sua disposição Pelo valor informado no ato da compra.

                <h2>SEÇÃO 1 - TERMOS DO APLICATIVO</h2>

                Ao concordar com os Termos de Uso e Política de privacidade, você confirma que você é maior de idade em seu estado ou província de residência e que você nos deu seu consentimento para permitir que qualquer um dos seus dependentes menores de idade usem esse aplicativo.

                Você não deve usar nosso aplicativo para qualquer fim ilegal ou não autorizado. Você também não pode, ao usufruir deste Serviço, violar quaisquer leis em sua jurisdição (incluindo, mas não limitado, a leis de direitos autorais).

                Você não deve transmitir nenhum vírus ou qualquer código de natureza destrutiva.

                Violar qualquer um dos Termos tem como consequência a rescisão imediata dos seus Serviços.

                <h2>SEÇÃO 2 - CONDIÇÕES GERAIS</h2>

                Reservamos o direito de recusar o serviço a qualquer pessoa por qualquer motivo a qualquer momento.

                Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do Serviço, uso do Serviço, acesso ao Serviço, ou qualquer contato no aplicativo através do qual o serviço é fornecido, sem nossa permissão expressa por escrito.

                Os títulos usados nesse acordo são incluídos apenas por conveniência e não limitam ou afetam os Termos.

                <h2>SEÇÃO 3 - PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES</h2>

                Esse aplicativo pode conter certas informações históricas. As informações históricas podem não ser atuais e são fornecidas apenas para sua referência. Reservamos o direito de modificar o conteúdo desse aplicativo a qualquer momento, mas nós não temos obrigação de atualizar nenhuma informação em nosso aplicativo. Você concorda que é de sua responsabilidade monitorar alterações no nosso aplicativo.

                <h2>SEÇÃO 4 - MODIFICAÇÕES DO SERVIÇO E PREÇOS</h2>

                Os preços dos nossos produtos são sujeitos a alterações sem notificação.

                Reservamos o direito de, a qualquer momento, modificar ou descontinuar o Serviço (ou qualquer parte ou conteúdo do mesmo) sem notificação em qualquer momento.

                Não nos responsabilizados por você ou por qualquer terceiro por qualquer modificação, alteração de preços, suspensão ou descontinuação do Serviço.

                <h2>SEÇÃO 5 - PRODUTOS OU SERVIÇOS (caso aplicável)</h2>

                Certos produtos ou serviços podem estar disponíveis exclusivamente online através do aplicativo. Tais produtos ou serviços podem ter quantidades limitadas e são sujeitos a apenas devolução ou troca, de acordo com nossa Política de devolução.

                Reservamos o direito, mas não somos obrigados, a limitar as vendas de nossos produtos ou Serviços para qualquer pessoa, região geográfica ou jurisdição. Podemos exercer esse direito conforme o caso. Reservamos o direito de limitar as quantidades de quaisquer produtos ou serviços que oferecemos. Todas as descrições de produtos ou preços de produtos são sujeitos a alteração a qualquer momento sem notificação, a nosso critério exclusivo. Reservamos o direito de descontinuar qualquer produto a qualquer momento. Qualquer oferta feita por qualquer produto ou serviço nesse site é nula onde for proibido por lei.

                Não garantimos que a qualidade de quaisquer produtos, serviços, informações ou outros materiais comprados ou obtidos por você vão atender às suas expectativas, ou que quaisquer erros no Serviço serão corrigidos.

                <h2>SEÇÃO 6 - FERRAMENTAS OPCIONAIS</h2>

                Podemos te dar acesso a ferramentas de terceiros que não monitoramos e nem temos qualquer controle.

                Você reconhece e concorda que nós fornecemos acesso a tais ferramentas ”como elas são” e “conforme a disponibilidade” sem quaisquer garantias, representações ou condições de qualquer tipo e sem qualquer endosso. Não nos responsabilizamos de forma alguma pelo seu uso de ferramentas opcionais de terceiros.

                Qualquer uso de ferramentas opcionais oferecidas através do aplicativo é inteiramente por sua conta e risco e você se familiarizar e aprovar os termos das ferramentas que são fornecidas por fornecedor(es) terceiro(s).

                Também podemos, futuramente, oferecer novos serviços e/ou recursos através do Aplicativo (incluindo o lançamento de novas ferramentas e recursos). Tais recursos e/ou serviços novos também devem estar sujeitos a esses Termos de serviço.

                <h2>SEÇÃO 7 - LINKS DE TERCEIROS</h2>

                Certos produtos, conteúdos e serviços disponíveis pelo nosso Serviço podem incluir materiais de terceiros.

                Os links de terceiros nesse aplicativo podem te direcionar para sites de terceiros que não são afiliados a nós. Não nos responsabilizamos por examinar ou avaliar o conteúdo ou precisão. Não garantimos e nem temos obrigação ou responsabilidade por quaisquer materiais ou sites de terceiros, ou por quaisquer outros materiais, produtos ou serviços de terceiros.

                Não somos responsáveis por quaisquer danos ou prejuízos relacionados com a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com quaisquer sites de terceiros. Por favor, revise com cuidado as políticas e práticas de terceiros e certifique-se que você as entende antes de efetuar qualquer transação. As queixas, reclamações, preocupações ou questões relativas a produtos de terceiros devem ser direcionadas ao terceiro.

                <h2>SEÇÃO 8 - COMENTÁRIOS, FEEDBACK, ETC. DO USUÁRIO</h2>

                Se, a nosso pedido, você enviar certos itens específicos (por exemplo, participação em um concurso), ou sem um pedido nosso, você enviar ideias criativas, sugestões, propostas, planos, ou outros materiais, seja online, por e-mail, pelo correio, ou de outra forma (em conjunto chamados de comentários), você concorda que podemos, a qualquer momento, sem restrição, editar, copiar, publicar, distribuir, traduzir e de outra forma usar quaisquer comentários que você encaminhar para nós. Não nos responsabilizamos por: (1) manter quaisquer comentários em sigilo; (2) indenizar por quaisquer comentários; ou (3) responder quaisquer comentários.

                Podemos, mas não temos a obrigação, de monitorar, editar ou remover conteúdo que nós determinamos a nosso próprio critério ser contra a lei, ofensivo, ameaçador, calunioso, difamatório, pornográfico, obsceno ou censurável ou que viole a propriedade intelectual de terceiros ou estes Termos de Uso e Política de privacidade.

                Você concorda que seus comentários não violarão qualquer direito de terceiros, incluindo direitos autorais, marcas registradas, privacidade, personalidade ou outro direito pessoal ou de propriedade. Você concorda que os seus comentários não vão conter material difamatório, ilegal, abusivo ou obsceno. Eles também não conterão nenhum vírus de computador ou outro malware que possa afetar a operação do Serviço ou qualquer site relacionado. Você não pode usar um endereço de e-mail falso, fingir ser alguém diferente de si mesmo, ou de outra forma enganar a nós ou terceiros quanto à origem de quaisquer comentários. Você é o único responsável por quaisquer comentários que você faz e pela veracidade deles. Nós não assumimos qualquer responsabilidade ou obrigação por quaisquer comentários publicados por você ou por qualquer terceiro.

                <h2>SEÇÃO 9 - INFORMAÇÕES PESSOAIS</h2>

                O envio de suas informações pessoais através do nosso aplicativo é regido pela nossa Política de privacidade. Ver nossa Política de privacidade.

                <h2>SEÇÃO 10 - ERROS, IMPRECISÕES E OMISSÕES</h2>

                Ocasionalmente, pode haver informações no nosso aplicativo ou no Serviço que contém erros tipográficos, imprecisões ou omissões que possam relacionar-se a descrições de produtos, preços, promoções, ofertas, taxas de envio do produto, o prazo de envio e disponibilidade. Reservamos o direito de corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou atualizar informações ou cancelar encomendas caso qualquer informação no Serviço ou em qualquer aplicativo relacionado seja imprecisa, a qualquer momento e sem aviso prévio (até mesmo depois de você ter enviado o seu pedido).

                Não assumimos nenhuma obrigação de atualizar, alterar ou esclarecer informações no Serviço ou em qualquer aplicativo relacionado, incluindo, sem limitação, a informações sobre preços, exceto conforme exigido por lei. Nenhuma atualização específica ou data de atualização no Serviço ou em qualquer site relacionado, deve ser utilizada para indicar que todas as informações do Serviço ou em qualquer aplicativo relacionado tenham sido modificadas ou atualizadas.

                <h2>SEÇÃO 11 - USOS PROIBIDOS</h2>

                Além de outras proibições, conforme estabelecido nos Termos de Uso e Política de privacidade, você está proibido de usar o aplicativo ou o conteúdo para: (a) fins ilícitos; (b) solicitar outras pessoas a realizar ou participar de quaisquer atos ilícitos; © violar quaisquer regulamentos internacionais, provinciais, estaduais ou federais, regras, leis ou regulamentos locais; (d) infringir ou violar nossos direitos de propriedade intelectual ou os direitos de propriedade intelectual de terceiros; (e) para assediar, abusar, insultar, danificar, difamar, caluniar, depreciar, intimidar ou discriminar com base em gênero, orientação sexual, religião, etnia, raça, idade, nacionalidade ou deficiência; (f) apresentar informações falsas ou enganosas; (g) fazer o envio ou transmitir vírus ou qualquer outro tipo de código malicioso que será ou poderá ser utilizado para afetar a funcionalidade ou operação do Serviço ou de qualquer site relacionado, outros sites, ou da Internet; (h) coletar ou rastrear as informações pessoais de outras pessoas; (i) para enviar spam, phishing, pharm, pretext, spider, crawl, ou scrape; (j) para fins obscenos ou imorais; ou (k) para interferir ou contornar os recursos de segurança do Serviço ou de qualquer site relacionado, outros sites, ou da Internet. Reservamos o direito de rescindir o seu uso do Serviço ou de qualquer aplicativo relacionado por violar qualquer um dos usos proibidos.

                <h2>SEÇÃO 12 - ISENÇÃO DE RESPONSABILIDADE DE GARANTIAS; LIMITAÇÃO DE RESPONSABILIDADE</h2>

                Nós não garantimos, representamos ou justificamos que o seu uso do nosso serviço será pontual, seguro, sem erros ou interrupções.

                Não garantimos que os resultados que possam ser obtidos pelo uso do serviço serão precisos ou confiáveis.

                Você concorda que de tempos em tempos, podemos remover o serviço por períodos indefinidos de tempo ou cancelar a qualquer momento, sem te notificar.

                Em nenhuma circunstância o GR Localizador, nossos diretores, oficiais, funcionários, afiliados, agentes, contratantes, estagiários, fornecedores, prestadores de serviços ou licenciadores serão responsáveis por qualquer prejuízo, perda, reclamação ou danos diretos, indiretos, incidentais, punitivos, especiais ou consequentes de qualquer tipo, incluindo, sem limitação, lucros cessantes, perda de receita, poupanças perdidas, perda de dados, custos de reposição, ou quaisquer danos semelhantes, seja com base em contrato, ato ilícito (incluindo negligência), responsabilidade objetiva ou de outra forma, decorrentes do seu uso de qualquer um dos serviços ou quaisquer produtos adquiridos usando o serviço, ou para qualquer outra reclamação relacionada de alguma forma ao seu uso do serviço ou qualquer produto, incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer conteúdo, ou qualquer perda ou dano de qualquer tipo como resultado do uso do serviço ou qualquer conteúdo (ou produto) publicado, transmitido ou de outra forma disponível através do serviço, mesmo se alertado ​​de tal possibilidade. Como alguns estados ou jurisdições não permitem a exclusão ou a limitação de responsabilidade por danos consequentes ou incidentais, em tais estados ou jurisdições, a nossa responsabilidade será limitada à extensão máxima permitida por lei.

                <h2>SEÇÃO 13 - INDENIZAÇÃO</h2>

                Você concorda em indenizar, defender e isentar GR Localizador e nossos subsidiários, afiliados, parceiros, funcionários, diretores, agentes, contratados, licenciantes, prestadores de serviços, subcontratados, fornecedores, estagiários e funcionários, de qualquer reclamação ou demanda, incluindo honorários de advogados, por quaisquer terceiros devido à violação destes Termos de Uso e Política de privacidade ou aos documentos que incorporam por referência, ou à violação de qualquer lei ou os direitos de um terceiro.

                <h2>SEÇÃO 14 - INDEPENDÊNCIA</h2>

                No caso de qualquer disposição destes Termos de Uso e Política de privacidade ser considerada ilegal, nula ou ineficaz, tal disposição deve, contudo, ser aplicável até ao limite máximo permitido pela lei aplicável, e a porção inexequível será considerada separada desses Termos de Uso e Política de privacidade. Tal determinação não prejudica a validade e aplicabilidade de quaisquer outras disposições restantes.

                <h2>SEÇÃO 15 - RESCISÃO</h2>

                As obrigações e responsabilidades das partes incorridas antes da data de rescisão devem continuar após a rescisão deste acordo para todos os efeitos.

                Estes Termos de Uso e Política de privacidade estão em vigor, a menos que seja rescindido por você ou por nós. Você pode rescindir estes Termos de Uso e Política de privacidade a qualquer momento, notificando-nos que já não deseja utilizar os nossos serviços, ou quando você deixar de usar o nosso aplicativo.

                Se em nosso critério exclusivo você não cumprir com qualquer termo ou disposição destes Termos de Uso e Política de privacidade, nós também podemos rescindir este contrato a qualquer momento sem aviso prévio e você ficará responsável por todas as quantias devidas até a data da rescisão; também podemos lhe negar acesso a nossos Serviços (ou qualquer parte deles).

                <h2>SEÇÃO 16 - ALTERAÇÕES DOS TERMOS DE SERVIÇO</h2>

                Você pode rever a versão mais atual dos Termos de Uso e Política de privacidade a qualquer momento nessa página.

                Reservamos o direito, a nosso critério, de atualizar, modificar ou substituir qualquer parte destes Termos de Uso e Política de privacidade ao publicar atualizações e alterações no nosso aplicativo. É sua responsabilidade verificar nosso aplicativo periodicamente. Seu uso contínuo ou acesso ao nosso aplicativo ou ao Serviço após a publicação de quaisquer alterações a estes Termos de serviço constitui aceitação dessas alterações.

                <h2>SEÇÃO 17 - COLETA E USO DE INFORMAÇOES</h2>

                Para aprimorar a experiência de uso do nosso Serviço, podemos solicitar que você forneça certas informações de identificação pessoal. Além das informações previamente mencionadas, agora incluímos a coleta de dados de localização. Esses dados são essenciais para oferecer funcionalidades específicas do nosso serviço e para traçar padrões de uso que ajudam na melhoria contínua do aplicativo.

                <br/><b>Coleta de Dados de Localização:</b>

                <br/> - Uso da Localização: Coletamos dados de localização do seu dispositivo para fornecer e melhorar serviços personalizados baseados em sua localização atual. Esses dados nos ajudam a entender padrões de mobilidade e preferências, permitindo aprimoramentos específicos e personalização do serviço.

                <br/> - Consentimento: A coleta de dados de localização será realizada apenas com o seu consentimento explícito. Você pode desativar a qualquer momento o acesso do aplicativo à sua localização nas configurações do seu dispositivo.

                <br/> - Retenção de Dados: As informações de localização são retidas apenas enquanto forem necessárias para fornecer o serviço solicitado e para análises internas.

                <h2>SEÇÃO 18 - INFORMAÇÕES DE CONTATO</h2>

                As perguntas sobre os Termos de uso e política de privacidade devem ser enviadas para nós através do c1010sistemas@gmail.com
            </div>
            <div className="header-footer"></div>
        </>
    );
}

export default PoliticaPrivacidade;
import { Model } from "./Base.js";

export class EmbalagemModel extends Model {
    get defaults() {
        return {
            nome: "",
            usuario: "",
            data: "",
        };
    }
}
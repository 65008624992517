import { api } from "./UrlPadrao"

export const cadastraCockpit = async (cockpit, token) => {
  return api.post("cockpit",
    cockpit
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getCockpit = async (token) => {
  return api.get("cockpit",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCockpitPorId = async (id, token) => {
  return api.get("cockpit/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteCockpitPorId = async (id, token) => {
  return api.delete("cockpit/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCockpitPorId = async (cockpit, token) => {
  return api.put("cockpit",
    cockpit,
  {
    headers: {
      'Authorization': token
    }
  })
}
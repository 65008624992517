import React, { useEffect, useState } from "react";
import "./NewIcon.css";
import iconeNovo from "../../images/iconeNovo.png";

function NewIcon({ onClick }) {

    return (
        <div className="hover-emb">
            <div className="tooltip-novo">
                <p className="texto-novo">Adicionar</p>
            </div>
            <img
                className="icone-novo"
                src={iconeNovo}
                alt="Novo"
                onClick={onClick}
            />
        </div>
    );
}

export default NewIcon;